export default {
    data: function() {
        return {
            listSetting: [],
            blockSelected: {},
            idSl: "",
            itemShow: ""
        }
    },
    components: {
    },
    watch: {
        "$store.state.editor.blockSelected": function (val) {
            if (val && val.id && val.id != this.idSl) {
                this.itemShow = "";
                this.idSl = val ? val.id : "";
                this.blockSelected = $.extend(true, {}, val);
                $(".item-q-setting").hide();
                $(".item-q-setting").hide();
            } else {
                if (val && val.id == this.idSl) {
                } else {
                    this.idSl = "";
                    this.blockSelected = undefined;
                    this.listSetting = []
                }
            }
        },
    },
    methods: {
        duplicateBlock: function () {
            if (!this.blockSelected) return;
            this.$store.commit("editor/setActionEditor", {
                type: "duplicateBlock",
                value: this.blockSelected
            });
        },
        moveUpBlock() {
            if (!this.blockSelected) return;
            this.$emit("moveUpBlock", this.blockSelected);
        },
        moveDownBlock() {
            if (!this.blockSelected) return;
            this.$emit("moveDownBlock", this.blockSelected);
        },
        showCssAdvance() {
            if (!this.blockSelected) return;

            $("#css-advance").modal("show");
        },
        saveTemplate: function() {
            this.$emit("quickGetTemplateSave");
        }
    },
    mounted: function() {
        $(document).on("click", function(ev) {
            var target = $(ev.target);
            var par = target.parent();
            if (target.hasClass("apo-dropdown-list") || 
            target.hasClass("apo-dropdown-val") || 
            target.hasClass("apo-dropdown") || 
            par.hasClass("apo-dropdown-list") || 
            par.hasClass("apo-dropdown-val") || 
            par.hasClass("apo-dropdown")) {} else {
                $(".apo-dropdown-list").hide();
            }
        })
    }
}