import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";

export default {
    components: {
        ColorPicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            listFontFamily: [
                "Amatic SC",
                "Anton",
                "Arial",
                "Bahianita",
                "Bangers",
                "Cabin",
                "Charm",
                "Charmonman",
                "Cuprum",
                "Dancing Script",
                "Dosis",
                "Farsan",
                "Fira Sans Condensed",
                "Francisco",
                "Francois One",
                "Grenze",
                "Helvetica",
                "Lobster",
                "Mulish",
                "Oswald",
                "Pacifico",
                "Pangolin",
                "Patrick Hand",
                "Patrick Hand SC",
                "Pattaya",
                "Poppins",
                "Raleway",
                "Roboto",
                "Sedgwick Ave Display",
                "Sigmar One",
                "Srisakdi",
                "Thasadith",
                "VT323",
                "Nunito",
                "Lato",
                "Lora",
                "Segoe UI Symbol"
            ],
            listFontSize: ["9", "10", "12", "13", "14", "18", "24", "36", "48", "64", "72", "96", "144", "288"],
            fontWeight: "normal",
            fontSize: "13",
            fontFamily: "Arial",
            color: "#000000",
            fontStyle: "normal",
            textDecoration: "none",
            textAlign: "left",
            idSL: "",
            device: "mobile",
            isGraPicker: false,
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},
            padding: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            spacing: 0,
            showPadd: false,
            focusInput: ""
        };
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL) {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;
                this.getInitialData(val);
                if (!this.block.padding) {
                    this.block.padding = {};
                }
                this.paddingBottom = parseFloat(this.block.padding.paddingBottom) || 0;
                this.paddingLeft = parseFloat(this.block.padding.paddingLeft) || 0;
                this.paddingRight = parseFloat(this.block.padding.paddingRight) || 0;
                this.paddingTop = parseFloat(this.block.padding.paddingTop) || 0;
                this.padding = parseFloat(this.block.padding.padding) || 0;
                this.spacing = parseFloat(this.block.spacing) || 0;
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device"(val) {
            this.device = val;
        },
        fontSize: _.debounce(function () {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            this.block[this.device].text.fontSize = this.fontSize + "px";
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "textSt" });
        }, 300),
        "padding": function (val) {
            if (!this.block.padding) {
                this.block.padding = {};
            }
            if (this.focusInput == "padding") {
                this.block.padding.padding = val + "px";
                this.block.padding.paddingBottom = val + "px";
                this.block.padding.paddingLeft = val + "px";
                this.block.padding.paddingRight = val + "px";
                this.block.padding.paddingTop = val + "px";
                this.paddingBottom = val;
                this.paddingLeft = val;
                this.paddingRight = val;
                this.paddingTop = val;
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "padding" });
            }
        },
        "paddingBottom": function (val) {
            if (!this.block.padding) {
                this.block.padding = {};
            }
            if (this.focusInput == "paddingBottom") {
                this.block.padding.paddingBottom = val + "px";
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "padding" });
            }
        },
        "paddingLeft": function (val) {
            if (!this.block.padding) {
                this.block.padding = {};
            }
            if (this.focusInput == "paddingLeft") {
                this.block.padding.paddingLeft = val + "px";
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "padding" });
            }
        },
        "paddingRight": function (val) {
            if (!this.block.padding) {
                this.block.padding = {};
            }
            if (this.focusInput == "paddingRight") {
                this.block.padding.paddingRight = val + "px";
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "padding" });
            }
        },
        "paddingTop": function (val) {
            if (!this.block.padding) {
                this.block.padding = {};
            }
            if (this.focusInput == "paddingTop") {
                this.block.padding.paddingTop = val + "px";
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "padding" });
            }
        },
        "spacing": function (val) {
            this.block.spacing = val + "px";
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "spacing" });
        }
    },
    methods: {
        forcusInput: function (val) {
            this.focusInput = val;
        },
        blurInput: function () {
            this.focusInput = "";
        },
        setshowPadd: function () {
            this.showPadd = !this.showPadd;
        },
        updateColor(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            this.color = color;
            this.block.color = color;
            this.block.colorText = color;
            this.block.isGraPicker = this.isGraPicker;
            this.updateBlock(this.block);
        },
        updateColorEffect(color) {
            if (!this.effectProperty.boxShadow) {
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
            this.effectProperty.boxShadow[4] = color;
        },
        setFontWeight() {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            if (this.fontWeight === "bold") {
                this.fontWeight = "normal";
            } else {
                this.fontWeight = "bold";
            }
            this.block[this.device].text.fontWeight = this.fontWeight;
            this.updateBlock(this.block);
        },
        setFontStyle() {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            if (this.fontStyle === "italic") {
                this.fontStyle = "normal";
            } else {
                this.fontStyle = "italic";
            }
            this.block[this.device].text.fontStyle = this.fontStyle;
            this.updateBlock(this.block);
        },
        setTextDecoration(val) {
            console.log(val);
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            if (this.textDecoration === val) {
                this.textDecoration = "none";
            } else {
                this.textDecoration = val;
            }
            this.block[this.device].text.textDecoration = this.textDecoration;
            this.updateBlock(this.block);
        },
        setFontFamily(val) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            this.fontFamily = val;
            this.block[this.device].text.fontFamily = this.fontFamily;
            this.updateBlock(this.block);
        },
        setTextAlign(val) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].text = {};
            } else {
                if (!this.block[this.device].text) {
                    this.block[this.device].text = {};
                }
            }
            this.textAlign = val;
            this.block[this.device].text.textAlign = val;
            this.updateBlock(this.block);
        },
        updateBlock(val) {
            this.block = val;
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "textSt" });
        },
        getInitialData(block) {
            if (block && block.id) {
                let blockTextData = block[this.device].text || {};

                if (block.color) {
                    this.color = block.color;
                } else {
                    this.color = $(`#${block.id} .block-content`).css("color") || "#000000";

                    if (block.componentName === "blockInput" || block.componentName === "blockTextarea") {
                        this.color = "rgb(82, 95, 127)";
                    }
                }
                // Disable gradient text
                this.isGraPicker = false;

                this.fontSize = blockTextData.fontSize ? blockTextData.fontSize.replace("px", "") : 13;
                this.fontFamily = blockTextData.fontFamily || "Arial";
                this.fontWeight = blockTextData.fontWeight || "normal";
                this.textAlign = blockTextData.textAlign || "left";
                this.fontStyle = blockTextData.fontStyle || "normal";
                this.textDecoration = blockTextData.textDecoration || "none";
                if (!blockTextData.textAlign && this.block.componentName === "blockButton") {
                    this.textAlign = "center";
                }
                if (!this.block.padding) {
                    this.block.padding = {};
                }
                this.paddingBottom = parseFloat(this.block.padding.paddingBottom) || 0;
                this.paddingLeft = parseFloat(this.block.padding.paddingLeft) || 0;
                this.paddingRight = parseFloat(this.block.padding.paddingRight) || 0;
                this.paddingTop = parseFloat(this.block.padding.paddingTop) || 0;
                this.padding = parseFloat(this.block.padding.padding) || 0;
                this.spacing = parseFloat(this.block.spacing) || 0;
            } else {
                this.color = "#000000";
                this.fontSize = 13;
                this.fontFamily = "Arial";
                this.fontWeight = "bold";
                this.textAlign = "left";
                this.fontStyle = "normal";
                this.textDecoration = "none";
                this.isGraPicker = false;
                this.paddingBottom = 0;
                this.paddingLeft = 0;
                this.paddingRight = 0;
                this.paddingTop = 0;
                this.padding = 0;
                this.spacing = 0;
            }
        },
        handleClickRemove(type, item) {
            if (type === "colorPicker") {
                this.isGraPicker = "";
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;

            switch (nameSection) {
                case "fold":
                    if ($("#text-st").hasClass("fold-section")) {
                        $("#text-st").removeClass("fold-section");
                    } else {
                        $("#text-st").addClass("fold-section");
                    }
                    break;
                case "style-color":
                    $("#text-st .fill-text .list-style-fill").toggle();
                    this.isGraPicker = val === "gradient" ? true : false;
                    break;
                case "font-size":
                    elementHeight = $(".font-size .size-dropdown .list-option").outerHeight();

                    $("#text-st .font-size .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $("#text-st .font-size .size-dropdown .list-option").css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                        return;
                    }
                    $("#text-st .font-size .size-dropdown .list-option").css({
                        top: `${topPosition - 8}px`
                    });
                    break;
                case "font-family":
                    elementHeight = $(".font-family .font-dropdown .list-option").outerHeight();

                    $("#text-st .font-family .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $("#text-st .font-family .font-dropdown .list-option").css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                        return;
                    }
                    $("#text-st .font-family .font-dropdown .list-option").css({
                        top: `${topPosition - 8}px`
                    });
                    break;
            }
        },
        closeDrop: function (event) {
            $(event.target).hide();
        },
        closeSt: function (event) {
            $("#text-st").parent().hide();
        }
    },
    mounted() {
        this.blockSelected = this.$store.state.editor.blockSelected;
        this.componentName = this.blockSelected && this.blockSelected.componentName;
        this.device = this.$store.state.editor.device;
        if (this.blockSelected && this.blockSelected.id) {
            this.block = $.extend(true, {}, this.blockSelected);
            this.getInitialData(this.block);
        }
        $(".apo-dropdown").off("click").on("click", function () {
            $(".apo-dropdown-list").hide();
            $(this).parent().find(".apo-dropdown-list").show();
        });
        $(".apo-dropdown").off("blur").on("blur", function () {
            $(".item-q-setting").hide();
            $("#quick-setting-block .item").removeClass("active");
        })

    }
};
