export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            dataTrackingAction: {},
            listTracking: []
        };
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataTrackingAction = val[this.indexAction];
            }
        },
        "$store.state.editor.listTracking"(val) {
            this.listTracking = val;
        }
    },
    methods: {
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataTrackingAction;
            this.$emit("updateBlock", dataAction);
        },
        openModelTrackingManage() {
            $("#modal-tracking-manage").modal("show");
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            switch (nameSection) {
                case "tracking":
                    selector = `.list-action > div:nth-child(${this.indexAction + 1}) .list-option`;
                    elementHeight = $(selector).outerHeight();

                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $(selector).css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                    } else {
                        $(selector).css({
                            top: `${topPosition}px`
                        });
                    }

                    if (val) {
                        $(selector).hide();
                        this.dataTrackingAction.data = val;
                        this.updateData();
                    }
                    break;
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-tracking`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
            }
        }
    },
    mounted() {
        let listTracking = this.$store.state.editor.listTracking || [];

        this.listTracking = listTracking;
        if (this.dataAction && this.indexAction !== undefined) {
            this.dataTrackingAction = this.dataAction[this.indexAction];
        }
    }
};
