import { render, staticRenderFns } from "./position-setting.html?vue&type=template&id=ac5dbe48&scoped=true&"
import script from "./position-setting.js?vue&type=script&lang=js&"
export * from "./position-setting.js?vue&type=script&lang=js&"
import style0 from "./position-setting.vue?vue&type=style&index=0&id=ac5dbe48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac5dbe48",
  null
  
)

export default component.exports