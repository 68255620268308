import { templateSV } from "./template-page-manager.service.js";
import { utils } from "@/libs-core/utils.min.js";

export default {
    props: ["listPage"],
    data: function() {
        return {
            list_template: [],
            type: "page",
            item_select: {},
            nameNewPage: "",
            listCate: [],
            cateSelect: "",
            idCateSl: "",
            createTemp: false
        };
    },
    watch: {
        nameNewPage: _.debounce(function() {
            this.nameNewPage = utils.resetNamePage(this.nameNewPage);
        }, 300)
    },
    methods: {
        getTemplate() {
            const thisVue = this;

            thisVue.idCateSl = "";
            templateSV.getListTemplate({ type: this.type }, function(data) {
                if (data && data.code === 200) {
                    thisVue.list_template = data.data;
                }
            });
        },
        selectedPage(item) {
            this.$store.commit("editor/setSourceTemplate", item);
            $("#new-page-template").modal("show");
            $("#modal-template-page-manager").modal("hide");
            this.createTemp = true;
        },
        getListCategoryPage() {
            const thisVue = this;

            templateSV.getListCategory({}, function(data) {
                if (data && data.code === 200) {
                    thisVue.listCate = data.data;
                    thisVue.idCateSl = data.data[0].id;
                    thisVue.getPageInCategory( thisVue.idCateSl);
                }
            });
        },
        getPageInCategory(id) {
            const thisVue = this;

            if (id) {
                thisVue.idCateSl = id;
            }
            templateSV.getAppInCategory({ id: thisVue.idCateSl }, function(data) {
                if (data && data.code === 200) {
                    thisVue.list_template = data.data;
                    console.log("thisVue.list_template ::: ", thisVue.list_template );
                }
            });
        },
        removeTem(val) {
            const thisVue = this;

            templateSV.removeTemplate(JSON.stringify({ id: val.id }), function(data) {
                //thisVue.getTemplate();
                if (data && data.code === 200) {
                    thisVue.$swal({
                        position: "bottom-start",
                        type: "success",
                        title: "Your app has been deleted.",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
        },
        close() {
            const thisVue = this;

            if (thisVue.$store.state.editor.before_popup.type === "page-action") {
                thisVue.$store.commit("editor/mutaBeforePopup", {});
                $("#action-page-setting").modal("show");
                $("#modal-template-page-manager").modal("hide");
            } else if (thisVue.$store.state.editor.before_popup.type === "block-action") {
                thisVue.$store.commit("editor/mutaBeforePopup", {});
                $("#action-setting").modal("show");
                $("#modal-template-page-manager").modal("hide");
            }
        },
        backModalAddScreens: function() {
            $("#show-modal-add-screen").modal("show");
        }
    },
    mounted() {
        const thisVue = this;
        $("#modal-template-page-manager").on("shown.bs.modal", function() {
            thisVue.getListCategoryPage();
            //thisVue.getTemplate();
        });
        $("#modal-template-page-manager").on("hidden.bs.modal", function() {
            thisVue.idCateSl = "";
            if (thisVue.createTemp === true) {
                $("#show-modal-add-screen").modal("hide");
            } else {
                $("#show-modal-add-screen").modal("show");
            }
            thisVue.createTemp = false;
        });
    }
};
