export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            dataPosAction: {},
            listPosition: []
        };
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataPosAction = val[this.indexAction];
            }
        },
        "$store.state.editor.api"(val) {
            this.listPosition = val;
        }
    },
    methods: {
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataPosAction;
            this.$emit("updateBlock", dataAction);
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            switch (nameSection) {
                case "position":
                    selector = `.list-action > div:nth-child(${this.indexAction + 1}) .list-option`;
                    elementHeight = $(selector).outerHeight();

                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $(selector).css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                    } else {
                        $(selector).css({
                            top: `${topPosition}px`
                        });
                    }

                    if (val) {
                        this.dataPosAction.data = val.id;
                        this.dataPosAction.name = val.name;
                        this.updateData();
                    }
                    break;
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-position`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
            }
        }
    },
    mounted() {
        let listPosition = this.$store.state.editor.api || [];

        this.listPosition = listPosition;
        if (this.dataAction && this.indexAction !== undefined) {
            this.dataPosAction = this.dataAction[this.indexAction];
        }
    }
};
