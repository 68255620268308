import { service } from "./action-gg-form.service.js";

export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            linkForm: "",
            requireParams: false,
            dataGgFormAction: {},
            listItemGgForm: [],
            timeShowAction: 0,
            msgRequireContent: ''
        };
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataGgFormAction = val[this.indexAction];
                if (this.dataGgFormAction && this.dataGgFormAction.data) {
                    this.listItemGgForm = this.dataGgFormAction.data.params || [];
                    this.linkForm = this.dataGgFormAction.data.formResponse || '';
                    this.requireParams = this.dataGgFormAction.data.requireParams || false;
                    this.msgRequireContent = this.dataGgFormAction.data.messager_require || '';
                }
            }
        },
        requireParams(val) {
            if (!this.dataGgFormAction.data) {
                this.dataGgFormAction.data = {};
            }
            this.dataGgFormAction.data.requireParams = val;
            this.updateData();
        },
        msgRequireContent(val) {
            if (!this.dataGgFormAction.data) {
                this.dataGgFormAction.data = {};
            }
            this.dataGgFormAction.data.messager_require = val;
            this.updateData();
        },
        "$store.state.editor.dataActionSelect"(val) {
            if (this.timeShowAction !== val.timeShowAction) return;
            this.listItemGgForm = val.listItemGgForm;
            this.dataGgFormAction.data.params = val.listItemGgForm;
            this.updateData();
        }
    },
    methods: {
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataGgFormAction;
            this.$emit("updateBlock", dataAction);
        },
        getFormInfo() {
            const thisVue = this;

            if (this.linkForm) {
                if (!this.dataGgFormAction.data) {
                    this.dataGgFormAction.data = {};
                }
                thisVue.dataGgFormAction.data.formResponse = thisVue.linkForm.replace("/viewform", "/formResponse");
                $("#modal-config-ggform").modal("show");
                service.getFormInfo(
                    {
                        url: thisVue.linkForm
                    },
                    function(data) {
                        if (data && data.code === 200) {
                            thisVue.listItemGgForm = [];
                            let googForm = $(data.data);
                            let itemForm = googForm.find('[role="listitem"]');
                            
                            thisVue.dataGgFormAction.data.title_form = googForm
                                .contents()
                                .find(".exportFormTitle")
                                .text();
                            thisVue.dataGgFormAction.data.fvv = googForm
                                .contents()
                                .find('input[name="fvv"]')
                                .attr("value");
                            thisVue.dataGgFormAction.data.draftResponse = googForm
                                .contents()
                                .find('input[name="draftResponse"]')
                                .attr("value");
                            thisVue.dataGgFormAction.data.pageHistory = googForm
                                .contents()
                                .find('input[name="pageHistory"]')
                                .attr("value");
                            thisVue.dataGgFormAction.data.fbzx = googForm
                                .contents()
                                .find('input[name="fbzx"]')
                                .attr("value");
                            if (itemForm && itemForm.length > 0) {
                                itemForm.each(function() {
                                    var item = {
                                        key:
                                            "entry." +
                                            $(this)
                                                .find("> div")
                                                .attr("data-params")
                                                .split(",")[4]
                                                .split("[[")[1],
                                        name: $(this)
                                            .find('[role="heading"')
                                            .text()
                                    };
                                    thisVue.listItemGgForm.push(item);
                                });
                            }
                            thisVue.updateData();
                            thisVue.saveDataActionSelect();
                        } else {
                            this.$swal({
                                title: "",
                                text: "Get form error",
                                type: "error"
                            });
                        }
                    }
                );
            }
        },
        saveDataActionSelect() {
            const timeShowAction = new Date().getTime();
            const listItemGgForm = this.listItemGgForm;
            this.timeShowAction = timeShowAction;

            const data = {
                timeShowAction,
                listItemGgForm
            };
            this.$store.commit("editor/mutaDataActionSelect", data);
        },
        clickOpenSetting() {
            $("#modal-config-ggform").modal("show");
            this.saveDataActionSelect();
        },
        toggleSection(nameSection) {
            let selector;
            switch (nameSection) {
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-gg-form`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
                    break;
            }
        }
    },
    mounted() {
        if (this.dataAction && this.indexAction !== undefined) {
            this.dataGgFormAction = this.dataAction[this.indexAction];
            if (this.dataGgFormAction && this.dataGgFormAction.data) {
                this.listItemGgForm = this.dataGgFormAction.data.params || [];
                this.linkForm = this.dataGgFormAction.data.formResponse || '';
                this.requireParams = this.dataGgFormAction.data.requireParams || false;
                this.msgRequireContent = this.dataGgFormAction.data.messager_require || '';
            }
        }
    }
};
