/* eslint-disable no-undef */
import { utils } from "@/libs-core/utils.min.js";
import { sevice } from "./model-template.service.js";
import { validate } from "@/libs-core/validate.min.js";

//------//
// CONFIG CODE MIRROR 
//------//
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// theme css

// language
import 'codemirror/mode/javascript/javascript.js'

// active-line.js
import 'codemirror/addon/selection/active-line.js'

// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'

// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/match-highlighter.js'

// END CONFIG CODE MIRROR //
///////////////////////////


export default {
  props: ["listModel"],
  components: {
    codemirror
  },
  data: function () {
    return {      
      list_model: this.listModel,
      form_data: {
        key: "",
        type: "string",
        value: "",
        isCreate: true,
        hash: ""
      },
      optionsCm: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/javascript",
        theme: "default" 
      },
      error_message: "",
      success_message: "",
      variableSelect: {},
      type: "string",
      valueDefault: "",
      error: "",
      clickHash: false
    };
  },
  watch: {
    "form_data.key": function (val) {
      if (val && val.length > 0) {
        this.form_data.key = utils.resetText(val);
      }
    },
    "form_data.hash": function (val) {
      if (this.clickHash) {
        if (this.form_data.hash == true && this.form_data.key && this.form_data.key.length > 0 && this.form_data.value) {
          this.encryptValue();
        }
        if (this.form_data.hash == false && this.form_data.value_hash && this.form_data.value_hash.length > 0) {
          this.decryptValue();
        }
        this.clickHash = false;
      }
    }
  },
  methods: {
    clickEncript: function () {
      this.clickHash = true;
    },
    updateOrCreate: function (val) {
      var tmp_val = val;
      var thisVue = this;
      var error_form = false;
      if (!val.key || val.key === "") {
        thisVue.error_message = "Field key is required!";
        setTimeout(function () {
          thisVue.error_message = "";
        }, 3000);
        return;
      }

      switch (val.type) {
        case "object":
          if (!thisVue.form_data.hash && val.value && val.value.length > 0 && !validate.json(val.value)) {
            error_form = true;
            thisVue.error_message = "Value object is invalid!";
            setTimeout(function () {
              thisVue.error_message = "";
            }, 3000);
            return;
          }
          break;

        case "number":
          if (val.value && val.value.length > 0 && parseInt(val.value) === NaN) {
            error_form = true;
            thisVue.error_message = "Value number is invalid!";
            setTimeout(function () {
              thisVue.error_message = "";
            }, 3000);
            return;
          }
          break;
      }
      if (error_form == true) {
        return;
      }
      // Update ID
      if (val && val.id) {
        thisVue.list_model.find(function (x) {
          if (x.id === val.id) {
            x.description = val.description;
            x.hash = val.hash;
            x.id = val.id;
            x.key = val.key;
            x.type = val.type;
            x.value = val.value;
            x.value_hash = val.value_hash;
          }
        })
        thisVue.success_message = "Updated variable success";
        thisVue.$emit("updateModel", thisVue.list_model);
        setTimeout(function () {
          thisVue.success_message = "";
        }, 3000);
        if (thisVue.$store.state.editor.before_popup.type === 'page-action') {
          thisVue.$store.commit('editor/mutaBeforePopup', {});
          $("#action-page-setting").modal("show");
          $("#model-template").modal("hide");
        } else if (thisVue.$store.state.editor.before_popup.type === 'block-action') {
          thisVue.$store.commit('editor/mutaBeforePopup', {});
          $("#action-setting").modal("show");
          $("#model-template").modal("hide");
        }
      } else {
        // Create new
        var item = _.find(thisVue.list_model, function (o) {
          return o.key === thisVue.form_data.key;
        });
        if (item && item.key) {
          thisVue.error_message = "This field already exists";
          setTimeout(function () {
            thisVue.error_message = "";
          }, 3000);
          thisVue.form_data = tmp_val;
          return;
        }
        var data = {
          id: new Date().getTime(),
          key: utils.resetText(val.key),
          type: val.type,
          value: val.value,
          description: val.description
        };
        thisVue.form_data = data;
        thisVue.list_model.push(data);
        thisVue.form_data = {
          type: "string",
          key: "",
          value: "",
          isRemove: true,
          description: "",
          isCreate: true
        };
        thisVue.success_message = "Create variable success";
        setTimeout(function () {
          thisVue.success_message = "";
        }, 3000);
        thisVue.$emit("updateModel", this.list_model);
        if (thisVue.$store.state.editor.before_popup.type === 'page-action') {
          thisVue.$store.commit('editor/mutaBeforePopup', {});
          $("#action-page-setting").modal("show");
          $("#model-template").modal("hide");
        } else if (thisVue.$store.state.editor.before_popup.type === 'block-action') {
          thisVue.$store.commit('editor/mutaBeforePopup', {});
          $("#action-setting").modal("show");
          $("#model-template").modal("hide");
        }
      }
    },
    deleteItem: function (index, e) {
      var thisVue = this;
      e.preventDefault();
      e.stopPropagation();
      thisVue
        .$swal({
          title: "",
          text: "You won't be able to revert this action!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            thisVue.list_model.splice(index, 1);
            thisVue.selectedItem();
            thisVue.$emit("updateModel", thisVue.list_model);
          }
        });
    },
    selectedItem: function (val) {
      if (val) {
        this.form_data = $.extend(true, {}, val);
      } else {
        this.form_data = {
          type: "string",
          key: "",
          value: "",
          isRemove: true,
          description: "",
          isCreate: true
        };
      }
    },
    encryptValue: function () {
      var thisVue = this;
      sevice.encrypt({ key: thisVue.form_data.key, value: thisVue.form_data.value }, function (data) {
        if (data && data.code === 200) {
          thisVue.form_data.value_hash = data.data;
          //thisVue.updateOrCreate(thisVue.form_data);
        }
      });
    },
    decryptValue: function () {
      var thisVue = this;
      sevice.decrypt({ hash: thisVue.form_data.value_hash }, function (data) {
        if (data && data.code === 200) {
          if (data.data.key) {
            thisVue.form_data.key = data.data.key;
            thisVue.form_data.value = data.data.value;
            thisVue.form_data.hash = false;
          }
          //thisVue.updateOrCreate(thisVue.form_data);
        } else {
          thisVue.form_data.hash = true;
        }
      });
    },

  },
  mounted: function () {
    var thisVue = this;
    $("#model-template").on("shown.bs.modal", function () {
      thisVue.list_model = thisVue.listModel;
      thisVue.form_data = {};

      if (
        thisVue.$store.state.editor.before_popup === 'block-action' ||
        thisVue.$store.state.editor.before_popup === 'page-action'
      ) {
        thisVue.selectedItem()
      }
    });

    $("#model-template").on("hidden.bs.modal", function () {
      thisVue.$store.commit('editor/mutaBeforePopup', {});
    })
  }
};
