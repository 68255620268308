import resful from "@/services/resful.js";
export const templateSV = {
  getListTemplate: async function (data, next) {
    let res = await resful.get("/app/templates?" + data);
    next(res);
  },
  removeTemplate: async function (data, next) {
    let res = await resful.post("/app/remove_template", data);
    next(res);
  },
  // get all category
  getListCategory: async function (data, next) {
    let res = await resful.get("/app/category?type_second=" + data.type_second, data);
    next(res);
  },
  // get App in category
  getAppInCategory: async function (data, next) {
    let res = await resful.get("/app/templates?category=" + data.id + "&skip=" + data.skip + "&limit=" + data.limit, data);
    next(res);
  },
  getSourceInPageTemplate: async function (data, next) {
    let res = await resful.get("/app/templates?id=" + data.id, data);
    next(res);
  },
  getCateAndTemp: async function(data, next) {
    let res = await resful.post("/app/get_templates_and_category", data)
    next(res);
  }
};
