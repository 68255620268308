import { render, staticRenderFns } from "./setting-page.component.html?vue&type=template&id=7066368c&scoped=true&"
import script from "./setting-page.component.js?vue&type=script&lang=js&"
export * from "./setting-page.component.js?vue&type=script&lang=js&"
import style0 from "./setting-page.vue?vue&type=style&index=0&id=7066368c&lang=scss&scoped=true&"
import style1 from "./setting-page.component.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7066368c",
  null
  
)

export default component.exports