export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            dataApiAction: {},
            listApi: [],
            timeShowAction: 0
        };
    },
    computed: {
        computeNameApiSelect() {
            let dataApiAction = this.dataApiAction;
            if (
                dataApiAction &&
                dataApiAction.data &&
                dataApiAction.data.api_select &&
                dataApiAction.data.api_select.name
            ) {
                return dataApiAction.data.api_select.name;
            }
            return "Select Api";
        }
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataApiAction = val[this.indexAction];
            }
        },
        "$store.state.editor.listApiTemplate"(val) {
            if (val) {
                this.listApi = val;
            }
        },
        "$store.state.editor.dataActionSelect"(val) {
            if (val.timeShowAction === this.timeShowAction) {
                this.dataApiAction = val.dataApiAction;
                this.updateData();
            }
        }
    },
    methods: {
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataApiAction;
            this.$emit("updateBlock", dataAction);
        },
        clickCreateNewApi() {
            $("#api-template-v3").modal("show");
        },
        openModalConfigApi() {
            const timeShowAction = new Date().getTime();
            const dataApiAction = this.dataApiAction;
            const keyObjApiSelect = Object.keys(dataApiAction.data.api_select);

            if (!keyObjApiSelect.length) return;
            this.timeShowAction = timeShowAction;

            const data = {
                timeShowAction,
                dataApiAction
            };
            this.$store.commit("editor/mutaDataActionSelect", data);
            $("#modal-config-api").modal("show");
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            switch (nameSection) {
                case "api":
                    selector = `.list-action > div:nth-child(${this.indexAction + 1}) .list-option`;
                    elementHeight = $(selector).outerHeight();

                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $(selector).css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                    } else {
                        $(selector).css({
                            top: `${topPosition}px`
                        });
                    }

                    if (val) {
                        this.dataApiAction.data.api_select = val;
                        this.dataApiAction.data.actionError = [];
                        this.dataApiAction.data.actionSuccess = [];
                        this.updateData();
                    }
                    break;
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-api`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
            }
        }
    },
    mounted() {
        let listApi = this.$store.state.editor.listApiTemplate || [];

        this.listApi = listApi;
        if (this.dataAction && this.indexAction !== undefined) {
            this.dataApiAction = this.dataAction[this.indexAction];
        }
    }
};
