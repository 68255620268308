import { templateSV } from "./template-page-manager.service.js";

export default {
    props: ["listPage", "menuSl"],
    data() {
        return {
            skip: 0,
            listCateAndTemp: [],
            category: {},
            listTemplate: [],
            stop: false,
            search: ""
        };
    },
    watch: {
        "$store.state.editor.saveTempStatus"(val) {
            if (!val || !val.includes("page")) return;
            if (this.listTemplate.length) {
                this.seeAllTemplate(this.listCateAndTemp[0]);
                return;
            }
            this.getCateAndTemplate();
        },
        menuSl: function (val) {
            if (val === "template-manage") {
                this.listTemplate = [];
            }
        }
    },
    methods: {
        dragTemplate() {
            $(".template-manager .template-avatar-image").draggable();
            $(".template-manager .template-avatar-image").draggable("destroy");
            $(".template-manager .template-avatar-image").draggable({
                appendTo: "body",
                helper: "clone",
                drag(event, ui) {
                    ui.helper.addClass("image-template-drag");
                },
                stop(event, ui) {
                    $(".image-template-drag").remove();
                }
            });
        },
        getCateAndTemplate() {
            const thisVue = this;
            templateSV.getCateAndTemp(
                JSON.stringify({
                    cate_query: {
                        type: "marketplace",
                        type_second: "page"
                    },
                    user_temp_name: "My templates",
                    temp_limit: 50,
                    owner_query: {
                        type: "page"
                    },
                    owner_limit: 3
                }),
                (data) => {
                    thisVue.listCateAndTemp = data.data;
                    console.log("thisVue.listCateAndTemp::: ", thisVue.listCateAndTemp);
                    setTimeout(() => {
                        thisVue.dragTemplate();
                    }, 500);
                }
            );
        },
        seeAllTemplate(cate) {
            const thisVue = this;

            this.category = cate;
            if (cate.name == "My templates" && !cate.id) {
                console.log("1");
                templateSV.getListTemplate(
                    $.param({
                        type: "section",
                        skip: thisVue.skip,
                        limit: 20 // 20
                    }),
                    (data) => {
                        thisVue.listTemplate = data.data;
                        $(".menu-div-content-v3").scrollTop(0);
                        setTimeout(() => {
                            thisVue.dragTemplate();
                        }, 500);
                    }
                );
                return;
            } else {
                templateSV.getListTemplate(
                    $.param({
                        category: cate.id,
                        skip: thisVue.skip,
                        limit: 20 // 20
                    }),
                    (data) => {
                        thisVue.listTemplate = data.data;
                        console.log("thisVue.listTemplate:: ", thisVue.listTemplate);
                        $(".menu-div-content-v3").scrollTop(0);
                        setTimeout(() => {
                            thisVue.dragTemplate();
                        }, 500);
                    }
                );
            }
        },
        back() {
            if (this.category.name === "My templates") {
                this.listCateAndTemp[0].templates = this.listTemplate.slice(0, 3);
            }
            this.listTemplate = [];
            this.skip = 0;
            this.category = {};
            this.stop = false;
        },
    },
    mounted() {
        this.dragTemplate();
        this.getCateAndTemplate();
    }
};
