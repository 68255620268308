import { utils } from "@/libs-core/utils.min.js";
import { VueEditor } from "vue2-editor";
import ListConnectData from "./list-connect-data/list-connect-data.vue";
import dataText from "./data-text/data-text.vue";

export default {
    props: ["thisVue"],
    components: {
        ListConnectData,
        VueEditor,
        dataText
    },
    data() {
        return {
            block: {},
            componentName: "",
            isCheckboxChecked: false,
            timeShow: "",
            typeSelectDataApp: "dataInput",
            // Text and button data
            idSl: "",
            html: "",
            editorOptions: {
                modules: {
                    toolbar: false
                }
            },
            finishUpdate: true,
            // Input data
            listTypeInput: ["Text", "Password", "Email", "Phone", "Number", "Date"],
            inputPlaceholder: "",
            inputName: "",
            inputTabindex: "",
            inputType: "",
            inputValueCheckboxRadio: "",
            inputModelCheckboxRadio: "",
            inputPattern: "",
            inputRequire: false,
            inputMess: "",
            inputModel: "",
            inputHour: "",
            inputMin: "",
            inputSecond: "",
            inputDate: "",
            // Select data
            selectModelList: "",
            selectDefault: "",
            selectTabindex: "",
            selectValueItem: "",
            selectValueText: "",
            selectIndexSheet: 0,
            selectListDataGet: [],
            // Video data
            videoData: {},
            checkTimer: "0",
            timeDay: 0,
            timeHour: 0,
            timeMin: 0,
            timeSec: 0,
            timeEnd: new Date(),
            imageSrc: "",
            nameBlock: "",
            timeupdate: new Date().getTime(),
            namedataGetSelect: ""
        };
    },
    computed: {
        isShowInputSection() {
            const componentName = this.componentName;
            if (!componentName) return;
            if (
                componentName === "blockInput" ||
                componentName === "blockRadio" ||
                componentName === "blockCheckbox" ||
                componentName === "blockTextarea"
            ) {
                return true;
            }
        },
        isCheckboxRadioBlock() {
            if (this.componentName === "blockRadio" || this.componentName === "blockCheckbox") return true;
        },
        computeDataGetSl() {
            if (
                !this.block.selectOption ||
                !this.block.selectOption.dataSelectOption ||
                !this.selectListDataGet[this.selectIndexSheet]
            )
                return "Select data";
            return this.selectListDataGet[this.selectIndexSheet].name;
        },
        computeSetDataSl() {
            const data = this.block.dataInput;
            if (!data || !Object.keys(data).length) {
                return "";
            }
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= data.indexTab) return "";
            return utils.getValueDataApp(data.indexTab, data.keySelect, data.indexKey);
        },
        listItemSl() {
            if (this.selectListDataGet && this.selectListDataGet.length > 0) {
                let data = this.selectListDataGet;
                let listData = data[this.selectIndexSheet];
                listData = listData && listData.data ? listData.data[0] : {};
                if (listData) {
                    
                    return Object.keys(listData).map((item) => "item." + item);
                } else {
                    return [];
                }
            } else {
                return [];
            }
        },
        computePlatformVideo() {
            let data = this.videoData.typevideo;
            if (data) {
                return data.charAt(0).toUpperCase() + data.substring(1);
            }
            return "Select data";
        },
        computeAutoPlay() {
            let data = this.videoData.autoplay;
            if (data) {
                return data == "0" ? "No" : "Yes";
            }
            return "Select data";
        }
    },
    watch: {
        "$store.state.editor.blockSelected": function(val) {
            if (val && val.id != this.idSL) {
                this.block = val;
                this.idSl = val.id;
                this.nameBlock = val.name || val.componentName;
                this.getInitialData();
            } else {
                if (!val) {
                    this.idSl = "";
                }else {
                    if (val && val.id == this.idSL) {
                        this.block = $.extend(true, {}, val);
                    }
                }
            }
        },
        inputRequire(val) {
            if (val) {
                $(".set-regex, .set-mess").show();
                $(".set-validate").css("margin-bottom", "12px");
            } else {
                $(".set-regex, .set-mess").hide();
                $(".set-validate").css("margin-bottom", "0");
            }
            this.block.input.require = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputName:function (val) {
            this.inputName = utils.resetNamePage(utils.resetText(val));
            this.block.input.name = this.inputName;
            this.updateBlock(this.block, "inputSt");
        },
        inputPlaceholder: function (val) {
            this.block.input.text = val;
            if (this.block.componentName === "blockInput") {
                $("#" + this.block.id + " > .block-content").attr("placeholder", val);
            } else {
                $("#" + this.block.id + " > .block-content").html(val);
            }
            this.updateBlock(this.block, "inputSt");
        },
        inputValueCheckboxRadio: function (val, oldVal) {
            if (val === oldVal || val === this.block.input.value) return;
            this.block.input.value = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputModelCheckboxRadio: function (val, oldVal) {
            if (val === oldVal || val === this.block.input.model) return;
            this.block.input.model = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputModel:function (val, oldVal) {
            if (val === oldVal || val === this.block.input.model) return;
            this.block.input.model = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputType(val) {
            this.block.input.type = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputMess: function (val) {
            this.block.input.message = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputPattern: function (val) {
            this.block.input.pattern = val;
            this.updateBlock(this.block, "inputSt");
        },
        inputTabindex: function (val) {
            if (Number(val) < 0 || isNaN(Number(val))) {
                this.tabindex = 0;
            }
            this.block.input.tabindex = val;
            this.updateBlock(this.block, "inputSt");
        },
        selectDefault:function (val) {
            this.block.selectOption.textDefaultOption = val;
            $("#" + this.block.id + " > .block-content option").text(val);
            this.updateBlock(this.block, "selectSt");
        },
        selectTabindex: function (val) {
            this.block.selectOption.tabindex = val;
            this.updateBlock(this.block, "selectSt");
        }, 
        "videoData.idvideo": function () {
            this.block.video = this.videoData;
            this.renderVideo();
            this.updateBlock(this.block, "videoSt");
        },
        imageSrc:function (val) {
            $("#" + this.block.id + " .block-content img").attr("src", this.imageSrc);
            this.block.src = this.imageSrc;
            this.updateBlock(this.block, "imageSt");
        }, 
        nameBlock: function (val) {
            this.block.name = val;
            this.updateBlock(this.block);
        },
    },
    methods: {
        updateHtmlValue: function () {
            if (this.block.componentName == "blockButton" || this.block.componentName == "blockText") {
                if (!this.finishUpdate || this.html === this.block.html) return;
                if (this.block && this.block.dataInput) {
                    this.block.dataInput.useValue = "";
                }
                this.block.html = this.html;
                this.updateBlock(this.block, "valueTextSt");
                $("#" + this.block.id + " > .block-content").html(this.html);
                utils.changeModelToSpan(this);
            }
        }, 
        handleTypingValue(event) {
            let value = event.target.value;
            this.inputValueCheckboxRadio = value;
            this.block.input.value = value;
            this.block.dataInput = {};
            this.updateBlock(this.block, "inputSt");
        },
        clickValidateCheckbox() {
            this.isCheckboxChecked = !this.isCheckboxChecked;
            if (this.isCheckboxChecked) {
                $(".set-regex, .set-mess").show();
                $(".set-validate").css("margin-bottom", "12px");
            } else {
                $(".set-regex, .set-mess").hide();
                $(".set-validate").css("margin-bottom", "0");
            }
        },
        renderVideo() {
            let src = "";
            if (this.videoData.typevideo === "youtube") {
                src = "https://www.youtube.com/embed/" + this.videoData.idvideo;
            }
            if (this.videoData.typevideo === "vimeo") {
                src = "https://player.vimeo.com/video/" + this.videoData.idvideo;
            }
            $("#" + this.block.id + " iframe").attr("src", src);
        },
        showSelectDataApp(type) {
            this.typeSelectDataApp = type;
            this.timeShow = new Date().getTime();
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);

            if (this.block && this.block.dataInput) {
                this.$store.commit("editor/mutaDataAppSelect", {
                    indexTab: this.block[type].indexTab,
                    keySelect: this.block[type].keySelect,
                    indexKey: this.block[type].indexKey,
                    saveData: false
                });
            }
            $("#select-data-app").modal("show");
        },
        updateBlock(val, type) {
            if (!val.id) return;
            this.$store.dispatch("editor/updateBlock", { val, type });
        },
        getInitialData() {
            let block = this.block;
            if (!block.id) return;
            this.componentName = block.componentName || "";

            switch (block.componentName) {
                case "blockText":
                case "blockButton":
                    this.html = block.html || "";
                    break;
                case "blockInput":
                case "blockTextarea":
                case "blockCheckbox":
                case "blockRadio":
                    this.inputName = block.input.name || "";
                    this.inputPlaceholder = block.input.text || "";
                    this.inputModelCheckboxRadio = block.input.model || "";
                    this.inputValueCheckboxRadio = block.input.value || "";
                    this.inputModel = block.input.model || "";
                    this.inputType = block.input.type || "";
                    this.inputRequire = block.input.require || false;
                    this.inputMess = block.input.message || "";
                    this.inputPattern = block.input.pattern || "";
                    this.inputTabindex = block.input.tabindex || "";

                    if (this.inputRequire) {
                        this.isCheckboxChecked = true;
                        $(".set-regex, .set-mess").show();
                        $(".set-validate").css("margin-bottom", "12px");
                    }
                    break;
                case "blockSelect":
                    if (block.selectOption) {
                        this.selectModelList = this.block.selectOption.modelList || "";
                        this.selectValueText = this.block.selectOption.valueText || "";
                        this.selectValueItem = this.block.selectOption.valueItem || "";
                        this.selectDefault = this.block.selectOption.textDefaultOption || "";
                        this.selectTabindex = this.block.selectOption.tabindex || "";
                        this.selectListDataGet = $dataApp && $dataApp.data ? $dataApp.data : [];
                        if (this.block.selectOption.dataSelectOption) {
                            this.selectIndexSheet = this.block.selectOption.dataSelectOption.indexTab;
                        } else {
                            this.selectIndexSheet = 0;
                        }
                    }
                    break;
                case "blockVideo":
                    this.videoData = this.block.video || {};
                    if (!this.videoData.typevideo) {
                        this.videoData.typevideo = "youtube";
                    }
                    if (!this.videoData.autoplay) {
                        this.videoData.autoplay = "0";
                    }
                    if (!_.isEqual(this.block.video, this.videoData)) {
                        this.block.video = this.videoData;
                        this.renderVideo();
                        this.updateBlock(this.block, "videoSt");
                    }
                    break;
                case "blockCountdown":
                    this.timeDay = block.timeDay || 0;
                    if (this.timeDay && timeDay > 0) {
                        this.timeHour = timeDay * 24 + this.block.timeHour;
                    } else {
                        this.timeHour = block.timeHour || 0;
                    }
                    this.timeMin = block.timeMin || 0;
                    this.timeSec = block.timeSec || 0;
                    this.timeEnd = block.timeEnd || new Date();
                    this.checkTimer = block.typeTime || "0";
                    break;
                case "blockImage":
                    this.imageSrc = this.block.src;
                    break;
            }
        },
        toggleSection(type, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let topSet;
            switch (type) {
                case "type-input":
                    $("#connect-to-data .type-input-dropdown .list-option").toggle();

                    if (val) {
                        this.inputType = val;
                        this.block.input.type = val;
                        this.updateBlock(this.block, "inputSt");
                    }
                    break;
                case "sl-data-get":
                    elementHeight = $("#connect-to-data .data-get-dropdown .list-option").outerHeight();

                    $("#connect-to-data .data-get-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(".data-get-dropdown .list-option").css({
                        top: `${topSet}px`
                    });
                    if (val !== undefined) {
                        this.selectIndexSheet = val;
                        if (!this.block.selectOption) {
                            this.block.selectOption = {}
                        }
                        this.namedataGetSelect = this.selectListDataGet[this.selectIndexSheet].name;
                        this.block.selectOption.dataSelectOption = {
                            indexTab: this.selectIndexSheet,
                            nameTab: this.selectListDataGet[this.selectIndexSheet].name,
                            keySelect: "",
                            indexKey: "",
                            saveData: true,
                            useValue: "model"
                        };
                        this.updateBlock(this.block, "selectSt");
                        this.timeupdate = new Date().getTime();
                    }
                    break;
                case "sl-text-item":
                    elementHeight = $("#connect-to-data .text-item-dropdown .list-option").outerHeight();

                    $("#connect-to-data .text-item-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(".text-item-dropdown .list-option").css({
                        top: `${topSet}px`
                    });
                    if (val) {
                        this.selectValueText = val;
                        this.block.selectOption.valueText = val;
                        this.updateBlock(this.block, "selectSt");
                    }
                    break;
                case "sl-value-item":
                    let leftPos = $("#connect-to-data .text-item-dropdown")
                        .get(0)
                        .getBoundingClientRect().left;
                    elementHeight = $("#connect-to-data .value-item-dropdown .list-option").outerHeight();

                    $("#connect-to-data .value-item-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(".value-item-dropdown .list-option").css({
                        top: `${topSet}px`,
                        left: `${leftPos}px`
                    });

                    if (val) {
                        this.selectValueItem = val;
                        this.block.selectOption.valueItem = val;
                        this.updateBlock(this.block, "selectSt");
                    }
                    break;
                case "platform-video":
                    $("#connect-to-data .platform-dropdown .list-option").toggle();
                    if (val) {
                        this.videoData.typevideo = val;
                        this.block.video = this.videoData;
                        this.renderVideo();
                        this.updateBlock(this.block, "videoSt");
                    }
                    break;
                case "play-video":
                    $("#connect-to-data .autoplay-dropdown .list-option").toggle();
                    break;
                case "countdown":
                    $(".countdown-data .countdown-dropdown .list-option").toggle();
                    if (val) {
                        this.checkTimer = val;
                        this.block.typeTime = val;
                        this.updateBlock(this.block, "countdownSt");
                        // if (val === "0") {
                        //     $(".countdown-data .st-countdown-by-hour").css("display", "flex");
                        //     $(".countdown-data .st-countdown-by-day").css("display", "none");
                        // } else {
                        //     $(".countdown-data .st-countdown-by-hour").css("display", "none");
                        //     $(".countdown-data .st-countdown-by-day").css("display", "block");
                        // }
                    }
                    break;
            }
            this.timeupdate = new Date().getTime();
        },
        updateValueTime: function () {
            this.block.timeDay = Math.floor(this.timeHour / 24);
            this.block.timeHour = this.timeHour % 24;
            this.block.timeMin = this.timeMin;
            this.block.timeSec = this.timeSec;
            this.block.timeEnd = this.timeEnd;
            this.updateBlock(this.block, "countdownSt");
        },
        showImageManager: function () {
            this.$emit("showImageManager", {
                type: "image",
                dataComp: this.block
            });
        },
        setDataAutoplay: function(data) {
            this.videoData.autoplay = data;
            this.block.video = this.videoData;
            this.renderVideo();
            this.updateBlock(this.block, "videoSt");
            $("#connect-to-data .autoplay-dropdown .list-option").toggle();
        }
    },
    mounted() {
        const block = this.$store.state.editor.blockSelected;
        if (block && block.id) {
            this.block = block;
            this.idSl = block.id;
            this.componentName = block.componentName;
            this.nameBlock = block.name || block.componentName;
            this.getInitialData();
        }
        const thisVue = this;
        $("#select-data-app").on("hidden.bs.modal", function () {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.block[thisVue.typeSelectDataApp] = $.extend(
                    true,
                    {},
                    thisVue.$store.state.editor.dataAppSelect
                );
                thisVue.block[thisVue.typeSelectDataApp].useValue = "model";

                let dataSelect = thisVue.block[thisVue.typeSelectDataApp];
                let computeModelName = "";
                if (thisVue.block && dataSelect) {
                    computeModelName = utils.getValueDataApp(
                        dataSelect.indexTab,
                        dataSelect.keySelect,
                        dataSelect.indexKey
                    );
                }

                switch (thisVue.componentName) {
                    case "blockText":
                        thisVue.finishUpdate = false;
                        setTimeout(function () {
                            thisVue.finishUpdate = true;
                        }, 1500);

                        thisVue.html = computeModelName;
                        thisVue.block.html = computeModelName;
                        thisVue.updateBlock(thisVue.block, "valueTextSt");
                        $("#" + thisVue.block.id + " > .block-content").html(thisVue.block.html);
                        break;
                    case "blockInput":
                    case "blockRadio":
                        thisVue.block[thisVue.typeSelectDataApp] = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                        thisVue.block[thisVue.typeSelectDataApp].useValue = "model";
                    case "blockCheckbox":
                        thisVue.block[thisVue.typeSelectDataApp] = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                        thisVue.block[thisVue.typeSelectDataApp].useValue = "model";
                    case "blockTextarea":
                        if (thisVue.typeSelectDataApp === "dataInput") {
                            if (
                                thisVue.block.componentName === "blockCheckbox" ||
                                thisVue.block.componentName === "blockRadio"
                            ) {
                                thisVue.inputValueCheckboxRadio = computeModelName;
                                thisVue.block.input.value = computeModelName;
                            } else {
                                thisVue.inputModel = computeModelName;
                                thisVue.block.input.model = computeModelName;
                            }
                        } else {
                            thisVue.inputModelCheckboxRadio = computeModelName;
                            thisVue.block.input.model = computeModelName;
                        }
                        thisVue.updateBlock(thisVue.block, "inputSt");
                        break;
                    case "blockSelect":
                        thisVue.block.input.model = thisVue.block.dataInput.keySelect;
                        thisVue.updateBlock(thisVue.block, "selectSt");
                        break;
                    case "blockVideo":
                        thisVue.videoData.idvideo = computeModelName;
                        thisVue.block.video = thisVue.videoData;
                        thisVue.renderVideo();
                        thisVue.updateBlock(thisVue.block, "videoSt");
                        break;
                    case "blockImage":
                        thisVue.block[thisVue.typeSelectDataApp] = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                        thisVue.block[thisVue.typeSelectDataApp].useValue = "model";
                        thisVue.imageSrc = utils.getValueDataApp(
                            thisVue.block[thisVue.typeSelectDataApp].indexTab,
                            thisVue.block[thisVue.typeSelectDataApp].keySelect,
                            thisVue.block[thisVue.typeSelectDataApp].indexKey
                        );
                        thisVue.block.src = thisVue.imageSrc;
                        break;
                }
            }
        });
    }
};
