import Vue from "vue";
import async from "async";
import { setStypeItem } from "@/libs-core/set-style-block.min.js";
import imageService from "./upload-image-manager.service.js";
import imageUserService from "../../layout/layout-left-component/media-manager/media-manger.service";

Vue.directive('outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
});

export default {
  props: {
    dataComp: {
      type: Object,
    },
    typeManagerImage: {
      type: String,
    },
  },
  data: function () {
    return {
      typeManagerImageUl: this.typeManagerImage,
      listImage: [],
      imageSelect: "",
      type: "",
      data: {},
      fileUpload: {},

      newImageCategory: "",
      alert: {
        type: "",
        message: "",
      },
      listImageCategory: [],
      selectImageCategory: "",
      image: {},

      category: "",
      images: [],
      showFolder: true,
      isActiveTitle: true,
      marketCateAndImage: [],
      templateImage: [],
      showCreateNewFolder: true,
      uploadCl: ""
    };
  },
  methods: {
    stopEvent: function (event) {
      event.stopPropagation();
    },
    uploadImg: function(file, cb){
      var thisVue = this;
      if (!file) {
        return cb("not file");
      }
      let formData = new FormData();
      formData.append("file", file);
      imageService.uploadImage(
        {
          file: formData,
          page: thisVue.$route.params.id,
          category: thisVue.selectImageCategory,
        },
        (res) => {
          thisVue.image = res.data;
          if (thisVue.images.length > 0 || !thisVue.showFolder) {
            thisVue.images.unshift(res.data);
          } else {
            thisVue.getAllImageCategory();
          }
          thisVue.image = {};
          cb(null, res);
        }
      );
    },
    handleFileUpload: function () {
      const thisVue = this;
      var listFile = this.$refs.file.files;
      async.eachSeries(listFile, thisVue.uploadImg, function(err, result) {
        if (err) {
          thisVue.doAlert("error", "Please check again!");
        } else {
          thisVue.doAlert("success", "Upload image success!");
        }
        
      });
    },
    selectedImage: function (val) {
      this.imageSelect = val;
    },
    usingImage() {
      const thisVue = this;
      const device = this.$store.state.editor.device;
      const blockSl = this.$store.state.editor.blockSelected;
      if (blockSl && blockSl.componentName == "blockSlider") {
        this.typeManagerImageUl = "";
      } else {
        this.typeManagerImageUl = this.typeManagerImage;
      }

      if (this.imageSelect && this.imageSelect != "") {
        let item;
        switch (this.typeManagerImageUl) {
          case "image":
            this.dataComp.src = this.imageSelect;
            let img = new Image();
            img.src = this.imageSelect;
            let width = parseFloat(thisVue.dataComp[device].width);
            img.onload = function () {
              let tyle = width / this.width;
              thisVue.dataComp[device].height = this.height * tyle + "px";
              if ($("#" + thisVue.dataComp.id + " > .block-content img").length > 0) {
                $("#" + thisVue.dataComp.id + " > .block-content img").attr("src", img.src);
              } else {
                $("#" + thisVue.dataComp.id + " > .block-content").html("<img src='" + img.src + "'/>");
              }
              thisVue.$store.dispatch("editor/updateBlock", { type: "imageSt", val: thisVue.dataComp });
            };
            break;
          case "background":
            if (this.dataComp && this.dataComp[device] && this.dataComp[device].background) {
              this.dataComp[device].background.backgroundImageUrl = this.imageSelect;
              thisVue.$store.dispatch("editor/updateBlock", {
                type: "backgroundSt",
                val: this.dataComp
              });
              setStypeItem(this.dataComp);
            }
            break;
          case "bgInsideSt":
            if (this.dataComp && this.dataComp[device] && this.dataComp[device].backgroundInside) {
              this.dataComp[device].backgroundInside.backgroundImageUrl = this.imageSelect;
              thisVue.$store.dispatch("editor/updateBlock", {
                type: "bgInsideSt",
                val: thisVue.dataComp
              });
              setStypeItem(this.dataComp);
            }
            break;
          case "favicon":
            this.dataComp.favicon = this.imageSelect;
            item = {
              val: this.dataComp,
              type: "true"
            };
            this.$emit("updateSettingPage", item);
            break;
          case "image-share":
            this.dataComp.imageShare = this.imageSelect;
            item = {
              val: this.dataComp,
              type: "true"
            };
            this.$emit("updateSettingPage", item);
            break;
          case "background-page":
            this.$emit("updateBgImagePageUrl", this.imageSelect);
            break;
          default: 
            thisVue.$store.commit("editor/mutaDataAppSelect", {value: thisVue.imageSelect});
            break;
        }
      }
      setTimeout(function () {
        thisVue.cateSelect = "";
        thisVue.imageSelect = "";
        $("#modal-upload-image-manager").modal("hide");
      }, 1000);
    },
    doAlert: function (type, message) {
      if (type === 'success') {
        this.$swal({
          title: "",
          text: message,
          type: "success",
          showConfirmButton: false,
          timer: 2500
        });
        return;
      }
      this.$swal({
        title: "",
        text: message,
        type: "error",
        showConfirmButton: false,
        timer: 2500
      });
    },
    showFolderCreate: function () {
      this.showCreateNewFolder = false;
    },
    createImageTemplate: function () {
      if (!this.newImageCategory) {
        this.showCreateNewFolder = true;
        return;
      }
      let itemCategory = this.listImageCategory.find((x) => x.name === this.newImageCategory);
      if (itemCategory) {
        this.doAlert("danger", "Category already exists, please try again!");
        return;
      }
      imageService.createCate(
        JSON.stringify({
          name: this.newImageCategory,
          type: "user",
          mode: "private",
          type_second: "image",
        }),
        (res) => {
          this.showCreateNewFolder = true;
          this.doAlert("success", "Create category success!");
          this.getAllImageCategory();

        }
      );
      this.newImageCategory = "";
    },
    getAllImageCategory: function () {
      imageService.getCateAndImage(
        JSON.stringify({
          owner: JSON.parse(localStorage.getItem("user")).id,
          type_second: "image",
          type: "user",
          asset_limit: 500,
          cate_limit: 500
        }),
        (res) => {
          this.$store.commit("editor/mutaCateAndImage", res.data);
          console.log("res.data::; ", res.data);
          this.listImageCategory = this.$store.state.editor.cate_and_image;
        }
      );
    },
    deleteFolder: async function (cate_id) {
      let confirm = await this.$swal({
        title: "Be careful",
        text:
          "This action will delete all images inside the catalog, and cannot be recovered",
        type: "warning",
        showCloseButton: true,
        showCancelButton: true,
      });
      if (!confirm.value) return;
      imageService.deleteCate(
        JSON.stringify({ category_id: cate_id }),
        (res) => {
          if (this.showFolder === false) {
            this.showFolder = true;
          }
          else {
            $(".popup-menu-item-folder-image").css("display", "none");
          }
          this.getAllImageCategory();

        }
      );
    },
    showPopupMenuFolderImage: function (type, event) {
      let indexCate = event.target.getAttribute("data-index-cate");
      let selector = `#modal-upload-image-manager .right-modal-body .folder-image .item-folder-image:nth-child(${Number(indexCate) + 2}) .popup-menu-item-folder-image`;
      if (type === "open") {
        $(selector).css("display", "flex");
        return;
      }
      $(".popup-menu-item-folder-image").css("display", "none");
    },
    showPopupMenuImage: function (type, event) {
      let indexCate = event.target.getAttribute("data-index-cate");
      let selector = `#modal-upload-image-manager .right-modal-body .folder-image .item-folder-image:nth-child(${Number(indexCate) + 1}) .popup-menu-item-folder-image`;
      if (type === "open") {
        $(selector).css("display", "flex");
        return;
      }
      $(".popup-menu-item-folder-image").css("display", "none");
    },
    showImageByCate: function (cate) {
      this.showFolder = false;
      this.images = cate.assets;
      this.category = cate;
      if (cate.id) {
        this.selectImageCategory = cate.id;
      }
    },
    back: function () {
      this.images = [];
      this.showFolder = true;
      this.selectImageCategory = "";
      this.category = "";
    },
    showActiveTitle: function (active) {
      if (active === false) {
        this.isActiveTitle = false;
        this.showFolder = true;
        return;
      }
      this.isActiveTitle = true;
      this.showFolder = true;
    },
    getMaketImageCate: function () {
      imageUserService.getMaketCateAndImage(
        JSON.stringify({
          asset_limit: 3,
        }),
        (res) => {
          this.marketCateAndImage = res.data;
        }
      );
    },
    getImageTemplate: function (cate) {
      this.category = cate;
      imageUserService.getImageTemplate(
        $.param({
          category: cate.id,
          skip: this.skip,
          limit: 500, //20
        }),
        (res) => {
          this.templateImage = res.data;
          this.showFolder = false;
        }
      );
    },
    deleteImage: async function (id, event) {
      $(".popup-menu-item-folder-image").css("display", "none");
      let result = await this.$swal({
        title: "Be careful!",
        text: "This action will delete image, and cannot be recovered",
        type: "warning",
        showCancelButton: true,
      });
      if (!result || !result.value) {
        return;
      }
      let res = await imageUserService.deleteImage(id);
      if (res.code === 200) {
        this.images = this.images.filter((item) => item.id !== id);
        this.getAllImageCategory();
      }
    },
  },
  mounted: function () {
    var thisVue = this;
    this.getAllImageCategory();
    this.getMaketImageCate();
    var pageId = this.$route.params.id;
    localStorage.removeItem("showuploadimage");
    $("#modal-upload-image-manager").on("shown.bs.modal", function () {
      thisVue.imageSelect = "";
      thisVue.uploadCl = localStorage.getItem("showuploadimage");
    })
    
    $("#modal-upload-image-manager").on("hidden.bs.modal", function () {
      thisVue.uploadCl = "";
      localStorage.removeItem("showuploadimage");
    })
    
  },
};
