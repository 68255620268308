import { utils } from "@/libs-core/utils.min.js";

export default {
    data() {
        return {
            block: "",
            idSl: "",
            conditionData: {},
            timeShow: 0,
            dataAppSelect: {},
            contentCondi: ""
        };
    },
    computed: {
        selectDataInfo() {
            const thisVue = this;

            if (this.dataAppSelect.indexTab === undefined) return "";
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= this.dataAppSelect.indexTab) return "";
            return utils.getValueDataApp(
                thisVue.dataAppSelect.indexTab,
                thisVue.dataAppSelect.keySelect,
                thisVue.dataAppSelect.indexKey
            );
        },
        computedConditionName() {
            switch (this.conditionData.condi) {
                case "smaller":
                    return "< Less than";
                case "larger":
                    return "> Greater than";
                case "equal":
                    return "== Equal";
                case "different":
                    return "!= Not Equal";
                case "lessequal":
                    return "<= Less than or Equal";
                case "greaterequal":
                    return ">= Greater than or Equal";
            }
            return "";
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL) {
                this.idSl = val.id;
                this.block = val;
                this.conditionData = $.extend(true, {}, val.condition) || {
                    key: "",
                    condi: "",
                    value: ""
                };
                if (this.conditionData.dataAppSelect) {
                    this.dataAppSelect = this.conditionData.dataAppSelect;
                } else {
                    this.dataAppSelect = {};
                }
                this.contentCondi = this.conditionData.condi;
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                    this.conditionData = $.extend(true, {}, val.condition) || {
                        key: "",
                        condi: "",
                        value: ""
                    };
                    this.contentCondi = this.conditionData.condi;
                    if (this.conditionData.dataAppSelect) {
                        this.dataAppSelect = this.conditionData.dataAppSelect;
                    } else {
                        this.dataAppSelect = {};
                    }
                }
            }
        },
        "conditionData.value"() {
            this.block.condition = this.conditionData;
            this.updateBlock(this.block);
        }
    },
    methods: {
        showSelectDataApp: function () {
            const dataAppSelect = this.conditionData.dataAppSelect;
            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, dataAppSelect);

            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
        },
        refreshCondition() {
            let defaultData = {
                key: "",
                condi: "",
                value: ""
            };
            this.dataAppSelect = {};
            this.conditionData = defaultData;
            this.block.condition = defaultData;
            this.updateBlock(this.block);
        },
        updateBlock(val) {
            this.block = val;
            this.$store.dispatch("editor/updateBlock", { val, type: "conditionSt" });
        },
        selectCondition: function (val) {
            var thisVue = this;
            $("#condition-st .condition-list .list-option").hide();
            if (!thisVue.conditionData) {
                thisVue.conditionData = {
                    key: "",
                    condi: "",
                    value: ""
                };
            }
            this.contentCondi = val;
            console.log("----------------------");
            console.log("val:: ", val);
            thisVue.conditionData.condi = val;
            console.log("thisVue.conditionData.condi:: ", thisVue.conditionData.condi);
            thisVue.block.condition = thisVue.conditionData;
            thisVue.updateBlock(thisVue.block);
        },
        showSelect: function (val) {
            var thisVue = this;
            $("#condition-st .condition-list .list-option").show();
        }
    },
    mounted() {
        const thisVue = this;
        $("#condition-st .list-option").hide();

        this.block = $.extend(true, {}, this.$store.state.editor.blockSelected);

        if (this.block && this.block.id) {
            this.idSl = this.block.id;
            this.conditionData = this.block.condition || {
                key: "",
                condi: "equal",
                value: ""
            };
            this.contentCondi = this.conditionData.condi;
        } else {
            this.block = {};
            this.conditionData = {
                key: "",
                condi: "equal",
                value: ""
            };
            this.contentCondi = "equal";
        }
        if (this.conditionData.dataAppSelect) {
            this.dataAppSelect = this.conditionData.dataAppSelect;
        } else {
            this.dataAppSelect = {};
        }

        $("#select-data-app").on("hidden.bs.modal", function () {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                thisVue.conditionData.dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                thisVue.conditionData.key = thisVue.conditionData.dataAppSelect.keySelect;
                thisVue.block.condition = $.extend(true, {}, thisVue.conditionData);
                thisVue.timeShow = new Date().getTime();
                thisVue.updateBlock(thisVue.block);
            }
        });
    }
};
