import service from "./manager-api-template.service.js";
import { utils } from "@/libs-core/utils.min.js";
import VueTribute from "vue-tribute";
import VueTagsInput from "@johmun/vue-tags-input";
import constant from "../../../../constants/index";
import FormApiTemplate from "./form-api-template/form-api-template.vue";

export default {
    components: {
        VueTribute,
        VueTagsInput,
        FormApiTemplate
    },
    props: ["blockselected"],
    data() {
        return {
            error_message: "",
            success_message: "",
            show: false,
            listApi: [],
            api_data: {
                name: "",
                description: "",
                type: "api",
                uri: "",
                method: "GET"
            },
            typeApi: "api",
            nameConfig: "",
            uriConfig: "",
            nameVariableConfig: "",
            apitemplateSelected: {},
            action: "list", // list or create or edit
            tabIndex: 0,
            package_type: ""
        };
    },
    watch: {
        error_message(val) {
            if (!val) return;
            this.$swal({
                title: "",
                text: val,
                type: "error",
                showConfirmButton: false,
                timer: 2500
            });
            this.error_message = "";
        },
        success_message(val) {
            if (!val) return;
            var lang = localStorage.getItem("langao");
            if (lang) {
                lang = JSON.parse(lang).value;
            }
            else {
                lang = "vn"
            }
            if (lang == "vn") {
                val = "Lưu API thành công";
            }
            this.$swal({
                title: "",
                text: val,
                type: "success",
                showConfirmButton: false,
                timer: 2500
            });
            this.success_message = "";
        }
    },
    methods: {
        close() {
            this.apitemplateSelected = {};
            $("#api-template-v3").modal("hide");
        },
        getAllApiTemplate() {
            const thisVue = this;
            service.getAllConfig(
                {
                    page: thisVue.$route.params.id
                },
                function(data) {
                    thisVue.listApi = data.data;
                    thisVue.$emit("updateListApiTemplate", thisVue.listApi);
                }
            );
        },
        selectedApi(val) {
            let user = JSON.parse(localStorage.getItem("user"));
            if (
                this.listApi &&
                this.listApi.length > 10 &&
                this.package_type != "unlimited" &&
                !utils.checkEmailNv(user.email)
            ) {
                this.$swal({
                    title: "",
                    text: "Please upgrade the app to use this feature more!",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                const thisVue = this;

                if (val) {
                    thisVue.api_data = val;
                } else {
                    this.api_data = {
                        id: "",
                        name: "",
                        description: "",
                        type: "api",
                        uri: "",
                        method: "GET"
                    };
                }
            }
        },
        deleteApi(id, vt, e) {
            const thisVue = this;

            e.preventDefault();
            e.stopPropagation();
            this.$swal({
                title: "",
                text: "You won't be able to revert this action!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.value) {
                    service.deleteConfig(JSON.stringify({ id: id, page: thisVue.$route.params.id }), function(
                        data
                    ) {
                        if (data && data.code === 200) {
                            thisVue.getAllApiTemplate();
                            thisVue.selectedApi();
                        }
                    });
                }
            });
        },
        saveApi(val) {
            const thisVue = this;

            if (val && !val.name) {
                thisVue.error_message = "Name not found";
                return;
            }
            if (val && !val.url) {
                thisVue.error_message = "Url not found";
                return;
            }
            if (val && val.url && utils.parseHttps(val.url) === false) {
                thisVue.error_message = "Url Invalid (require http)";
                return;
            }
            let api = this.listApi.find((x) => x.name === val.name);
            if (api && !val.id) {
                thisVue.error_message = "Name already exists please try again with another name!";
            } else {
                let dataSave = {
                    type: "api",
                    page: thisVue.$route.params.id,
                    id: val.id,
                    name: val.name,
                    configs: {
                        url: val.url,
                        method: val.method,
                        params: val.params,
                        body: val.body,
                        header: val.header,
                        security: val.security
                    }
                };
                if (dataSave.id && dataSave.id.length > 0) {
                    service.updateConfig(JSON.stringify(dataSave), (data) => {
                        if (data.code === 200) {
                            thisVue.getAllApiTemplate();
                            thisVue.error_message = "";
                            thisVue.success_message = "Save API Success";
                            if (thisVue.$store.state.editor.before_popup.type === "page-action") {
                                thisVue.$store.commit("editor/mutaBeforePopup", {});
                                $("#action-page-setting").modal("show");
                                $("#api-template-v3").modal("hide");
                            } else if (thisVue.$store.state.editor.before_popup.type === "block-action") {
                                thisVue.$store.commit("editor/mutaBeforePopup", {});
                                $("#action-setting").modal("show");
                                $("#api-template-v3").modal("hide");
                            }
                        } else {
                            thisVue.error_message = "Error Template";
                        }
                    });
                } else {
                    service.createConfig(JSON.stringify(dataSave), (data) => {
                        if (data.code === 200) {
                            thisVue.getAllApiTemplate();
                            thisVue.error_message = "";
                            thisVue.success_message = "Save API Success"; 
                            if (thisVue.$store.state.editor.before_popup.type === "page-action") {
                                thisVue.$store.commit("editor/mutaBeforePopup", {});
                                $("#action-page-setting").modal("show");
                                $("#api-template-v3").modal("hide");
                            } else if (thisVue.$store.state.editor.before_popup.type === "block-action") {
                                thisVue.$store.commit("editor/mutaBeforePopup", {});
                                $("#action-setting").modal("show");
                                $("#api-template-v3").modal("hide");
                            }
                        } else {
                            thisVue.error_message = "Error Template";
                        }
                    });
                }
            }
        },
        convertApi(val) {
            if (val.config) {
                return val;
            } else {
                val.config = {};
                val.config.url = val.uri;
                val.config.method = val.method;
            }
        }
    },
    mounted() {
        const thisVue = this;

        $("#api-template-v3").on("shown.bs.modal", function() {
            thisVue.api_data = constant._api.manage_api_template.api_data;
            thisVue.options_tribute = constant._tribute.options($listModel);
            thisVue.getAllApiTemplate();
            thisVue.show = true;
            thisVue.package_type = localStorage.getItem("package_type");
        });

        $("#api-template-v3").on("hidden.bs.modal", function() {
            thisVue.$store.commit("editor/mutaBeforePopup", {});
        });
    }
};
