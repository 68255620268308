import { sevice } from "./payment-app.service.js";
export default {
    data: function () {
        return {
            package: 'CLOUD_1_6',
            user: {},
            page: '',
            payType: "vnpay"
        }
    },
    methods: {
        vnPayPayment() {
            if (!this.package) {
                return this.$swal({
                    title: 'Required package',
                    type: 'error'
                })
            }

            sevice.createInvoiceVnPay({
                package: this.package,
                app_id: this.page
            }, (res) => {
                console.log(res)
                if (!res || !res.data || !res.data.vn_pay_url) {
                    return this.$swal({
                        title: 'Payment error',
                        type: 'error'
                    })
                }

                window.open(res.data.vn_pay_url, '_blank');
            })
        },
        paypalPayment() {
            let _this = this
            if (!this.package) {
                return this.$swal({
                    title: 'Required package',
                    type: 'error'
                })
            }

            $('#paypal-button-container').empty()
            paypal.Buttons({
                createOrder: async function (data, actions) {
                    let id = await new Promise(resolve => {
                        sevice.createInvoicePaypal({
                            package: _this.package,
                            app_id: _this.page
                        }, (res) => {
                            if (
                                res && res.data && res.data.paypal
                                && res.data.paypal.result
                                && res.data.paypal.result.id
                            ) {
                                console.log(res.data.paypal.result.id)
                                resolve(res.data.paypal.result.id)
                                return res.data.paypal.result.id
                            } else {

                            }
                        })
                    })
                    return id
                },
                onApprove: function (data, actions) {
                    $('#paypal-button-container').empty()
                    _this.$swal({
                        title: 'Done transaction',
                        text: 'Please wait 10 minutes, your app has been upgrade to cloud',
                        type: 'success'
                    })
                }
            }).render('#paypal-button-container');
        },
        payment: function() {
            var thisVue = this;
            switch(this.payType) {
                case 'vnpay': 
                    thisVue.vnPayPayment();
                break;
                case 'paypal':
                    thisVue.paypalPayment();
                break;
            }
        }
    },
    mounted: function () {
        var thisVue = this;
        $("#payment-app").on("hidden.bs.modal", function () {
            return;
        });
        $("#payment-app").on("shown.bs.modal", function () {
            let user = localStorage.getItem("user");
            thisVue.user = JSON.parse(user);
            thisVue.page = thisVue.$route.params.id
        });
    }
}