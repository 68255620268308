import resful from "@/services/resful.js";

export default {
  uploadImage: async function (data, next) {
    let res = await resful.uploadImage("/app/upload", data);
    next(res);
  },
  getAllImage: async function (data, next) {
    let res = await resful.post("/app/asset", data);
    next(res);
  },
  deleteImage: async function (id) {
    let res = await resful.get("/app/delete_asset?asset_id=" + id)
    return res
  },
  getCateAndImage: async function(data, next) {
    let res = await resful.post("/app/get_cate_asset", data);
    next(res);
  }, 
  getMaketCateAndImage: async function(data, next) {
    let res = await resful.post("/app/get_maket_cate_asset", data);
    next(res);
  },
  getImageTemplate: async function(data, next) {
    let res = await resful.get("/app/templates?" + data);
    next(res);
  }  
};
