import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import ImagePicker from "@/components/editor-v3/setting-blocks/image-picker/image-picker.vue";

export default {
    components: {
        ColorPicker,
        ImagePicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            idSL: "",
            device: "",
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},
            listBorderStyle: ["none", "dotted", "dashed", "solid"],
            borderStyle: "solid",
            borderWidth: "0",
            borderColor: "#000000",
            borderType: "inside",
            listMargin: "0",
            listDirection: "down",
            listDisplay: "normal",
            marginCollection: [1, 5, 10, 15, 20, 25, 30, 40, 50],

            // BACKGROUND DATA
            bgType: "color",
            backgroundImageType: "0",
            backgroundImageRepeat: "0",
            isGraPicker: "",
            backgroundColor: "#FFFFFF",
            overlayBackgroundImage: "",
            colorOverlay: "",
            backgroundImageUrl: "",
            backgroundData: {},
            isOverlayGraPicker: ""
        };
    },
    computed: {
        borderTypeName() {
            let name = this.borderType.charAt(0).toUpperCase() + this.borderType.substring(1);
            return name;
        },
        borderStyleName() {
            let name = this.borderStyle.charAt(0).toUpperCase() + this.borderStyle.substring(1);
            return name;
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (
                val &&
                val.componentName === "blockList" &&
                (val.id !== this.idSL || !_.isEqual(val.background, this.backgroundData))
            ) {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;

                if (val && val[this.device] && val[this.device].background) {
                    this.backgroundData = $.extend(true, {}, val[this.device].background);
                } else {
                    this.backgroundData = {};
                    this.block.mobile.background = {};

                    this.isGraPicker = false;
                    this.isOverlayGraPicker = true;
                }
                this.getInitialData();
            }else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device": function(val) {
            this.device = val;
        },
        listMargin: _.debounce(function() {
            this.updateSettingList();
        }, 300),
        borderWidth: _.debounce(function() {
            if (this.borderWidth < 0) {
                this.borderWidth = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.block.border.borderWidth = this.borderWidth + "px";
            this.block[this.device].border.borderWidth = this.borderWidth + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300)
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager", {
                type: "background",
                dataComp: this.block
            });
        },
        updateColor(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].background = {};
            } else {
                if (!this.block[this.device].background) {
                    this.block[this.device].background = {};
                }
            }

            this.backgroundColor = color;
            this.block[this.device].background.isGraPicker = this.isGraPicker;
            this.block[this.device].background.backgroundColor = color;
            this.backgroundData = this.block[this.device].background;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateOverlayColor(color) {
            if (this.block && this.backgroundData) {
                this.colorOverlay = color;
                this.backgroundData.colorOverlay = color;

                if (!this.isOverlayGraPicker) {
                    color = `linear-gradient( ${color}, ${color})`;
                }

                this.overlayBackgroundImage = color;
                this.backgroundData.overlayBackgroundImage = color;
                this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                this.block[this.device].background = this.backgroundData;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        setBgType(val) {
            if (this.block && this.backgroundData) {
                this.bgType = val;
                this.backgroundData.backgroundType = val;
                this.block[this.device].background.backgroundType = val;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        updateBgImageData(val) {
            if (!val) return;
            this.backgroundData.backgroundImageUrl = val.backgroundImageUrl;
            this.backgroundData.backgroundImageRepeat = val.backgroundImageRepeat;
            this.backgroundData.backgroundImageType = val.backgroundImageType;
            this.block[this.device].background = this.backgroundData;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBorderColor(color) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderColor = color;
            this.block.border.borderColor = color;
            this.block[this.device].border.borderColor = color;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderType(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderType = val;
            this.block.border.borderType = val;
            this.block[this.device].border.borderType = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderStyle(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderStyle = val;
            this.block.border.borderStyle = val;
            this.block[this.device].border.borderStyle = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateSettingList() {
            if (!this.block.settingList) {
                this.block.settingList = {};
            }
            this.block.settingList.direction = this.listDirection;
            this.block.settingList.displayslider = this.listDisplay;
            this.block.settingList.margin = this.listMargin;
            this.updateBlock(this.block, "listSt");
        },
        updateBlock(val, type) {
            let blockStore = this.$store.state.editor.blockSelected || {};
            this.block = val;
            if (type === "borderSt" && !val.border.borderStyle) {
                val.border.borderStyle = "solid";
                val[this.device].border.borderStyle = "solid";
            }
            if (_.isEqual(val, blockStore) || blockStore.componentName !== "blockList") return;
            this.$store.dispatch("editor/updateBlock", { val, type: type });
        },
        getInitialData() {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
            }
            if (!this.block.settingList) {
                this.block.settingList = {};
            }
            let borderData = this.block[this.device].border || {};
            let settingList = this.block.settingList;
            let backgroundData = this.backgroundData;

            this.borderColor = borderData.borderColor || "#CCCCCC";
            this.borderWidth = borderData.borderWidth ? parseFloat(borderData.borderWidth) : "0";
            this.borderType = borderData.borderType || "inside";
            this.borderStyle = borderData.borderStyle || "solid";
            this.listMargin = settingList.margin || 0;
            this.listDirection = settingList.direction || "down";
            this.listDisplay = settingList.displayslider || "normal";

            this.bgType = backgroundData.backgroundType || "color";
            this.isGraPicker = backgroundData.isGraPicker || false;
            this.backgroundColor = backgroundData.backgroundColor || "#FFFFFF00";
            this.overlayBackgroundImage = backgroundData.overlayBackgroundImage || "#00000000";
            this.colorOverlay = backgroundData.colorOverlay || "#00000000";
            this.backgroundImageUrl = backgroundData.backgroundImageUrl || "";
            this.isOverlayGraPicker = backgroundData.isOverlayGraPicker || false;
            this.backgroundImageType = backgroundData.backgroundImageType || "0";
            this.backgroundImageRepeat = backgroundData.backgroundImageRepeat || "0";
        },
        handleClickRemove(type, item) {
            if (type === "effect") {
                this.listEffectBlock = this.listEffectBlock.filter((i) => i !== item);
                this.effectProperty[item] = "";
                this.updateEffectConfig();
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let topSet;

            switch (nameSection) {
                case "fold":
                    if ($("#list-st").hasClass("fold-section")) {
                        $("#list-st").removeClass("fold-section");
                    } else {
                        $("#list-st").addClass("fold-section");
                    }
                    break;
                case "list-direct":
                    $("#list-st .list-direct-dropdown .list-option").toggle();
                    if (val) {
                        this.listDirection = val;
                        if (val === "down") {
                            this.listDisplay = "normal";
                        }
                        this.updateSettingList();
                    }
                    break;
                case "margin":
                    elementHeight = $("#list-st .margin-dropdown .list-option").outerHeight();

                    $("#list-st .margin-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition - 8;
                    }
                    $("#list-st .margin-dropdown .list-option").css({
                        top: `${topSet}px`
                    });

                    if (val || val == 0) {
                        this.listMargin = val;
                        this.updateSettingList();
                    }
                    break;
                case "display-list":
                    $("#list-st .display-list-dropdown .list-option").toggle();

                    if (val) {
                        this.listDisplay = val;
                        this.updateSettingList();
                    }
                    break;
                case "border":
                    $("#list-st .style-border .list-option")
                        .eq(0)
                        .toggle();

                    if (val) {
                        this.updateBorderType(val);
                    }
                    break;
                case "border-style":
                    $("#list-st .style-border .list-option")
                        .eq(1)
                        .toggle();

                    if (val) {
                        this.updateBorderStyle(val);
                    }
                    break;
                case "style-color":
                    $("#list-st .fill-bg .list-style-fill").toggle();
                    if (val === "image") {
                        this.isGraPicker = "";
                        this.setBgType("image");
                        this.backgroundData.backgroundType = "image";
                    } else {
                        this.isGraPicker = val === "gradient" ? true : false;
                        this.setBgType("color");
                        this.backgroundData.backgroundType = "color";
                    }
                    this.backgroundData.isGraPicker = this.isGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "style-overlay":
                    $("#list-st .fill-bg .list-style-overlay").toggle();
                    this.isOverlayGraPicker = val === "gradient" ? true : false;

                    this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
            }
        },
        closeSt: function() {
            $("#list-st").parent().hide();
        },
        closeDrop: function(event) {
            $(event.target).hide();
        },
    },
    mounted() {
        this.blockSelected = this.$store.state.editor.blockSelected || {};
        this.device = this.$store.state.editor.device;
        this.block = this.blockSelected;
        if (this.blockSelected && this.blockSelected[this.device]) {
            if (this.blockSelected[this.device].background) {
                this.backgroundData = this.blockSelected[this.device].background;
            } else {
                this.block.mobile.background = {};
                this.backgroundData = {};
                this.bgType = "color";
                this.backgroundImageType = "0";
                this.backgroundImageRepeat = "0";
                this.backgroundColor = "rgba(0,0,0,0)";
            }
            this.getInitialData();
        }
    }
};
