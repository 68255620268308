import { sevice } from "./upgrade-app.service.js";
export default {
    data: function () {
        return {
            listUpgrade: [],
            listInvoice: [],
            listPlans: [],
            user: {},
            plansSelect: "",
            upgradeSelect: "",
            skip: 0
        }
    },
    methods: {
        upgradeApp: function () {
            var thisVue = this;
            if (thisVue.upgradeSelect && thisVue.upgradeSelect.id) {
                sevice.upgradeApp(JSON.stringify({page: thisVue.$route.params.id, upgrade: thisVue.upgradeSelect.id}), function(data) {
                   if (data && data.code === 200) {
                    $("#upgrade-app").modal("hide");
                    thisVue.$swal({
                        position: "bottom-start",
                        type: "success",
                        title: "Your app has been upgrade.",
                        showConfirmButton: false,
                        timer: 1500
                      });
                   } else {
                       if (data && data.message === "THIS_APP_UPGRADED_CLOUD") {

                       } else {

                       }
                   }
                })
            } else {
                this.$swal({
                    title: "",
                    text: "Please select Upgrade!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Close"
                });
            }
        },
        getAllUpgrade: function () {
            var thisVue = this;
            sevice.getAllUpgrade({skip: thisVue.skip}, function (data) {
                if (data && data.code === 200) {
                    if (data.data < 20) {

                    } else {
                        thisVue.skip += 20;
                        thisVue.getAllUpgrade();
                    }
                    thisVue.listUpgrade = thisVue.listUpgrade.concat(data.data);
                } else {

                }
            });
        },
        getAllInvoice: function () {
            var thisVue = this;
            sevice.getAllInvoice({}, function (data) {
                if (data && data.code === 200) {
                    thisVue.listInvoice = data.data;
                } else {

                }
            });
        },
        getAllPlans: function () {
            var thisVue = this;
            sevice.getAllPlan({}, function (data) {
                if (data && data.code === 200) {
                    thisVue.listPlans = data.data;
                } else {

                }
            });
        },
        activeInvoice: function (val) {
            var thisVue = this;
            sevice.activeInvoice(JSON.stringify({ invoice: val.id }), function (data) {
                if (data && data.code === 200) {
                    thisVue.getAllInvoice();
                    var user = localStorage.getItem("user");
                    user = JSON.parse(user);
                    user.point = user.point - val.amount_point;
                    thisVue.user = user;
                    localStorage.setItem("user", JSON.stringify(user));
                    thisVue.getAllUpgrade();
                } else {
                    thisVue.$swal({
                        title: "",
                        text: "An error occurred, please try again!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Close"
                    });
                }
            })
        },
        createPlans: function () {
            if (this.plansSelect && this.plansSelect.PACKAGES_NAME) {
                var thisVue = this;
                sevice.createInvoice(JSON.stringify({ package: thisVue.plansSelect.PACKAGES_NAME }), function (data) {
                    if (data && data.code === 200) {
                        thisVue.getAllInvoice();
                    } else {
                        thisVue.$swal({
                            title: "",
                            text: "An error occurred, please try again!",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Close"
                        });
                    }
                });
            } else {
                this.$swal({
                    title: "",
                    text: "Please select Plans!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Close"
                });
                //   .then(result => {
                //     if (result.value) {
                //       dashboardSV.removePage(JSON.stringify(data), function(data) {
                //         console.log("delete: ", data);
                //         thisVue.$emit("deleteItemPage", data);
                //       });
                //     }
                //   });
            }
        },
        formatPrice: function (price) {
            let val = (price * 1000 / 1).toFixed(0).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    mounted: function () {
        var thisVue = this;
        $("#upgrade-app").on("hidden.bs.modal", function () {
            return;
        });
        $("#upgrade-app").on("shown.bs.modal", function () {
            var user = localStorage.getItem("user");
            thisVue.user = JSON.parse(user);
            thisVue.getAllPlans();
            thisVue.getAllUpgrade();
            thisVue.getAllInvoice();
        });
    }
}