import Language from "@/components/shared/language/language.vue";
export default {
    props: [
        "infoApp",
        "listPage",
        "pageSelect"
    ],
    data() {
        return {
            activeHover: "",
            activeSelect: "",
            device: "mobile"
        }
    },
    components: {
        Language
    },
    methods: {
        changeDevice: function() {
            this.$store.dispatch('editor/changeDevice', this.device);
            this.$store.commit('editor/setDevice', this.device);
        },
        saveApp: function() {
            this.$emit("saveApp", {});
            this.$swal({
                position: "bottom-start",
                type: "success",
                title: "Your app has been saved.",
                showConfirmButton: false,
                timer: 1500
            });
        },
        showUpGrade: function() {
            $("#payment-app").modal("show");
        },
        openModalDeploy: function() {
            console.log("show publish");
            $("#modal-setting-publish").modal("show");
        },
        showSettingPage: function() {
            $("#modal-setting-page").modal("show");
        },
        showAddNewPage: function() {
            this.$emit("showAddNewPage");
        },
        changePage: function(item) {
            this.$emit("changePage", item);
        }
    },
    mounted: function() {
       console.log("this.listPage:: ", this.listPage);
    }
}