import { ShapeTemplate } from "@/libs-core/shape-template.min.js";

export default {
    props: ["dataComp"],
    data() {
        return {
            svgContent: "",
            listSvgTemplate: [],
            idSl: "",
            listCateTemplate: [],
            cateTemplate: "all",
            typeTemplate: "icons"
        };
    },
    computed: {
        computeCateTemplateName() {
            let arrChar = this.cateTemplate.split("");

            if (this.cateTemplate === "all") return "All Shape";
            return arrChar
                .map((item, ind) => {
                    if (item === "_") return " ";
                    if (ind === 0 || arrChar[ind - 1] === "_") return item.toUpperCase();
                    return item;
                })
                .join("");
        },
        computeTypeTemplateName() {
            switch (this.typeTemplate) {
                case "icons":
                    return "Icons";
                case "shapes":
                    return "Shapes";
            }
        }
    },
    methods: {
        generateListTemplate() {
            let listType = Object.keys(ShapeTemplate);
            listType = listType.filter((item) => {
                if (item.includes("icons") && this.typeTemplate === "icons") return item;
                if (!item.includes("icons") && this.typeTemplate === "shapes") return item;
            });

            this.listSvgTemplate = [];
            this.listCateTemplate = listType;
            if (this.cateTemplate === "all") {
                listType.forEach((item) => {
                    this.listSvgTemplate = this.listSvgTemplate.concat(ShapeTemplate[item]);
                });
            } else {
                this.listSvgTemplate = ShapeTemplate[this.cateTemplate];
            }
            $("#modal-svg-manager .modal-body").scrollTop(0);
        },
        changeHtml() {
            this.dataComp.html = this.svgContent.replace("<svg", "<svg preserveAspectRatio='none' ");
            $("#" + this.dataComp.id + " > .block-content").html(this.dataComp.html);
            $("#" + this.dataComp.id + " > .block-content svg").attr({ height: "100%", width: "100%", preserveAspectRatio :"none" });

            this.$store.dispatch("editor/updateBlock", { val: this.dataComp, type: "htmlSvgSt" });
            $("#modal-svg-manager .item-svg").removeClass("active-item");
        },
        selectItem(val, ind) {
            this.svgContent = val;
            $("#modal-svg-manager .item-svg").removeClass("active-item");
            $(`#modal-svg-manager .item-svg:nth-child(${ind + 1})`).addClass("active-item");
        },
        cateTemplateName(val) {
            let arrChar = val.split("");

            if (val === "all") return "All Shape";
            return arrChar
                .map((item, ind) => {
                    if (item === "_") return " ";
                    if (ind === 0 || arrChar[ind - 1] === "_") return item.toUpperCase();
                    return item;
                })
                .join("");
        },
        toggleSection(type, event, val) {
            switch (type) {
                case "cate-template":
                    $("#modal-svg-manager .category-dropdown .list-option").toggle();
                    if (val) {
                        this.cateTemplate = val;
                        this.generateListTemplate();
                    }
                    break;
                case "type-template":
                    $("#modal-svg-manager .type-dropdown .list-option").toggle();
                    if (val) {
                        this.typeTemplate = val;
                        this.cateTemplate = "all";
                        this.generateListTemplate();
                    }
                    break;
            }
        }
    },
    mounted() {
        let thisVue = this;
        $("#modal-svg-manager").on("shown.bs.modal", function() {
            let val = thisVue.dataComp;
            if (val && val.html && thisVue.idSl != val.id && val.componentName === "blockSvg") {
                thisVue.svgContent = val.html;
                thisVue.idSl = val.id;
            }
            thisVue.generateListTemplate();
        });
        $("#modal-svg-manager").on("hide.bs.modal", function() {
            $("#modal-svg-manager .modal-body").scrollTop(0);
        });
    }
};
