export default {
    data() {
        return {
            componentName: "",
            block: {},
            idSL: "",
            listDistance: ["5", "10", "15", "20", "25", "30", "40", "50", "75", "100", "150", "200", "300", "500"],
            stickyPosition: "none",
            stickyDistance: 0
        };
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL) {
                this.idSL = val.id;
                this.block = $.extend(true, {}, val);
                this.componentName = val.componentName;
                this.getInitialData();
            }else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        stickyDistance: _.debounce(function(val) {
            this.block.sticky.distance = val;
            this.updateBlock(this.block);
        }, 300),
        stickyPosition: _.debounce(function(val) {
            this.stickyPosition = val;
            this.block.sticky.position = val;
            this.updateBlock(this.block);
        }, 300),
    },
    methods: {
        updateBlock(val) {
            this.block = val;
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "stickySt" });
        },
        getInitialData() {
            if (!this.block.sticky) {
                this.block.sticky = {};
            }
            this.stickyPosition = this.block.sticky.position || "none";
            this.stickyDistance = this.block.sticky.distance || 0;
        },
        closeDrop: function(event) {
            $(event.target).hide();
        },
        closeSt: function(event) {
            $("#sticky-st").parent().hide();
        }
    },
    mounted() {
        this.block = this.$store.state.editor.blockSelected || {};
        this.device = this.$store.state.editor.device;
        this.idSL = this.block.id;
        this.componentName = this.block.componentName;
        this.getInitialData();
        $(".apo-dropdown").off("click").on("click", function () {
            $(".apo-dropdown-list").hide();
            $(this).parent().find(".apo-dropdown-list").show();
        });
        $(".apo-dropdown").off("blur").on("blur", function() {
            $(".item-q-setting").hide();
            $("#quick-setting-block .item").removeClass("active");
        })
    }
};
