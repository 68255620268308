import { listSvgTemplate } from "@/libs-core/list-svg-template.min.js";

export default {
    data() {
        return {
            componentName: "",
            block: {},
            device: "",
            idSL: "",

            // SVG THEME
            listSvgTemplate: listSvgTemplate,
            svgContent: "",

            // CHECKBOX RADIO STYLE
            listThemeRadio: [
                { name: "Theme 1", value: "theme-radio-1" },
                { name: "Theme 2", value: "theme-radio-2" },
                { name: "Theme 3", value: "theme-radio-3" },
                { name: "Theme 4", value: "theme-radio-4" },
                { name: "Theme 5", value: "theme-radio-5" },
                { name: "Theme 6", value: "theme-radio-6" }
            ],
            listThemeCheckbox: [
                { name: "Theme 1", value: "theme-checkbox-1" },
                { name: "Theme 2", value: "theme-checkbox-2" },
                { name: "Theme 3", value: "theme-checkbox-3" },
                { name: "Theme 4", value: "theme-checkbox-4" },
                { name: "Theme 5", value: "theme-checkbox-5" },
                { name: "Theme 6", value: "theme-checkbox-6" }
            ],
            themeSelect: ""
        };
    },
    computed: {
        computeNameSvg() {
            let nameTheme;
            this.listSvgTemplate.forEach((item, ind) => {
                if (item === this.svgContent) {
                    nameTheme = "Theme " + ++ind;
                }
            });
            if (!nameTheme) {
                nameTheme = "Theme 3";
                this.svgContent = this.listSvgTemplate[2];
            }
            return nameTheme;
        },
        computeSelectTheme() {
            let name;
            let listTheme = this.componentName === "blockRadio" ? this.listThemeRadio : this.listThemeCheckbox;

            listTheme.forEach((item) => {
                if (item.value === this.themeSelect) {
                    name = item.name;
                }
            });
            if (!name) {
                name = "Select theme";
            }
            return name;
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL) {
                this.idSL = val.id;
                this.block = $.extend(true, {}, val);
                this.componentName = val.componentName;
                this.getInitialData();
            }else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device": function(val) {
            this.device = val;
        }
    },
    methods: {
        updateBlock(val, type) {
            this.block = val;
            this.$store.dispatch("editor/updateBlock", { val, type });
        },
        getInitialData() {
            switch (this.componentName) {
                case "blockSvg":
                    this.svgContent = this.block.html;
                    break;
                case "blockRadio":
                case "blockCheckbox":
                    this.themeSelect = this.block.theme || "";
                    break;
            }
        },
        toggleSection(nameSection, event, val) {
            let selector;
            switch (nameSection) {
                case "fold":
                    if ($("#theme-block-st").hasClass("fold-section")) {
                        $("#theme-block-st").removeClass("fold-section");
                    } else {
                        $("#theme-block-st").addClass("fold-section");
                    }
                    break;
                case "svg-theme":
                    $("#theme-block-st .svg-theme .list-option").toggle();
                    if (val) {
                        this.svgContent = val;
                        this.block.html = val;
                        $("#" + this.block.id + " > .block-content").html(val);
                        $("#" + this.block.id + " > .block-content svg").attr({ height: "100%", width: "100%" });
                        this.updateBlock(this.block, "svgSt");
                    }
                    break;
                case "selection-theme":
                    $("#theme-block-st .selection-theme .list-option").toggle();
                    if (val) {
                        this.themeSelect = val;
                        this.block.theme = val;
                        this.updateBlock(this.block, "themeSt");
                    }
                    break;
            }
        }
    },
    mounted() {
        this.block = this.$store.state.editor.blockSelected;
        this.device = this.$store.state.editor.device;
        this.idSL = this.block.id;
        this.componentName = this.block.componentName;
        this.getInitialData();
    }
};
