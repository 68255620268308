import ColorPickerUI from "@easylogic/colorpicker";
import "@easylogic/colorpicker/dist/colorpicker.css";

export default {
    props: ["colors", "type", "input"],
    data() {
        return {
            myColors: "#FFFFFF",
            colorPicker: ""
        };
    },
    watch: {
        colors(val) {
            if (val != this.myColors) {
                this.myColors = val;
                if (val === "#000") {
                    this.myColors = "#000000";
                }
                if (val === "#0000") {
                    this.myColors = "#00000000";
                }

                this.createColorPicker();
                this.$emit("colorPicker", this.myColors);
            }
        }
    },
    methods: {
        pickColor(e) {
            var htmlblack = `<div class="color-item" style="float:left" title="#000000" data-index="19" data-color="#000000">
            <div class="empty"></div>
            <div class="color-view" style="background-color: #000000"></div>
        </div>
        <div class="color-item" style="float:left" title="#ffffff" data-index="20" data-color="#ffffff">
            <div class="empty"></div>
            <div class="color-view" style="background-color: #ffffff"></div>
        </div>`;
            let topElement;
            $(".easylogic-colorpicker").remove();
            this.createColorPicker();

            $("body").append('<div id="overlay-colorpicker"></div>');
            $("#overlay-colorpicker").on("click", function () {
                $(".easylogic-colorpicker").remove();
                $("#overlay-colorpicker").remove();
            });
            var style = "display: block!important; position: unset!important;padding:unset!important";
            $(".easylogic-colorpicker .colorpicker-body").attr("style", style);
            if (this.type === "gradient") {
                $(".easylogic-colorpicker .colorpicker-body").prepend('<div class="steps-placeholder"></div>');
            }
            $(".easylogic-colorpicker .colorpicker-body").prepend(
                `<div class="title-picker"><p>${this.type.charAt(0).toUpperCase() + this.type.substring(1)}</p></div>`
            );
            $(".easylogic-colorpicker").show();
            var addColor = $('.easylogic-colorpicker .colorsets .color-list .current-color-sets .add-color-item');
            if (addColor && addColor.length > 0) {
                $('.easylogic-colorpicker .colorsets .color-list .current-color-sets .add-color-item').before(htmlblack);
            } else {
                $('.easylogic-colorpicker .colorsets .color-list .current-color-sets').append(htmlblack);
            }
            topElement = e.target.getBoundingClientRect().top;
            if (topElement + 380 >= window.innerHeight) {
                topElement = window.innerHeight - 400;
            }
            $(".easylogic-colorpicker").css({ left: e.target.getBoundingClientRect().left + "px", top: topElement + "px", "z-index": "999999" });
            $(".easylogic-colorpicker").show();
            $("#overlay-colorpicker").show();
        },
        createColorPicker() {
            let thisVue = this;
            let initialColor = thisVue.myColors && !thisVue.myColors.includes("gradient") ? thisVue.myColors : "#FFF";
            let initialGradientColor =
                thisVue.myColors && thisVue.myColors.includes("gradient")
                    ? thisVue.myColors
                    : "linear-gradient(to right, black 0%, black 100%)";

            this.colorPicker = "";
            if (this.type === "gradient") {
                this.colorPicker = ColorPickerUI.createGradientPicker({
                    position: "inline",
                    gradient: initialGradientColor,
                    outputFormat: "hex",
                    mode: 'edit',
                    onChange: _.debounce(function (colorString) {
                        if (colorString != thisVue.myColors) {
                            thisVue.myColors = colorString;
                            thisVue.$emit("colorPicker", thisVue.myColors);
                        }
                    }, 300)
                });
                return;
            }
            this.colorPicker = ColorPickerUI.create({
                position: "inline",
                color: initialColor,
                outputFormat: "hex",
                onChange: _.debounce(function (colorString) {
                    if (colorString != thisVue.myColors) {
                        thisVue.myColors = colorString;
                        thisVue.$emit("colorPicker", thisVue.myColors);
                    }
                }, 300)
            });
        },
        inputColorPicker: _.debounce(function () {
            this.$emit("colorPicker", this.myColors);
        }, 500),
        removeColorPicker() {
            this.$emit("removeColorPicker", "colorPicker");
        }
    },
    mounted() {
        this.myColors = this.colors || "#FFFFFF";
        if (this.myColors === "#000") {
            this.myColors = "#000000";
        }
        if (this.myColors === "#0000") {
            this.myColors = "#00000000";
        }
    }
};
