export default {
    data() {
        return {
            listDataShow: [],
            type: "",
            subType: "method",
            dataApp: [],
            listFunction: [],
            listApi: [],
            listMethod: ["gm", "sm"],
            codeMirrorData: {},
            lineActive: "",
            indexActiveChar: 0,
            showPopup: false,
            topCursorMirror: "",
            leftCursorMirror: "",
            methodSelect: "",
            collectionSelect: "",
            columnSelect: "",
            indexDataSelect: "",
            typingContent: ""
        };
    },
    watch: {
        "$store.state.editor.dataApp"(val) {
            if (!val || !val.data) return;
            this.dataApp = val.data;
        },
        "$store.state.editor.listFunction"(val) {
            this.listFunction = val || [];
            this.mapListFunction();
        },
        "$store.state.editor.listApiTemplate"(val) {
            this.listApi = val || [];
            this.mapListApi();
        },
        "$store.state.editor.dataAddOn"(val) {
            if (val.type !== "suggest-data") return;
            this.getData(val);
        }
    },
    methods: {
        generateListData() {
            const dataApp = this.dataApp || [];
            console.log("this.dataApp:: ", this.dataApp);
            let showPopup = false;
            const typingApi = /\$api\.\w*$/g;
            const typingFunction = /\$function\.\w*$/g;
            const typingMethod = /\$model\.$/g;
            const typingCollection = /\$model\.(sm|gm)\( *("|')\w*$/g;
            const typingColumn = /\$model\.(sm|gm)\( *("|')\w*("|') *, *("|')\w*$/g;
            const typingIndex = /\$model\.(sm|gm)\( *("|')\w*("|') *, *("|')\w*("|') *, *("|')$/g;

            if (typingIndex.test(this.lineActive)) {
                if (!this.columnSelect.length) {
                    this.listDataShow = [""];
                } else {
                    let columnData = dataApp.find((item) => item.name === this.collectionSelect);
                    if (!columnData || !columnData.data) return;
                    this.listDataShow = columnData.data.map((item, ind) => ind);
                    this.listDataShow = ["", ...this.listDataShow];
                }
                this.subType = "index";
                showPopup = true;
            } else if (typingColumn.test(this.lineActive)) {
                let columnData = dataApp.find((item) => item.name === this.collectionSelect);
                if (!columnData || !columnData.data || !columnData.data[0]) return;
                let typingContent = this.lineActive.match(/\w+$/g);
                typingContent = typingContent !== null ? typingContent[0] : "";
                this.typingContent = typingContent;

                this.listDataShow = Object.keys(columnData.data[0]);
                if (typingContent) {
                    this.listDataShow = this.listDataShow.filter((item) => item.includes(typingContent));
                }
                this.listDataShow = ["", ...this.listDataShow];
                this.subType = "column";
                showPopup = true;
            } else if (typingCollection.test(this.lineActive)) {
                let typingContent = this.lineActive.match(/\w+$/g);
                typingContent = typingContent !== null ? typingContent[0] : "";
                this.typingContent = typingContent;

                this.listDataShow = dataApp.map((item) => item.name);
                if (typingContent) {
                    this.listDataShow = this.listDataShow.filter((item) => item.includes(typingContent));
                }
                this.subType = "collection";
                showPopup = true;
            } else if (typingMethod.test(this.lineActive)) {
                this.listDataShow = this.listMethod;
                this.subType = "method";
                showPopup = true;
            } else if (typingApi.test(this.lineActive)) {
                let typingContent = this.lineActive.match(/\w+$/g);
                typingContent = typingContent !== null ? typingContent[0] : "";
                this.typingContent = typingContent;

                this.listDataShow = this.listApi;
                if (typingContent) {
                    this.listDataShow = this.listDataShow.filter((item) => item.includes(typingContent));
                }
                this.subType = "api";
                showPopup = true;
            } else if (typingFunction.test(this.lineActive)) {
                let typingContent = this.lineActive.match(/\w+$/g);
                typingContent = typingContent !== null ? typingContent[0] : "";
                this.typingContent = typingContent;

                this.listDataShow = this.listFunction;
                if (typingContent) {
                    this.listDataShow = this.listDataShow.filter((item) => item.includes(typingContent));
                }
                this.subType = "function";
                showPopup = true;
            }

            this.showPopup = showPopup;
        },
        getData(val) {
            let thisVue = this;
            let cursorSelector = $(".CodeMirror-cursors .CodeMirror-cursor");
            let offsetCursor = cursorSelector.offset();

            if (offsetCursor && ( offsetCursor.left === 0 || offsetCursor.top === 0 )) {
                let codeMirror = $(".vue-codemirror").offset();

                offsetCursor.top = codeMirror.top + cursorSelector.css("top");
                offsetCursor.left = codeMirror.left + cursorSelector.css("left");
            }
            console.log('val.lineActive.lastIndexOf("$model."):: ', val.lineActive.lastIndexOf("$model."));
            if (
                val.lineActive.lastIndexOf("$model.") === -1 &&
                val.lineActive.lastIndexOf("$function.") === -1 &&
                val.lineActive.lastIndexOf("$api.") === -1
            ) {
                this.showPopup = false;
                return;
            }
            this.type = val.type;
            this.codeMirrorData = val.val;
            this.indexActiveChar = val.indexActiveChar;
            this.lineActive = val.lineActive;
            this.topCursorMirror = offsetCursor.top;
            this.leftCursorMirror = offsetCursor.left;
            this.typingContent = "";
            this.generateListData();

            let heightPopup = this.listDataShow.length > 10 ? 330 : this.listDataShow.length * 30 + 30;
            if ($(window).height() < this.topCursorMirror + 330) {
                $("#suggest-data-widget").css({
                    top: this.topCursorMirror - heightPopup + "px",
                    left: this.leftCursorMirror + "px"
                });
            } else {
                $("#suggest-data-widget").css({
                    top: this.topCursorMirror + 30 + "px",
                    left: this.leftCursorMirror + "px"
                });
            }
            setTimeout(function() {
                thisVue.addEvent();
            }, 500);
        },
        selectSuggestOption(text) {
            const doc = this.codeMirrorData.getDoc();
            let cursor = this.codeMirrorData.getCursor();
            cursor.ch = cursor.ch - this.typingContent.length;

            switch (this.subType) {
                case "method":
                    if (text.includes("sm")) {
                        this.methodSelect = "sm";
                    } else {
                        this.methodSelect = "gm";
                    }
                    text += `("`;
                    break;
                case "collection":
                    this.collectionSelect = text;
                    if (/"\w*$/g.test(this.lineActive)) {
                        text += `", "`;
                    } else {
                        text += `', '`;
                    }
                    break;
                case "column":
                    this.columnSelect = text;
                    if (/"\w*$/g.test(this.lineActive)) {
                        text += `", "`;
                    } else {
                        text += `', '`;
                    }
                    break;
                case "index":
                    this.indexDataSelect = text;
                    if (this.methodSelect === "sm") {
                        if (/"\w*$/g.test(this.lineActive)) {
                            text += `", "dataSet")`;
                        } else {
                            text += `', 'dataSet')`;
                        }
                    } else {
                        if (/"\w*$/g.test(this.lineActive)) {
                            text += `")`;
                        } else {
                            text += `')`;
                        }
                    }
                    break;
                case "function":
                case "api":
                    text = `${text}()`;
            }

            this.showPopup = false;
            this.offEvent();
            doc.replaceRange(text, cursor);
            doc.replaceSelection("");
        },
        hidePopup() {
            this.showPopup = false;
            this.offEvent();
        },
        addEvent() {
            let thisVue = this;
            $("body").on("click", thisVue.hidePopup);
        },
        offEvent() {
            let thisVue = this;
            $("body").off("click", thisVue.hidePopup);
        },
        mapListFunction() {
            console.log("function", this.listFunction);
            this.listFunction = this.listFunction.map((item) => item.name);
        },
        mapListApi() {
            this.listApi = this.listApi.map((item) => item.name);
        }
    },
    mounted() {
        let dataApp = this.$store.state.editor.dataApp;

        this.listFunction = this.$store.state.editor.listFunction || [];
        this.listApi = this.$store.state.editor.listApiTemplate || [];
        this.dataApp = dataApp.data || [];
        this.mapListFunction();
        this.mapListApi();
    }
};
