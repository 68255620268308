import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import ImagePicker from "@/components/editor-v3/setting-blocks/image-picker/image-picker.vue";

export default {
    components: {
        ColorPicker,
        ImagePicker
    },
    data() {
        return {
            componentName: "",
            block: {},
            idSL: "",
            device: "mobile",
            bgType: "color",
            backgroundImageType: "0",
            backgroundImageRepeat: "0",
            isGraPicker: "",
            backgroundColor: "rgba(0,0,0,0)",
            overlayBackgroundImage: "",
            colorOverlay: "",
            backgroundImageUrl: "",
            backgroundData: {},
            isOverlayGraPicker: "",
            focusBd: false
        };
    },
    watch: {
        "$store.state.editor.blockSelected": function(val) {
            if ( val && val.id != this.idSL) {
                this.idSL = val.id;
                this.block = $.extend(true, {}, val);
                this.componentName = val.componentName;

                if (val && val[this.device] && val[this.device].background) {
                    this.backgroundData = $.extend(true, {}, val[this.device].background);
                    this.getInitialData();
                } else {
                    this.backgroundData = {};
                    if (!this.block.mobile) {
                        this.block.mobile = {}
                    }
                    this.block.mobile.background = {};
                    this.isGraPicker = false;
                    this.isOverlayGraPicker = true;
                }
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                    this.backgroundData = val[this.device].background;
                    this.getInitialData();
                }
            }
        },
        "$store.state.editor.device": function (val) {
            this.device = val;
        },
        "backgroundImageUrl": function(val) {
            console.log("update bg image");
            this.backgroundData.backgroundImageUrl = val;
            this.updateBgImageData(this.backgroundData);
        },
        "backgroundImageType": function(val) {
            this.backgroundData.backgroundImageType = val;
            this.updateBgImageData(this.backgroundData);
        },
        "backgroundImageRepeat": function(val) {
            this.backgroundData.backgroundImageRepeat = val;
            this.updateBgImageData(this.backgroundData);
        }
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager", {
                type: "background",
                dataComp: this.$store.state.editor.blockSelected
            });
        },
        updateColor(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].background = {};
            } else {
                if (!this.block[this.device].background) {
                    this.block[this.device].background = {};
                }
            }
            this.backgroundColor = color;
            this.block[this.device].background.isGraPicker = this.isGraPicker;
            this.block[this.device].background.backgroundColor = color;
            this.backgroundData = $.extend(true, {},this.block[this.device].background);
            this.updateBlock(this.block, "backgroundSt");
        },
        updateOverlayColor(color) {
            if (this.block && this.backgroundData) {
                this.colorOverlay = color;
                this.backgroundData.colorOverlay = color;

                if (!this.isOverlayGraPicker) {
                    color = `linear-gradient( ${color}, ${color})`;
                }
                this.overlayBackgroundImage = color;
                this.backgroundData.overlayBackgroundImage = color;
                this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                this.block[this.device].background = this.backgroundData;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        setBgType(val) {
            if (this.block && this.backgroundData) {
                this.bgType = val;
                this.backgroundData.backgroundType = val;
                this.block[this.device].background.backgroundType = val;
                this.updateBlock(this.block, "backgroundSt");
            }
        },
        updateBgImageData(val) {
            if (!val) return;
            this.backgroundData.backgroundImageUrl = val.backgroundImageUrl;
            this.backgroundData.backgroundImageRepeat = val.backgroundImageRepeat;
            this.backgroundData.backgroundImageType = val.backgroundImageType;
            this.block[this.device].background = this.backgroundData;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBlock(val, type) {
            this.block = val;
            if (type === "borderSt" && !val.border.borderStyle) {
                val.border.borderStyle = "solid";
                val[this.device].border.borderStyle = "solid";
            }
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: type });
        },
        getInitialData() {
            let backgroundData = this.backgroundData;

            this.bgType = backgroundData.backgroundType || "color";
            this.isGraPicker = backgroundData.isGraPicker || false;
            this.backgroundColor = backgroundData.backgroundColor || "rgba(0,0,0,0)";
            this.overlayBackgroundImage = backgroundData.overlayBackgroundImage || "rgba(0,0,0,0)";
            this.colorOverlay = backgroundData.colorOverlay || "rgba(0,0,0,0)";
            this.backgroundImageUrl = backgroundData.backgroundImageUrl || "";
            this.isOverlayGraPicker = backgroundData.isOverlayGraPicker || false;
            this.backgroundImageType = backgroundData.backgroundImageType || "0";
            this.backgroundImageRepeat = backgroundData.backgroundImageRepeat || "0";
        },
        handleClickRemove(type, item) {
            if (type === "colorPicker") {
                if (typeof this.isOverlayGraPicker === "boolean") {
                    this.isOverlayGraPicker = "";
                    return;
                }
                this.isGraPicker = "";
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            switch (nameSection) {
                case "style-color":
                    if (val === "image") {
                        this.isGraPicker = "";
                        this.setBgType("image");
                        this.backgroundData.backgroundType = "image";
                    } else {
                        this.isGraPicker = val === "gradient" ? true : false;
                        this.setBgType("color");
                        this.backgroundData.backgroundType = "color";
                        
                    }
                    this.backgroundData.isGraPicker = this.isGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "style-overlay":
                    $("#button-st .fill-bg .list-style-overlay").toggle();
                    this.isOverlayGraPicker = val === "gradient" ? true : false;

                    this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
            }
        },
        closeDrop: function (event) {
            $(event.target).hide();
        },
        closeSt: function (event) {
            $("#button-st").parent().hide();
        }
    },
    mounted() {
        this.device = this.$store.state.editor.device;
        var thisVue = this;
        this.block = this.$store.state.editor.blockSelected;
        if (this.block && this.block.id){
            this.backgroundData = $.extend(true, {}, this.block[this.device].background);
            this.getInitialData();
        }
        $("#modal-upload-image-manager").on("hidden.bs.modal", function () {
            var bl = thisVue.$store.state.editor.blockSelected;
            if(bl && thisVue.block && bl.id == thisVue.block.id) {
                thisVue.backgroundImageUrl = bl[thisVue.device].background.backgroundImageUrl;
            }
        });
        $(".apo-dropdown").off("click").on("click", function () {
            $(".apo-dropdown-list").hide();
            $(this).parent().find(".apo-dropdown-list").show();
        });
        $(".apo-dropdown").off("blur").on("blur", function() {
            $(".item-q-setting").hide();
            $("#quick-setting-block .item").removeClass("active");
        })
    }
};
