import { render, staticRenderFns } from "./function-manage.html?vue&type=template&id=84309ec0&scoped=true&"
import script from "./function-manage.js?vue&type=script&lang=js&"
export * from "./function-manage.js?vue&type=script&lang=js&"
import style0 from "./function-manage.vue?vue&type=style&index=0&id=84309ec0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84309ec0",
  null
  
)

export default component.exports