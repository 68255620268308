import resfult from "../../../../services/resful.js";
import { utils } from "@/libs-core/utils.min.js";

export default {
    createConfig: async function(data, next) {
        let res = await resfult.post("/app/create_api", data);
        next(res);
    },
    updateConfig: async function(data, next) {
        let res = await resfult.post("/app/update_api", data);
        next(res);
    },
    getAllConfig: async function(data, next) {
        let res = await resfult.get("/app/api?" + utils.jsonToParams(data));
        next(res);
    },
    deleteConfig: async function(data, next) {
        let res = await resfult.post("/app/remove_api", data);
        next(res);
    }
};
