import { utils } from "@/libs-core/utils.min.js";

export default {
    data() {
        return {
            listItemGgForm: "",
            timeShowAction: 0,
            timeShow: 0,
            indexCurrentItem: "",
            dataAppSelect: {}
        };
    },
    watch: {
        "$store.state.editor.dataActionSelect"(val) {
            this.listItemGgForm = val.listItemGgForm || [];
            this.timeShowAction = val.timeShowAction;
        }
    },
    methods: {
        getNameDataGgForm(index) {
            const dataAppSelect = this.listItemGgForm[index].dataAppSelect || {};

            if (dataAppSelect.indexTab === undefined) {
                return "";
            }
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= dataAppSelect.indexTab) return "";
            return utils.getValueDataApp(
                dataAppSelect.indexTab,
                dataAppSelect.keySelect,
                dataAppSelect.indexKey
            );
        },
        showSelectDataApp(index) {
            this.indexCurrentItem = index;
            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, this.listItemGgForm[index].dataAppSelect);
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
            $("#modal-config-ggform").css("opacity", 0);
        },
    },
    mounted() {
        const thisVue = this;

        $("#modal-config-ggform").on("hide.bs.modal", function() {
            let data = {
                listItemGgForm: thisVue.listItemGgForm,
                timeShowAction: thisVue.timeShowAction
            };
            thisVue.$store.commit("editor/mutaDataActionSelect", data);
            thisVue.listItemGgForm = "";
        });

        $("#select-data-app").on("hidden.bs.modal", function() {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                thisVue.listItemGgForm[thisVue.indexCurrentItem].dataAppSelect = $.extend(
                    true,
                    {},
                    thisVue.dataAppSelect
                );
                thisVue.timeShow = new Date().getTime();
                thisVue.$forceUpdate();
                $("#modal-config-ggform").css("opacity", 1);
            }
        });
    }
};
