import { createNewBlock } from "@/libs-core/create-block-editor.min.js";
import { templateSV } from "@/components/editor-v3/layout/layout-left-component/template-manager/template-page-manager.service.js";
import async from "async";

export default {
    props: ["menuSl"],
    data() {
        return {
            skip: 0,
            listCateTemplate: [],
            category: {},
            listCategory: [],
            stop: false,
            listGroupBlock: [],
            listGroupTemplate: [],
            search: "",
            isSeeAll: false,
            cateSelectName: ""
        };
    },
    watch: {
        "$store.state.editor.saveTempStatus"(val) {
            if (!val || !val.includes("section")) return;
            if (this.listCategory.length) {
                this.seeAll(this.listCateTemplate[0]);
                return;
            }
            this.getCateAndTemp();
        },
        menuSl: function(val) {
            if (val === "block-manage") {
                this.isSeeAll = false;
            }
        }
    },
    computed: {
        listMyBlocks() {
            if (!this.listCateTemplate[0] || !this.listCateTemplate[0].templates) return;
            return this.listCateTemplate[0].templates;
        },
        listCategoryFilter() {
            return this.listCategory.filter((i) => i.status === "active" || !i.category);
        }
    },
    methods: {
        initBlockDraggable() {
            const thisVue = this;
            let component = "";
            $(".item-block-template").draggable();
            $(".item-block-template").draggable("destroy");
            $(".item-block-template").draggable({
                appendTo: "body",
                helper: "clone",
                iframeFix: true,
                iframeOffset: $("#content-main").offset(),
                start(ui) {
                    if ($(ui.target).hasClass("isBeta") === true) {
                        thisVue.$swal("", "This block openning soon!");
                        return;
                    }
                },
                drag(ui) {
                    component = $(ui.target).attr("comp");
                    console.log("component:: ", component);
                },
                stop(ui) {
                    if ($(ui.originalEvent.target).hasClass("main-builder")) {
                        //thisVue.$emit("dragBlockLefed", createNewBlock.init(component, thisVue));
                    }
                }
            });
        },
        draggableBlock() {
            const thisVue = this;
            let source = {};
            let wgItemTemplate = $(".item-block-template");
            if ((wgItemTemplate && wgItemTemplate, length > 0)) {
                $(".item-block-template").draggable();
                $(".item-block-template").draggable("destroy");
                $(".item-block-template").draggable({
                    appendTo: "body",
                    helper: "clone",
                    drag(ui) {
                        source.componentName = $(ui.target).attr("tem-name");
                        source.api = $(ui.target).attr("api-template");
                    },
                    stop(ui) {
                        if ($(ui.originalEvent.target).hasClass("main-builder")) {
                            thisVue.$emit("dragBlockLefed", source);
                        }
                    }
                });
            }
        },
        removeTemplate(item) {
            const thisVue = this;
            templateSV.removeTemplate(JSON.stringify({ id: item.id }), function(data) {
                if (data && data.code === 200) {
                    if (thisVue.listCategory.length > 0) {
                        thisVue.listCategory = thisVue.listCategory.filter(function(tem, index) {
                            return tem.id !== item.id;
                        });
                        thisVue.listCateTemplate[0].templates = thisVue.listCategory.slice(0, 3);
                    } else {
                        thisVue.getCateAndTemp();
                    }
                    thisVue.$swal({
                        position: "bottom-start",
                        type: "success",
                        title: "Your custom template has been deleted.",
                        showConfirmButton: false,
                        timer: 1000
                    });
                }
            });
        },
        getCateAndTemp() {
            const thisVue = this;
            async.waterfall(
                [
                    (next) => {
                        templateSV.getCateAndTemp(
                            JSON.stringify({
                                cate_query: {
                                    type: "marketplace",
                                    type_second: "section"
                                },
                                cate_sort: "updatedAt ASC",
                                temp_limit: 2,
                                owner_query: {
                                    type: "section"
                                },
                                owner_limit: 3
                            }),
                            (res) => next(null, res.data)
                        );
                    },
                    (listCateTemplate, next) => {
                        let listGroupBlock = {};
                        listCateTemplate = listCateTemplate.map((cate) => {
                            switch (cate.name) {
                                case "Column":
                                    cate.comp = "blockColumnOnly";
                                    cate.class = "_column-icon";
                                    if (!listGroupBlock.column) {
                                        listGroupBlock.column = [];
                                    }
                                    listGroupBlock.column.push(cate);
                                    break;
                                case "Text":
                                    cate.comp = "blockText";
                                    cate.class = "_text-icon";
                                    if (!listGroupBlock.text) {
                                        listGroupBlock.text = [];
                                    }
                                    listGroupBlock.text.push(cate);
                                    break;
                                case "Button":
                                    cate.comp = "blockButton";
                                    cate.class = "_button-icon";
                                    if (!listGroupBlock.button) {
                                        listGroupBlock.button = [];
                                    }
                                    listGroupBlock.button.push(cate);
                                    break;
                                case "Video":
                                    cate.comp = "blockVideo";
                                    cate.class = "_video-icon";
                                    if (!listGroupBlock.video) {
                                        listGroupBlock.video = [];
                                    }
                                    listGroupBlock.video.push(cate);
                                    break;
                                case "Input":
                                    cate.comp = "blockInput";
                                    cate.class = "_input-icon";
                                    if (!listGroupBlock.input) {
                                        listGroupBlock.input = [];
                                    }
                                    listGroupBlock.input.push(cate);
                                    break;
                                case "Textarea":
                                    cate.comp = "blockTextarea";
                                    cate.class = "_textarea-icon";
                                    if (!listGroupBlock.input) {
                                        listGroupBlock.input = [];
                                    }
                                    listGroupBlock.input.push(cate);
                                    break;
                                case "Checkbox":
                                    cate.comp = "blockCheckbox";
                                    cate.class = "_checkbox-icon";
                                    if (!listGroupBlock.selection) {
                                        listGroupBlock.selection = [];
                                    }
                                    listGroupBlock.selection.push(cate);
                                    break;
                                case "Radio":
                                    cate.comp = "blockRadio";
                                    cate.class = "_radio-icon";
                                    if (!listGroupBlock.selection) {
                                        listGroupBlock.selection = [];
                                    }
                                    listGroupBlock.selection.push(cate);
                                    break;
                                case "List":
                                    cate.comp = "blockList";
                                    cate.class = "_list-icon";
                                    if (!listGroupBlock.list) {
                                        listGroupBlock.list = [];
                                    }
                                    listGroupBlock.list.push(cate);
                                    break;
                                case "Select":
                                    cate.comp = "blockSelect";
                                    cate.class = "_select-icon";
                                    if (!listGroupBlock.selection) {
                                        listGroupBlock.selection = [];
                                    }
                                    listGroupBlock.selection.push(cate);
                                    break;
                                case "Html":
                                    cate.comp = "blockHtml";
                                    cate.class = "_html-icon";
                                    if (!listGroupBlock.html) {
                                        listGroupBlock.html = [];
                                    }
                                    listGroupBlock.html.push(cate);
                                    break;
                                case "Svg":
                                    cate.comp = "blockSvg";
                                    cate.class = "_svg-icon";
                                    if (!listGroupBlock.shape) {
                                        listGroupBlock.shape = [];
                                    }
                                    listGroupBlock.shape.push(cate);
                                    break;
                                case "blockLightBox":
                                    cate.comp = "blockLightBox";
                                    cate.class = "_lightbox-icon";
                                    if (!listGroupBlock.popup) {
                                        listGroupBlock.popup = [];
                                    }
                                    listGroupBlock.popup.push(cate);
                                    break;
                                case "Form":
                                    cate.comp = "blockForm";
                                    cate.class = "_form-icon";
                                    if (!listGroupBlock.form) {
                                        listGroupBlock.form = [];
                                    }
                                    listGroupBlock.form.push(cate);
                                    break;
                            }
                            return cate;
                        });
                        thisVue.listGroupBlock = listGroupBlock;
                        thisVue.generateGroupTemplate();
                        next(null, listCateTemplate);
                    }
                ],
                (e, r) => {
                    let listCate = r.map((item) => {
                        if (item && item.templates && item.templates.length) {
                            item.templates = item.templates.filter((i) => i.status === "active" || !i.category);
                        }
                        return item;
                    });
                    thisVue.listCateTemplate = listCate;
                    setTimeout(() => {
                        thisVue.draggableBlock();
                        thisVue.initBlockDraggable();
                    }, 500);
                }
            );
        },
        seeAll(cate, name) {
            const thisVue = this;

            this.isSeeAll = true;
            this.listCategory = [];
            this.category = cate;
            $(".menu-div-content-v3").scrollTop(0);

            if (cate === "My block") {
                cate = {
                    name: "My block"
                };
                this.cateSelectName = "My block";
                this.category = cate;
                templateSV.getListTemplate(
                    {
                        type: "section",
                        limit: "20",
                        skip: "0"
                    },
                    (data) => {
                        if (data.code === 200) {
                            thisVue.listCategory = data.data;
                            setTimeout(() => {
                                thisVue.draggableBlock();
                                thisVue.initBlockDraggable();
                            }, 500);
                        } else {
                            console.log("error get list template section");
                        }
                    }
                );
                return;
            }

            this.cateSelectName = name;
            cate.forEach((item) => {
                templateSV.getAppInCategory(
                    {
                        id: item.id,
                        skip: 0,
                        limit: 20
                    },
                    (data) => {
                        if (data && data.code === 200) {
                            thisVue.listCategory = thisVue.listCategory.concat(data.data);
                            setTimeout(() => {
                                thisVue.draggableBlock();
                                thisVue.initBlockDraggable();
                            }, 500);
                        } else {
                            console.log("error get app in category");
                        }
                    }
                );
            });
        },
        back() {
            this.category = {};
            this.skip = 0;
            this.isSeeAll = false;
            this.listCategory = [];
            this.stop = false;
            this.getCateAndTemp();
        },
        loadMore() {
            if (this.category.name === "My block") {
                this.skip += 20;
                templateSV.getListTemplate(
                    {
                        type: "section",
                        limit: "20",
                        skip: this.skip
                    },
                    (data) => {
                        if (data.code === 200) {
                            if (data.data.length === 0) {
                                this.stop = true;
                            }
                            this.listCategory = [...this.listCategory, ...data.data];
                            setTimeout(() => {
                                this.draggableBlock();
                                this.initBlockDraggable();
                            }, 500);
                        } else {
                            console.log("error get list template section");
                        }
                    }
                );
                return;
            }

            this.skip += 20;
            templateSV.getAppInCategory(
                {
                    id: this.category.id,
                    skip: this.skip,
                    limit: 20
                },
                (data) => {
                    if (data && data.code === 200) {
                        if (data.data.length === 0) {
                            this.stop = true;
                        }
                        this.listCategory = [...this.listCategory, ...data.data];
                        setTimeout(() => {
                            this.draggableBlock();
                            this.initBlockDraggable();
                        }, 500);
                    } else {
                        console.log("error get app in category");
                    }
                }
            );
        },
        handleScroll(event) {
            if (event.wheelDelta > 0) return;
            if (this.stop) return;
            this.loadMore();
        },
        clearInput() {
            this.search = "";
        },
        generateGroupTemplate() {
            let listBlock = this.listGroupBlock;

            let listTemplate = {};
            for (let i in listBlock) {
                listBlock[i].forEach((item) => {
                    if (!item.templates) return;
                    if (!listTemplate[i]) {
                        listTemplate[i] = [];
                    }
                    item.templates = item.templates.filter((e) => e.status === "active" || !e.category);
                    listTemplate[i] = [...listTemplate[i], ...item.templates];
                });
            }
            this.listGroupTemplate = listTemplate;
        },
        isShowTemplate(val) {
            if (this.listGroupTemplate[val]) return true;
        },
        toggleSection(type, event, val) {
            if (type === "action-popup") {
                if (val === undefined) {
                    $("#block-manager .see-all-list .action-popup").hide();
                    return;
                }
                $(`#block-manager .see-all-list .item-template:nth-child(${val + 1}) .action-popup`).css(
                    "display",
                    "flex"
                );
            }
        }
    },
    mounted() {
        this.initBlockDraggable();
        this.getCateAndTemp();
    }
};
