import { service } from "./function-manage.service.js";

export default {
    data() {
        return {
            listFunction: []
        };
    },
    methods: {
        getAllFunction() {
            const thisVue = this;

            service.getAllConfig(JSON.stringify({ type: "func", page: thisVue.$route.params.id }), function(
                data
            ) {
                if (data && data.code === 200) {
                    thisVue.listFunction = data.data;
                    thisVue.$emit("updateListFunction", thisVue.listFunction);
                }
            });
        },
        removeFunction(id) {
            const thisVue = this;

            thisVue
                .$swal({
                    title: "",
                    text: "You won't be able to revert this action!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes"
                })
                .then((result) => {
                    if (result.value) {
                        service.remove(JSON.stringify({ id: id, page: thisVue.$route.params.id }), function(
                            data
                        ) {
                            if (data && data.code === 200) {
                                thisVue.getAllFunction();
                            }
                        });
                    }
                });
        },
        handleAddNewFunc() {
            $("#modal-function-manage").modal("hide");
            this.$emit("newFunction");
        }
    },
    mounted() {
        const thisVue = this;
        $("#modal-function-manage").on("show.bs.modal", function() {
            thisVue.getAllFunction();
        });
    }
};
