import { service } from "./tracking-manage.service.js";

export default {
    data() {
        return {
            listTracking: []
        };
    },
    watch: {
        "$store.state.editor.listTracking"(val) {
            this.listFunction = val;
        }
    },
    methods: {
        getAllTracking() {
            const thisVue = this;

            service.getAllConfig(JSON.stringify({ type: "tracking", page: thisVue.$route.params.id }), function(
                data
            ) {
                if (data && data.code === 200) {
                    thisVue.listTracking = data.data;
                    thisVue.$emit("updateListTracking", thisVue.listTracking);
                }
            });
        },
        removeTracking(id) {
            const thisVue = this;

            thisVue
                .$swal({
                    title: "",
                    text: "You won't be able to revert this action!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes"
                })
                .then((result) => {
                    if (result.value) {
                        service.remove(JSON.stringify({ id: id, page: thisVue.$route.params.id }), function(
                            data
                        ) {
                            if (data && data.code === 200) {
                                thisVue.getAllTracking();
                            }
                        });
                    }
                });
        },
        handleAddNewTracking() {
            $("#modal-tracking-manage").modal("hide");
            this.$emit("newTracking");
        }
    },
    mounted() {
        const thisVue = this;
        $("#modal-tracking-manage").on("show.bs.modal", function() {
            thisVue.getAllTracking();
        });
    }
};
