import { utils } from "@/libs-core/utils.min.js";

export default {
    data() {
        return {
            dataApiAction: {},
            timeShow: 0,
            timeShowAction: 0,
            dataAppSelect: {},
            tabActive: "success",
            listAction: ["link", "model", "function", "tracking", "email", "phone", "position", "page"],
            listTypeValue: ["length", "assign", "concat"],
            onSuccessAction: [],
            onErrorAction: [],
            currentIndex: 0,
            listFunction: [],
            listTracking: [],
            listPosition: [],
            listPage: []
        };
    },
    methods: {
        isShowValueInput(type) {
            if (type === "function" || type === "tracking" || type === "page" || type === "position") {
                return false;
            }
            return true;
        },
        removeActionItem(ind) {
            if (this.tabActive === "success") {
                this.onSuccessAction.splice(ind, 1);
            } else {
                this.onErrorAction.splice(ind, 1);
            }
        },
        getNameDataAction(index) {
            let dataAppSelect;
            if (this.tabActive === "success") {
                if (!this.onSuccessAction[index]) return "";
                dataAppSelect = this.onSuccessAction[index].dataAppSelect || {};
            } else {
                if (!this.onErrorAction[index]) return "";
                dataAppSelect = this.onErrorAction[index].dataAppSelect || {};
            }
            if (dataAppSelect.indexTab === undefined) {
                return "";
            }
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= dataAppSelect.indexTab) return "";
            return utils.getValueDataApp(dataAppSelect.indexTab, dataAppSelect.keySelect, dataAppSelect.indexKey);
        },
        showSelectDataApp(index) {
            this.currentIndex = index;
            this.timeShow = new Date().getTime();
            if (this.tabActive === "success") {
                this.dataAppSelect = $.extend(true, {}, this.onSuccessAction[index].dataAppSelect);
            } else {
                this.dataAppSelect = $.extend(true, {}, this.onErrorAction[index].dataAppSelect);
            }
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
            $("#modal-config-api").css("opacity", 0);
        },
        addMoreAction() {
            const item = {
                dataAppSelect: {},
                type: "model",
                value_action: "",
                value_type: "",
                type_set_value: "assign"
            };
            if (this.tabActive === "success") {
                this.onSuccessAction.push(item);
                return;
            }
            this.onErrorAction.push(item);
        },
        clickCreateNewApi() {
            $("#modal-config-api").modal("hide");
            $("#api-template-v3").modal("show");
        },
        toggleSection(type, event, val, ind) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            switch (type) {
                case "switch-tab":
                    this.tabActive = val;
                    break;
                case "type-action":
                    selector = `#modal-config-api .action:nth-child(${ind + 1}) .action-dropdown .list-option`;
                    $(selector).toggle();
                    if (val) {
                        if (this.tabActive === "success") {
                            this.onSuccessAction[ind].type = val;
                            this.onSuccessAction[ind].value_action = "";
                            this.onSuccessAction[ind].value_type = "";
                            if (val === "model") {
                                this.onSuccessAction[ind].type_set_value = "assign";
                            } else {
                                delete this.onSuccessAction[ind].type_set_value;
                            }
                            return;
                        }
                        this.onErrorAction[ind].type = val;
                        this.onErrorAction[ind].value_action = "";
                        this.onErrorAction[ind].value_type = "";
                        if (val === "model") {
                            this.onErrorAction[ind].type_set_value = "assign";
                        } else {
                            delete this.onErrorAction[ind].type_set_value;
                        }
                    }
                    break;
                case "type-value":
                    selector = `#modal-config-api .action:nth-child(${ind + 1}) .type-value-dropdown .list-option`;
                    $(selector).toggle();
                    if (val) {
                        if (this.tabActive === "success") {
                            this.onSuccessAction[ind].type_set_value = val;
                            return;
                        }
                        this.onErrorAction[ind].type_set_value = val;
                    }
                    break;
                case "value-dropdown":
                    selector = `.list-action > div:nth-child(${ind + 1}) .value-dropdown .list-option`;
                    $(selector).toggle();

                    if (val) {
                        if (this.tabActive === "success") {
                            this.onSuccessAction[ind].value_action = val;
                        } else {
                            this.onErrorAction[ind].value_action = val;
                        }
                    }
                    break;
            }
        }
    },
    mounted() {
        const thisVue = this;

        $("#modal-config-api").on("shown.bs.modal", function() {
            let data = thisVue.$store.state.editor.dataActionSelect;
            console.log("data::: ", data);

            if (!data) return;
            thisVue.dataApiAction = data.dataApiAction;
            thisVue.timeShowAction = data.timeShowAction;
            thisVue.onSuccessAction = data.dataApiAction.data.actionSuccess || [];
            thisVue.onErrorAction = data.dataApiAction.data.actionError || [];
            thisVue.tabActive = "success";
            thisVue.listFunction = thisVue.$store.state.editor.listFunction || [];
            thisVue.listPosition = thisVue.$store.state.editor.api || [];
            thisVue.listTracking = thisVue.$store.state.editor.listTracking || [];
            thisVue.listPage = thisVue.$store.state.editor.listPage || [];
        });
        $("#modal-config-api").on("hide.bs.modal", function() {
            thisVue.dataApiAction.data.actionSuccess = thisVue.onSuccessAction;
            thisVue.dataApiAction.data.actionError = thisVue.onErrorAction;

            let data = {
                dataApiAction: thisVue.dataApiAction,
                timeShowAction: thisVue.timeShowAction
            };
            thisVue.$store.commit("editor/mutaDataActionSelect", data);
        });

        $("#select-data-app").on("hidden.bs.modal", function() {
            let index = thisVue.currentIndex;
            console.log("index:: ", index);
            console.log("thisVue.timeShow:: ", thisVue.timeShow);
            console.log("thisVue.$store.state.editor.timeShowDataApp:: ", thisVue.$store.state.editor.timeShowDataApp);
            console.log("thisVue.$store.state.editor.dataAppSelect.saveData: ", thisVue.$store.state.editor.dataAppSelect.saveData);
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                console.log("vào đây này");
                console.log("thisVue.$store.state.editor.dataAppSelect.saveData:: ", thisVue.$store.state.editor.dataAppSelect.saveData);
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                if (thisVue.tabActive === "success") {
                    thisVue.onSuccessAction[index].dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                    thisVue.onSuccessAction[index].value_type = thisVue.getNameDataAction(index);
                } else {
                    thisVue.onErrorAction[index].dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                    thisVue.onErrorAction[index].value_type = thisVue.getNameDataAction(index);
                }
                thisVue.timeShow = new Date().getTime();
                thisVue.$forceUpdate();
                $("#modal-config-api").css("opacity", 1);
            }
        });
    }
};
