export default {
    data: function() {
        return {
            dataCollection: [],
            dataItem: [],
            tabItem: [],
            tabSelect: "",
            listKeySelect: [],
            error: "",
            indexTabSelect: 0,
            indexTab: 0,
            indexRow: 0,
            keySelect: "",
            indexKey: 0,
            step: 0,
            classAnimate: "fadeInRight animated",
            listValueOfKey: [],
            nameTab: "",
            blockSelect: "",
            showSelect: "1"
        };
    },
    methods: {
        resetDataTab(data) {
            this.tabItem = [];
            for (let i = 0; i < data.length; i++) {
                this.tabItem.push(data[i].name);
            }
            data[0] && data[0].name && this.selectTab(data[0].name, 0);
        },
        selectTab(val, indexTab) {
            const thisVue = this;
            this.nameTab = val;
            this.keySelect = undefined;
            this.indexKey = undefined;
            if (this.indexTab != indexTab) {
                this.keySelect = "";
                this.indexKey = undefined;
            }
            this.indexTab = indexTab;
            this.tabSelect = val;
            this.dataCollection.find(function(x, index) {
                if (x.name === val) {
                    thisVue.indexTabSelect = index;
                    thisVue.dataItem = x.data;
                }
            });
            this.listKeySelect = [];
            for (let j = 0; j < this.dataItem.length; j++) {
                this.listKeySelect = $.extend(true, [], this.listKeySelect, Object.keys(this.dataItem[j]));
            }
        },
        selectKey(key) {
            if (this.keySelect != key) {
                this.indexKey = undefined;
            }
            this.keySelect = key;
            this.listValueOfKey = [];
            for (let i = 0; i < this.dataItem.length; i++) {
                this.listValueOfKey.push(this.dataItem[i][key]);
            }
        },
        selectValueDefault(indexD, indexK) {
            this.indexKey = indexD;
            this.indexRow = indexK;
            this.keySelect = this.listKeySelect[indexK];
        },
        setKeySelect(val) {
            this.keySelect = val;
            this.indexKey = undefined;
        },
        selectData() {
            if (!this.indexTab) {
                this.indexTab = 0;
            }
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.indexTab,
                keySelect: this.keySelect,
                indexKey: this.indexKey,
                saveData: true,
                nameTab: this.nameTab
            });
            $("#modal-collect-select").modal("hide");
            $("#select-data-app").modal("hide");
        },
        // HANDLE UI
        clickCollection(val, ind) {
            this.selectTab(val, ind);
            $("#select-data-app .item-data").css("box-shadow", "");
            $(`#select-data-app .item-data:nth-child(${ind + 1})`).css("box-shadow", "inset 0 0 2px #f87657");
        },
        openCollectDetail(val, indexTab) {
            this.selectTab(val, indexTab);
            $("#select-data-app .item-data").css("box-shadow", "");
            $("#modal-collect-select").modal("show");
            $("#select-data-app").css("opacity", "0");
        },
        handleFocusColumn(item, ind) {
            if (this.showSelect != '1') {
                return;
            }
            $("#modal-collect-select .row-title-column .title-column").css("outline", "");
            $("#modal-collect-select .data-value").css("outline", "");
            $(`#modal-collect-select .row-title-column .title-column:nth-child(${ind + 2})`).css(
                "outline",
                "1px solid #f87657"
            );
            this.setKeySelect(item);
        },
        handleFocusFieldData(indRow, indCol) {
            $("#modal-collect-select .row-title-column .title-column").css("outline", "");
            $("#modal-collect-select .data-value").css("outline", "");
            $(`#modal-collect-select .data-value[data-item="${indRow} ${indCol}"]`).css("outline", "1px solid #f87657");
            this.selectValueDefault(indRow, indCol);
        }
    },
    mounted() {
        const thisVue = this;
        $("#select-data-app").on("shown.bs.modal", function() {
            thisVue.blockSelect = $.extend(true, {}, thisVue.$store.state.editor.blockSelected);
            if (thisVue.blockSelect.componentName === "blockList" || thisVue.blockSelect.componentName === "blockCheckbox") {
                thisVue.showSelect = "1";
            } else {
                thisVue.showSelect = "0";
            }
            thisVue.step = 0;
            thisVue.dataCollection = $dataApp.data || [];
            thisVue.resetDataTab(thisVue.dataCollection);
            thisVue.indexTab = 0;
            thisVue.keySelect = thisVue.$store.state.editor.dataAppSelect.keySelect || undefined;
            thisVue.indexKey = thisVue.$store.state.editor.dataAppSelect.indexKey || undefined;
            thisVue.indexRow = thisVue.listKeySelect.indexOf(thisVue.keySelect);
        });
        $("#select-data-app").on("hidden.bs.modal", function() {
            $("#modal-collect-select .data-value").css("outline", "");
            $("#select-data-app .item-data").css("box-shadow", "");
        });
        $("#modal-collect-select").on("hidden.bs.modal", function() {
            $("#select-data-app").css("opacity", "");
            $("#modal-collect-select .data-value").css("outline", "");
        });
    }
};
