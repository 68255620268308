import resfult from "../../../../services/resful.js";
import { utils } from "@/libs-core/utils.min.js";

export const service = {
    createConfig: async function(data, next) {
        let res = await resfult.post("/app/create_functions", data);
        next(res);
    },
    update: async function(data, next) {
        let res = await resfult.post("/app/update_functions", data);
        next(res);
    },
    remove: async function(data, next) {
        let res = await resfult.post("/app/remove_functions", data);
        next(res);
    },
    getAllConfig: async function(data, next) {
        let url = "/app/functions?" + utils.jsonToParams(data);
        let res = await resfult.get(url);
        next(res);
    }
};
