import { utils } from "@/libs-core/utils.min.js";
import { editorSV } from "./list-connect-data.service.js";
export default {
    props: [],
    data() {
        return {
            block: {},
            idSl: "",
            timeShow: "",
            listDataGet: [],
            listModelItem: [],
            listItemList: [],
            componentName: "",
            dataAppSelect: {},
            dataModelList: {},
            dataModelIndex: "",
            dataModelSet: {},
            modelSetName: "",
            dataColect: {
                parkey: "",
                itemkey: "",
                using: false
            },
            keyDataPar: {
                using: false,
                value: ""
            }
        };
    },
    computed: {
        computeNameModelList() {
            if (typeof this.dataModelIndex === "number") {
                if (!this.listDataGet[this.dataModelIndex]) return "Select data";
                return this.listDataGet[this.dataModelIndex].name;
            }
            return "Select data";
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL) {
                this.block = val;
                this.idSl = this.block.id;
                this.getInitialData();
                this.keyDataPar = this.block.keyDataPar || {using: false, value: ""};
                this.dataColect = this.block.dataColect || {using: true, parkey: "", itemkey: ""};
            }
        },
        "$store.state.editor.api": {
            deep: true,
            handler(val) {
                this.getAllItemList();
            }
        }
    },
    methods: {
        setValuekeyData: function() {
            this.block.keyDataPar = this.keyDataPar;
            this.block.dataColect = this.dataColect;
            this.updateBlock(this.block, "listSt");
        },
        changeModelItem: function(valInd) {
            var thisVue = this;
            let api = this.$store.state.editor.api;
            let id = this.listItemList[valInd].id;
            this.block.listItem = this.listItemList;
            api.map((apiItem) => {
                if (apiItem.id === id) {
                    apiItem.model = thisVue.listItemList[valInd].key;
                    apiItem.valueList = "true";
                    if (apiItem.componentName != "blockList") {
                        $("#" + apiItem.id + " > .block-content").text(thisVue.listItemList[valInd].key);
                    } else {
                        apiItem.keyDataPar.using = true;
                        apiItem.dataColect.using = false;
                        apiItem.keyDataPar.value = thisVue.listItemList[valInd].key;
                        //$("#" + apiItem.id + " > .block-content").text(item.key);
                    }
                }
                return apiItem;
            });
            
            this.updateBlock(this.block, "listSt");
            this.$store.dispatch("editor/updateApi", api);
        },
        updateDataColect: function() {
            this.keyDataPar.using = false;
            this.dataColect.using = true;
            this.block.keyDataPar = this.keyDataPar;
            this.block.dataColect = this.dataColect;
            this.updateBlock(this.block, "listSt");
        },
        usingKeyDataPar: function() {
            this.keyDataPar.using = true;
            this.dataColect.using = false;
            this.block.keyDataPar = this.keyDataPar;
            this.block.dataColect = this.dataColect;
            this.updateBlock(this.block, "listSt");
            let selector;
            selector = "#connect-to-data .list-connect-data .list-data-dropdown .list-option";
            $(selector).toggle();
        },
        showSelectDataApp() {
            this.timeShow = new Date().getTime();
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);

            if (this.block && this.block.dataInput) {
                this.$store.commit("editor/mutaDataAppSelect", {
                    indexTab: this.dataAppSelect.indexTab,
                    keySelect: this.dataAppSelect.keySelect,
                    indexKey: this.dataAppSelect.indexKey,
                    saveData: false
                });
            }
            $("#select-data-app").modal("show");
        },
        resetModelSetName(val) {
            const dataApp = $dataApp.data || [];

            if (!dataApp.length || dataApp.length <= val.indexTab) return "";
            return utils.getValueDataApp(val.indexTab, val.keySelect, val.indexKey);
        },
        getInitialData() {
            let block = this.block;
            if (!block.id) return;

            this.componentName = block.componentName;
            if (block.componentName !== "blockList") return;
            if (!block.settingList) {
                block.settingList = {};
            }

            this.listDataGet = $dataApp && $dataApp.data ? $dataApp.data : [];
            this.dataModelList = block.settingList.dataModelList || {};
            this.dataModelSet = block.settingList.dataModelSet || {};
            this.listItemList = block.listItem || [];
            this.dataModelIndex = this.dataModelList.indexTab !== undefined ? this.dataModelList.indexTab : "";
            this.modelSetName = this.resetModelSetName(this.dataModelSet);
            this.getListItemModel();
            this.getAllItemList();
        },
        getAllItemList() {
            const thisVue = this;
            const listItemWg = [];

            if (!this.listItemList) {
                this.listItemList = [];
            }
            const wgItem =  $(`.block-widget[data-id ="${this.block.id}"]`).find(" > .block-content > .block-widget");
            if (wgItem && wgItem.length > 0) {
                wgItem.each(function () {
                    let item = {
                        name: $(this).attr("bt-type"),
                        id: $(this).attr("data-id"),
                        key: ""
                    };
                    listItemWg.push(item);
                    if (thisVue.listItemList && thisVue.listItemList.length > 0) {
                        let itemB = thisVue.listItemList.find((x) => x.id === item.id);
                        if (!itemB) {
                            thisVue.listItemList.push(item);
                        }
                    } else {
                        thisVue.listItemList.push(item);
                    }
                });
            }
            thisVue.listItemList = thisVue.listItemList.filter((item) => {
                let findItemWg = listItemWg.find((itemWg) => itemWg.id === item.id);
                return findItemWg;
            });
            if (!_.isEqual(thisVue.block.listItem, thisVue.listItemList)) {
                thisVue.block.listItem = thisVue.listItemList;
                this.updateBlock(this.block, "listSt");
            }
        },
        getListItemModel() {
            if (this.block && this.block.settingList && this.block.settingList.dataModelList) {
                if (!this.listDataGet[this.dataModelIndex]) return;
                if (!this.listDataGet[this.dataModelIndex].data) return;
                if (typeof this.listDataGet[this.dataModelIndex].data[0] === "object") {
                    this.listModelItem = Object.keys(this.listDataGet[this.dataModelIndex].data[0]);
                    if (this.listModelItem.length <= 0) {
                        this.listModelItem = Object.keys(this.listDataGet[this.dataModelIndex].data[1]);
                    }
                }
            } else {
                this.listModelItem = [];
            }
        },
        updateBlock(val, type) {
            if (!val.id) return;
            this.$store.dispatch("editor/updateBlock", { val, type });
        },
        hoverItemList(id) {
            $(`#${id}`).css({ outline: "2px solid #00d8a6" });
        },
        unhoverItemList(id) {
            $(`#${id}`).css({ outline: "" });
        },
        toggleSection(type, event, val, valInd) {
            var thisVue = this;
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let topSet;
            let selector;
            switch (type) {
                case "list-data-get":
                    thisVue.keyDataPar.using = false;
                    thisVue.block.keyDataPar = thisVue.keyDataPar;
                    thisVue.updateBlock(thisVue.block, "listSt");
                    selector = "#connect-to-data .list-connect-data .list-data-dropdown .list-option";
                    elementHeight = $(selector).outerHeight();

                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition - 8;
                    }
                    $(selector).css({
                        top: `${topSet}px`
                    });

                    if (val !== undefined) {
                        if (this.dataModelIndex !== val) {
                            this.dataModelIndex = val;
                            this.listItemList = this.block.listItem || [];
                            let api = this.$store.state.editor.api;
                            this.listItemList.forEach((item) => {
                                item.key = "";
                                api.map((apiItem) => {
                                    if (apiItem.id === item.id) {
                                        apiItem.model = "";
                                        apiItem.valueList = "false";
                                        if(apiItem.componentName != "blockList") {
                                            $("#" + apiItem.id + " > .block-content").html(apiItem.html);
                                            if (apiItem.componentName === "blockImage") {
                                                $("#" + apiItem.id + " > .block-content").html("<img src='" + apiItem.src + "'/>");
                                            }
                                        } else {
                                            if (apiItem.keyDataPar) {
                                                apiItem.keyDataPar.using = false;
                                            }
                                            if (apiItem.dataColect) {
                                                apiItem.dataColect.using = true;
                                            }else {
                                                apiItem.dataColect = {
                                                    using: true
                                                }
                                            }
                                        }
                                    }
                                    return apiItem;
                                });
                            });
                            this.updateBlock(this.block, "listSt");
                            this.$store.dispatch("editor/updateApi", api);
                        }
                        if (this.dataModelIndex === "") return;
                        if (!this.block.settingList) {
                            this.block.settingList.dataModelList = {};
                        }
                        this.block.settingList.dataModelList = {
                            indexTab: this.dataModelIndex,
                            keySelect: "",
                            indexKey: 0,
                            saveData: true,
                            useValue: "model"
                        };
                        this.getListItemModel();
                        this.updateBlock(this.block, "listSt");
                        // var name = $dataApp.data[val].name + "Detail";
                        // var itemData = $.extend(true, {}, $dataApp.data[val]);
                        // if (!$dataApp.data.find((x) => x.name.search("Detail") != -1)) {
                        //     itemData.name = name;
                        //     itemData.data.length = 1;
                        //     $dataApp.data.push(itemData);
                        //     editorSV.createOrUpdateDataApp(
                        //         JSON.stringify({ page_use: this.$route.params.id, data: $dataApp }),
                        //         function () {
                        //             thisVue.dataAppSelect.nameTab = name;
                        //             thisVue.dataAppSelect.indexTab = $dataApp.data.length - 1;
                        //             thisVue.dataAppSelect.keySelect = ""
                        //             thisVue.dataAppSelect.useValue = "model";
                        //             thisVue.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                        //             thisVue.block.settingList.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                        //             thisVue.modelSetName = thisVue.resetModelSetName(thisVue.dataAppSelect);
                        //             thisVue.updateBlock(thisVue.block, "listSt");
                        //         }
                        //     );
                        // } else {
                        //     itemData.name = name;
                        //     itemData.data.length = 1;
                        //     thisVue.dataAppSelect.nameTab = name;
                        //     thisVue.dataAppSelect.indexTab = $dataApp.data.findIndex((k) => k.name === name);
                        //     thisVue.dataAppSelect.keySelect = ""
                        //     thisVue.dataAppSelect.useValue = "model";
                        //     thisVue.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                        //     thisVue.block.settingList.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                        //     thisVue.modelSetName = thisVue.resetModelSetName(thisVue.dataAppSelect);
                        //     thisVue.updateBlock(thisVue.block, "listSt");
                        // }
                    }
                    break;
                case "item-list":
                    let leftPos = $("#connect-to-data .list-connect-data .list-item .item input")
                        .get(0)
                        .getBoundingClientRect().left;
                    selector = `#connect-to-data .list-connect-data .item:nth-child(${valInd + 1}) .list-option`;
                    elementHeight = $(selector).outerHeight();

                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(selector).css({
                        top: `${topSet}px`,
                        left: `${leftPos}px`
                    });
                    let api = this.$store.state.editor.api;
                    this.listItemList[valInd].key = val;
                    let id = this.listItemList[valInd].id;
                    this.block.listItem = this.listItemList;
                    api.map((apiItem) => {
                        if (apiItem.id === id) {
                            apiItem.model = val;
                            apiItem.valueList = "true";
                            if (val && apiItem.componentName != "blockList") {
                                $("#" + apiItem.id + " > .block-content").text(val);
                            } else {
                                if (apiItem.keyDataPar) {
                                    apiItem.keyDataPar.using = true;
                                    apiItem.keyDataPar.value = val;
                                } else {
                                    apiItem.keyDataPar = {};
                                    apiItem.keyDataPar.using = true;
                                    apiItem.keyDataPar.value = val;
                                }
                                if(apiItem.dataColect) {
                                    apiItem.dataColect.using = false;
                                }
                                //$("#" + apiItem.id + " > .block-content").text(item.key);
                            }
                        }
                        return apiItem;
                    });
                    
                    this.updateBlock(this.block, "listSt");
                    this.$store.dispatch("editor/updateApi", api);
                    break;
            }
        },
        noSetDataBlock(itemBlock, valInd) {
            let api = this.$store.state.editor.api;
            this.listItemList[valInd].key = '';
            api.map((apiItem) => {
                if (apiItem.id === itemBlock.id) {
                    apiItem.model = '';
                    apiItem.valueList = "false";
                    if (apiItem.componentName == "blockList") {
                        if (apiItem.keyDataPar) {
                            apiItem.keyDataPar.using = false;
                        }
                        if(apiItem.dataColect) {
                            apiItem.dataColect.using = true;
                        } else {
                            apiItem.dataColect = {
                                using: true
                            }
                        }
                    }
                }
                return apiItem;
            });
            this.updateBlock(this.block, "listSt");
            this.$store.dispatch("editor/updateApi", api);
            $(".item-list-dropdown.dropdown-pattern .list-option").hide();
        }
    },
    mounted() {
        const thisVue = this;
        const block = this.$store.state.editor.blockSelected;
        if (block && block.id) {
            this.block = block;
            this.idSl = block.id;
        }
        thisVue.getInitialData();

        $("#select-data-app").on("hidden.bs.modal", function () {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                thisVue.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                thisVue.block.settingList.dataModelSet = $.extend(true, {}, thisVue.dataAppSelect);
                thisVue.modelSetName = thisVue.resetModelSetName(thisVue.dataAppSelect);
                console.log("thisVue.dataAppSelect::: ", thisVue.dataAppSelect);
                thisVue.updateBlock(thisVue.block, "listSt");
            }
        });
        $("#modal-manage-data").on("hidden.bs.modal", function () {
            thisVue.getInitialData();
        });
    }
};
