import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
export default {
    components: {
        ColorPicker
    },
    data: function () {
        return {
            idSL: "",
            block: {},
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "rgba(0,0,0,0)",
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderRadius: 0,
            showAllB: false,
            focusInput: ""
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id != this.idSL) {
                this.idSL = val.id;
                this.block = $.extend(true, {}, val);
                if (!this.block.border) {
                    this.block.border = {};
                }
                this.borderWidth = parseFloat(this.block.border.borderWidth) || 0;
                this.borderTop = parseFloat(this.block.border.borderTop) || 0;
                this.borderLeft = parseFloat(this.block.border.borderLeft) || 0;
                this.borderBottom = parseFloat(this.block.border.borderBottom) || 0;
                this.borderRight = parseFloat(this.block.border.borderRight) || 0;
                this.borderRadius = parseFloat(this.block.border.borderRadius) || 0;
                this.borderStyle = this.block.border.borderStyle || "solid";
                this.borderColor = this.block.border.borderColor || "rgba(0,0,0,0)";
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                    if (!this.block.border) {
                        this.block.border = {};
                    }
                    this.borderWidth = parseFloat(this.block.border.borderWidth) || 0;
                    this.borderTop = parseFloat(this.block.border.borderTop) || 0;
                    this.borderLeft = parseFloat(this.block.border.borderLeft) || 0;
                    this.borderBottom = parseFloat(this.block.border.borderBottom) || 0;
                    this.borderRight = parseFloat(this.block.border.borderRight) || 0;
                    this.borderRadius = parseFloat(this.block.border.borderRadius) || 0;
                    this.borderStyle = this.block.border.borderStyle || "solid";
                    this.borderColor = this.block.border.borderColor || "rgba(0,0,0,0)";
                }
            }
        },
        "borderWidth": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderWidth") {
                this.block.border.borderWidth = val + "px";
                this.block.border.borderTop = val + "px";
                this.block.border.borderLeft = val + "px";
                this.block.border.borderBottom = val + "px";
                this.block.border.borderRight = val + "px";
                this.borderWidth = val;
                this.borderTop = val;
                this.borderLeft = val;
                this.borderBottom = val;
                this.borderRight = val;
                this.updateBlock();
            }
        },
        "borderTop": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderTop") {
                this.borderTop = val;
                this.block.border.borderTop = val + "px";
                this.updateBlock();
            }
        },
        "borderLeft": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderLeft") {
                this.borderLeft = val;
                this.block.border.borderLeft = val + "px";
                this.updateBlock();
            }
        },
        "borderBottom": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderBottom") {
                this.borderBottom = val;
                this.block.border.borderBottom = val + "px";
                this.updateBlock();
            }
        },
        "borderRight": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderRight") {
                this.borderRight = val;
                this.block.border.borderRight = val + "px";
                this.updateBlock();
            }
        },
        "borderRadius": function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (this.focusInput == "borderRadius") {
                this.borderRadius = val;
                this.block.border.borderRadius = val + "px";
                this.updateBlock();
            }

        },
    },
    methods: {
        setBoderStyle: function (val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!val || val == "") {
                this.borderStyle = '';
                this.block.border.borderStyle = 'none';
            } else {
                this.borderStyle = val;
                this.block.border.borderStyle = val;
            }
            this.updateBlock();
        },
        forcusInput: function (val) {
            this.focusInput = val;
        },
        blurInput: function () {
            this.focusInput = "";
        },
        setshowAllB: function () {
            this.showAllB = !this.showAllB;
        },
        updateBlock: function () {
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "borderSt" });
        },
        updateColor: function (color) {
            this.borderColor = color;
            if (!this.block.border) {
                this.block.border = {};
            }
            this.block.border.borderColor = color
            this.updateBlock();
        },
        closeDrop: function (event) {
            $(event.target).hide();
        },
        closeSt: function (event) {
            $("#border-st").parent().hide();
        }
    },
    mounted: function () {
        this.block = this.$store.state.editor.blockSelected;
        if (this.block) {
            if (!this.block.border) {
                this.block.border = {};
            }
            this.borderWidth = parseFloat(this.block.border.borderWidth) || 0;
            this.borderTop = parseFloat(this.block.border.borderTop) || 0;
            this.borderLeft = parseFloat(this.block.border.borderLeft) || 0;
            this.borderBottom = parseFloat(this.block.border.borderBottom) || 0;
            this.borderRight = parseFloat(this.block.border.borderRight) || 0;
            this.borderRadius = parseFloat(this.block.border.borderRadius) || 0;
            this.borderStyle = this.block.border.borderStyle || "solid";
            this.borderColor = this.block.border.borderColor || "rgba(0,0,0,0)";
        }

        $(".apo-dropdown").off("click").on("click", function () {
            $(".apo-dropdown-list").hide();
            $(this).parent().find(".apo-dropdown-list").show();
        });
        $(".apo-dropdown").off("blur").on("blur", function () {
            $(".item-q-setting").hide();
            $("#quick-setting-block .item").removeClass("active");
        })
    }
}