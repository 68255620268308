import resfult from "../../../../services/resful.js";

export const sevice = {
  // lấy thông tin 1 invoice
  getOnceInvoice: async function(data, next) {
      let res = await resfult.get("/app/invoices?id" + data.id, data);
      next(res);
  },
  // lấy danh sách upgrade
  getAllUpgrade: async function(data, next) {
      let res = await resfult.get("/app/upgrades?skip="+data.skip, data);
      next(res);
  },
  // lấy danh sách các gói
  getAllPlan: async function(data, next) {
      let res = await resfult.get("/app/plans", data);
      next(res);
  },
  // lấy danh sách hóa đơn
  getAllInvoice: async function(data, next) {
      let res = await resfult.get("/app/invoices", data);
      next(res);
  },
  // active một hóa đơn key invoice === id invoice
  activeInvoice: async function(data, next) {
      let res = await resfult.post("/app/active-invoice", data);
      next(res);
  },
  // nâng cấp app với page === id app; upgrade === id upgrade
  upgradeApp: async function(data, next) {
      let res = await resfult.post("/app/upgrade-app", data);
      next(res);
  },
  // tạo hóa đơn với package = id plan package
  createInvoice: async function(data, next) {
      let res = await resfult.post("/app/create-invoice", data);
      next(res);
  },
  createInvoicePaypal: async function(data, next) {
      let res = await resfult.post("/app/create-invoice-paypal", data);
      next(res);
  }
};
