import { render, staticRenderFns } from "./suggest-data-widget.html?vue&type=template&id=2f16a844&scoped=true&"
import script from "./suggest-data-widget.js?vue&type=script&lang=js&"
export * from "./suggest-data-widget.js?vue&type=script&lang=js&"
import style0 from "./suggest-data-widget.vue?vue&type=style&index=0&id=2f16a844&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f16a844",
  null
  
)

export default component.exports