//------//
// CONFIG CODE MIRROR
//------//
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/css/css.js";

import "codemirror/addon/selection/active-line.js";

import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// END CONFIG CODE MIRROR //
///////////////////////////

export default {
    props: ["blockSelected", "timeUpdate"],
    components: {
        codemirror
    },
    data() {
        return {
            block: {},
            cssAdvance: "",
            idSl: "",
            optionsCm: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                mode: "text/css",
                theme: "default",
                autocorrect: true
            }
        };
    },
    watch: {
        "$store.state.editor.device"() {
            this.idSl = "";
        },
        cssAdvance() {
            if (this.block && this.block.id) {
                const device = this.$store.state.editor.device;
                this.block[device].cssAdvance = this.cssAdvance;
                this.$store.dispatch("editor/updateBlock", { val: this.block, type: "cssAdvanceSt" });
            }
        }
    },
    methods: {
        getData() {
            let val = this.$store.state.editor.blockSelected;
            if (val && val.id !== this.idSl) {
                const device = this.$store.state.editor.device;
                this.idSl = val.id;
                this.block = val;
                const css =
                    `.` +
                    this.idSl +
                    ` > .block-content{ 
	/*
	* This feature only support developer.
	* Use !important for overwrite css.
	* For example:
	* font-size: 15px !important;
	* text-align: center !important;
	* ...
	*/
}`;
                if (this.block[device].cssAdvance) {
                    this.cssAdvance = this.block[device].cssAdvance || css;
                } else {
                    this.cssAdvance = this.block.cssAdvance || css;
                }
            }
        }
    },
    mounted() {
        const thisVue = this;
        $("#css-advance").on("shown.bs.modal", function() {
            setTimeout(() => thisVue.getData(), 100);
        });
    }
};
