import resfult from "../../../../services/resful.js";

export const sevice = {
    createInvoiceVnPay: async function (data, next) {
        let res = await resfult.post("/app/create-invoice-vnpay", JSON.stringify(data));
        next(res);
    },
    createInvoicePaypal: async function (data, next) {
        let res = await resfult.post("/app/create-invoice-paypal", JSON.stringify(data));
        next(res);
    },
};
