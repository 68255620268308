export default {
    data() {
        return {
            block: {},
            listAnimation: [
                "rollIn",
                "bounceInDown",
                "lightSpeedIn",
                "pulse",
                "bounceInRight",
                "bounceInLeft",
                "flipInX",
                "shake",
                "swing",
                "bounceInUp",
                "bounce",
                "flip"
            ],
            animationSelect: "",
            duration: 0.5,
            delay: 0.5,
            iteration: 1,
            idSl: ""
        };
    },
    computed: {
        computeNameAnimation() {
            if (!this.animationSelect) return "None";
            let name = this.animationSelect.charAt(0).toUpperCase() + this.animationSelect.substring(1);
            return name;
        }
    },
    watch: {
        "$store.state.editor.blockSelected": function(val) {
            if (val && val.id !== this.idSl) {
                this.idSl = val.id;
                this.block = $.extend(true, {}, val);
                if (this.block.animated) {
                    this.animationSelect = this.block.animated.animation || "";
                    this.duration = this.block.animated.duration || 0.5;
                    this.delay = this.block.animated.delay || 0.5;
                    this.iteration = this.block.animated.iteration || 1;
                    if (Object.keys(this.block.animated).length) {
                        $(".animation").show();
                    } else {
                        $(".animation").hide();
                    }
                } else {
                    this.block.animated = {};
                    this.animationSelect = "";
                    this.duration = 0.5;
                    this.delay = 0.5;
                    this.iteration = 1;
                    $("animation").hide();
                }
            }else {
                if (val && val.id == this.idSL) {
                    console.log("hay lắm");
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        duration: _.debounce(function(val) {
            if (this.block && this.block.animated) {
                this.block.animated.duration = val;
            } else {
                this.block.animated = {};
                this.block.animated.duration = val;
            }
            this.updateBlock();
        }, 300),
        delay: _.debounce(function(val) {
            if (this.block && this.block.animated) {
                this.block.animated.delay = val;
            }else {
                this.block.animated = {};
                this.block.animated.delay = val;
            }
            this.updateBlock();
        }, 300),
        iteration: _.debounce(function(val) {
            if (this.block && this.block.animated) {
                this.block.animated.iteration = val;
            } else {
                this.block.animated = {};
                this.block.animated.iteration = val;
            }
            this.updateBlock();
        }, 300)
    },
    methods: {
        updateBlock() {
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: "animationSt" });
        },
        updateAnimationSt(type) {
            if (type === "add") {
                $(".animation").show();
                return;
            }
            this.animationSelect = "";
            this.block.animated = {};
            $(".animation").hide();
            this.updateBlock();
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let leftPosition = event.target.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;
            let topSet;
            let leftSet;
            let elementHeight;
            let selector;
            switch (nameSection) {
                case "animation":
                    selector = "#animation-st .animation-dropdown .list-option";
                    elementHeight = $(selector).outerHeight();

                    $("#animation-st .animation").removeClass("popup-show");
                    $("#animation-st .edit-popup").hide();
                    $(selector).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(selector).css({
                        top: `${topSet}px`
                    });

                    if (val) {
                        this.animationSelect = val;
                        if (!this.block.animated) {
                            this.block.animated = {};
                        }
                        this.block.animated.animation = val;
                        this.updateBlock();
                        $("#demo-animated").attr("class", "");
                        var classani = "wow animated " + val;
                        $("#demo-animated").addClass(classani);
                        
                    }
                    $("#animation-st .apo-dropdown-list").css({"display":"none"});
                    break;
                case "edit-animation":
                    selector = "#animation-st .animation-list .animation";
                    if ($(selector).hasClass("popup-show")) {
                        $(selector).removeClass("popup-show");
                        $(selector + " .edit-popup").hide();
                    } else {
                        $(selector).addClass("popup-show");
                        $(selector + " .edit-popup").show();
                    }

                    $(".edit-popup").removeClass("no-after");
                    if (windowHeight < topPosition + 290) {
                        topSet = windowHeight - 300;
                        leftSet = leftPosition - 10;

                        $(".edit-popup").addClass("no-after");
                    } else {
                        topSet = topPosition - 7;
                        leftSet = leftPosition - 10;
                    }
                    $(".edit-popup").css({
                        top: `${topSet}px`,
                        left: `${leftSet}px`
                    });
                    break;
            }
        },
        closeDrop: function(event) {
            $(event.target).hide();
        },
        closeSt: function(event) {
            $("#animation-st").parent().hide();
        }
    },
    mounted() {
        this.block = $.extend(true, {}, this.$store.state.editor.blockSelected);
        if (this.block && this.block.animated) {
            this.animationSelect = this.block.animated.animation || "";
            this.duration = this.block.animated.duration || 0.5;
            this.delay = this.block.animated.delay || 0.5;
            this.iteration = this.block.animated.iteration || 1;
            if (Object.keys(this.block.animated).length) {
                $(".animation").show();
            }
        } else {
            this.animationSelect = "";
            this.duration = 0.5;
            this.delay = 0.5;
            this.iteration = 1;
        }
    }
};
