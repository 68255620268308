import resful from "@/services/resful.js";
export const editorSV = {
  getDataApp: async function(data, next) {
    let res = await resful.post("/public/data-app_read_public", data);
    next(res);
  },
  createOrUpdateDataApp: async function(data, next) {
    let res = await resful.post("/app/data-app_create_owner", data);
    next(res);
  },
};
