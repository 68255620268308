import { utils } from "@/libs-core/utils.min.js";

export default {
    data() {
        return {
            listItemValidate: [],
            timeShow: 0,
            timeShowAction: 0,
            indexCurrentItem: 0,
            dataAppSelect: {}
        };
    },
    methods: {
        deleteValidateItem(indRemoveItem) {
            this.listItemValidate = this.listItemValidate.filter((item, ind) => {
                return ind !== indRemoveItem;
            })
        },
        getNameDataValidate(index) {
            const dataAppSelect = this.listItemValidate[index].dataAppSelect || {};

            if (dataAppSelect.indexTab === undefined) {
                return "";
            }
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= dataAppSelect.indexTab) return "";
            return utils.getValueDataApp(dataAppSelect.indexTab, dataAppSelect.keySelect, dataAppSelect.indexKey);
        },
        showSelectDataApp(index) {
            this.indexCurrentItem = index;
            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, this.listItemValidate[index].dataAppSelect);
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
            $("#modal-config-validate").css("opacity", 0);
        },
        addValidateItem() {
            if (!this.listItemValidate) {
                this.listItemValidate = [];
            }
            const item = {
                dataAppSelect: {},
                mess: "",
                syntax: "",
                type: "empty"
            };
            this.listItemValidate.push(item);
        },
        toggleSection(type, event, val, ind) {
            let seletor;
            if (type === 'validate') {
                seletor = `#modal-config-validate .item:nth-child(${ind + 1}) .validate-dropdown .list-option`;
                $(seletor).toggle();
            }
            if (val) {
                this.listItemValidate[ind].type = val;
            }
        }
    },
    mounted() {
        const thisVue = this;

        $("#modal-config-validate").on("shown.bs.modal", function() {
            let data = thisVue.$store.state.editor.dataActionSelect;

            if (!data) return;
            thisVue.listItemValidate = data.listItemValidate;
            thisVue.timeShowAction = data.timeShowAction;
        });
        $("#modal-config-validate").on("hide.bs.modal", function() {
            let data = {
                listItemValidate: thisVue.listItemValidate,
                timeShowAction: thisVue.timeShowAction
            };
            thisVue.$store.commit("editor/mutaDataActionSelect", data);
        });

        $("#select-data-app").on("hidden.bs.modal", function() {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                thisVue.listItemValidate[thisVue.indexCurrentItem].dataAppSelect = $.extend(
                    true,
                    {},
                    thisVue.dataAppSelect
                );
                thisVue.timeShow = new Date().getTime();
                thisVue.$forceUpdate();
                $("#modal-config-validate").css("opacity", 1);
            }
        });
    }
};
