import resful from "@/services/resful.js";
export default {
  uploadImage: async function (data, next) {
    let res = await resful.uploadImage("/app/upload", data);
    next(res);
  },
  getAllImage: async function (data, next) {
    let res = await resful.get("/app/asset?sort=createdAt DESC", data);
    next(res);
  },
  createCate: async function(data, next) {
    let res = await resful.post("/app/create_category", data);
    next(res);
  },
  getCate: async function(data, next) {
    let res = await resful.get("/app/category?" + data);
    next(res);
  },
  deleteCate: async function(data, next) {
    let res = await resful.post("/app/remove_image_category", data);
    next(res);
  },
  getCateAndImage: async function(data, next) {
    let res = await resful.post("/app/get_cate_asset", data);
    next(res);
  },
};
