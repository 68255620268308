import { render, staticRenderFns } from "./block-manager.component.html?vue&type=template&id=147e3c63&scoped=true&"
import script from "./block-manager.component.js?vue&type=script&lang=js&"
export * from "./block-manager.component.js?vue&type=script&lang=js&"
import style0 from "./block-manager.component.vue?vue&type=style&index=0&id=147e3c63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147e3c63",
  null
  
)

export default component.exports