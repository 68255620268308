export default {
    data() {
        return {
            namePage: ""
        }
    },
    methods: {
        createPageClone() {
            this.$emit("clonePage", this.namePage);
            this.namePage = "";
        }
    }
}