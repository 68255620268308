import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";

export default {
    components: {
        ColorPicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            device: "",
            idSL: "",
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},
            fill: "#000000",
            stroke: "#000000",
            shadowX: "",
            shadowY: "",
            shadowBlur: "",
            shadowColor: "",
            strokeWidth: 0
        };
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id !== this.idSL && val.componentName === "blockSvg") {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;
                this.getInitialData();
            }else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device": function(val) {
            this.device = val;
        },
        shadowX: _.debounce(function(val) {
            if (this.block) {
                if (!this.block.shadow) {
                    this.block.shadow = {};
                }
                this.block.shadow.shadowX = val;
                this.block.filter = this.generateFilter();
                this.updateEffectConfig();
                this.updateBlock(this.block, "shadowSt");
            }
        }, 300),
        shadowY: _.debounce(function(val) {
            if (this.block) {
                if (!this.block.shadow) {
                    this.block.shadow = {};
                }
                this.block.shadow.shadowY = val;
                this.block.filter = this.generateFilter();
                this.updateEffectConfig();
                this.updateBlock(this.block, "shadowSt");
            }
        }, 300),
        shadowBlur: _.debounce(function(val) {
            if (this.block) {
                if (!this.block.shadow) {
                    this.block.shadow = {};
                }
                this.block.shadow.shadowBlur = val;
                this.block.filter = this.generateFilter();
                this.updateEffectConfig();
                this.updateBlock(this.block, "shadowSt");
            }
        }, 300),
        strokeWidth: _.debounce(function(val) {
            if (this.block) {
                this.block.strokeWidth = val + "px";
                this.updateBlock(this.block, "svgSt");
            }
        }, 300),
    },
    methods: {
        updateFill(color) {
            if (this.block && this.block.id) {
                this.fill = color;
                this.block.fill = color;
                this.updateBlock(this.block, "svgSt");
            }
        },
        updateStroke(color) {
            if (this.block && this.block.id) {
                this.stroke = color;
                this.block.stroke = color;
                this.updateBlock(this.block, "svgSt");
            }
        },
        updateColorEffect(color) {
            if (!this.block) return;
            if (!this.block.shadow) {
                this.block.shadow = {};
            }

            this.shadowColor = color;
            this.block.shadow.shadowColor = color;
            this.block.filter = this.generateFilter();
            this.updateEffectConfig();
            this.updateBlock(this.block, "shadowSt");
        },
        generateFilter() {
            let filterString = "";
            filterString += this.shadowX + "px ";
            filterString += this.shadowY + "px ";
            filterString += this.shadowBlur + "px ";
            filterString += this.shadowColor;
            return filterString;
        },
        updateEffectConfig() {
            let boxShadow = "";
            let blur = "";

            boxShadow = this.generateFilter();
            if (this.effectProperty.blur) {
                blur = this.effectProperty.blur + "px";
            }
            this.effectBlock = {
                boxShadow,
                blur
            };

            this.block[this.device].effect = this.effectBlock;
            this.updateBlock(this.block, "effectSt");
        },
        generateListEffect() {
            this.listEffectBlock = [];
            let convertEffectProperty = (item) => {
                if (item === "blur") {
                    this.effectProperty.blur = parseFloat(this.effectBlock.blur);
                    return;
                }
                if (this.block.filter) {
                    this.effectProperty.boxShadow = this.block.filter;
                }
            };
            for (let item in this.effectBlock) {
                if (this.effectBlock[item]) {
                    this.listEffectBlock.push(item);
                    convertEffectProperty(item);
                }
            }
        },
        updateBlock(val, type) {
            this.block = val;
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: type });
        },
        getInitialData() {
            this.fill = this.block.fill || "#000000";
            this.stroke = this.block.stroke;
            // this.fill = this.block.fill || "#B9B7C0";
            // this.stroke = this.block.stroke || "#B9B7C0";
            if (this.block.shadow) {
                this.shadowX = this.block.shadow.shadowX || 0;
                this.shadowY = this.block.shadow.shadowY || 0;
                this.shadowBlur = this.block.shadow.shadowBlur || 0;
                this.shadowColor = this.block.shadow.shadowColor || "#000000";
            }
            this.strokeWidth = parseFloat(this.block.strokeWidth) || 0;
            this.generateListEffect();
        },
        handleClickRemove(type, item) {
            if (type === "effect") {
                this.listEffectBlock = this.listEffectBlock.filter((i) => i !== item);
                this.effectProperty[item] = "";

                if (item === "boxShadow") {
                    this.shadowX = 0;
                    this.shadowY = 0;
                    this.shadowBlur = 0;
                    this.shadowColor = "#000000";
                    this.block.filter = this.generateFilter();
                    this.block.shadow = {
                        shadowX: 0,
                        shadowY: 0,
                        shadowBlur: 0,
                        shadowColor: "#000000"
                    };
                    this.updateBlock(this.block, "shadowSt");
                }
                this.updateEffectConfig();
            }
        },
        handleClickAdd(type, item) {
            if (type === "effect") {
                if (this.listEffectBlock.length === 2) return;
                if (this.listEffectBlock.includes("boxShadow")) {
                    this.listEffectBlock.push("blur");
                    this.effectProperty.blur = 0;
                    return;
                }
                this.listEffectBlock.push("boxShadow");
                this.effectProperty.boxShadow = "";
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let leftPosition = event.target.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;
            let selector;
            let topSet;
            let leftSet;
            switch (nameSection) {
                case "fold":
                    if ($("#shape-st").hasClass("fold-section")) {
                        $("#shape-st").removeClass("fold-section");
                    } else {
                        $("#shape-st").addClass("fold-section");
                    }
                    break;
                case "effect":
                    $(`#shape-st .effect-shape .effect:nth-child(${index + 1}) .list-option`).toggle();

                    if (item === val || this.listEffectBlock.includes(val) || !val) return;
                    this.listEffectBlock = this.listEffectBlock.map((i) => {
                        if (i === item) {
                            this.effectProperty[item] = "";
                            if (val === "blur") {
                                this.effectProperty.blur = 0;
                            } else {
                                this.effectProperty.boxShadow = "";
                            }
                            return val;
                        }
                        return i;
                    });
                    break;
                case "edit-effect":
                    selector = `#shape-st .effect-shape .effect:nth-child(${index + 1})`;
                    if ($(selector).hasClass("popup-show")) {
                        $(selector).removeClass("popup-show");
                        $(selector + " .edit-popup").hide();
                        this.updateEffectConfig();
                        return;
                    } else {
                        $(selector).addClass("popup-show");
                        $(selector + " .edit-popup").show();
                    }

                    $(".edit-popup").removeClass("no-after");
                    if (windowHeight < topPosition + 290 && item === "boxShadow") {
                        topSet = windowHeight - 300;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else if (windowHeight < topPosition + 100 && item === "blur") {
                        topSet = windowHeight - 110;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else {
                        topSet = topPosition - 7;
                        leftSet = leftPosition - 10;
                    }
                    $(".edit-popup").css({
                        top: `${topSet}px`,
                        left: `${leftSet}px`
                    });
                    break;
            }
        },
        closeSt: function() {
            $("#shape-st").parent().hide();
        }
    },
    mounted() {
        // this.blockSelected = this.$store.state.editor.blockSelected;
        // this.device = this.$store.state.editor.device;
        // this.idSL = this.blockSelected.id;
        // this.block = $.extend(true, {}, this.blockSelected);
        // this.componentName = this.blockSelected.componentName;
        //this.getInitialData();
    }
};
