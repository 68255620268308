import resful from "@/services/resful.js";
export const historyService = {
    getAllHistory: async function(data, next) {
        let res = await resful.post("/app/history-page", data);
        next(res);
    },
    getSource: async function(data, next) {
        let res = await resful.post("/app/history-page", data);
        next(res);
    },
};