import VueTribute from "vue-tribute";
import constant from "../../../../../constants/index.js";
import { utils } from "@/libs-core/utils.min.js";

//------//
// CONFIG CODE MIRROR
//------//
import { codemirror } from "vue-codemirror";

// THEME
import "codemirror/lib/codemirror.css";

// LANGUAGE
import "codemirror/mode/javascript/javascript.js";

// ACTIVE LINE
import "codemirror/addon/selection/active-line.js";

// SELECT TEXT STYLE
import "codemirror/addon/selection/mark-selection.js";

// HIGHLIGHT TEXT SELECTION
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";
// END CONFIG CODE MIRROR //
///////////////////////////

export default {
    components: {
        VueTribute,
        codemirror
    },
    props: ["api_data", "listApi"],
    data() {
        return {
            nameApi: "",
            urlApi: "",
            typeApi: "", // public or security
            params: [],
            header: [],
            dataApp: [],
            body: {
                type: "normal", // normal or raw
                valueNormal: [],
                valueRaw: ""
            },
            id: "",
            method: "GET",
            list_model: $listModel,
            response: "",
            timeShow: 0,
            dataAppSelect: {},
            type: "",
            indexList: 0,
            optionsCm: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                mode: "text/javascript",
                theme: "default"
            }
        };
    },
    watch: {
        nameApi: _.debounce(function (val) {
            this.nameApi = utils.resetText(this.nameApi);
            this.options_tribute = constant._tribute.options($listModel);
        }),
        api_data() {
            this.setvalueDefault();
            this.id = this.api_data.id;
            this.nameApi = this.api_data.name;
            if (this.api_data.configs) {
                this.urlApi = this.api_data.configs.url;
                this.typeApi = this.api_data.configs.security;
                this.params = $.extend(true, [], this.api_data.configs.params);
                this.header = $.extend(true, [], this.api_data.configs.header);
                this.body = $.extend(true, {}, this.api_data.configs.body);
                this.method = this.api_data.configs.method;
            }
        }
    },
    methods: {
        textReplacedTribute(e, tab, vt) {
            const thisVue = this;

            switch (tab) {
                case "params":
                    thisVue.params[vt].value = utils.parseModel(e.target.value);
                    break;
                case "header":
                    thisVue.header[vt].value = utils.parseModel(e.target.value);
                    break;
                case "body":
                    thisVue.body.valueNormal[vt].value = utils.parseModel(e.target.value);
                    break;
            }
        },
        saveApi() {
            this.params = this.formatValue(this.params);
            console.log("params::: ", this.params);
            this.header = this.formatValue(this.header);
            this.body.valueNormal = this.formatValue(this.body.valueNormal);
            if (this.body.valueRaw && this.body.valueRaw.length > 0) {
                this.body.valueRawCode = this.utoa(this.body.valueRaw);
            }
            let datasave = {
                url: this.urlApi,
                method: this.method,
                name: this.nameApi,
                id: this.id,
                params: this.params,
                header: this.header,
                body: this.body,
                security: this.typeApi
            };

            this.$emit("saveApi", datasave);
        },
        utoa(str) {
            return window.btoa(unescape(encodeURIComponent(str)));
        },
        atou(str) {
            return decodeURIComponent(escape(window.atob(str)));
        },
        testApi() {
            const thisVue = this;

            $("#pills-tab #pills-resuflt-tab").trigger("click");
            try {
                let dataHeader = {};
                let data = {};
                let dataGet = "";
                let dataParams = {};
                let arrBody = thisVue.body.valueNormal || [];
                let arrParams = thisVue.params || [];
                let arrHeader = thisVue.header || [];
                let url = thisVue.urlApi;
                let method = thisVue.method;
                let urlCurent = thisVue.resetString(url);
                let is_form = true;
                let id = thisVue.id;

                let dataApp = $dataApp && $dataApp.data ? $dataApp.data : [];
                let getValueDataApp = (indexTab, keySelect, indexKey) => {
                    if (indexTab === undefined || !keySelect || indexKey === undefined) return "";

                    let tabData = dataApp[indexTab];
                    if (!tabData.data || !tabData.data.length) return "";

                    let keyObj = tabData.data[indexKey];
                    if (!keyObj) return "";

                    return tabData.data[indexKey][keySelect];
                };

                for (let i = 0; i < arrBody.length; i++) {
                    if (arrBody[i].dataAppSelect && arrBody[i].dataAppSelect.useValue === "model") {
                        data[arrBody[i].key] = getValueDataApp(
                            arrBody[i].dataAppSelect.indexTab,
                            arrBody[i].dataAppSelect.keySelect,
                            arrBody[i].dataAppSelect.indexKey
                        );
                    } else {
                        data[arrBody[i].key] = thisVue.resetString(arrBody[i].value);
                    }
                }
                for (let j = 0; j < arrParams.length; j++) {
                    if (arrParams[j].key && arrParams[j].key.length > 0) {
                        let value;
                        if (arrParams[j].dataAppSelect && arrParams[j].dataAppSelect.useValue === "model") {
                            value = getValueDataApp(
                                arrParams[j].dataAppSelect.indexTab,
                                arrParams[j].dataAppSelect.keySelect,
                                arrParams[j].dataAppSelect.indexKey
                            );
                        } else {
                            value = thisVue.resetString(arrParams[j].value);
                        }
                        dataParams[arrParams[j].key] = value;
                        if (dataGet && dataGet.length > 0) {
                            dataGet += "&" + arrParams[j].key + "=" + value;
                        } else {
                            dataGet += "?" + arrParams[j].key + "=" + value;
                        }
                    }
                }
                for (let k = 0; k < arrHeader.length; k++) {
                    if (arrHeader[k].dataAppSelect && arrHeader[k].dataAppSelect.useValue === "model") {
                        dataHeader[arrHeader[k].key] = getValueDataApp(
                            arrHeader[k].dataAppSelect.indexTab,
                            arrHeader[k].dataAppSelect.keySelect,
                            arrHeader[k].dataAppSelect.indexKey
                        );
                    } else {
                        dataHeader[arrHeader[k].key] = thisVue.resetString(arrHeader[k].value);
                    }
                }
                if (thisVue.body.type === "raw") {
                    data = JSON.stringify(data);
                }
                if (thisVue.typeApi === "security") {
                    url = "https://security.appon.vn/v1/public/bu_send";
                    dataHeader.xapi = id;
                } else {
                    url = thisVue.resetString(url);
                }
                if (dataGet && dataGet.length > 0 && thisVue.typeApi != "security") {
                    url += dataGet;
                    urlCurent += dataGet;
                }
                dataHeader.xurl = urlCurent;
                if (thisVue.typeApi === "security") {
                    let time = new Date().getTime();
                    let dataHeaderSave = {
                        header: JSON.stringify(dataHeader),
                        body: JSON.stringify(data),
                        params: JSON.stringify(dataParams),
                        time: time,
                        is_form: is_form,
                        url: urlCurent,
                        method: val.config.method,
                        mbot5: MD5(time + "" + JSON.stringify(dataHeader))
                    };
                    data = window.btoa(encodeURIComponent(JSON.stringify(dataHeaderSave)));
                    let part = data.slice(10, 23);
                    let string = "";
                    for (let i = 0; i < data.length; i++) {
                        string += data[i];
                        if (i === 15 || i === data.length - 15) {
                            string += part;
                        }
                    }
                    data = JSON.stringify({ data: string });
                    method = "POST";
                    dataHeader = "";
                    dataParams = "";
                }

                // Check valid header
                for (let i in dataHeader) {
                    if (!i) delete dataHeader[i];
                }

                $.ajax({
                    async: true,
                    method: method,
                    crossDomain: true,
                    dataType: "json",
                    contentType: "application/json",
                    url: "" + url,
                    headers: dataHeader,
                    params: dataParams,
                    processData: false,
                    data: data,
                    success: function (res) {
                        thisVue.response = JSON.stringify(res, null, " ");
                    },
                    error: function (err) {
                        thisVue.response = JSON.stringify(err, null, " ");
                    }
                });
            } catch (e) {
                thisVue.response = JSON.stringify(e);
            }
        },
        setvalueDefault() {
            this.response = "";
            this.nameApi = "";
            this.urlApi = "";
            this.typeApi = "normal";
            this.params = [{ key: "", value: "" }];
            this.header = [{ key: "", value: "" }];
            this.body = {
                type: "normal",
                valueNormal: [{ key: "", value: "" }],
                valueRaw: ""
            };
            this.method = "GET";
        },
        addMore(val) {
            let thisVue = this;
            let item = {
                key: "",
                value: ""
            };
            switch (val) {
                case "params":
                    thisVue.params.push(item);
                    break;
                case "header":
                    thisVue.header.push(item);
                    break;
                case "body":
                    thisVue.body.valueNormal.push(item);
                    break;
            }
        },
        formatValue(arr) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (!arr[i].key || arr[i].key === "" || arr[i].key.trim() === "") {
                    if (arr.length > 1) {
                        arr.splice(i, 1);
                    }
                }
                if (arr[i].value && arr[i].value.search("{{gm") == -1) {
                    arr[i].dataAppSelect = "";
                }
                if (i === 0) {
                    return arr;
                }
            }
        },
        resetString(str) {
            let arr = str.split("{{");
            let arrMo = [];
            let thismodel = {};
            for (let k = 0; k < $listModel.length; k++) {
                thismodel[$listModel[k].key] = $listModel[k].value;
            }
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].search("}}") != -1) {
                    arrMo.push(arr[i].split("}}")[0]);
                }
            }
            for (let j = 0; j < arrMo.length; j++) {
                let val = arrMo[j];
                if (val.search(".") != -1) {
                    let child = val.split(".");
                    let values = thismodel[child[0]];
                    for (let n = 1; n < child.length; n++) {
                        values = values[child[n]];
                    }
                    str = str.replace("{{" + arrMo[j] + "}}", values);
                } else {
                    str = str.replace("{{" + arrMo[j] + "}}", thismodel[val]);
                }
            }
            return str;
        },
        showSelectDataApp(type, index) {
            this.indexList = index;
            this.type = type;
            this.timeShow = new Date().getTime();
            let thisVue = this;
            switch (type) {
                case "params":
                    thisVue.dataAppSelect = $.extend(true, {}, thisVue.params[index].dataAppSelect);
                    break;
                case "header":
                    thisVue.dataAppSelect = $.extend(true, {}, thisVue.header[index].dataAppSelect);
                    break;
                case "body":
                    thisVue.dataAppSelect = $.extend(true, {}, thisVue.body.valueNormal[index].dataAppSelect);
                    break;
            }
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
        },
        handleCursor(val) {
            let indexActiveChar = val.getCursor().ch;
            let indexLineActive = val.getCursor().line;
            let lineActive = val.getLine(indexLineActive);
            lineActive = lineActive.substring(0, indexActiveChar);
            let dataObject = {
                val,
                indexActiveChar,
                lineActive
            };

            if (val.getCursor(true).ch !== val.getCursor(false).ch) return;

            if (lineActive.lastIndexOf("$model.") !== -1) {
                dataObject.type = "suggest-data";
                this.$store.commit("editor/setDataAddOn", dataObject);
            }
        }
    },
    mounted() {
        this.dataApp = $dataApp;
        this.setvalueDefault();
        if (!this.dataAppSelect) {
            this.dataAppSelect = {};
        }
        let thisVue = this;
        $("#select-data-app").on("hidden.bs.modal", function () {
            $("#api-template-v3").css("opacity", 1);
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                switch (thisVue.type) {
                    case "params":
                        thisVue.params[thisVue.indexList].dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                        thisVue.params[thisVue.indexList].value = utils.getValueDataApp(
                            thisVue.dataAppSelect.indexTab,
                            thisVue.dataAppSelect.keySelect,
                            thisVue.dataAppSelect.indexKey
                        );
                        break;
                    case "header":
                        thisVue.header[thisVue.indexList].dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                        thisVue.header[thisVue.indexList].value = utils.getValueDataApp(
                            thisVue.dataAppSelect.indexTab,
                            thisVue.dataAppSelect.keySelect,
                            thisVue.dataAppSelect.indexKey
                        );
                        break;
                    case "body":
                        thisVue.body.valueNormal[thisVue.indexList].dataAppSelect = $.extend(
                            true,
                            {},
                            thisVue.dataAppSelect
                        );
                        thisVue.body.valueNormal[thisVue.indexList].value = utils.getValueDataApp(
                            thisVue.dataAppSelect.indexTab,
                            thisVue.dataAppSelect.keySelect,
                            thisVue.dataAppSelect.indexKey
                        );
                        break;
                }
            } else {
                console.log("hidden");
            }
        });
        $("#select-data-app").on("show.bs.modal", function () {
            $("#api-template-v3").css("opacity", 0);
        });
    }
};
