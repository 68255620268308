import { render, staticRenderFns } from "./model-template.component.html?vue&type=template&id=07e5b227&scoped=true&"
import script from "./model-template.component.js?vue&type=script&lang=js&"
export * from "./model-template.component.js?vue&type=script&lang=js&"
import style0 from "./model-template.vue?vue&type=style&index=0&id=07e5b227&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e5b227",
  null
  
)

export default component.exports