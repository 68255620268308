import { render, staticRenderFns } from "./border-setting.html?vue&type=template&id=7fc9650e&scoped=true&"
import script from "./border-setting.js?vue&type=script&lang=js&"
export * from "./border-setting.js?vue&type=script&lang=js&"
import style0 from "./border-setting.vue?vue&type=style&index=0&id=7fc9650e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc9650e",
  null
  
)

export default component.exports