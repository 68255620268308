import { utils } from "@/libs-core/utils.min.js";
import { templateSV } from "../../layout/layout-left-component/template-manager/template-page-manager.service.js";
// import _ from "lodash";
export default {
  props: ["api", "timeUpdate", "listPage"],
  data: function() {
    return {
      nameNewPage: "",
      apiNew: [],
      selectTemp: {},
    };
  },
  watch: {
    timeUpdate: function() {
      this.apiNew = $.extend(true, [], this.api);
    },
    nameNewPage: function() {
      this.nameNewPage = utils.resetNamePage(utils.resetText(this.nameNewPage));
    },
  },
  methods: {
    newPage: function() {
      if (this.nameNewPage && this.nameNewPage != "package") {
        var thisVue = this;
        let selectedTemp = this.$store.state.editor.sourceTemplate;
        thisVue.selectTemp = selectedTemp;
        var itemPage = this.listPage.find((x) => x.name === this.nameNewPage);
        if (!itemPage) {
          if (
            selectedTemp === "" ||
            (typeof selectedTemp === "object" &&
              !Object.keys(selectedTemp).length)
          ) {
            this.$emit("newPageTemplate", { name: this.nameNewPage, api: this.api });
            $("#new-page-template").modal("hide");
          } else {
            thisVue.$emit("newPageTemplate", {
              name: thisVue.nameNewPage,
              api: selectedTemp.source.api,
              style: selectedTemp.style,
            });
            thisVue.$store.commit(
              "editor/setSourceTemplate",
              thisVue.selectTemp
            );
            thisVue.$swal({
              position: "bottom-start",
              type: "success",
              title: "Template has been created.",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else {
          this.$swal({
            title: "",
            text: "The page already exists, please try again!",
            type: "warning",
          });
        }
      } else {
        this.$swal({
          title: "",
          text: "Please enter the page name!",
          type: "warning",
        });
      }
    },
  },
  mounted: function() {
    var thisVue = this;
    $("#new-page-template").on("hidden.bs.modal", function() {
      thisVue.selectTemp = {};
      thisVue.nameNewPage = "";
      thisVue.$store.commit("editor/setSourceTemplate", thisVue.selectTemp);
    });
  },
};
