import { render, staticRenderFns } from "./list-setting.html?vue&type=template&id=4dbe1047&scoped=true&"
import script from "./list-setting.js?vue&type=script&lang=js&"
export * from "./list-setting.js?vue&type=script&lang=js&"
import style0 from "./list-setting.vue?vue&type=style&index=0&id=4dbe1047&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dbe1047",
  null
  
)

export default component.exports