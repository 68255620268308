import { service } from "./manage-data.service.js";
import async from "async";
import xlsx from "xlsx";
import { utils } from "@/libs-core/utils.min.js";

export default {
    props: ["dataApp"],
    data: function() {
        return {
            typeData: "",
            idSheetSelect: "",
            dataCollection: [],
            error: "",
            listSheet: [],
            tabSelect: "",
            fileUpload: {},
            selectSheetData: [],
            listKeySelect: [],
            dataItem: [],
            tabItem: [],
            indexTabSelect: "",
            sheetNameSelect: "",
            datanew: {
                tab: "",
                name: "",
                data: [],
                column: "",
                key_value: ""
            },
            // Import collection
            dataSelect: {
                upload_sheet_tab: [],
                data_sheet_tab: [],
                tab_name: "",
                select_tab_name: "",
                file_sheet: "",
                key_value: ""
            },

            // Data UI
            typeUpdateCollect: "",
            typeUpdateData: "",
            collectSelect: {},
            dataObjectSelect: {},
            typeFocus: "",
            timeOutSaveId: "",
            blockSelect: undefined
        };
    },
    computed: {
        computeTitleUpateModal() {
            switch (this.typeUpdateCollect) {
                case "new-collect":
                    return "Add Collection Data";
                case "rename-collect":
                    return "Rename Collection Data";
                case "rewrite-collect":
                    return "Rewrite Collection Data";
                case "export-collect":
                    return "Rename Collection Data";
                case "import-data-app":
                    return "Import Data App";
            }
        },
        computeRewiteSelect() {
            if (typeof this.dataSelect.select_tab_name === "number") {
                let result = this.dataSelect.upload_sheet_tab[this.dataSelect.select_tab_name];
                return result;
            }
            return "Select data";
        }
    },
    watch: {
        "datanew.name"(val) {
            this.datanew.name = utils.resetText(val);
        },
        "datanew.tab"(val) {
            this.datanew.tab = utils.resetText(val);
        },
        "datanew.column"(val) {
            this.datanew.column = utils.resetText(val);
        },
        "datanew.key_value"(val) {
            this.datanew.key_value = utils.resetText(val);
        }
    },
    methods: {
        computeItemInCollect(dataCollect) {
            if (!dataCollect || !dataCollect.data || !dataCollect.data[0]) return '0 item';
            if (dataCollect.data[0] && Object.keys(dataCollect.data[0]).length) {
                return Object.keys(dataCollect.data[0]).length + ' items';
            }
            let arrData = dataCollect.data.find(item => {
                return Object.keys(item).length;
            });
            return arrData ? Object.keys(arrData).length + ' items' : '0 item';
        },

        // UPDATE COLLECTION
        addModel() {
            if (!this.datanew.name || this.datanew.name.length <= 0) return;

            if (this.tabItem.includes(this.datanew.name))
                return this.$swal({
                    title: "Invalid tab name",
                    text: "The sheet name must not be empty or a duplicate of an existing name",
                    type: "error"
                });

            let item = {
                name: this.datanew.name,
                data: []
            };
            this.dataCollection.push(item);
            this.typeData = this.dataApp.type;
            this.idSheetSelect = this.dataApp.idsheet;
            this.resetDataTab(this.dataCollection);
            this.saveModel();
            this.datanew.name = "";
            $("#modal-update-collect").modal("hide");
        },

        // IMPORT SHEET
        readFile(e) {
            const thisVue = this;

            thisVue.error = "";
            this.fileUpload = e.target.files[0];

            let formData = new FormData();
            formData.append("file", this.fileUpload);
            service.uploadSheet(
                {
                    file: formData,
                    page: thisVue.$route.params.id
                },
                function(data) {
                    thisVue.typeData = "file";
                    if (data && data.data) {
                        if (data.data[0] && data.data[0].data_source) {
                            thisVue.dataCollection = data.data[0].data_source;
                        } else if (data.data.data_source) {
                            thisVue.dataCollection = data.data.data_source;
                        }
                    }

                    e.target.value = "";
                    thisVue.saveModel();
                    thisVue.resetDataTab(thisVue.dataCollection);
                }
            );
        },
        signIn() {
            gapi.auth2.getAuthInstance().signIn();
        },
        getSheetData(val) {
            const thisVue = this;
            thisVue.error = "";
            this.idSheetSelect = val;
            this.typeData = "googlesheet";
            gapi.client.sheets.spreadsheets
                .get({
                    spreadsheetId: val,
                    ranges: [],
                    includeGridData: true
                })
                .then(
                    (r) => {
                        $("#modal-update-collection").modal("hide");
                        thisVue.selectSheetData = r.result.sheets.map((tab) => {
                            let keys = [];
                            let indexEm = 1;
                            if (tab.data[0].rowData && tab.data[0].rowData.length > 0) {
                                return {
                                    name: tab.properties.title,
                                    data: tab.data[0].rowData.map((row, indexR) => {
                                        let value = {};
                                        let test = null;
                                        if (row && row.values && row.values && row.values.length) {
                                            test = row.values.map((item) => {
                                                return item.formattedValue;
                                            });
                                        } else {
                                            test = [];
                                        }
                                        if (indexR == 0) {
                                            for (let i = 0; i < test.length; i++) {
                                                if (test[i]) {
                                                    keys[i] = test[i];
                                                } else {
                                                    keys[i] = "_EMPTY_" + indexEm;
                                                    indexEm++;
                                                }
                                            }
                                        } else {
                                            for (let j = 0; j < test.length; j++) {
                                                value[keys[j]] = test[j] || null;
                                            }
                                        }
                                        return value;
                                    })
                                };
                            } else {
                                return {
                                    name: tab.properties.title,
                                    data: []
                                };
                            }
                        });
                        thisVue.dataCollection = $.extend(true, [], thisVue.selectSheetData);
                        thisVue.resetDataTab(thisVue.dataCollection);
                        thisVue.saveModel();
                    },
                    (e) => {
                        $("#modal-update-collection").modal("hide");
                        thisVue.error = e.result.error.message;
                        console.log("error::: ", thisVue.error);
                    }
                );
        },
        importSheetData(event) {
            let file_sheet = event.target.files[0];
            this.dataSelect.file_sheet = file_sheet;
            const reader = new FileReader();
            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = xlsx.read(bstr, { type: "binary" });

                this.dataSelect.upload_sheet_tab = wb.SheetNames;
                this.dataSelect.data_sheet_tab = wb.Sheets;
                thisVue.saveModel();
                event.target.value = "";
            };
            reader.readAsBinaryString(file_sheet);
        },
        importTabToTab() {
            const reader = new FileReader();
            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = xlsx.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[this.dataSelect.select_tab_name];
                const ws = wb.Sheets[wsname];
                let tab_data = xlsx.utils.sheet_to_json(ws, { header: 1 });
                tab_data = tab_data.filter((n) => n.length > 0);

                let header_data = tab_data.slice(0, 1)[0];
                let body_data = tab_data.slice(1, tab_data.length);

                let max_leng_body_data = 0;
                body_data.map((n) => {
                    if (n.length <= max_leng_body_data) return;
                    max_leng_body_data = n.length;
                });
                if (header_data.length < max_leng_body_data) {
                    let step = max_leng_body_data - header_data.length;
                    for (let i = 0; i < step; i++) {
                        header_data.push(`__Empty_${i}`);
                    }
                }

                body_data = body_data.map((n, i) => {
                    let temp = {};
                    header_data.map((_n, _i) => {
                        temp[header_data[_i]] = n[_i] || "";
                    });
                    return temp;
                });

                this.listKeySelect = header_data;
                this.dataCollection = this.dataCollection.map((n) => {
                    if (n.name !== this.dataSelect.tab_name) return n;
                    n.data = body_data;
                    return n;
                });
                this.dataItem = body_data;

                this.saveModel();
                $("#modal-update-collection").modal("hide");
            };
            reader.readAsBinaryString(this.dataSelect.file_sheet);
        },
        pushTab(event) {
            function rnd(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
            let file_sheet = event.target.files[0];
            this.dataSelect.file_sheet = file_sheet;
            const reader = new FileReader();
            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = xlsx.read(bstr, { type: "binary" });

                let data_source = wb.SheetNames.map((sheet_name) => {
                    let ws = wb.Sheets[sheet_name];
                    let data = xlsx.utils.sheet_to_json(ws, { blankrows: false });
                    return {
                        name: `${sheet_name}__${rnd(1000, 9999)}`,
                        data: data
                    };
                });

                this.dataCollection = [...this.dataCollection, ...data_source];
                this.resetDataTab(this.dataCollection);
                this.saveModel();
                event.target.value = "";
                $("#modal-update-collection").modal("hide");
            };
            reader.readAsBinaryString(file_sheet);
        },
        changeTabName() {
            if (this.tabItem.includes(this.datanew.tab))
                return this.$swal({
                    title: "Duplicate tab name",
                    type: "error"
                });

            this.tabItem = this.tabItem.map((n) => {
                if (n === this.dataSelect.tab_name) return this.datanew.tab;
                return n;
            });
            this.dataCollection = this.dataCollection.map((n) => {
                if (n.name === this.dataSelect.tab_name) n.name = this.datanew.tab;
                return n;
            });

            this.saveModel();
            $("#modal-update-collection").modal("hide");
        },
        // END UPDATE COLLECTION

        // UPDATE DATA APP
        resetDataTab(data) {
            this.tabItem = [];
            for (let i = 0; i < data.length; i++) {
                this.tabItem.push(data[i].name);
            }
            this.selectTab(data[0].name);
        },
        saveModel() {
            const data = this.dataCollection;
            const type = this.typeData;
            const idSheet = this.idSheetSelect;
            const item = {
                data,
                type,
                idSheet
            };

            this.$emit("updateDataApp", item);
        },
        // END UPDATE DATA APP

        // TAB METHODS
        selectTab(val) {
            var thisVue = this;
            this.tabSelect = val;
            this.dataCollection.find(function(x, index) {
                if (x.name === val) {
                    thisVue.indexTabSelect = index;
                    thisVue.dataItem = x.data;
                }
            });
            this.listKeySelect = [];
            for (let j = 0; j < this.dataItem.length; j++) {
                this.listKeySelect = $.extend(true, [], this.listKeySelect, Object.keys(this.dataItem[j]));
            }
        },
        deleteTab() {
            const tab_name = this.collectSelect.name;
            const data = {};
            async.waterfall(
                [
                    (next) => {
                        this.$swal({
                            title: "Delete tab",
                            text: "This action is not reverse",
                            type: "warning",
                            showCancelButton: true
                        }).then((r) => {
                            if (!r || !r.value) return next(1);
                            data.is_delete = r.value;
                            next();
                        });
                    },
                    (next) => {
                        if (!data.is_delete) return next();

                        this.dataCollection = this.dataCollection.filter((o) => o.name !== tab_name);

                        if (!this.dataCollection.length) {
                            this.dataCollection = [
                                {
                                    data: [],
                                    name: "Empty_1"
                                }
                            ];
                        }

                        this.resetDataTab(this.dataCollection);
                        this.saveModel();
                    }
                ],
                (e, r) => {}
            );
        },
        exportSheet() {
            let tab_name = this.collectSelect.name;
            let tab_data = this.dataCollection.find((o) => o.name === tab_name);

            const ws = xlsx.utils.json_to_sheet(tab_data.data);
            const wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, tab_name);
            xlsx.writeFile(wb, `${tab_name}.xlsx`);
        },
        exportAllSheet() {
            const wb = xlsx.utils.book_new();
            this.dataCollection.map((n) => {
                const ws = xlsx.utils.json_to_sheet(n.data);
                xlsx.utils.book_append_sheet(wb, ws, n.name);
            });
            xlsx.writeFile(wb, `botup_data.xlsx`);
        },
        // TAB METHODS

        // ROW AND COLUMN METHODS
        addNewRow() {
            if (!this.dataCollection || !this.dataCollection.length || !this.dataCollection[this.indexTabSelect].data)
                return;

            if (!this.dataCollection[this.indexTabSelect].data.length)
                return this.$swal({
                    title: "Please add new column first",
                    type: "error"
                });

            let tab_data = this.dataCollection[this.indexTabSelect].data;
            let last_row = { ...tab_data[tab_data.length - 1] };
            for (const k in last_row) last_row[k] = "";
            tab_data = [...tab_data, last_row];

            this.dataCollection[this.indexTabSelect].data = tab_data;
            this.dataItem = tab_data;
            this.saveModel();
        },
        addNewColumn() {
            if (
                !this.dataCollection ||
                !this.dataCollection.length ||
                !this.dataCollection[this.indexTabSelect].data ||
                !this.datanew ||
                !this.datanew.column
            )
                return;

            let tab_data = this.dataCollection[this.indexTabSelect].data;

            if (this.dataCollection[this.indexTabSelect].data.length === 0) {
                let temp = {};
                temp[this.datanew.column] = "";
                tab_data.push(temp);
                this.dataItem = tab_data;
            } else {
                if (this.listKeySelect.includes(this.datanew.column))
                    return this.$swal({
                        type: "error",
                        text: `Column "${this.datanew.column}" is exist`
                    });

                tab_data = tab_data.map((n) => {
                    n[this.datanew.column] = "";
                    return n;
                });
            }

            this.dataCollection[this.indexTabSelect].data = tab_data;
            this.listKeySelect = _.union(this.listKeySelect, [this.datanew.column]);
            this.datanew.column = "";
            this.saveModel();
            $("#modal-update-data").modal("hide");
        },
        changeKeyValue() {
            if (!this.datanew.key_value) return;
            if (this.listSheet.includes(this.datanew.key_value))
                return this.$swal({
                    type: "error",
                    title: "Duplicate key value"
                });
            let temp = this.dataCollection[this.indexTabSelect].data;
            temp = temp.map((n) => {
                n[this.datanew.key_value] = n[this.dataSelect.key_value];
                delete n[this.dataSelect.key_value];
                return n;
            });
            this.dataCollection[this.indexTabSelect].data = temp;
            this.dataItem = temp;
            this.listKeySelect = this.listKeySelect.map((n) => {
                if (n !== this.dataSelect.key_value) return n;
                return this.datanew.key_value;
            });
            this.saveModel();
            $("#modal-update-data").modal("hide");
        },
        deleteColumn(delete_key) {
            if (
                !this.dataCollection ||
                !this.dataCollection.length ||
                !this.dataCollection[this.indexTabSelect].data ||
                !this.dataCollection[this.indexTabSelect].data.length
            )
                return;

            const data = {};
            async.waterfall(
                [
                    (next) => {
                        this.$swal({
                            title: "Delete column",
                            text: "This action is not reverse",
                            type: "warning",
                            showCancelButton: true
                        }).then((r) => {
                            if (!r || !r.value) return next(1);
                            data.is_delete = r.value;
                            next();
                        });
                    },
                    (next) => {
                        if (!data.is_delete) return next();

                        let tab_data = this.dataCollection[this.indexTabSelect].data;
                        tab_data = tab_data.map((n) => {
                            delete n[delete_key];
                            return n;
                        });

                        this.dataCollection[this.indexTabSelect].data = tab_data;
                        this.listKeySelect = this.listKeySelect.filter((e) => e !== delete_key);
                        this.saveModel();
                        next();
                    }
                ],
                (e, r) => {}
            );
        },
        deleteRow(index_row) {
            if (
                !this.dataCollection ||
                !this.dataCollection.length ||
                !this.dataCollection[this.indexTabSelect].data ||
                !this.dataCollection[this.indexTabSelect].data.length
            )
                return;

            const data = {};
            async.waterfall(
                [
                    (next) => {
                        this.$swal({
                            title: "Delete row",
                            text: "This action is not reverse",
                            type: "warning",
                            showCancelButton: true
                        }).then((r) => {
                            if (!r || !r.value) return next(1);
                            data.is_delete = r.value;
                            next();
                        });
                    },
                    (next) => {
                        let tab_data = this.dataCollection[this.indexTabSelect].data;
                        tab_data.splice(index_row, 1);

                        if (tab_data.length === 0) this.listKeySelect = [];

                        this.dataCollection[this.indexTabSelect].data = tab_data;
                        this.dataItem = tab_data;
                        this.saveModel();
                        next();
                    }
                ],
                (e, r) => {}
            );
        },
        deleteAction() {
            if (this.typeFocus === "column") {
                this.deleteColumn(this.dataObjectSelect);
                this.typeFocus = "";
            }
            if (this.typeFocus === "row") {
                this.deleteRow(this.dataObjectSelect);
                this.typeFocus = "";
            }
        },
        // END ROW AND COLUMN METHODS

        changeValue: _.debounce(function() {
            const thisVue = this;

            this.dataCollection[this.indexTabSelect].data = this.dataItem;
            this.saveModel();
            if (this.timeOutSaveId) {
                clearTimeout(this.timeOutSaveId);
            }
            this.timeOutSaveId = setTimeout(function() {
                thisVue.timeOutSaveId = "";
            }, 1000);
        }, 300),

        // HANDLE UI
        handleFocusColumn(item, ind) {
            $(`#modal-collect-detail .row-data-collect`).css("outline", "");
            $(`#modal-collect-detail .row-title-column .title-column`).css("outline", "");
            $(`#modal-collect-detail .row-title-column .title-column:nth-child(${ind + 2})`).css(
                "outline",
                "1px solid #f87657"
            );
            this.dataObjectSelect = item;
            this.typeFocus = "column";
        },
        handleFocusFieldData(val) {
            $(`#modal-collect-detail .row-title-column .title-column`).css("outline", "");
            $(`#modal-collect-detail .row-data-collect`).css("outline", "");
            this.typeFocus = "data";
        },
        handleFocusRow(ind) {
            $(`#modal-collect-detail .row-data-collect`).css("outline", "");
            $(`#modal-collect-detail .row-title-column .title-column`).css("outline", "");
            $(`#modal-collect-detail .row-data-collect:nth-child(${ind + 1})`).css("outline", "1px solid #f87657");
            this.dataObjectSelect = ind;
            this.typeFocus = "row";
        },
        openMenuKeySl(val, event) {
            event.preventDefault();
            event.stopPropagation();
            let text = "$model.gm('" + this.tabSelect + "','" + val + "', '');";
            alert("Use the function to get data: " + text);
        },
        rightCopyModel(event, key, index, val) {
            event.preventDefault();
            event.stopPropagation();
            let text = "$model.gm('" + this.tabSelect + "','" + key + "','" + index + "');";
            alert("Use the function to get data: " + text);
        },
        toggleSection(type, val, item) {
            let selector;
            let topPosition = "";
            let leftPosition = "";
            switch (type) {
                case "update-collect-modal":
                    $("#modal-update-collection").modal("show");
                    this.typeUpdateCollect = val;
                    if (val === "rename-collect" || val === "rewrite-collect") {
                        this.dataSelect.tab_name = this.collectSelect.name;
                    }
                    break;
                case "update-collect-popup":
                    if (val !== undefined) {
                        selector = $(`#modal-manage-data .item-data:nth-child(${val + 2})`);
                        topPosition = selector.get(0).getBoundingClientRect().top;
                        leftPosition = selector.get(0).getBoundingClientRect().left;
                    }
                    $(".update-collect").toggle();
                    $(".update-collect").css({
                        top: `${topPosition - 20}px`,
                        left: `${leftPosition + 10}px`
                    });
                    if (item) {
                        this.collectSelect = item;
                    }
                    break;
                case "list-collect-rewrite":
                    $("#modal-update-collection .rewrite-collect .list-option").toggle();
                    if (val !== undefined) {
                        this.dataSelect.select_tab_name = val;
                    }
                    break;
                case "open-collect-detail":
                    this.selectTab(val);
                    $("#modal-collect-detail").modal("show");
                    break;
                case "modal-update-data":
                    if (val === "new-column") {
                        this.datanew.column = "";
                        this.typeUpdateData = "new-column";
                    } else if (val === "change-column") {
                        this.datanew.key_value = "";
                        this.dataSelect.key_value = this.dataObjectSelect;
                        this.typeUpdateData = "change-column";
                    }
                    $("#modal-update-data").modal("show");
                    break;
                case "gg-sheet-dropdown":
                    $("#modal-update-collection .import-data .list-option").toggle();
                    if (val !== undefined) {
                        this.getSheetData(val);
                    }
            }
        }
        // END HANDLE UI
    },
    mounted() {
        const thisVue = this;
        const apiKey = "AIzaSyBDf06mn72XmLdy3tGCMpVmTVwnOs2pBQM";
        const clientId = "846744505361-55m7j81v018ha3rr42fst6rsref0tlb3.apps.googleusercontent.com";
        const scope = "https://www.googleapis.com/auth/drive";
        const discoveryDocs = [
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
        ];

        $("#modal-manage-data").on("shown.bs.modal", function() {
            if (thisVue.dataApp && thisVue.dataApp.data && thisVue.dataApp.data.length > 0) {
                thisVue.dataCollection = $.extend(true, [], thisVue.dataApp.data);
                thisVue.typeData = thisVue.dataApp.type;
                thisVue.idSheetSelect = thisVue.dataApp.idSheet;
            } else {
                thisVue.dataCollection = [];
            }
            thisVue.resetDataTab(thisVue.dataCollection);
            thisVue.saveModel();
        });
        $("#modal-manage-data").on("hidden.bs.modal", function() {
            thisVue.saveModel();
        });

        $("#modal-update-collection").on("shown.bs.modal", function() {
            $(this).css("background", "#676c90aa");
            thisVue.dataSelect = {
                upload_sheet_tab: [],
                data_sheet_tab: [],
                tab_name: "",
                select_tab_name: "",
                file_sheet: "",
                key_value: ""
            };
        });

        $("#modal-collect-detail").on("shown.bs.modal", function() {
            $("#modal-manage-data").css("opacity", "0");
            $("#modal-collect-detail .modal-body").scrollTop(0);
            $("#modal-collect-detail .modal-body").scrollLeft(0);
        });
        $("#modal-collect-detail").on("hidden.bs.modal", function() {
            $("#modal-manage-data").css("opacity", "");
            $(`#modal-collect-detail .row-data-collect`).css("outline", "");
            $(`#modal-collect-detail .row-title-column .title-column`).css("outline", "");
            thisVue.typeFocus = "";
        });

        async.waterfall(
            [
                (next) => {
                    gapi.load("client:auth2", () => next());
                },
                (next) => {
                    gapi.client.init({ apiKey, clientId, scope, discoveryDocs }).then(() => {
                        let is_signed_in = gapi.auth2.getAuthInstance().isSignedIn.get();
                        if (!is_signed_in) {
                            next("not_sign_in");
                            return;
                        }
                        next();
                    });
                },
                (next) => {
                    gapi.client.drive.files
                        .list({
                            q: `mimeType='application/vnd.google-apps.spreadsheet'`,
                            fields: "nextPageToken, files(id, name)"
                        })
                        .then((r) => next(null, r.result.files));
                }
            ],
            (e, r) => {
                if (e === "not_sign_in") {
                    thisVue.error = e;
                    return;
                }
                if (e) {
                    thisVue.error = e;
                    return;
                }
                thisVue.listSheet = r;
            }
        );
    }
};
