import service from "./add-screen.service.js";
import { templateSV } from "../../layout/layout-left-component/template-manager/template-page-manager.service.js";
export default {
  data: function() {
    return {
      myTemplate: [],
      selectTemplate: {},
    };
  },
  watch: {
    "$store.state.editor.saveTempStatus"(val) {
      if(val && val.includes("page")) {
        this.getCateAndTemplate();
      }
    }
  },
  methods: {
    showModalTemplateManager: function() {
      $("#modal-template-page-manager").modal("show");
      $("#show-modal-add-screen").modal("hide");
    },
    showModalCreateNewPage: function() {
      $("#new-page-template").modal("show");
      $("#show-modal-add-screen").modal("hide");
    },
    getCateAndTemplate: function() {
      var thisVue = this;
      templateSV.getCateAndTemp(
        JSON.stringify({
          cate_query: {
            type: "marketplace",
            type_second: "page",
          },
          user_temp_name: "My templates",
          temp_limit: 20,
          owner_query: {
            type: "page",
          },
          owner_limit: 20,
        }),
        (data) => {
          thisVue.myTemplate = data.data[0].templates;
        }
      );
    },
    selectedTemplate: function(item) {
      this.$store.commit("editor/setSourceTemplate", item);
      this.showModalCreateNewPage();
    },
  },
  mounted: function() {
    var thisVue = this;
    thisVue.getCateAndTemplate();
  },
};
