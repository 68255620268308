import VoerroTagsInput from "@voerro/vue-tagsinput";
import { sevice } from "../function-template/function-template.service.js";
//------//
// CONFIG CODE MIRROR
//------//
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
// theme css

// language
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

// active-line.js
import "codemirror/addon/selection/active-line.js";

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// END CONFIG CODE MIRROR //
///////////////////////////

export default {
    components: {
        codemirror,
        "tags-input": VoerroTagsInput
    },
    props: ["dataPage"],
    data: function() {
        return {
            activeContent: "setting-other",
            settingHead: "",
            settingBody: "",
            link_app_share: "",
            optionsCm: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                mode: "text/html",
                theme: "default"
            },
            favicon: "",
            imageShare: "",
            modeApp: false,
            autoSave: false,
            listFunction: [],
            listTracking: []
        };
    },
    watch: {
        settingHead: function() {
            // this.dataPage.activeContent = "setting-head";
            // this.activeContent = "setting-head";
            this.dataPage.settingHead = this.settingHead;
            this.$emit("updateSettingPage", this.dataPage);
            console.log("change setting head", this.settingHead);
        },
        settingBody: function() {
            // this.dataPage.activeContent = "setting-body";
            // this.activeContent = "setting-body";
            this.dataPage.settingBody = this.settingBody;
            this.$emit("updateSettingPage", this.dataPage);
        },
        "dataPage.tags": _.debounce(function() {
            this.$emit("updateSettingPage", this.dataPage);
        }, 300),
        "dataPage.title": _.debounce(function() {
            this.$emit("updateSettingPage", this.dataPage);
        }, 300),
        "dataPage.description": _.debounce(function(val) {
            this.$emit("updateSettingPage", this.dataPage);
        }, 300),
        "dataPage.saveLocal": function(val) {
            this.$emit("updateSettingPage", this.dataPage);
        },
        // "dataPage.settingBody": _.debounce(function() {
        //     this.settingBody = this.dataPage.settingBody;
        // }, 300),
        // "dataPage.settingHead": _.debounce(function() {
        //     this.settingHead = this.dataPage.settingHead;
        // }, 300),
        "dataPage.app_id_fb": _.debounce(function() {
            this.$emit("updateSettingPage", this.dataPage);
        }, 300),
        "dataPage.mode": function(val) {
            this.modeApp = val;
            this.$emit("updateSettingPage", this.dataPage);
        },
        "dataPage.logbug": function() {
            this.$emit("updateSettingPage", this.dataPage);
        },
        "dataPage.isSaveToHomeScreenIos": function(val) {
            if (val === "yes" && !this.dataPage.apperingTimes) {
                this.dataPage.apperingTimes = 1;
            }
            this.$emit("updateSettingPage", this.dataPage);
        },
        activeContent() {
            if (
                this.activeContent === "setting-other" ||
                this.activeContent === "setting-head" ||
                this.activeContent === "setting-body"
            ) {
                let listEl = $(".setting-function, .setting-tracking");
                listEl[0].classList.remove("active");
                listEl[1].classList.remove("active");
            }
        },
        modeApp: function(val) {
            if (val) {
                this.dataPage.mode = "public";
                this.$emit("updateSettingPage", this.dataPage);
                return;
            }
            this.dataPage.mode = "private";
            this.$emit("updateSettingPage", this.dataPage);
        },
        autoSave: function(val) {
            if (val) {
                this.dataPage.saveLocal = "save";
                this.$emit("updateSettingPage", this.dataPage);
                return;
            }
            this.dataPage.saveLocal = "none";
            this.$emit("updateSettingPage", this.dataPage);
        }
    },
    methods: {
        setActiveContent: function(val) {
            this.activeContent = val;
        },
        showManagerImage: function(val) {
            this.dataPage.activeContent = "setting-other";
            $("#modal-setting-page").modal("hide");
            this.$emit("showManagerImage", { type: val, dataComp: this.dataPage });
        },
        openModal: function(name) {
            $("#modal-setting-page").modal("hide");
            if (name === "modal-function" || name === "modal-tracking") {
                $("#" + name).css("z-index", "10000");
            }
            $("#" + name).modal("show");
            this.activeContent = "setting" + name.split("-")[1];
        },
        getAllFunction: function(type) {
            var thisVue = this;
            sevice.getAllConfig(JSON.stringify({ type: type, page: thisVue.$route.params.id }), function(data) {
                if (data && data.code === 200) {
                    if (type == "func") {
                        thisVue.listFunction = data.data;
                        thisVue.$emit("updateListFunction", thisVue.listFunction);
                        thisVue.$store.commit("editor/setListFunction", data.data);
                    } else {
                        thisVue.listTracking = data.data;
                        thisVue.$emit("updateListTracking", thisVue.listTracking);
                        thisVue.$store.commit("editor/setListTracking", data.data);
                    }
                }
            });
        },
        deleteFunc: function(id, e, type) {
            var thisVue = this;
            e.preventDefault();
            e.stopPropagation();
            thisVue
                .$swal({
                    title: "",
                    text: "You won't be able to revert this action!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes"
                })
                .then((result) => {
                    if (result.value) {
                        sevice.remove(JSON.stringify({ id: id, page: thisVue.$route.params.id }), function(data) {
                            if (data && data.code === 200) {
                                thisVue.getAllFunction(type);
                            }
                        });
                    }
                });
        },
        selectFunction: function(item) {
            console.log("item selctFunction::", item);
            this.$emit("editFunction", item);
        },
        selectTracking: function(item) {
            this.$emit("editTracking", item);
        }
    },
    mounted: function() {
        const thisVue = this;

        $("#modal-setting-page").on("shown.bs.modal", function() {
            thisVue.link_app_share = window.location.protocol;
            thisVue.link_app_share += "//" + window.location.hostname;
            thisVue.favicon = thisVue.dataPage.favicon;
            thisVue.imageShare = thisVue.dataPage.imageShare;
            console.log("thisVue.favicon:: ", thisVue.favicon);
            setTimeout(() => {
                thisVue.settingHead = thisVue.dataPage.settingHead || "";
                thisVue.settingBody = thisVue.dataPage.settingBody || "";
            }, 300);

            if (window.location.port) {
                thisVue.link_app_share += ":" + window.location.port;
            }
            thisVue.link_app_share += "/studio/dashboard/" + thisVue.$route.params.id;
            thisVue.getAllFunction("func");
            thisVue.getAllFunction("tracking");
        });

        if (this.dataPage) {
            this.modeApp = this.dataPage.mode === "public" ? true : false;
            this.autoSave = this.dataPage.saveLocal === "save" ? true : false;
        }
    }
};
