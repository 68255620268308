//------//
// CONFIG CODE MIRROR
//------//
import {
    codemirror
} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// theme css
// import 'codemirror/theme/base16-dark.css'
// language
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'

// active-line.js
import 'codemirror/addon/selection/active-line.js'

// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'

// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/match-highlighter.js'

// END CONFIG CODE MIRROR //
///////////////////////////

export default {
    components: {
        codemirror
    },
    props: {
        dataComp: {
            type: Object
        }
    },
    data: function() {
        return {
            time: 0,
            htmlContent: '',
            optionsCm: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                lineWrapping: true,
                line: true,
                mode: "text/html",
                theme: "default"
            },
            idSl: "",
            block: {}
        };
    },
    watch: {
        // "$store.state.editor.blockSelected"(val) {
        //     // let val = this.$store.state.editor.blockSelected;
        //     if (val && val.id !== this.idSl) {
        //         this.html = val.html;
        //         this.idSl = val.id;
        //     }
        // },
        // dataComp: function(val) {
        //     if (val && val.id) {
        //         this.id = val.id;
        //         this.html = val.html;
        //     }
        // }
    },
    methods: {
        changeHtml: function() {
            this.block.html = this.htmlContent;
            $("#" + this.dataComp.id + " > .block-content").html(this.htmlContent);
            this.$emit('updateDataComp', this.dataComp);
            this.$store.dispatch('editor/updateBlock', {
                val: this.block,
                type: 'htmlSt'
            });
        },
        getData() {
            let val = this.$store.state.editor.blockSelected;
            if (val && val.id !== this.idSl) {
                this.block = val;
                this.htmlContent = val.html + ' ';
                this.idSl = val.id;
            }
        }
    },
    mounted: function() {
        var thisVue = this;
        thisVue.htmlContent = "";
        $("#modal-html-manager").on("show.bs.modal", function() {
            setTimeout(() => thisVue.getData(), 300);
        });
    }
};
