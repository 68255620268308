import { utils } from "@/libs-core/utils.min.js";
import { VueEditor } from "vue2-editor";
import { moveableElement } from "@/libs-core/moveable-element.min.js";
export default {
    props: ["thisVue"],
    components: {
        VueEditor
    },
    data: function () {
        return {
            componentName: "",
            block: {},
            idSl: "",
            html: "",
            timeShow: "",
            finishUpdate: true,
            editorOptions: {
                modules: {
                    toolbar: false
                }
            },
        }
    },
    watch: {
        "$store.state.editor.blockSelected": function (val) {
            if (val && val.id != this.idSL) {
                this.block = val;
                this.idSl = val.id;
                this.componentName = val.componentName;
                this.html = this.block.html || "";
            } else {
                if (!val) {
                    this.idSl = "";
                } else {
                    if (val && val.id == this.idSL) {
                        this.block = $.extend(true, {}, val);
                        this.html = this.block.html || "";
                        this.componentName = val.componentName;
                    }
                }
            }
        },
    },
    methods: {
        updateHtmlValue: function () {
            var $this = this;
            if (this.block.componentName == "blockButton" || this.block.componentName == "blockText") {
                if (!this.finishUpdate || this.html === this.block.html) return;
                if (this.block && this.block.dataInput) {
                    this.block.dataInput.useValue = "";
                }
                this.block.html = this.html;
                $("#" + this.block.id + " > .block-content").html(this.html);
                this.updateBlock(this.block, "valueTextSt");
                utils.changeModelToSpan(this);
                moveableElement.init($this.thisVue, document.querySelector("#" + $this.block.id), document.querySelector("#" + $this.block.idPar));
            }
        },
        updateBlock(val, type) {
            if (!val.id) return;
            this.$store.dispatch("editor/updateBlock", { val, type });
        },
        showSelectDataApp(type) {
            this.typeSelectDataApp = type;
            this.timeShow = new Date().getTime();
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);

            if (this.block && this.block.dataInput) {
                this.$store.commit("editor/mutaDataAppSelect", {
                    indexTab: this.block[type].indexTab,
                    keySelect: this.block[type].keySelect,
                    indexKey: this.block[type].indexKey,
                    saveData: false
                });
            }
            $("#select-data-app").modal("show");
        },
    },
    mounted: function () {
        var $this = this;
        $("#select-data-app").on("hidden.bs.modal", function () {
            if ($this.timeShow === $this.$store.state.editor.timeShowDataApp && $this.$store.state.editor.dataAppSelect.saveData) {
                $this.block[$this.typeSelectDataApp] = $.extend(true, {},$this.$store.state.editor.dataAppSelect);
                $this.block[$this.typeSelectDataApp].useValue = "model";
                let dataSelect = $this.block[$this.typeSelectDataApp];
                let computeModelName = "";
                if ($this.block && dataSelect) {
                    computeModelName = utils.getValueDataApp(
                        dataSelect.indexTab,
                        dataSelect.keySelect,
                        dataSelect.indexKey
                    );
                }
                $this.finishUpdate = false;
                setTimeout(function () {
                    $this.finishUpdate = true;
                }, 1500);

                $this.html = computeModelName;
                $this.block.html = computeModelName;
                $("#" + $this.block.id + " > .block-content").html($this.html);
                $this.updateBlock($this.block, "valueTextSt");
                utils.changeModelToSpan($this);
                moveableElement.init($this.thisVue, document.querySelector("#" + $this.block.id), document.querySelector("#" + $this.block.idPar));
            }
        });
    }
}