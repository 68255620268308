import { sevice } from "./function-template.service.js";
import { utils } from "@/libs-core/utils.min.js";

//------//
// CONFIG CODE MIRROR
//------//
import EslintEditor from "vue-eslint-editor"
const Linter = require("eslint4b");
// END CONFIG CODE MIRROR //
///////////////////////////

export default {
    components: {
        EslintEditor
    },
    props: ["itemFuncSelect"],
    data: function() {
        return {
            errorMessage: "",
            successMessage: "",
            name: "",
            formData: {},
            listFunction: [],
            linter: new Linter(),
            optionsCm: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                mode: "text/javascript",
                theme: "default"
            },
            display: "list",
            packageType: "",
            functionSelect: {},
            config: {
                globals: {
                    // ES2015 globals
                    ArrayBuffer: false,
                    DataView: false,
                    Float32Array: false,
                    Float64Array: false,
                    Int16Array: false,
                    Int32Array: false,
                    Int8Array: false,
                    Map: false,
                    Promise: false,
                    Proxy: false,
                    Reflect: false,
                    Set: false,
                    Symbol: false,
                    Uint16Array: false,
                    Uint32Array: false,
                    Uint8Array: false,
                    Uint8ClampedArray: false,
                    WeakMap: false,
                    WeakSet: false,
                },
                rules: {
                    // eslint:recommended rules
                    "constructor-super": "error",
                    "no-case-declarations": "error",
                    "no-class-assign": "error",
                    "no-compare-neg-zero": "error",
                    "no-cond-assign": "error",
                    "no-console": "error",
                    "no-const-assign": "error",
                    "no-constant-condition": "error",
                    "no-control-regex": "error",
                    "no-debugger": "error",
                    "no-delete-var": "error",
                    "no-dupe-args": "error",
                    "no-dupe-class-members": "error",
                    "no-dupe-keys": "error",
                    "no-duplicate-case": "error",
                    "no-empty": "error",
                    "no-empty-character-class": "error",
                    "no-empty-pattern": "error",
                    "no-ex-assign": "error",
                    "no-extra-boolean-cast": "error",
                    "no-extra-semi": "error",
                    "no-fallthrough": "error",
                    "no-func-assign": "error",
                    "no-global-assign": "error",
                    "no-inner-declarations": "error",
                    "no-invalid-regexp": "error",
                    "no-irregular-whitespace": "error",
                    "no-mixed-spaces-and-tabs": "error",
                    "no-new-symbol": "error",
                    "no-obj-calls": "error",
                    "no-octal": "error",
                    "no-redeclare": "error",
                    "no-regex-spaces": "error",
                    "no-self-assign": "error",
                    "no-sparse-arrays": "error",
                    "no-this-before-super": "error",
                    "no-undef": "error",
                    "no-unexpected-multiline": "error",
                    "no-unreachable": "error",
                    "no-unsafe-finally": "error",
                    "no-unsafe-negation": "error",
                    "no-unused-labels": "error",
                    "no-unused-vars": "error",
                    "no-useless-escape": "error",
                    "require-yield": "error",
                    "use-isnan": "error",
                    "valid-typeof": "error",
                    // a few style rules.
                    semi: "error",
                    quotes: "error",
                    indent: ["error", 4],
                },
                parserOptions: {
                    ecmaVersion: 2020,
                    sourceType: "module",
                },
            },
        };
    },
    watch: {
        name: _.debounce(function() {
            this.name = utils.resetText(this.name);
        }, 300),
        successMessage(val) {
            if (!val) return;
            this.$swal({
                title: "",
                text: val,
                type: "success",
                showConfirmButton: false,
                timer: 2000
            });
            this.successMessage = "";
        },
        errorMessage(val) {
            if (!val) return;
            this.$swal({
                title: "",
                text: val,
                type: "error",
                showConfirmButton: false,
                timer: 2000
            });
            this.errorMessage = "";
        }
    },
    methods: {
        createOrUpdate: function(val) {
            var thisVue = this;
            let listTracking = thisVue.$store.state.editor.listTracking;
            if (this.name === "") {
                this.errorMessage = "Name is required";
                setTimeout(() => {
                    this.errorMessage = "";
                }, 3000);
                return;
            }
            var item = {
                page: localStorage.getItem("pageId"),
                type: "func",
                name: this.name,
                source: val.source,
                id: val.id
            };

            var index = this.listFunction.find((x) => x.name === item.name);
            if (val.id) {
                sevice.update(JSON.stringify(item), function(data) {
                    if (data && data.code === 200) {
                        thisVue.successMessage = "Updated success function";
                        setTimeout(() => {
                            thisVue.successMessage = "";
                        }, 3000);
                    }
                });
            } else if (!index && !val.id) {
                sevice.createConfig(JSON.stringify(item), function(data) {
                    if (data && data.code === 200) {
                        thisVue.successMessage = "Created success function";
                        listTracking.unshift(data.data);
                        thisVue.$store.commit("editor/setListTracking", listTracking);
                        setTimeout(() => {
                            thisVue.successMessage = "";
                        }, 3000);
                    }
                });
            } else {
                this.errorMessage = "Name already exists, please use another name!";
                setTimeout(() => {
                    this.errorMessage = "";
                }, 3000);
            }
        },
        resetValue: function() {
            this.selectItem();
            this.display = "list";
        },
        back: function() {
            let thisVue = this;
            if (thisVue.$store.state.editor.before_popup.type === "page-action") {
                thisVue.$store.commit("editor/mutaBeforePopup", {});
                $("#action-page-setting").modal("show");
                $("#modal-function").modal("hide");
            } else if (thisVue.$store.state.editor.before_popup.type === "block-action") {
                thisVue.$store.commit("editor/mutaBeforePopup", {});
                $("#action-setting").modal("show");
                $("#modal-function").modal("hide");
            } else {
                $("#modal-function").modal("hide");
                $("#modal-setting-page").modal("show");
            }
        },
        selectedItem: function() {
            let thisVue = this;
            if (thisVue.itemFuncSelect) {
                thisVue.functionSelect = thisVue.itemFuncSelect;
                thisVue.name = thisVue.functionSelect.name || "";
            } else {
                var user = JSON.parse(localStorage.getItem("user"));
                if (
                    this.listFunction &&
                    this.listFunction.length > 10 &&
                    this.packageType != "unlimited" &&
                    !utils.checkEmailNv(user.email)
                ) {
                    this.$swal({
                        title: "",
                        text: "Please upgrade the app to use this feature more!",
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Close"
                    });
                } else {
                    thisVue.display = "item";
                    thisVue.functionSelect = {
                        name: "",
                        source: `(function(cb) {
              //Warning : This feature only support for developer.
            });`
                    };
                    thisVue.name = "";
                }
            }
        },
        handleCursor(val) {
            let indexActiveChar = val.getCursor().ch;
            let indexLineActive = val.getCursor().line;
            let lineActive = val.getLine(indexLineActive);
            lineActive = lineActive.substring(0, indexActiveChar);
            let dataObject = {
                val,
                indexActiveChar,
                lineActive
            };

            if (val.getCursor(true).ch !== val.getCursor(false).ch) return;

            if (
                lineActive.lastIndexOf("$model") !== -1 &&
                lineActive.lastIndexOf("$function") !== -1 &&
                lineActive.lastIndexOf("$api") !== -1
            )
                return;

            dataObject.type = "suggest-data";
            this.$store.commit("editor/setDataAddOn", dataObject);
        },
        onInputCode: function(val) {
            console.log("val::: ", val);
        },
        onChangeValue: function(val) {
            this.functionSelect.source = val.code;
        }
    },
    mounted: function() {
        var thisVue = this;
        $("#modal-function").on("shown.bs.modal", function() {
            thisVue.packageType = localStorage.getItem("package_type");
            thisVue.display = "list";
            thisVue.selectedItem();
        });
        $("#modal-function").on("hidden.bs.modal", function() {
            $("#modal-setting-page").modal("show");
        });
    }
};
