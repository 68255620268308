export default {
    props: ["infoApp"],
    components: {

    },
    data: function() {
        return {
            completeGuide: [],
            idApp: ''
        };
    },
    methods: {
        clickCompleteGuide() {
            $("#model-new-clone-page").modal('hide');
            this.completeGuide.push(this.idApp);
            localStorage.setItem('completeGuide', JSON.stringify(this.completeGuide));
        }
    },
    mounted: function() {
        var thisVue = this;
        let completeGuide = localStorage.getItem('completeGuide');
        this.completeGuide = completeGuide ? JSON.parse(completeGuide) : [];
        this.idApp = this.$route.params.id;

        $("#model-new-clone-page").on("shown.bs.modal", function() {

        });

        $("#model-new-clone-page").on("hidden.bs.modal", function() {
            thisVue.$store.commit('editor/mutaBeforePopup', {});
        })
    }
};
