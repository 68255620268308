import resful from "@/services/resful.js";
export const service = {
    saveTemplateBlock: async function(data, next) {
        let res = await resful.post("/app/create_template", data);
        next(res);
    },
    uploadBase64: async function(data, next) {
        let res = await resful.post("/app/upload_base64", data);
        next(res);
    },
    getListCategory: async function(data, next) {
        let res = await resful.get("/app/category?" + data);
        next(res);
    },
    uploadImage: async function(data, next) {
        let res = await resful.uploadImage("/app/upload", data);
        next(res);
    }
};
