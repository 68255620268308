export default {
    props: ["backgroundData", 'typeImage'],
    data() {
        return {
            dataBgImage: {
                backgroundImageRepeat: "0",
                backgroundImageType: "0",
                backgroundImageUrl: ""
            },
            url: ""
        };
    },
    computed: {
        computedCurrentBgType() {
            switch (this.dataBgImage.backgroundImageType) {
                case "0":
                    return "Full width";
                case "1":
                    return "Full height";
                case "2":
                    return "Cover";
                case "3":
                    return "Parallax";
            }
        }
    },
    watch: {
        backgroundData: {
            handler(val) {
                this.dataBgImage.backgroundImageUrl = val.backgroundImageUrl || "";
                this.dataBgImage.backgroundImageType = val.backgroundImageType || "0";
                this.dataBgImage.backgroundImageRepeat = val.backgroundImageRepeat || "0";
            },
            deep: true
        }
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager");
        },
        updateUrl(event) {
            let url = event.target.value;

            if (!url) return;
            this.dataBgImage.backgroundImageUrl = url;
            this.$emit("updateBgImageData", this.dataBgImage);
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.currentTarget.getBoundingClientRect().top;
            let leftPosition = event.currentTarget.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;

            switch (nameSection) {
                case "select-img":
                    if (this.typeImage && this.typeImage === 'outside') {
                        $("#countdown-st .fill-outside #image-picker .image-popup").toggle();
                    } else if (this.typeImage && this.typeImage === 'inside') {
                        $("#countdown-st .fill-inside #image-picker .image-popup").toggle();
                    } else {
                        $("#image-picker .image-popup").toggle();
                    }
                    if (windowHeight < topPosition + 340) {
                        $(".image-popup").css({
                            top: `${windowHeight - 360}px`,
                            left: `${leftPosition - 10}px`
                        });
                        $(".image-popup").addClass("no-after");
                        return;
                    }
                    $(".image-popup").css({
                        top: `${topPosition - 7}px`,
                        left: `${leftPosition - 10}px`
                    });
                    $(".image-popup").removeClass("no-after");
                    break;
                case "bg-size":
                    $("#image-picker .image-popup .bg-size-dropdown .list-option").toggle();

                    if (val) {
                        this.dataBgImage.backgroundImageType = val;
                        this.$emit("updateBgImageData", this.dataBgImage);
                    }
                    break;
                case "bg-repeat":
                    $("#image-picker .image-popup .bg-repeat-dropdown .list-option").toggle();

                    if (val) {
                        this.dataBgImage.backgroundImageRepeat = val;
                        this.$emit("updateBgImageData", this.dataBgImage);
                    }
                    break;
            }
        }
    },
    mounted() {
        if (!this.backgroundData) return;

        this.dataBgImage.backgroundImageUrl = this.backgroundData.backgroundImageUrl || "";
        this.dataBgImage.backgroundImageType = this.backgroundData.backgroundImageType || "0";
        this.dataBgImage.backgroundImageRepeat = this.backgroundData.backgroundImageRepeat || "0";
    }
};
