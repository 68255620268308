let setStyle = require("@/libs-core/render-style-preview.min.js");
import VRuntimeTemplate from "v-runtime-template";
import eachSeries from 'async/eachSeries';
export default {
    components: {
        VRuntimeTemplate
    },
    props: ["itemPage", "list_model", "pageSelect", "timeChange", "listApi_Template", "list_tracking", "list_function"],
    data: function () {
        var item = {};
        for (var i = 0; i < this.list_model.length; i++) {
            item[[this.list_model[i].key]] = this.list_model[i].value;
        }
        item.html = "";
        item.apiFunction = {};
        item.trackFunction = {};
        item.funsFunction = {};
        item.item_page = {};
        item.api = [];
        item.pageName = "";
        return item;
    },
    watch: {
        timeChange: function (val) {
            this.resetModelLocal();
            this.item_page = $.extend(true, {}, this.itemPage);
            this.api = $.extend(true, [], this.item_page.api);
            this.pageName = this.item_page.name;
            this.renderHtml(this.api);
            setTimeout(function () {
                $(".loading-preview").hide();
            }, 1000);
        }
    },
    methods: {
        renderHtml: function (api) {
            if (this.pageName === this.pageSelect) {
                var thisVue = this;
                thisVue.html = "<div>";
                setTimeout(function () {
                    thisVue.renderSortChild(api);
                }, 300);
            }
        },
        renderSortChild: function (api) {
            var thisVue = this;
            for (var i = 0; i < api.length; i++) {
                if (api[i] && api[i].data && api[i].data.idPar && api[i].data.idPar.length > 0) {
                    $("#preview-" + api[i].data.idPar + " > .preview-block-content").append($("#preview-" + api[i].data.id));
                }
            }
            setStyle.init(api, thisVue.device);
            setStyle.setStypePage(this.item_page.style);
            setTimeout(function () {
                $(".loading-preview").hide();
            }, 1000);
        },
        click: function (event, val) {
            event.stopPropagation();
            var thisVue = this;
            console.log("start action:: ", val);
            eachSeries(val, thisVue.runAction, function (err, result) {
            });
        },
        atou: function (str) {
            return decodeURIComponent(escape(window.atob(str)));
        },
        runAction: function (val, cb) {
            if (!val) {
                return;
            }
            val.config = val.data;
            var this_vue = this;
            var timeout = val.time_out || 0;
            switch (val.type) {
                case "validate":
                    setTimeout(function () {
                        try {
                            var status = this_vue.actionValidate(val.config);
                            if (!status) {
                                return false;
                            }
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "link":
                    setTimeout(function () {
                        try {
                            this_vue.actionLink(val.config);
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "page":
                    setTimeout(function () {
                        try {
                            this_vue.actionPage(val.config);
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "phone":
                    setTimeout(function () {
                        try {
                            this_vue.actionPhone(val.config);
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "email":
                    setTimeout(function () {
                        try {
                            this_vue.actionEmail(val.config);
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "position":
                    try {
                        setTimeout(function () {
                            this_vue.actionPosition(val.config);
                        }, timeout);
                        cb(null, val.config);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "model":
                    setTimeout(function () {
                        try {
                            this_vue.actionModel(
                                val.config.type,
                                val.config.key,
                                val.config.value_set_model,
                                val.config.type_set_value
                            );
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "api":
                    setTimeout(function () {
                        try {
                            var api_select = $.extend(true, {}, this_vue.resetApi(val.config.api_select));
                            var dataHeader = {};
                            var data = {};
                            var dataParams = {};
                            var dataGet = "";
                            var arrBody = api_select.configs.body.valueNormal || [];
                            var arrParams = api_select.configs.params || [];
                            var arrHeader = api_select.configs.header || [];
                            var url = api_select.configs.url;
                            var urlCurent = this_vue.resetString(url);
                            var method = val.config.method;
                            var id = api_select.id;
                            for (var i = 0; i < arrBody.length; i++) {
                                if (arrBody[i].key && arrBody[i].key.length > 0) {
                                    data[arrBody[i].key] = this_vue.resetString(arrBody[i].value);
                                }
                            }
                            for (var j = 0; j < arrParams.length; j++) {
                                if (arrParams[j].key && arrParams[j].key.length > 0) {
                                    if (arrParams[j].key && arrParams[j].key.length > 0) {
                                        dataParams[arrParams[j].key] = this_vue.resetString(arrParams[j].value);
                                        if (dataGet && dataGet.length > 0) {
                                            dataGet += "&" + arrParams[j].key + "=" + this_vue.resetString(arrParams[j].value);
                                        } else {
                                            dataGet += "?" + arrParams[j].key + "=" + this_vue.resetString(arrParams[j].value);
                                        }
                                    }

                                }
                            }
                            for (var k = 0; k < arrHeader.length; k++) {
                                if (arrHeader[k].key && arrHeader[k].key.length > 0) {
                                    dataHeader[arrHeader[k].key] = this_vue.resetString(arrHeader[k].value);
                                }
                            }
                            if (api_select.configs.body.type === "raw") {
                                if (api_select.configs.body.valueRawCode && api_select.configs.body.valueRawCode.length > 0) {
                                    var valueraw = this_vue.atou(api_select.configs.body.valueRawCode);
                                    data = this_vue.resetString(valueraw);
                                } else {
                                    data = this_vue.resetString(api_select.configs.body.valueRaw);
                                }
                            }
                            if (val.config.security === "security") {
                                url = "https://security.appon.vn/v1/public/send_api";
                                dataHeader.xapi = id;
                            } else {
                                url = this_vue.resetString(url);
                            }
                            if (dataGet && dataGet.length > 0) {
                                url += dataGet;
                                urlCurent += dataGet;
                            }
                            dataHeader.xurl = urlCurent;
                            $.ajax({
                                "async": true,
                                method: method,
                                crossDomain: true,
                                url: url,
                                headers: dataHeader,
                                params: dataParams,
                                "processData": false,
                                data: data,
                                success: function (res) {
                                    if (val.config.actionSuccess && val.config.actionSuccess.length > 0) {
                                        var dataSuc = val.config.actionSuccess;
                                        dataSuc.find(function (x) {
                                            x.res = res;
                                        });
                                        eachSeries(dataSuc, this_vue.functionSuccess, function (res, result) {
                                        });
                                    }
                                    cb(null, res);
                                },
                                error: function (err) {
                                    if (val.config.actionError && val.config.actionError.length > 0) {
                                        var dataSuc = val.config.actionError;
                                        dataSuc.find(function (x) {
                                            x.err = err;
                                        });
                                        eachSeries(dataSuc, this_vue.functionError, function (err, result) {
                                        });
                                    }
                                    cb(null, err);
                                }
                            });

                        } catch (e) {
                            cb(e);
                        }
                    });
                    break;
                case "function":
                    setTimeout(function () {
                        try {
                            var func = this_vue.getFunction(val.config).trim();
                            var runFun = "";
                            if (func && func.length > 0 && func[func.length - 1] == ";") {
                                runFun = func.substring(0, func.length - 1);
                            }
                            runFun += "();";
                            runFun = runFun.replace(/\$model/g, "this_vue");
                            runFun = runFun.replace(/\$api/g, "this_vue.apiFunction");
                            runFun = runFun.replace(/\$function/g, "this_vue.funsFunction");
                            runFun = runFun.replace(/\$tracking/g, "this_vue.trackFunction");
                            eval(runFun);
                            cb(null, val.config);
                        } catch (e) {
                            console.log("lỗi: ", e);
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "tracking":
                    setTimeout(function () {
                        try {
                            var func = this_vue.getTracking(val.config).trim();
                            var runFun = "";
                            if (func && func.length > 0 && func[func.length - 1] == ";") {
                                runFun = func.substring(0, func.length - 1);
                            }
                            runFun += "();";
                            runFun = runFun.replace(/\$model/g, "this_vue");
                            runFun = runFun.replace(/\$api/g, "this_vue.apiFunction");
                            runFun = runFun.replace(/\$function/g, "this_vue.funsFunction");
                            runFun = runFun.replace(/\$tracking/g, "this_vue.trackFunction");
                            eval(runFun);
                            cb(null, val.config);
                        } catch (e) {
                            cb(e);
                        }
                    }, timeout);
                    break;
                case "googleform":
                    try {
                        var data = {};
                        data.fvv = val.config.fvv;
                        data.draftResponse = val.config.draftResponse;
                        data.pageHistory = val.config.pageHistory;
                        data.fbzx = val.config.fbzx;
                        if (val.config.params && val.config.params.length > 0) {
                            for (var i = 0; i < val.config.params.length; i++) {
                                if (val.config.requireParams) {
                                    if (this_vue[val.config.params[i].model] && this_vue[val.config.params[i].model].length > 0) {
                                    } else {
                                        var mess = val.config.messager_require || "Please check value!";
                                        alert(mess);
                                        return false;
                                    }
                                }
                                if (val.config.params[i].key === "timestamp") {
                                    data[val.config.params[i].key] = new Date().getTime();
                                } else {
                                    data[val.config.params[i].key] =
                                        this_vue[val.config.params[i].model];
                                }
                            }
                            $.ajax({
                                method: "POST",
                                url: "" + val.config.formResponse,
                                data: data
                            });
                        }
                        cb(null, val.config);
                    } catch (e) {
                        cb(null, val.config);
                    }
                    break;
            }
        },
        actionValidate: function (val) {
            if (val && val.length > 0) {
                for (var i = 0; i < val.length; i++) {
                    var key = val[i].model;
                    if (key && key.length > 0) {
                        var value = this[key];
                        var mess = val[i].mess || "please check value!";
                        if (val[i].type === "empty" && !this[key]) {
                            alert(mess);
                            return false;
                        }
                        var syntax = new RegExp(val[i].syntax);
                        if (val[i].type === "syntax" && !syntax.test(this[key])) {
                            alert(mess);
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        functionSuccess: function (val, cb) {
            var this_vue = this;
            switch (val.type) {
                case "link":
                    try {
                        this_vue.actionLink(val.value_action);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "page":
                    try {
                        this_vue.actionPage(val.value_action);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "phone":
                    try {
                        this_vue.actionPhone(val.value_action);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "email":
                    try {
                        this_vue.actionEmail(val.value_action);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "position":
                    try {
                        this_vue.actionPosition(val.value_action);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "model":
                    try {
                        var value = "";
                        value = val;
                        if (val.value_type && val.value_action.search("res.") != -1) {
                            var str = "value = val." + val.value_action;
                            eval(str);
                        } else {
                            value = val.value_action;
                        }
                        this_vue.actionModel("", val.value_type, value, val.type_set_value);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "tracking":
                    try {
                        var func = "" + val.value_action + "()";
                        eval(func);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "function":
                    try {
                        var func = "" + val.value_action + "()";
                        eval(func);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
            }
        },
        functionError: function (val, cb) {
            var this_vue = this;
            switch (val.type) {
                case "link":
                    this_vue.actionLink(val.value_action);
                    cb(null, val.value_action);
                    break;
                case "page":
                    this_vue.actionPage(val.value_action);
                    cb(null, val.value_action);
                    break;
                case "phone":
                    this_vue.actionPhone(val.value_action);
                    cb(null, val.value_action);
                    break;
                case "email":
                    this_vue.actionEmail(val.value_action);
                    cb(null, val.value_action);
                    break;
                case "position":
                    this_vue.actionPosition(val.value_action);
                    cb(null, val.value_action);
                    break;
                case "model":
                    var value = "";
                    value = val;
                    if (val.value_type && val.value_action && val.value_action.search("err.") != -1) {
                        var str = "value = val." + val.value_action;
                        eval(str);
                    } else {
                        value = val.value_action;
                    }
                    this_vue.actionModel("", val.value_type, value, val.type_set_value);
                    cb(null, val.value_action);
                    break;
                case "tracking":
                    try {
                        var func = "" + val.value_action + "()";
                        eval(func);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
                case "function":
                    try {
                        var func = "" + val.value_action + "()";
                        eval(func);
                        cb(null, val.value_action);
                    } catch (e) {
                        cb(e);
                    }
                    break;
            }
        },
        actionLink: function (link) {
            var action = document.createElement("a");
            action.setAttribute("href", link);
            action.setAttribute("target", "_blank");
            action.click();
            action.remove();
        },
        actionEmail: function (email) {
            var action = "mailto:" + email;
            var email = document.createElement("a");
            email.setAttribute("href", action);
            email.setAttribute("target", "_blank");
            email.click();
            email.remove();
        },
        actionPhone: function (phone) {
            var action = "tel:" + phone;
            var phone = document.createElement("a");
            phone.setAttribute("href", action);
            phone.setAttribute("target", "_blank");
            phone.click();
            phone.remove();
        },
        actionPosition: function (position) {
            var top = $("#preview-" + position).offset().top;
            $("#view-app-editor .custom-card-body").animate({
                scrollTop: top
            }, 500);
        },
        actionModel: function (type, key, value, type_set_value) {
            var val = "";
            var valuesetModel;
            if (typeof value === "string") {
                val = value.trim();
            }
            if (val != "" && val.length > 4 && val[0] === "{" && val[1] === "{" && val[(val.length - 1)] === "}" && val[(val.length - 2)] === "}") {
                val = val.replace("{{", "");
                val = val.replace("}}", "");
                var str = "valuesetModel = this." + val;
                eval(str);
            } else {
                if (type === "number") {
                    valuesetModel = parseFloat(value);
                } else {
                    valuesetModel = value;
                }
            }
            var thisVue = this;
            valuesetModel = thisVue.resetString(valuesetModel);
            switch (type_set_value) {
                case "length":
                    thisVue[key] = valuesetModel.length;
                    break;
                case "assign":
                    thisVue[key] = valuesetModel;
                    break;
                case "concat":
                    if (Array.isArray(valuesetModel) && valuesetModel.length > 0) {
                        try {
                            for (var i = 0; i < valuesetModel.length; i++) {
                                thisVue[key].push(valuesetModel[i]);
                            }
                        } catch (e) {
                        }
                    } else {
                        try {
                            if (typeof thisVue[val] == "string" && JSON.parse(thisVue[val])) {
                                thisVue[val] = JSON.parse(thisVue[val]);
                            }
                            thisVue[key].push(valuesetModel);
                        } catch (e) {
                            thisVue[key] = [];
                            thisVue[key].push(valuesetModel);
                        }
                    }
                    break;
                default:
                    thisVue[key] = valuesetModel
                    break;
            }
            var data = JSON.parse(localStorage.getItem("botup-model"));
            data[key] = this[key];
            localStorage.setItem("botup-model", JSON.stringify(data));
        },
        actionPage: function (page) {
            var data = {};
            for (var i = 0; i < this.list_model.length; i++) {
                data[[this.list_model[i].key]] = this[this.list_model[i].key];
            }
            localStorage.setItem("botup-model", JSON.stringify(data));
            this.$emit("changePagePreview", page);
        },
        blurInput: function () {

        },
        changeValuePreviewCheckBox: function (event, val) {
            if (val) {
                if (typeof this[val] == "string" && JSON.parse(this[val])) {
                    this[val] = JSON.parse(this[val]);
                    if (this[val] && this[val].length > 0) {
                        var index = this[val].findIndex((x) => x === $(event.target).val());
                        if (index >= 0) {
                            this[val].splice(index, 1);
                        } else {
                            this[val].push($(event.target).val());
                        }
                    } else {
                        this[val] = [];
                        this[val].push($(event.target).val());
                    }
                } else {
                    if (this[val] && this[val].length > 0) {
                        var index = this[val].findIndex((x) => x === $(event.target).val());
                        if (index >= 0) {
                            this[val].splice(index, 1);
                        } else {
                            this[val].push($(event.target).val());
                        }
                    } else {
                        this[val] = [];
                        this[val].push($(event.target).val());
                    }
                }
            }
        },
        changeValuePreview: function (event, val) {
            if (val) {
                this[val] = $(event.target).val();
            }
        },
        getSrcBlock: function (val) {
            var src = val.trim().replace("{{", "").replace("}}", "");
            return this[src];
        },
        getmodel: function (val) {
            if (val) {
                var str = val.trim().replace("{{", "").replace("}}", "");
                if (this[str] || this[str] == "") {
                    return this[str];
                } else {
                    return str;
                }
            } else {
                return "";
            }
        },
        getValueJson: function (val) {
            var value = this.getmodel(val);
            if (JSON.parse(value)) {
                return JSON.parse(value);
            } else {
                return [];
            }
        },
        resetModelLocal: function () {
            var data = localStorage.getItem("botup-model");
            if (data) {
                data = JSON.parse(data);
                for (var i = 0; i < this.list_model.length; i++) {
                    this[[this.list_model[i].key]] = data[this.list_model[i].key];
                }
            }
        },
        resetString: function (str) {
            if (typeof str == 'string') {
                var arr = str.split("{{");
                var arrMo = [];
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].search("}}") != -1) {
                        arrMo.push(arr[i].split("}}")[0]);
                    }
                }
                for (var j = 0; j < arrMo.length; j++) {
                    var val = arrMo[j];
                    if (val.search(".") != 0 && val.search(".") != -1) {
                        var child = val.split(".");
                        var values = this[child[0]];
                        for (var n = 1; n < child.length; n++) {
                            values = values[child[n]];
                            if (n === (child.length - 1) && typeof values === "object") {
                                var stringconvert = JSON.stringify(values);
                                str = str.replace("{{" + arrMo[j] + "}}", stringconvert);
                            }
                        }
                        str = str.replace("{{" + arrMo[j] + "}}", values);
                    } else {
                        if (typeof this[val] === "object") {
                            var stringconvert = JSON.stringify(this[val]);
                            str = str.replace("{{" + arrMo[j] + "}}", stringconvert);
                        } else {
                            str = str.replace("{{" + arrMo[j] + "}}", this[val]);
                        }
                    }
                    str = str.replace("[[", "[");
                    str = str.replace("]]", "]");
                }
                return str;
            } else {
                return str;
            }
        },
        resetApi: function (api) {
            var apiEnd = this.listApi_Template.find(x => x.id === api.id);
            return apiEnd;
        },
        getFunction: function (nameFs) {
            var valueFs = this.list_function.find(x => x.name === nameFs);
            if (valueFs && valueFs.id) {
                return valueFs.source;
            }
            return "";
        },
        getTracking: function (nameFs) {
            var valueFs = this.list_tracking.find(x => x.name === nameFs);
            if (valueFs && valueFs.id) {
                return valueFs.source;
            }
            return "";
        },
        previewApi: function () {
            var this_vue = this;
            var api = {};
            for (var i = 0; i < this.listApi_Template.length; i++) {
                api = $.extend(true, {}, this.listApi_Template[i]);
                this.apiFunction[this.listApi_Template[i].name] = function (next) {
                    var datasend = this_vue.getDataSendApi(api);
                    try {
                        $.ajax({
                            method: datasend.method,
                            crossDomain: true,
                            url: datasend.url,
                            headers: datasend.dataHeader,
                            params: datasend.dataParams,
                            "processData": false,
                            data: datasend.data,
                            success: function (res) {
                                if (typeof next === "function") {
                                    next(null, res);
                                }
                            },
                            error: function (err) {
                                if (typeof next === "function") {
                                    next(err, null);
                                }
                            }
                        });

                    } catch (e) {
                        console.log("lỗi à:: ", e);
                        cb(e);
                    }
                };
            }
        },
        getDataSendApi: function (val) {
            var this_vue = this;
            var dataHeader = {};
            var data = {};
            var dataParams = {};
            var dataGet = "";
            var arrBody = val.configs.body.valueNormal || [];
            var arrParams = val.configs.params || [];
            var arrHeader = val.configs.header || [];
            var url = val.configs.url;
            var method = val.configs.method;
            var id = val.id;
            for (var i = 0; i < arrBody.length; i++) {
                if (arrBody[i].key && arrBody[i].key.length > 0) {
                    data[arrBody[i].key] = this_vue.resetString(arrBody[i].value);
                }
            }
            for (var j = 0; j < arrParams.length; j++) {
                if (arrParams[j].key && arrParams[j].key.length > 0) {
                    if (arrParams[j].key && arrParams[j].key.length > 0) {
                        dataParams[arrParams[j].key] = this_vue.resetString(arrParams[j].value);
                        if (dataGet && dataGet.length > 0) {
                            dataGet += "&" + arrParams[j].key + "=" + this_vue.resetString(arrParams[j].value);
                        } else {
                            dataGet += "?" + arrParams[j].key + "=" + this_vue.resetString(arrParams[j].value);
                        }
                    }
                }
            }
            for (var k = 0; k < arrHeader.length; k++) {
                if (arrHeader[k].key && arrHeader[k].key.length > 0) {
                    dataHeader[arrHeader[k].key] = this_vue.resetString(arrHeader[k].value);
                }
            }
            if (val.configs.body.type === "raw") {
                data = this_vue.resetString(val.configs.body.valueRaw);
            }
            if (val.configs.security === "security") {
                url = "https://security.appon.vn/v1/public/send_api";
                dataHeader.xapi = id;
            } else {
                url = this_vue.resetString(url);
            }
            if (dataGet && dataGet.length > 0) {
                url += dataGet;
            }
            return {
                url: url,
                method: method,
                dataHeader: dataHeader,
                data: data,
                dataParams: dataParams,
            }
        },
        renderFunction: function () {
            var this_vue = this;
            for (var i = 0; i < this.list_function.length; i++) {
                var source = this.getFunction(this.list_function[i].name);
                source = source.replace(/\$model/g, "this_vue");
                source = source.replace(/\$api/g, "this_vue.apiFunction");
                source = source.replace(/\$function/g, "this_vue.funsFunction");
                source = source.replace(/\$tracking/g, "this_vue.trackFunction");
                this.funsFunction[this.list_function[i].name] = eval(source);
            }
        },
        renderTracking: function () {
            var this_vue = this;
            for (var i = 0; i < this.list_tracking.length; i++) {
                var source = this.getTracking(this.list_tracking[i].name);
                source = source.replace(/\$model/g, "this_vue");
                source = source.replace(/\$api/g, "this_vue.apiFunction");
                source = source.replace(/\$function/g, "this_vue.funsFunction");
                source = source.replace(/\$tracking/g, "this_vue.trackFunction");
                this.trackFunction[this.list_tracking[i].name] = eval(source);
            }
        },
        checkCondition: function (val) {
            var html = "";
            if (val && val.key && val.condi) {
                if (!val.value) {
                    val.value = "";
                }
                switch (val.condi) {
                    case "larger":
                        html = ` v-show="` + val.key + ` > ` + val.value + `" `;
                        break;
                    case "smaller":
                        html = ` v-show="` + val.key + ` < ` + val.value + `" `;
                        break;
                    case "equal":
                        html = ` v-show="` + val.key + ` == '` + val.value + `'" `;
                        break;
                    case "different":
                        html = ` v-show="` + val.key + ` != '` + val.value + `'" `;
                        break;
                    case "lessequal":
                        html = ` v-show="` + val.key + ` >= ` + val.value + `" `;
                        break;
                    case "greaterequal":
                        html = ` v-show="` + val.key + ` >= ` + val.value + `" `;
                        break;
                }
            }
            return html;
        }
    },
    mounted: function () {
        this.item_page = $.extend(true, {}, this.itemPage);
        this.api = $.extend(true, [], this.item_page.api);
        this.pageName = this.item_page.name;
        this.renderHtml(this.api);
        if (!localStorage.getItem("botup-model")) {
            var data = {};
            for (var i = 0; i < this.list_model.length; i++) {
                data[[this.list_model[i].key]] = this.list_model[i].value;
            }
            localStorage.setItem("botup-model", JSON.stringify(data));
        }
        this.previewApi();
        this.renderFunction();
        this.renderTracking();
    }
}