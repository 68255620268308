import ScreenManager from "@/components/editor-v3/layout/layout-left-component/screen-manager/screen-manager.component.vue";
import BlockManager from "@/components/editor-v3/layout/layout-left-component/block-manager/block-manager.component.vue";
import MediaManager from "@/components/editor-v3/layout/layout-left-component/media-manager/media-manager.component.vue";
import TemplateManager from "@/components/editor-v3/layout/layout-left-component/template-manager/template-manager.component.vue";
// import Language from "@/components/shared/language/language.vue";
export default {
  components: {
    ScreenManager,
    BlockManager,
    MediaManager,
    TemplateManager,
    // Language
  },
  data: function () {
    return {
      current: 1,
      menuSl: ''
    };
  },
  methods: {
    showAddMember: function() {
      var info = this.$store.state.editor.infoApp;
      if (info.user == info.owner || (info.user != info.owner && !info.owner)) {
        $("#modal-add-member").modal("show");
      } else {
        alert("bạn không có quyền sử dụng chức năng này")
      }
      
    },
    showhistory: function() {
      $("#history-app").modal("show");
    },
    changeMenu: function (current) {

    },
    changeRouter: function () {
      this.$router.push({ path: "/dashboard/", query: {} });
    },
    showApiTemplate: function () {
      $("#api-template-v3").modal("show");
    },
    showDataTemplate: function () {
      $("#modal-manage-data").modal("show");
    },
    addClassLeftShow: function (val) {
      this.menuSl = val;
      $('.lay-out-left li[data!="' + val + '"]').find(".content").hide();
      var item = $('.lay-out-left li[data="' + val + '"]');
      if (item && item.length > 0) {
        var content = item.find(".content").eq(0);
        if (content.css("display") == "none") {
          content.show();
          $("#bt-layout-center").addClass("left-show");
          $("#bt-layout-top").addClass("left-show");
        } else {
          content.hide();
          $("#bt-layout-center").removeClass("left-show");
          $("#bt-layout-top").removeClass("left-show");
        }
      }
    },
    moveClassLeft: function () {
      $("#bt-layout-center").removeClass("left-show");
      $("#bt-layout-top").removeClass("left-show");
    }
  },
};
