import resful from "../../../../services/resful.js";
import { utils } from "@/libs-core/utils.min.js";
export const serive = {
  checkdomain: async function (data, next) {
    let res = await resful.get("/app/txt-domain?domain=" + data.domain, data);
    next(res);
  },
  generateDnsTxt: async function (data, next) {
    let res = await resful.get("/app/dns-txt?domain=" + data.domain, data)
    next(res)
  },
  getAllConfig: async function (data, next) {
    let res = await resful.get(
      "/app/api?" + utils.jsonToParams(data)
    );
    next(res);
  },
  getAllConfigTrackingFunc: async function(data, next) {
    let url = "/app/functions?" + utils.jsonToParams(data);
    let res = await resful.get(url);
    next(res);
}
};