import { moveableElement } from "@/libs-core/moveable-element.min.js";
export default {
    props: ["thisVue"],
    data() {
        return {
            top: "",
            left: "",
            width: "",
            height: "",
            listRadius: [],
            dataBlock: {},
            setting: [],
            device: "",
            idSl: "",
            mixedRadius: false,
            constrainProportions: false,
            calculateDataDone: false
        };
    },
    computed: {
        isAbleRadiusSetting() {
            if (this.dataBlock && this.dataBlock.componentName) {
                let compName = this.dataBlock.componentName;

                if (
                    compName === "blockTextarea" ||
                    compName === "blockInput" ||
                    compName === "blockButton" ||
                    compName === "blockImage" ||
                    compName === "blockList" ||
                    compName === "blockCountdown"
                ) {
                    return true;
                }
            }
        },
        isAbleConstrain() {
            if (this.dataBlock && this.dataBlock.componentName) {
                let compName = this.dataBlock.componentName;

                if (compName !== "blockColumnOnly" && compName !== "blockList" && compName !== "blockCheckbox") {
                    return true;
                }
            }
        }
    },
    watch: {
        "$store.state.editor.blockSelected": function(val) {
            if (val && val.id != this.idSl) {
                this.dataBlock = val;
                this.idSl = val.id;

                this.top = Math.round(parseInt(val[this.device].top));
                this.left = Math.round(parseInt(val[this.device].left));
                this.width = Math.round(parseInt(val[this.device].width));
                this.height =
                    Math.round(parseInt(val[this.device].height)) ||
                    Math.round(parseInt($("#" + val.id).outerHeight()));
                this.getRadiusValue(val);
                this.constrainProportions = false;
                this.handleException();
            } else {
                if (val && val.id == this.idSl) {
                    this.block = $.extend(true, {}, val);
                    this.dataBlock = $.extend(true, {}, val);
                    this.top = Math.round(parseInt(val[this.device].top));
                    this.left = Math.round(parseInt(val[this.device].left));
                    this.width = Math.round(parseInt(val[this.device].width));
                    this.height =
                        Math.round(parseInt(val[this.device].height)) ||
                        Math.round(parseInt($("#" + val.id).outerHeight()));
                }
            }
        },
        width(val, oldVal) {
            var $this = this;
            if (this.dataBlock && this.dataBlock.id && !isNaN(val)) {
                if (this.constrainProportions) {
                    this.calculateWidthHeight("width", val, oldVal);
                    return;
                }
                this.dataBlock[this.device].width = val + "px";
                this.updateBlock(this.dataBlock, "sizeBlockSt");
                $(".block-pos").html("w: " + $this.width + " - h: " + $this.height);
                if (this.dataBlock.idPar) {
                    moveableElement.init($this.thisVue, document.querySelector("#" + $this.dataBlock.id), document.querySelector("#" + $this.dataBlock.idPar));
                } else {
                    if (this.dataBlock.componentName == "blockList") {
                        moveableElement.dragResizeList(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    } else {
                        moveableElement.dragResizeColumn(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    }
                }
            }
        },
        height(val, oldVal) {
            var $this = this;
            if (this.dataBlock && this.dataBlock.id && !isNaN(val)) {
                if (this.constrainProportions) {
                    this.calculateWidthHeight("height", val, oldVal);
                    return;
                }
                this.dataBlock[this.device].height = val + "px";
                this.updateBlock(this.dataBlock, "sizeBlockSt");
                $(".block-pos").html("w: " + $this.width + " - h: " + $this.height);
                if (this.dataBlock.idPar) {
                    moveableElement.init($this.thisVue, document.querySelector("#" + $this.dataBlock.id), document.querySelector("#" + $this.dataBlock.idPar));
                } else {
                    if (this.dataBlock.componentName == "blockList") {
                        moveableElement.dragResizeList(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    } else {
                        moveableElement.dragResizeColumn(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    }
                }
                
            }
        },
        top(val) {
            var $this = this;
            if (this.dataBlock && this.dataBlock.id && !isNaN(val)) {
                this.dataBlock[this.device].top = val + "px";
                this.updateBlock(this.dataBlock, "posBlockSt");
                $(".block-pos").attr({"datay": val}).html("x: " + $this.left + " - y: " + val);
                if (this.dataBlock.idPar) {
                    moveableElement.init($this.thisVue, document.querySelector("#" + $this.dataBlock.id), document.querySelector("#" + $this.dataBlock.idPar));
                } else {
                    if (this.dataBlock.componentName == "blockList") {
                        moveableElement.dragResizeList(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    } else {
                        moveableElement.dragResizeColumn(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    }
                }
            }
        },
        left(val) {
            var $this = this;
            if (this.dataBlock && this.dataBlock.id && !isNaN(val)) {
                this.dataBlock[this.device].left = val + "px";
                this.updateBlock(this.dataBlock, "posBlockSt");
                $(".block-pos").attr({"datax": val}).html("x: " + val + " - y: " + $this.top);
                if (this.dataBlock.idPar) {
                    moveableElement.init($this.thisVue, document.querySelector("#" + $this.dataBlock.id), document.querySelector("#" + $this.dataBlock.idPar));
                } else {
                    if (this.dataBlock.componentName == "blockList") {
                        moveableElement.dragResizeList(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    } else {
                        moveableElement.dragResizeColumn(this.thisVue, document.querySelector("#" + this.dataBlock.id));
                    }
                }
            }
        },
        listRadius: {
            deep: true,
            handler: _.debounce(function(val) {
                let radius;
                let areRadiusEqual = this.listRadius.every((item) => item === this.listRadius[0]);

                if (!this.dataBlock) return;
                if (!this.dataBlock.border) {
                    this.dataBlock.border = {};
                }
                if (!this.mixedRadius && !areRadiusEqual) {
                    this.listRadius = val.map(() => this.listRadius[0]);
                }
                radius = val.map((item) => {
                    if (!item) return "0px";
                    return item + "px";
                });
                radius = radius.join(" ");

                if (this.dataBlock.border.borderRadius === radius) return;
                this.updateDataDevice(radius);
                this.dataBlock.border.borderRadius = radius;
                this.$store.dispatch("editor/updateBlock", { val: this.dataBlock, type: "radiusSt" });
            }, 100)
        }
    },
    methods: {
        handleException() {
            let compName = this.dataBlock && this.dataBlock.componentName;
            if (compName === "blockCountdown" && !this.dataBlock[this.device].width) {
                this.width = 288;
            }
        },
        isDisableInput(type) {
            if (!this.dataBlock || !this.dataBlock.componentName) return true;
            if (this.dataBlock.componentName === "blockLightBox" && type === "w") return false;
            if (
                this.dataBlock.componentName.includes("blockColumn") ||
                this.dataBlock.componentName.includes("blockList") ||
                this.dataBlock.componentName.includes("blockLightBox")
            ) {
                return true;
            }
        },
        updateBlock(val, type) {
            if (!val || !val.id) return;
            this.$store.dispatch("editor/updateBlock", { val, type });
        },
        calculateWidthHeight(type, val, oldVal) {
            let currentHeight = Number(this.height);
            let currentWidth = Number(this.width);
            val = Number(val);
            oldVal = Number(oldVal);

            if (this.calculateDataDone) {
                this.calculateDataDone = false;
                return;
            }
            if (type === "width") {
                let ratio = currentHeight / oldVal;
                let changeValue = ratio * (val - oldVal);
                let height = Number((currentHeight + changeValue).toFixed(2));

                if (height === Number(this.height)) return;
                this.height = height;
            }
            if (type === "height") {
                let ratio = currentWidth / oldVal;
                let changeValue = ratio * (val - oldVal);
                let width = Number((currentWidth + changeValue).toFixed(2));

                if (width === Number(this.width)) return;
                this.width = width;
            }

            this.calculateDataDone = true;
            this.dataBlock[this.device].height = this.height + "px";
            this.dataBlock[this.device].width = this.width + "px";
            this.updateBlock(this.dataBlock, "sizeBlockSt");
        },
        getRadiusValue(block) {
            let item = block && block.id && $(`#${block.id} .block-content`);
            let radius =
                block && block[this.device] && block[this.device].border && block[this.device].border.borderRadius;
            let listRadius;

            if (!radius) {
                let radiusItem = item && item.css("border-radius");
                radius = radiusItem ? `${radiusItem} ${radiusItem} ${radiusItem} ${radiusItem}` : "0px 0px 0px 0px";
            }
            listRadius = radius.split(" ");
            listRadius = listRadius.slice(0, 4);
            if (listRadius.length < 4) {
                while (listRadius.length < 4) {
                    listRadius.push(listRadius[0]);
                }
            }
            listRadius = listRadius.map((item) => {
                return Math.round(parseInt(item));
            });

            let isMixedRadius = !listRadius.every((item) => item === listRadius[0]);
            if (isMixedRadius) {
                this.mixedRadius = true;
            } else {
                this.mixedRadius = false;
            }
            this.listRadius = listRadius;
        },
        updateDataDevice(value) {
            if (!this.dataBlock[this.device]) {
                this.dataBlock[this.device] = {};
            }
            if (!this.dataBlock[this.device].border) {
                this.dataBlock[this.device].border = {};
            }
            this.dataBlock[this.device].border.borderRadius = value;
        },
        handleFocus() {
            this.width = $("#w-st").val();
            this.height = $("#h-st").val();
            this.left = $("#x-st").val();
            this.top = $("#y-st").val();
        },
        toggleSection(nameSection, event) {
            switch (nameSection) {
                case "radius":
                    let listRadius = $.extend(true, [], this.listRadius);
                    this.mixedRadius = !this.mixedRadius;
                    this.listRadius = listRadius;
                    break;
                case "link":
                    this.constrainProportions = !this.constrainProportions;
                    break;
            }
        }
    },
    mounted: function() {
        this.dataBlock = this.$store.state.editor.blockSelected;
        this.device = this.$store.state.editor.device;
        if (this.dataBlock && this.dataBlock.id) {
            this.top = Math.round(parseInt(this.dataBlock[this.device].top));
            this.left = Math.round(parseInt(this.dataBlock[this.device].left));
            this.width = Math.round(parseInt(this.dataBlock[this.device].width));
            this.height =
                Math.round(parseInt(this.dataBlock[this.device].height)) ||
                Math.round(parseInt($("#" + this.dataBlock.id).outerHeight()));
        }
        this.getRadiusValue(this.dataBlock);
        this.handleException();
    }
};
