import { SlickList, SlickItem } from "vue-slicksort";

export default {
    components: {
        SlickList,
        SlickItem
    },
    data() {
        return {
            block: {},
            idSl: "",
            dataAction: [],
            listPage: [],
            paramActionLink: [],
            indexParamSelected: 0,
            indexLinkSelected: 0,
            timeShowAction: 0
        };
    },
    watch: {
        "$store.state.editor.blockSelected"(val, oldVal) {
            if (val && val.id && (!_.isEqual(val.dataAction, this.dataAction) || !oldVal)) {
                this.idSl = val.id;
                this.block = val;
                this.dataAction = this.resetAction(val.dataAction);
            }
        },
        "$store.state.editor.listPage"(val) {
            if (val) {
                this.listPage = val;
            }
        },
        "$store.state.editor.dataActionSelect"(val) {
            if (this.timeShowAction !== val.timeShowAction) return;
            this.paramActionLink = val.dataParam;
            this.dataAction[this.indexLinkSelected].params = this.paramActionLink;
            this.block.dataAction = this.dataAction;
            this.updateBlock(this.block);
        }
    },
    methods: {
        addAction(type) {
            let item = {
                id: new Date().getTime(),
                type: type,
                data: "",
                time_out: 0
            };
            if (!this.dataAction) {
                this.dataAction = [];
            }
            if (type === "phone") {
                item.data = "tel:";
            }
            this.dataAction.push(item);
            this.block.dataAction = $.extend(true, [], this.dataAction);
            this.updateBlock(this.block);
        },
        removeAction(index) {
            const thisVue = this;

            this.$swal({
                title: "",
                text: "You won't be able to revert this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove"
            }).then((result) => {
                if (!result.value) return;

                thisVue.dataAction.splice(index, 1);
                thisVue.block.dataAction = $.extend(true, [], this.dataAction);
                this.updateBlock(this.block);
            });
        },
        updateDataAction(event, index) {
            const value = event.target.value;

            if (index === undefined) return;
            this.dataAction[index].data = value;
            this.block.dataAction = this.dataAction;
            this.updateBlock(this.block);
        },
        resetAction: function(val) {
            if (val) {
                return val.filter((item) => item);
            }
            return [];
        },
        getParamActionLink(action) {
            this.paramActionLink = action.params || [];
        },
        addMoreParam(index) {
            let selector = `#link-st .list-action > div:nth-child(${index + 1}) .action-item`;
            const item = {
                key: "key-param-" + this.paramActionLink.length,
                dataAppSelect: {}
            };
            this.paramActionLink.push(item);
            this.calHeightActionLink(selector);
        },
        removeParam(index, indexParam) {
            let selector = `#link-st .list-action > div:nth-child(${index + 1}) .action-item`;

            this.paramActionLink.splice(indexParam, 1);
            this.calHeightActionLink(selector);
        },
        openConfigParam(indexParam) {
            let timeShowAction = new Date().getTime();

            this.indexParamSelected = indexParam;
            this.timeShowAction = timeShowAction;
            const data = {
                indexParam: this.indexParamSelected,
                dataParam: this.paramActionLink,
                timeShowAction
            };
            this.$store.commit("editor/mutaDataActionSelect", data);
            $("#modal-config-params").modal("show");
        },
        updateParamData(val) {
            if (val) {
                this.paramActionLink = val;
                this.dataAction[this.indexLinkSelected].params = this.paramActionLink;
                this.block.dataAction = this.dataAction;
                this.updateBlock(this.block);
            }
        },
        sortEnd() {
            this.block.dataAction = this.dataAction;
            this.updateBlock(this.block);
        },
        updateBlock(val) {
            this.$store.dispatch("editor/updateBlock", {
                val,
                type: "actionSt"
            });
        },
        calHeightActionLink(selector) {
            let heightSet = this.paramActionLink.length ? 125 + this.paramActionLink.length * 40 : 121;
            $(selector).css("height", `${heightSet}px`);
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let topSet;
            let selector;
            switch (nameSection) {
                case "add-action":
                    elementHeight = $("#link-st .add-action-dropdown .list-option").outerHeight();

                    $("#link-st .add-action-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition + 35;
                    }
                    $("#link-st .add-action-dropdown .list-option").css({
                        top: `${topSet}px`
                    });

                    if (val) {
                        this.addAction(val);
                    }
                    break;
                case "page-dropdown":
                    elementHeight = $(`#link-st .list-action > div:nth-child(${index + 1}) .page-dropdown .list-option`).outerHeight();

                    $(`#link-st .list-action > div:nth-child(${index + 1}) .page-dropdown .list-option`).toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition;
                    }
                    $(".page-dropdown .list-option").css({
                        top: `${topSet}px`
                    });

                    if (val && index !== undefined) {
                        this.dataAction[index].data = val;
                        this.block.dataAction = this.dataAction;
                        this.updateBlock(this.block);
                    }
                    break;
                case "expand":
                    selector = `#link-st .list-action > div:nth-child(${index + 1}) .action-item`;

                    if (item.type === "link") {
                        this.getParamActionLink(item);
                        this.indexLinkSelected = index;
                    }
                    $(`#link-st .list-action > div:not(:nth-child(${index + 1})) .action-item`)
                        .removeClass("expand")
                        .css("height", "");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");

                        if (item.type === "link") {
                            $(selector).css("height", "");
                        }
                    } else {
                        $(selector).addClass("expand");

                        if (item.type === "link") {
                            this.calHeightActionLink(selector);
                        }
                    }
            }
        }
    },
    mounted() {
        const thisVue = this;
        const block = this.$store.state.editor.blockSelected;

        if (block) {
            this.block = block;
            this.idSl = block.id;
            this.dataAction = this.resetAction(block.dataAction);
        }

        this.listPage = this.$store.state.editor.listPage || [];

        $("#link-st .list-option").hide();
        // $(".list-action").sortable({
        //     start(event, ui) {
        //         thisVue.indexSortStart = ui.item.index();
        //     },
        //     stop(event, ui) {
        //         // thisVue.indexSortEnd = ui.item.index();
        //         let dataAction = $.extend(true, [], thisVue.dataAction);
        //         let dataActionSorted = [];
        //         let listAction = $("#link-st .list-action .action-item");

        //         thisVue.dataAction = [];
        //         if (listAction && listAction.length > 0) {
        //             listAction.each(function(i) {
        //                 let idItem = $(this).attr('id');

        //                 dataActionSorted.push(dataAction.find(action => action.id == idItem));
        //                 if (i === listAction.length - 1) {
        //                     setTimeout(function() {
        //                         console.log(dataActionSorted)
        //                         thisVue.dataAction = dataActionSorted;
        //                         thisVue.block.dataAction = dataActionSorted;
        //                         thisVue.updateBlock(thisVue.block);
        //                     });
        //                 }
        //             });
        //         }
        //     }
        // });
    }
};
