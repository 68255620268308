import { render, staticRenderFns } from "./modal-config-validate.html?vue&type=template&id=90bcfb16&scoped=true&"
import script from "./modal-config-validate.js?vue&type=script&lang=js&"
export * from "./modal-config-validate.js?vue&type=script&lang=js&"
import style0 from "./modal-config-validate.vue?vue&type=style&index=0&id=90bcfb16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90bcfb16",
  null
  
)

export default component.exports