import ActionPosition from "./action-position/action-position.vue";
import ActionFunction from "./action-function/action-function.vue";
import ActionTracking from "./action-tracking/action-tracking.vue";
import ActionModel from "./action-model/action-model.vue";
import ActionAPI from "./action-api/action-api.vue";

import { SlickList, SlickItem } from "vue-slicksort";
import { service } from "./action-page-setting.service.js";

export default {
    props: ["pageAction"],
    components: {
        ActionPosition,
        ActionFunction,
        ActionTracking,
        ActionModel,
        ActionAPI,
        SlickList,
        SlickItem
    },
    data() {
        return {
            dataAction: [],
            indexActionSelect: 0,
            listFunction: [],
            listTracking: [],
            listApi: [],
            listPage: []
        };
    },
    watch: {
        pageAction(val) {
            if (val) {
                this.dataAction = val;
            }
        }
    },
    methods: {
        addAction(type) {
            let item = {
                id: new Date().getTime(),
                type: type,
                data: "",
                time_out: 0
            };
            if (type === "api") {
                item.data = {
                    actionSuccess: [],
                    actionError: [],
                    api_select: {},
                    success: {},
                    error: {}
                };
            }
            if (!this.dataAction) {
                this.dataAction = [];
            }
            this.dataAction.push(item);
            this.updatePageAction(this.dataAction);
        },
        removeAction(index) {
            const thisVue = this;

            this.$swal({
                title: "",
                text: "You won't be able to revert this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove"
            }).then((result) => {
                if (!result.value) return;

                thisVue.dataAction.splice(index, 1);
                thisVue.updatePageAction(thisVue.dataAction);
            });
        },
        resetAction: function(val) {
            if (val) {
                return val.filter((item) => item);
            }
            return [];
        },
        sortEnd() {
            this.updatePageAction(this.dataAction);
        },
        updatePageAction(val) {
            this.dataAction = val;
            this.$emit("updatePageAction", val);
        },
        getInitialData() {
            let thisVue = this;
            service.getAllData(JSON.stringify({ type: "func", page: thisVue.$route.params.id }), function(data) {
                if (data && data.code === 200) {
                    thisVue.listFunction = data.data;
                    thisVue.$store.commit("editor/setListFunction", thisVue.listFunction);
                }
            });
            service.getAllData(
                JSON.stringify({
                    type: "tracking",
                    page: thisVue.$route.params.id
                }),
                function(data) {
                    if (data && data.code === 200) {
                        thisVue.listTracking = data.data;
                        thisVue.$store.commit("editor/setListTracking", thisVue.listTracking);
                    }
                }
            );
            service.getApi(
                JSON.stringify({
                    page: thisVue.$route.params.id
                }),
                function(data) {
                    if (data && data.code === 200) {
                        thisVue.listApi = data.data;
                        thisVue.$store.commit("editor/setListApiTemplate", thisVue.listApi);
                    }
                }
            );
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let topSet;
            switch (nameSection) {
                case "add-action":
                    elementHeight = $("#action-page-st .add-action-dropdown .list-option").outerHeight();

                    $("#action-page-st .add-action-dropdown .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        topSet = windowHeight - elementHeight - 40;
                    } else {
                        topSet = topPosition + 35;
                    }
                    $("#action-page-st .add-action-dropdown .list-option").css({
                        top: `${topSet}px`
                    });
                    if (val) {
                        this.addAction(val);
                    }
                    break;
            }
        }
    },
    mounted() {
        this.dataAction = this.pageAction || [];
        this.listPage = this.$store.state.editor.listPage;

        $("#action-page-st .list-option").hide();
        this.getInitialData();
    }
};
