import { setStypeItem } from "@/libs-core/set-style-block.min.js";
import { imageService } from "./image-manager.service.js";
export default {
    props: {
        dataComp: {
            type: Object
        },
        typeManagerImage: {
            type: String
        }
    },
    data() {
        return {
            listImage: [],
            imageSelect: "",
            listImageInCate: [],
            cateSelect: ""
        };
    },
    methods: {
        selectImage(val, ind) {
            this.imageSelect = val;
            $(`#modal-image-manager .image-item`).css("border", "");
            $(`#modal-image-manager .image-item:nth-child(${ind + 1})`).css("border", "1px solid #f87657");
        },
        selectCate(ind) {
            this.listImageInCate = this.listImage[ind].assets;
            this.cateSelect = this.listImage[ind].name;
        },
        backToCate() {
            this.cateSelect = "";
            this.imageSelect = "";
        },
        usingImage() {
            const thisVue = this;
            const device = this.$store.state.editor.device;

            if (this.imageSelect && this.imageSelect != "") {
                let item;
                switch (this.typeManagerImage) {
                    case "image":
                        this.dataComp.src = this.imageSelect;
                        let img = new Image();
                        img.src = this.imageSelect;
                        let width = parseFloat(thisVue.dataComp[device].width);
                        img.onload = function() {
                            let tyle = width / this.width;
                            thisVue.dataComp[device].height = this.height * tyle + "px";
                            if($("#" + thisVue.dataComp.id + " > .block-content img").length > 0) {
                                console.log(1);
                                $("#" + thisVue.dataComp.id + " > .block-content img").attr("src", img.src);
                            } else {
                                console.log(2);
                                $("#" + thisVue.dataComp.id + " > .block-content").html("<img src='"+img.src+"'/>");
                            }
                            
                            thisVue.$store.dispatch("editor/updateBlock", { type: "imageSt", val: thisVue.dataComp });
                        };
                        break;
                    case "background":
                        if (this.dataComp && this.dataComp[device] && this.dataComp[device].background) {
                            this.dataComp[device].background.backgroundImageUrl = this.imageSelect;
                            thisVue.$store.dispatch("editor/updateBlock", {
                                type: "backgroundSt",
                                val: thisVue.dataComp
                            });
                            setStypeItem(this.dataComp);
                        }
                        break;
                    case "bgInsideSt":
                        if (this.dataComp && this.dataComp[device] && this.dataComp[device].backgroundInside) {
                            this.dataComp[device].backgroundInside.backgroundImageUrl = this.imageSelect;
                            thisVue.$store.dispatch("editor/updateBlock", {
                                type: "bgInsideSt",
                                val: thisVue.dataComp
                            });
                            setStypeItem(this.dataComp);
                        }
                        break;
                    case "favicon":
                        this.dataComp.favicon = this.imageSelect;
                        item = {
                            val: this.dataComp,
                            type: "true"
                        };
                        this.$emit("updateSettingPage", item);
                        break;
                    case "image-share":
                        this.dataComp.imageShare = this.imageSelect;
                        item = {
                            val: this.dataComp,
                            type: "true"
                        };
                        this.$emit("updateSettingPage", item);
                        break;
                    case "background-page":
                        this.$emit("updateBgImagePageUrl", this.imageSelect);
                        break;
                }
            }
            setTimeout(function() {
                thisVue.cateSelect = "";
                thisVue.imageSelect = "";
            }, 1000);
        }
    },
    mounted() {
        const thisVue = this;
        imageService.getCateAndImage(
            $.param({
                owner: JSON.parse(localStorage.getItem("user")).id,
                type_second: "image",
                type: "user"
            }),
            (res) => {
                this.listImage = res.data;
            }
        );
        $("#modal-image-manager").on("show.bs.modal", function() {
            imageService.getCateAndImage(
                $.param({
                    owner: JSON.parse(localStorage.getItem("user")).id,
                    type_second: "image",
                    type: "user"
                }),
                (res) => {
                    thisVue.listImage = res.data;
                }
            );
        });
        $("#modal-image-manager").on("hidden.bs.modal", function() {
            $(`#modal-image-manager image-item`).css("border", "");
            thisVue.cateSelect = "";
            thisVue.imageSelect = "";
        });
    }
};
