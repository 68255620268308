import LayoutEditorLeft from "@/components/editor-v3/layout/layout-left-component/layout-left-component.vue";
import LayoutEditorRight from "@/components/editor-v3/layout/layout-right/layout-editor-right.vue";
import LayoutEditorTop from "@/components/editor-v3/layout/layout-top/layout-top.vue";
import { cssDefault } from "@/libs-core/css-default-editor.min.js";
import async from "async";
import API from "@/services/api/api.js";
import { utils } from "@/libs-core/utils.min.js";
import { selectedBlock } from "@/libs-core/selected-block.min.js";
import { groupBlock } from "@/libs-core/group-block.min.js";
import { shortKey } from "@/libs-core/short-key.min.js";
import { history } from "@/libs-core/history.min.js";
import { editorSV } from "./editor-v3.service.js";
import constant from "../../constants/index.js";
import { renderBlockEditor } from "@/libs-core/render-block-editor.min.js";
import { dropblock } from "@/libs-core/drop-block.min.js";
import { renderHtml } from "@/libs-core/render-html.min.js";
import { convertBlock } from "@/libs-core/convert-block.min.js";

import CssAdvanced from "../../components/editor-v3/help-editor-block/css-advance/css-advance.vue";
import Profile from "../../components/editor-v3/help-editor-block/profile-account/profile-account.vue";
import FunctionTemplate from "../../components/editor-v3/help-editor-block/function-template/function-template.vue";
import TrackingTemplate from "../../components/editor-v3/help-editor-block/tracking-template/tracking-template.vue";
import ApiTemplate from "../../components/editor-v3/help-editor-block/manager-api-template/manager-api-template.vue";
import SvgManager from "../../components/editor-v3/help-editor-block/svg-manager/svg-manager.vue";
import HelpManagerHtml from "../../components/editor-v3/help-editor-block/edit-html-block/edit-html-block.vue";
import UpgradeApp from "../../components/editor-v3/help-editor-block/upgrade-app/upgrade-app.vue";
import PaymentApp from "../../components/editor-v3/help-editor-block/payment-app/payment-app.vue";
import ManageData from "../../components/editor-v3/help-editor-block/manage-data/manage-data.vue";
import SelectDataApp from "../../components/editor-v3/help-editor-block/select-data-app/select-data-app.vue";
import SettingPublish from "../../components/editor-v3/help-editor-block/setting-publish-page/setting-publish-page.vue";
import SettingPage from "../../components/editor-v3/help-editor-block/setting-page/setting-page.vue";
import HelpManagerImage from "../../components/editor-v3/help-editor-block/image-manager/image-manager.vue";
import ModelTemplate from "../../components/editor-v3/help-editor-block/model-template/model-template.vue";
import SaveTemplateBlock from "../../components/editor-v3/help-editor-block/save-template/save-template.vue";
import CreateNewPageTemplate from "../../components/editor-v3/help-editor-block/create-new-page/create-new-page.vue";
import TemplatePageManager from "../../components/editor-v3/help-editor-block/template-page-manager/template-page-manager.vue";
import ClonePage from "../../components/editor-v3/help-editor-block/clone-page/clone-page.vue";
import UploadImageManager from "../../components/editor-v3/help-editor-block/upload-image-manager/upload-image-manager.vue";
import ModelNewClonePage from "../../components/editor-v3/help-editor-block/model-new-clone-page/model-new-clone-page.vue";
import ModalAddScreen from "../../components/editor-v3/help-editor-block/add-screen/add-screen.component.vue";
import ModalConfigParams from "../../components/editor-v3/help-editor-block/modal-config-params/modal-config-params.vue";
import FunctionManage from "../../components/editor-v3/help-editor-block/function-manage/function-manage.vue";
import TrackingManage from "../../components/editor-v3/help-editor-block/tracking-manage/tracking-manage.vue";
import ModalConfigGgForm from "../../components/editor-v3/help-editor-block/modal-config-ggform/modal-config-ggform.vue";
import ModalConfigValidate from "../../components/editor-v3/help-editor-block/modal-config-validate/modal-config-validate.vue";
import ModalConfigApi from "../../components/editor-v3/help-editor-block/modal-config-api/modal-config-api.vue";
import LayoutSettingBlock from "../../components/editor-v3/layout/layout-setting-block/layout-setting-block.vue"

import HelpEditorText from "../../components/editor-v3/help-editor-block/editor-text-block/editor-text-block.vue";
import Preview from "../../components/editor-v3/help-editor-block/preview/preview.vue";
import Loading from "../loading/loading.vue";
import SuggestDataWidget from "../../components/editor-v3/help-editor-block/suggest-data-widget/suggest-data-widget.vue";

import "jquery-ui/ui/widgets/droppable";
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui/ui/widgets/sortable";
import "jquery.hotkeys";

let setStyle = require("@/libs-core/set-style-block.min.js");
window.$listModel = constant._model.variable_default;
window.$history = { undo: [], redo: [] };
window.$dataApp = {};

export default {
  name: "editor",
  data: function () {
    return {
      blockCopy: [],
      id: "id editor",
      createNew: false,
      preview: false,
      api: [],
      pageSelect: "home",
      listPage: [{ name: "home", api: [] }],
      settingPage: {},
      ofsetTop: 0,
      time_update: new Date().getTime(),
      blockSelected: {},
      posEditorText: null,
      statusEditorText: 0,
      statusEditorForm: 1,
      moveable: "",
      statusManagerImage: 1,
      statusSelectedBlock: 1,
      statusSettingpage: 2,
      statusAttribute: 3,
      listModel: constant._model.variable_default,
      listApiTemplate: [],
      timePublish: 10,
      typeManagerImage: "image",
      domain: "",
      page_action: [],
      dataChangeImage: {},
      dataChangeHtml: {},
      dataChangeSvg: {},
      zoom: "1",
      infoApp: {
        name: "",
        type: "",
        category: "",
        description: "",
      },
      index: 0,
      valueSaveTemplate: {},
      apiCreateTemplate: [],
      timeupdateCreatePageTemplate: 100,
      backgroundPage: {},
      statusGroup: {
        down: false,
        move: false,
        up: false,
      },
      positionGroup: {
        startX: 0,
        startY: 0,
        stopX: 0,
        stopY: 0,
      },
      changeBlock: false,
      blockOld: {},
      blockNew: {},
      listHistory: [],
      dataApp: {},
      version: "0",
      device: "mobile",
      pageData: {},
      quickFuncPosition: {},
      itemFuncSelect: {},
      itemTrackingSelect: {},
    };
  },
  components: {
    LayoutEditorRight,
    LayoutEditorTop,
    SelectDataApp,
    ManageData,
    Profile,
    Preview,
    TemplatePageManager,
    CreateNewPageTemplate,
    LayoutEditorLeft,
    HelpEditorText,
    HelpManagerImage,
    SettingPage,
    SettingPublish,
    ApiTemplate,
    ModelTemplate,
    FunctionTemplate,
    TrackingTemplate,
    CssAdvanced,
    Loading,
    SaveTemplateBlock,
    UpgradeApp,
    HelpManagerHtml,
    SvgManager,
    ClonePage,
    UploadImageManager,
    PaymentApp,
    ModelNewClonePage,
    ModalAddScreen,
    ModalConfigParams,
    ModalConfigGgForm,
    ModalConfigValidate,
    ModalConfigApi,
    FunctionManage,
    TrackingManage,
    SuggestDataWidget,
    LayoutSettingBlock,
  },
  watch: {
    "$store.state.editor.backgroundPage": function (val) {
      this.updateBgPage(val);
    },
    "$store.state.editor.device": function (val) {
      this.device = val;
    },
    "$store.state.editor.pageSelect": function (val) {
      const thisVue = this;
      this.api.length = 0;
      this.pageSelect = val;
      this.listPage.find(function (x) {
        if (x.name === val) {
          thisVue.api = $.extend(true, [], x.api);
          renderBlockEditor.init(thisVue.api, thisVue);
        }
      });
    },
    "$store.state.editor.actionEditor": function (val) {
      let thisVue = this;
      let actionEditor = thisVue.$store.state.editor.actionEditor;
      if (actionEditor.type === "removePage") {
        this.removePage();
        return;
      }
      if (actionEditor.type === "removeBlock") {
        this.quickDeleteBlock();
        return;
      }
      if (actionEditor.type === "duplicateBlock") {
        this.quickDuplicateBlock();
        return;
      }
      if (actionEditor.type === "changePage") {
        let temp = actionEditor.value;
        this.changePage(temp, this.listPage);
        return;
      }
    },
    "$store.state.editor.selectBlock": function () {
      let thisVue = this;
      let selectBlock = thisVue.$store.state.editor.selectBlock;
      this.selectBlock(selectBlock);
    },
    api: function (val, oldVal) {
      setTimeout(this.checkNewBlockStyle(val, oldVal), 1000);
    }
  },
  methods: {
    checkNewBlockStyle: function (val, oldVal) {
      if (!val || _.isEqual(val, oldVal) || val.length <= oldVal.length) return;

      let device = this.device || 'mobile';
      let arrId = val.map(item => item.id);
      let arrOldId = oldVal.map(item => item.id);
      let newComponentId = arrId.find(item => arrOldId.indexOf(item) === -1);
      let newComponent = val.find(item => item.id === newComponentId);

      let textData = null;
      let background = null;
      let height = null;

      if (!newComponent) return;
      if (!newComponent[device]) {
        newComponent[device] = {};
      }
      if (!newComponent[device].text) {
        newComponent[device].text = {};
      }
      switch (newComponent.componentName) {
        case 'blockRadio':
        case 'blockCheckbox':
          textData = newComponent[device].text;

          if (textData.textAlign) return;
          newComponent[device].text.textAlign = "left";

          this.$store.dispatch("editor/updateBlock", { val: newComponent, type: "textSt" });
          break;
        case 'blockTextarea':
          height = newComponent[device].height;
          background = newComponent[device].background;

          if (!height) {
            newComponent[device].height = "50px";
            this.$store.dispatch("editor/updateBlock", { val: newComponent, type: "sizeBlockSt" });
          }
          if (!background || !background.backgroundColor) {
            newComponent[device].background.backgroundColor = "#00000000";
            this.$store.dispatch("editor/updateBlock", { val: newComponent, type: "backgroundSt" });
          }

          break;
        case 'blockInput':
          background = newComponent[device].background;

          if (!background || !background.backgroundColor) {
            newComponent[device].background.backgroundColor = "#00000000";
            this.$store.dispatch("editor/updateBlock", { val: newComponent, type: "backgroundSt" });
          }
          break;
        case 'blockList':
          if (!newComponent.border) {
            newComponent.border = {};
          };
          if (!newComponent[device].border) {
            newComponent[device].border = {};
          };
          if (!newComponent.border.borderWidth) {
            newComponent.borderWidth = "0px";
            newComponent[device].border.borderWidth = "0px";
            this.$store.dispatch("editor/updateBlock", { val: newComponent, type: "borderSt" });
          }
          break;
      }
    },
    dragBlockLefed: function (val) {
      console.log("emit val from editor::", val);
      if (val) {
        if (val && val.length > 1) {
          for (var i = 0; i < val.length; i++) {
            this.api.push(val[i]);
          }
        } else {
          this.api.push(val);
        }
        setStyle.init(this.api, this.device);
      }
    },
    moveComp: function (val) {
      var itemMove = this.api.find((x) => x.id === val.idMove);
      var itemParMove = this.api.find((x) => x.id === itemMove.idPar);
      var itemImport = this.api.find((x) => x.id === val.idImport);
      if (itemParMove && itemImport && itemParMove.id === itemImport.id) {
        return;
      } else {
        if (itemParMove) {
          if (
            itemParMove.dataChild &&
            itemParMove.dataChild.indexOf(val.idMove) >= 0
          ) {
            itemParMove.dataChild.splice(
              itemParMove.dataChild.indexOf(val.idMove),
              1
            );
          }
          if (itemImport.id != itemParMove.id) {
            this.updateDataComp(itemParMove);
          }
        }
        itemMove.idPar = val.idImport;
        if (!itemImport.dataChild) {
          itemImport.dataChild = [];
        }
        itemImport.dataChild.push(val.idMove);
        this.updateDataComp(itemImport);
      }
      if (itemImport.componentName === "blockList") {
        itemMove.itemlist = true;
      } else {
        itemMove.itemlist = false;
      }
      itemMove[this.device].top = val.top + "px";
      itemMove[this.device].left = val.left + "px";
      this.updateDataComp(itemMove);
      this.$forceUpdate();
    },
    publish: function (val) {
      //this.changePage(this.listPage[0]);
      var dataPublish;
      if (val.domain && val.domain.length > 0) {
        dataPublish = val.data;
      } else {
        dataPublish = val;
      }
      $("#hellopreloader")
        .css({ background: "#ccc", opacity: "0.5" })
        .show();
      var thisVue = this;
      dataPublish.push({
        path: "/public/index.html",
        source: renderHtml.renderIndex(this.settingPage),
      });

      thisVue.domain = "";
      if (!val.domain) {
        editorSV.publish(
          JSON.stringify({ pageID: thisVue.$route.params.id, files: dataPublish, host: "app.appon.vn" }),
          function (data) {
            if (data && data.code === 200) {
              thisVue.timePublish = new Date().getTime();
              thisVue.domain = data.data.link_preview.replace(
                "http://",
                "https://"
              );
              $("#modal-setting-publish").modal("show");
            } else {
              thisVue.$swal({
                type: "error",
                title: "Please try again or contact us",
                showConfirmButton: false,
                timer: 1500,
              });
              $("#modal-setting-publish").modal("hide");
            }
            $("#hellopreloader")
              .css({ background: "#ff886d", opacity: "1" })
              .hide();
          }
        );
      } else {
        editorSV.publishCustom(
          JSON.stringify({
            pageID: thisVue.$route.params.id,
            files: dataPublish,
            domain: val.domain,
          }),
          function (data) {
            if (data && data.code === 200) {
              thisVue.timePublish = new Date().getTime();
              thisVue.domain = data.data.link_preview.replace(
                "http://",
                "https://"
              );
              $("#modal-setting-publish").modal("show");
            } else {
              thisVue.$swal({
                type: "error",
                title: "Please try again or contact us",
                showConfirmButton: false,
                timer: 1500,
              });
              $("#modal-setting-publish").modal("hide");
            }

            $("#hellopreloader")
              .css({ background: "#ff886d", opacity: "1" })
              .hide();
          }
        );
      }
      this.saveSource();
    },
    myZoom: function (zoom) {
      this.zoom = zoom;
    },
    appuptomarket: function () {
      if (!this.valueSaveTemplate.source) {
        this.valueSaveTemplate.source = {};
      }
      this.valueSaveTemplate.source.listPage = this.listPage;
      this.valueSaveTemplate.source.settingPage = this.settingPage;
      this.valueSaveTemplate.source.componentName = "app";
      this.valueSaveTemplate.source.listModel = this.listModel;
      this.valueSaveTemplate.type = "app";
      this.time_update = new Date().getTime();
      $("#save-template").modal("show");
      $(".left-bar-item.templates.active").removeClass("active");
    },
    sortBlockColumList: function () {
      var thisVue = this;
      $("body").sortable();
      $("body").sortable("destroy");
      $(function () {
        $("body").sortable({
          cursor: "move",
          stop: function (event, ui) {
            var itemsortEnd = ui.item.index();
            var iditem = ui.item.attr("id");
            var apiItem = thisVue.api.find((x) => x.id === iditem);
            if (apiItem) {
              var itemNext = ui.item.next();
              var arrTmp = [];
              if (itemNext && itemNext.length > 0) {
                var idnext = itemNext.attr("id");
                for (var i = 0; i < thisVue.api.length; i++) {
                  if (thisVue.api[i].id != iditem) {
                    if (thisVue.api[i].id === idnext) {
                      arrTmp.push(apiItem);
                    }
                    arrTmp.push(thisVue.api[i]);
                  }
                }
              } else {
                for (var i = 0; i < thisVue.api.length; i++) {
                  if (thisVue.api[i].id != iditem) {
                    arrTmp.push(thisVue.api[i]);
                  }
                  if (i === thisVue.api.length - 1) {
                    arrTmp.push(apiItem);
                  }
                }
              }
              let arr = [];
              let arrBlock = arrTmp.filter(item => item.name !== "blockList" && item.name !== "blockColumnOnly");
              let arrCL = arrTmp.filter(item => item.name == "blockList" || item.name == "blockColumnOnly");
              let obj = {};
              arrBlock.forEach(item => {
                if (obj[item.idPar]) {
                  obj[item.idPar].push(item);
                } else {
                  obj[item.idPar] = [];
                  obj[item.idPar].push(item);
                }
              });
              arrCL.forEach(item => {
                arr.push(item);
                if (obj[item.id]) {
                  arr.push(...obj[item.id]);
                }
              })
              thisVue.api = [];
              setTimeout(function () {
                thisVue.api = $.extend(true, [], arr);
                thisVue.updateListPage(thisVue.pageSelect);
              });
            }
          },
        });
        $("body").disableSelection();
      });
    },
    resetIndexElement: function () {
      var thisVue = this;
      var arr = [];
      var wg = $(".block-widget");
      if (wg && wg.length > 0) {
        wg.each(function () {
          var $this = $(this);
          var wgItem = thisVue.api.find((x) => x.id === $this.attr("id"));
          if (wgItem && wgItem.id && wgItem.id.length > 0) {
            arr.push(wgItem);
          }
        });
        thisVue.api = [];
        thisVue.api = $.extend(true, [], arr);

        this.$store.commit("editor/setApi", thisVue.api);

        if (thisVue.blockSelected) {
          thisVue.selectBlock(
            thisVue.api.find((y) => y.id === thisVue.blockSelected.id)
          );
        }
      }
    },

    //////////////////
    // TEMPLATE ACTION
    //////////////////
    dropCreatePageTemplate: function (val) {
      this.apiCreateTemplate = $.extend(true, [], val);
      $("#new-page-template").modal("show");
      this.timeupdateCreatePageTemplate = new Date().getTime();
    },
    newPageTemplate: function (val) {
      this.createPage(val);
      $("#new-page-template").modal("hide");
    },
    // ENDTEMPLATE ACTION
    /////////////////////

    //////////////
    // PAGE ACTION
    //////////////
    createPage: function (val) {
      var thisVue = this;
      this.listPage.push({
        name: val.name,
        api: convertBlock.convertBlockV1(val.api) || [],
        page_action: [],
        style: val.style || {},
      });
      if (thisVue.$store.state.editor.before_popup.type === "block-action") {
        $("#modal-template-page-manager").modal("hide");
        $("#action-setting").modal("show");
        thisVue.$store.commit("editor/mutaBeforePopup", {});
      } else {
        this.changePage(
          {
            name: val.name,
            api: convertBlock.convertBlockV1(val.api) || [],
            style: val.style || {},
          },
          this.listPage
        );
        $("#add-new-page").modal("hide");
      }
    },
    removePage: function () {
      let thisVue = this;
      let index = thisVue.$store.state.editor.actionEditor;
      this.listPage.splice(index.value, 1);
      this.changePage(this.listPage[0], this.listPage);
    },
    showListpageFast: function (ev) {
      var wglist = $(".select-screen-fast .dropdown-menu");
      if (wglist.css("visibility") === "hidden") {
        wglist.css({ "visibility": "visible" });
      }
      console.log(wglist.css("visibility"));
    },
    changePage: function (val, listPage) {
      $("#loading-component").show();
      var wglist = $(".select-screen-fast .dropdown-menu");
      if (wglist.css("visibility") != "hidden") {
        wglist.css({ "visibility": "hidden" });
      }
      this.listPage =
        $.extend(true, [], listPage) ||
        $.extend(true, [], this.$store.state.editor.listPage);
      var thisVue = this;
      this.blockSelected = undefined;

      // vuex
      this.$store.commit("editor/setBlockSelected", undefined);
      // vuex

      $(".block-widget.selected").removeClass("selected");
      for (var i = 0; i < val.api.length; i++) {
        if (val.api[i] && val.api[i].idPar && val.api[i].idPar.length > 0) {
          var itemPar = val.api.find((x) => x.id === val.api[i].idPar);
          if (!itemPar) {
            val.api.splice(i, 1);
          }
        }
      }
      if (thisVue.device != "mobile") {
        val.api.find(function (x) {
          if (!x[thisVue.device] || !x[thisVue.device].top) {
            x[thisVue.device] = $.extend(true, {}, x.mobile);
            if (
              thisVue.device == "desktop" &&
              x.componentName != "blockColumnOnly" &&
              x.componentName != "blockList" &&
              x.componentName != "blockLightBox"
            ) {
              x[thisVue.device].left =
                480 + parseFloat(x["mobile"].left) + "px";
            }
            if (
              thisVue.device == "tablet" &&
              x.componentName != "blockColumnOnly" &&
              x.componentName != "blockList" &&
              x.componentName != "blockLightBox"
            ) {
              x[thisVue.device].left =
                224 + parseFloat(x["mobile"].left) + "px";
            }
          }
        });
      }
      // this.listPage.find(function (n) {
      //   if (n.name === val.name) {
      //     n.api = $.extend(true, [], val.api);

      //     // vuex

      //     thisVue.$store.commit("editor/setListPage", thisVue.listPage);
      //     // vuex
      //   }
      // });
      this.api = $.extend(true, [], val.api);

      //vuex
      this.$store.commit("editor/setApi", $.extend(true, [], val.api));
      //vuex

      this.page_action = [];
      this.page_action = val.page_action;
      // vuex
      this.$store.commit("editor/setPageAction", val.page_action);
      // vuex

      if (val.style && val.style.background) {
        this.backgroundPage = $.extend(true, {}, val.style.background);

        // vuex
        this.$store.commit(
          "editor/setBackgroundPage",
          $.extend(true, {}, val.style.background)
        );
        // vuex

        setStyle.setStypePage(val.style, thisVue.device);
      } else {
        this.backgroundPage = {};

        // vuex
        this.$store.commit("editor/setBackgroundPage", {});
        // vuex

        setStyle.setStypePage();
      }
      this.$forceUpdate();
      setTimeout(function () {
        setStyle.init(thisVue.api, thisVue.device);
      }, 0);
      this.pageSelect = val.name;
      thisVue.time_update = new Date().getTime();
      this.$store.commit("editor/setActionEditor", {});
      this.$store.commit("editor/setPageSelect", val.name);
      this.$store.commit("editor/setTimeUpdate", new Date().getTime());
      renderBlockEditor.init(this.api, this);
    },
    // END PAGE ACTION
    //////////////////

    /////////////
    // COPY PASTE
    /////////////
    copyBlock: function () {
      var wgSelect = $(".block-widget.selected");
      if (wgSelect && wgSelect.length > 0) {
        var val = $.extend(
          true,
          {},
          this.api.find((x) => x.id === wgSelect.attr("id"))
        );
        var arr = [val];
        this.api.find(function (x) {
          if (x.idPar === val.id) {
            arr.push($.extend(true, {}, x));
          }
        });
        this.blockCopy = $.extend(true, [], arr);
      }
    },
    pasteBlockCopied: function (offsetY) {
      if (this.blockCopy && this.blockCopy.length > 0) {
        var blockCopy = $.extend(true, [], this.blockCopy);
        var thisVue = this;
        var wgColumn = $(".block-widget.selected");
        var idSl;
        if (
          (wgColumn && wgColumn.attr("bt-type") === "blockColumnOnly") ||
          wgColumn.attr("bt-type") === "blockList"
        ) {
          idSl = wgColumn.attr("id");
          if (!idSl) {
            idSl = wgColumn.eq(0).attr("id");
          }
          if (
            blockCopy[0].componentName === "blockColumnOnly" ||
            blockCopy[0].componentName === "blockList"
          ) {
            var index = this.api.findIndex((x) => x.id === idSl);
            let id0 = "bt-" + new Date().getTime();
            blockCopy[0].cssAdvance = utils.replaceAllText(
              blockCopy[0].cssAdvance,
              blockCopy[0].id,
              id0
            );
            blockCopy[0].id = id0;
            this.api.splice(index, 0, blockCopy[0]);
            renderBlockEditor.renderOnceBlock(blockCopy[0], this);
            console.log(1);
            $("#" + blockCopy[0].id).insertBefore($("#" + idSl));
            for (var i = 1; i < blockCopy.length; i++) {
              if (blockCopy[i]) {
                let idi = "bt-" + new Date().getTime() + "" + i;
                blockCopy[i].cssAdvance = utils.replaceAllText(
                  blockCopy[i].cssAdvance,
                  blockCopy[i].id,
                  idi
                );
                blockCopy[i].id = idi;
                blockCopy[i].idPar = blockCopy[0].id;
                this.api.push(blockCopy[i]);
                renderBlockEditor.renderOnceBlock(blockCopy[i], this);
              }
            }
          } else {
            console.log(2);
            blockCopy[0].idPar = idSl;
            let id0 = "bt-" + new Date().getTime();
            blockCopy[0].cssAdvance = utils.replaceAllText(
              blockCopy[0].cssAdvance,
              blockCopy[0].id,
              id0
            );
            blockCopy[0].id = id0;
            blockCopy[0][this.device].top = offsetY + "px";
            this.api.push(blockCopy[0]);
            renderBlockEditor.renderOnceBlock(blockCopy[0], this);
          }
        } else {
          console.log(3);
          if (
            blockCopy[0].componentName === "blockColumnOnly" ||
            blockCopy[0].componentName === "blockList"
          ) {
            blockCopy[0].id = "bt-" + new Date().getTime();
            this.api.push(blockCopy[0]);
            renderBlockEditor.renderOnceBlock(blockCopy[0], this);
            $("#" + blockCopy[0].id).insertBefore($("#" + idSl));
            for (var i = 1; i < blockCopy.length; i++) {
              if (blockCopy[i]) {
                let idi = "bt-" + new Date().getTime() + "" + i;
                blockCopy[i].cssAdvance = utils.replaceAllText(
                  blockCopy[i].cssAdvance,
                  blockCopy[i].id,
                  idi
                );
                blockCopy[i].id = idi;
                blockCopy[i].idPar = blockCopy[0].id;
                this.api.push(blockCopy[i]);
                renderBlockEditor.renderOnceBlock(blockCopy[i], this);
              }
            }
          } else {
            var id0 = "bt-" + new Date().getTime();
            blockCopy[0].cssAdvance = utils.replaceAllText(
              blockCopy[0].cssAdvance,
              blockCopy[0].id,
              id0
            );
            blockCopy[0].id = id0;
            blockCopy[0][this.device].top = offsetY + "px";
            this.api.push(blockCopy[0]);
            renderBlockEditor.renderOnceBlock(blockCopy[0], this);
          }
        }
        this.listPage.find(function (x) {
          if (x.name === thisVue.pageSelect) {
            x.api = $.extend(true, [], thisVue.api);
          }
        });
        selectedBlock.init(this);
      }
    },
    // END COPY PASTE
    /////////////////

    /////////////
    // SAVE EVENT
    /////////////
    saveApp: function (val) {
      this.saveSource();
    },
    saveSource: function () {
      this.listPage = $.extend(true, [], this.$store.state.editor.listPage);
      var thisVue = this;
      var dataUpdate = {
        listPage: this.listPage,
        settingPage: this.settingPage,
        listModel: this.listModel,
        v: "1.0",
      };
      editorSV.updateSource(
        JSON.stringify({
          name: thisVue.infoApp.name,
          id: thisVue.$route.params.id,
          source: dataUpdate,
          status: "active",
          thumbnail: this.settingPage.imageShare,
          mode: this.settingPage.mode || "private",
        }),
        function () { }
      );
    },
    // ENDSAVE EVENT
    ////////////////

    ///////////////
    // SELECT EVENT
    ///////////////
    selectBlock: function (val) {
      this.blockSelected = $.extend(true, {}, val);
      this.blockSelected.time_update = new Date().getTime();
      this.time_update = new Date().getTime();
      var device = this.$store.state.editor.device;
      if (device == "mobile") {
        this.statusSelectedBlock = new Date().getTime();
      }
      // vuex
      this.$store.commit("editor/setBlockSelected", this.blockSelected);
      this.$store.commit("editor/setTimeUpdate", new Date().getTime());
      // vuex
    },
    selectedMain: function (event) {
      if ($(event.target).attr("id") == "bt-layout-center") {
        $(".selected").removeClass("selected");
        this.blockSelected = undefined;
        $("#quick-setting").removeAttr("class").hide();
        if (this.moveable.tempElement) {
          this.moveable.destroy();
        }
        //vuex
        this.$store.commit("editor/setBlockSelected", undefined);
        //vuex
      }
      if ($(event.target).attr("id") != "dropdownMenu2") {
        $(".select-screen-fast .dropdown-menu").css({ "visibility": "hidden" });
      }
    },
    // END SELECT EVENT
    ///////////////////

    ////////////
    // GET EVENT
    ////////////
    getChildFromArr: function (api, arr) {
      var arrChil = [];
      if (arr && arr.length > 0) {
        arr.forEach(function (el) {
          api.find(function (o) {
            if (o.id === el) {
              arrChil.push(o);
            }
          });
        });
      }
      return arrChil;
    },
    getChild: function (id) {
      var item = this.api.find((x) => x.id === id);
      var arrChild = [];
      if (item.dataChild && item.dataChild.length > 0) {
        arrChild = this.getChildFromArr(this.api, item.dataChild);
      }
      return arrChild;
    },
    // END GET EVENT
    ////////////////

    //////////////
    // CLONE EVENT
    //////////////
    clonePage: function (val) {
      if (val && val != "package") {
        var item_page = this.listPage.find((x) => x.name === val);
        var itemSlect = this.listPage.find((x) => x.name === this.pageSelect);

        if (!item_page) {
          this.listPage.push({
            name: val,
            api: convertBlock.convertBlockV1(itemSlect.api) || [],
            page_action: itemSlect.page_action || [],
            style: itemSlect.style || {},
          });
          this.changePage(
            {
              name: val,
              api: convertBlock.convertBlockV1(itemSlect.api) || [],
              page_action: itemSlect.page_action || [],
              style: itemSlect.style || {},
            },
            this.listPage
          );
          $("#clone-page").modal("hide");
        } else {
          this.$swal({
            title: "",
            text: "The page already exists, please try again!",
            type: "warning",
          });
        }
      } else {
        this.$swal({
          title: "",
          text: "Please enter the page name!",
          type: "warning",
        });
      }
    },
    cloneApiItem: function (id, arrApi) {
      if (arrApi && arrApi.length > 0) {
        var itemApi = $.extend(true, {}, arrApi.find((x) => x.id === id));
        itemApi.id = "bt-" + new Date().getTime() + this.index;
        this.index++;
        return itemApi;
      }
      return false;
    },
    cloneItemApi: function (id, idPar, arr) {
      var arrClone = [];
      var arrListChild = [];
      var dataChild = [];
      var itemClone = $.extend(true, {}, arr.find((x) => x.id === id));
      if (itemClone) {
        itemClone.id = "bt-" + new Date().getTime() + "" + this.index;
        dataChild = $.extend(true, [], itemClone.dataChild);
        if (idPar && idPar.length > 0) {
          itemClone.idPar = idPar;
        }
        this.index++;
        itemClone.dataChild = [];
        if (dataChild && dataChild.length > 0) {
          for (var i = 0; i < dataChild.length; i++) {
            arrListChild = this.cloneItemApi(dataChild[i], itemClone.id, arr);
            if (arrListChild && arrListChild.length > 0) {
              for (var j = 0; j < arrListChild.length; j++) {
                arrClone.push(arrListChild[j]);
              }
            }
          }
        }
        arrClone.push(itemClone);
      }
      return arrClone;
    },
    // END CLONE EVENT
    //////////////////

    /////////////
    // SHOW EVENT
    /////////////
    showEditorText: function (val) {
      this.posEditorText = val;
      this.statusEditorText = new Date().getTime();
    },
    showSettingForm: function () {
      this.statusEditorForm = new Date().getTime();
    },
    showManagerImage: function (val) {
      this.typeManagerImage = val.type;
      this.dataChangeImage = val.dataComp;
      $("#modal-upload-image-manager").css("z-index", "9999999999");
      $("#modal-upload-image-manager").modal("show");
    },
    showManagerHtml: function (val) {
      this.dataChangeHtml = val.dataComp;
      $("#modal-html-manager").modal("show");
    },
    showManagerSvg: function (val) {
      this.dataChangeSvg = val.dataComp;
      $("#modal-svg-manager").modal("show");
    },
    // END SHOW EVENT
    /////////////////

    //////////////
    // QUICK EVENT
    //////////////
    quickUpdate: function (val) {
      if (val && val.id) {
        this.$store.dispatch("editor/updateBlock", {
          val: val,
          type: "quickFuncSt",
        });
      }
    },
    quickDeleteBlock: function (val) {
      this.blockSelected = undefined;
      //vuex
      this.$store.commit("editor/setBlockSelected", undefined);
      if (!val) {
        val = this.$store.state.editor.actionEditor.value;
      }
      //vuex
      $(".block-widget.selected")
        .attr("style", "")
        .removeClass("selected");
      if (val && val.id) {
        var wg = $("#" + val.id);
        var item = wg.find(".block-widget");
        var arrDelete = [];
        arrDelete.push(val.id);
        if (item.length > 0) {
          item.each(function () {
            arrDelete.push($(this).attr("id"));
          });
        }
        var thisVue = this;
        var arr = [];
        for (var i = 0; i < this.api.length; i++) {
          if (arrDelete.indexOf(thisVue.api[i].id) === -1) {
            arr.push(this.api[i]);
          }
        }
        this.api = $.extend(true, [], arr);
        thisVue.listPage.find(function (n) {
          if (n && n.name === thisVue.pageSelect) {
            n.api = $.extend(true, [], thisVue.api);
          }
        });
        wg.remove();
        this.$forceUpdate();
        this.$store.commit("editor/setBlockSelected", undefined);
        this.$store.commit("editor/setApi", this.api);
        //this.$store.commit("editor/setListPage", this.listPage);
        setTimeout(function () {
          setStyle.init(thisVue.api, thisVue.device);
        }, 100);
      }
    },
    quickShowEditBlock: function (val) {
      if (val && val.id) {
        switch (val.componentName) {
          case "blockText":
            this.showEditorText(val);
            break;
          case "blockImage":
            this.showManagerImage({ type: "image", dataComp: val });
            break;
          case "blockHtml":
            this.showManagerHtml({ type: "html", dataComp: val });
            break;
          case "blockSvg":
            this.showManagerSvg({ type: "svg", dataComp: val });
            break;
          case "blockForm":
            this.showSettingForm();
            break;
          default:
            break;
        }
      }
    },
    quickDuplicateBlock: function () {
      let idBlockSl = this.$store.state.editor.actionEditor.value.id;
      let val = $(".block-widget.selected");
      if (!this.device || this.device.length <= 0) {
        this.device = "mobile";
      }
      if (idBlockSl || (val && val.length > 0)) {
        const thisVue = this;
        let id = idBlockSl || val.attr("id");
        let index = this.api.findIndex((x) => x.id === id);
        let wgItem = $("#" + id).find(".block-widget");
        let dataItem = this.$store.state.editor.actionEditor.value || this.api.find((x) => x.id === id) || {};
        let idClone = "bt-" + new Date().getTime();
        let dataClone = $.extend(
          true,
          {},
          dataItem
        );
        function convertCss(dataItem, idItem, dataClone) {
          dataClone.cssAdvance = utils.replaceAllText(
            dataItem.cssAdvance,
            dataItem.id,
            idItem
          );
          if (dataClone.mobile) {
            dataClone.mobile.cssAdvance = utils.replaceAllText(
              dataItem.mobile.cssAdvance,
              dataItem.id,
              idItem
            );
          }
          if (dataClone.tablet) {
            dataClone.tablet.cssAdvance = utils.replaceAllText(
              dataItem.tablet.cssAdvance,
              dataItem.id,
              idItem
            );
          }
          if (dataClone.desktop) {
            dataClone.desktop.cssAdvance = utils.replaceAllText(
              dataItem.desktop.cssAdvance,
              dataItem.id,
              idItem
            );
          }
        }
        convertCss(dataItem, idClone, dataClone);

        dataClone.id = idClone;
        if (
          dataClone.componentName != "blockColumnOnly" &&
          dataClone.componentName != "blockList"
        ) {
          dataClone[this.device].top =
            parseFloat(dataClone[this.device].top) +
            parseFloat(dataClone[this.device].height) +
            10 +
            "px";
        }
        this.api.splice(index, 0, dataClone);
        renderBlockEditor.renderOnceBlock(dataClone, thisVue);
        $("#" + dataClone.id).insertAfter($("#" + id));
        if (wgItem && wgItem.length > 0) {
          wgItem.each(function (i) {
            let dataItemApi = thisVue.api.find(x => x.id === $(this).attr("id")) || {};
            let dataItem = $.extend(
              true,
              {},
              dataItemApi
            );
            let idItem = "bt-" + new Date().getTime() + "" + i;

            dataItem.id = idItem;
            convertCss(dataItemApi, idItem, dataItem);
            if (
              dataClone.componentName === "blockColumnOnly" ||
              dataClone.componentName === "blockList"
            ) {
              dataItem.idPar = dataClone.id;
            }
            thisVue.api.splice((index + 1), 0, dataItem);
            renderBlockEditor.renderOnceBlock(dataItem, thisVue);
          });
        }
        this.listPage.find(function (x) {
          if (x.name === thisVue.pageSelect) {
            x.api = $.extend(true, [], thisVue.api);
          }
        });
        this.$store.commit("editor/setApi", this.api);
        //this.$store.commit("editor/setListPage", this.listPage);
        selectedBlock.init(this);
        $(".popup-menu-api").css("display", "none");
      }
    },
    quickMoveDownBlock: function (val) {
      var thisVue = this;
      if (val && val.idPar) {
        $("#" + val.id).prependTo($("#" + val.idPar + " > .block-content"));
        thisVue.resetIndexElement();
        thisVue.updateListPage(thisVue.pageSelect);
        return;
      }
      if (val && !val.idPar) {
        var item = $("#" + val.id);
        var itemNext = $("#" + val.id)[0].nextSibling;
        if (itemNext) {
          item.insertAfter(itemNext);
          this.resetIndexElement();
        }
        return;
      }
    },
    quickMoveUpBlock: function (val) {
      var thisVue = this;
      if (val && val.idPar) {
        $("#" + val.id).appendTo($("#" + val.idPar + " > .block-content"));
        thisVue.resetIndexElement();
        thisVue.updateListPage(thisVue.pageSelect);
        return;
      }
      if (val && !val.idPar) {
        var item = $("#" + val.id);
        var itemPrev = $("#" + val.id)[0].previousSibling;
        if (itemPrev) {
          item.insertBefore(itemPrev);
          this.resetIndexElement();
        }
        return;
      }

    },
    quickGetTemplateSave: function () {
      if (!this.valueSaveTemplate.source) {
        this.valueSaveTemplate.source = {};
      }
      if (this.blockSelected && this.blockSelected.id) {
        var arr = [];
        var thisVue = this;
        arr.push(
          $.extend(
            true,
            {},
            this.api.find((x) => x.id === this.blockSelected.id)
          )
        );
        this.api.find(function (x) {
          if (x.idPar === thisVue.blockSelected.id) {
            arr.push(x);
          }
        });
        this.valueSaveTemplate.source.api = $.extend(true, [], arr);
        this.valueSaveTemplate.source.componentName = this.blockSelected.componentName;
        this.valueSaveTemplate.type = "section";
      } else {
        this.valueSaveTemplate.source.style = {
          background: this.backgroundPage,
        };
        this.valueSaveTemplate.source.api = $.extend(true, [], this.api);
        this.valueSaveTemplate.source.componentName = "page";
        this.valueSaveTemplate.type = "page";
      }
      this.time_update = new Date().getTime();
      $("#save-template").modal("show");
      $(".left-bar-item.templates.active").removeClass("active");
    },
    saveQuickFuncPosition: function (position) {
      this.quickFuncPosition = position;
    },
    // END QUICK EVENT
    //////////////////

    ///////////////
    // UPDATE EVENT
    ///////////////
    updateDataApp: function (data) {
      var thisVue = this;
      editorSV.createOrUpdateDataApp(
        JSON.stringify({ page_use: this.$route.params.id, data: data }),
        function (dataS) {
          console.log("responive data sheet::: ", dataS);
          if (dataS && dataS.code === 200) {
            thisVue.dataApp = $.extend(true, {}, data);
            $dataApp = $.extend(true, {}, data);

            thisVue.$store.commit("editor/setDataApp", data);
          } else {
            console.log("error");
          }
        }
      );
    },
    updateBgPage: function (val) {
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          if (!x.style) {
            x.style = {};
            x.style.background = {};
          }
          x.style.background = $.extend(true, {}, val);
          thisVue.backgroundPage = $.extend(true, {}, val);
          thisVue.time_update = new Date().getTime();

          // VUEX
          //thisVue.$store.commit("editor/setListPage", thisVue.listPage);
          thisVue.$store.commit("editor/setBackgroundPage", val);
          thisVue.$store.commit("editor/setTimeUpdate", new Date().getTime());
          // VUEX

          setStyle.setStypePage(x.style);
          if (thisVue.changeBlock) {
            thisVue.blockNew = $.extend(true, {}, val);
            history.saveBlockChange(thisVue);
          }
        }
      });
    },
    updateBgImagePageUrl: function (val) {
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          if (!x.style) {
            x.style = {};
            x.style.background = {};
          }
          x.style.background.bgImageUrl = val;
          thisVue.backgroundPage = $.extend(true, {}, x.style.background);
          setStyle.setStypePage(x.style);
          thisVue.updateBgPage(thisVue.backgroundPage);
        }
      });
    },
    updateListPage: function (pagesl) {
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === pagesl) {
          x.api.length = 0;
          x.api = $.extend(true, [], thisVue.api);
        }
      });
      //this.$store.commit('editor/setApi', this.api);
      this.$store.commit("editor/setListPage", this.listPage);
    },
    updateModel: function (val) {
      this.listModel = val;
      $listModel = val;
      this.saveSource();
    },
    updateListApiTemplate: function (val) {
      this.listApiTemplate = val;
    },
    updateSettingPage: function (val) {
      if (val.type === "true") {
        this.settingPage = val.val;
        this.settingPage.show = "true";
        this.statusSettingpage = new Date().getTime();
        $("#modal-setting-page").modal("show");
      } else {
        this.settingPage = val;
      }
    },
    updateblock: function (val) {
      var thisVue = this;
      if (
        this.blockSelected &&
        // this.blockSelected.data &&
        val &&
        // val.data &&
        this.blockSelected.id === val.id
      ) {
        this.updateDataComp(val);
        setStyle.setStypeItem(val, thisVue.device);
      }
    },
    updatePreview: function (status) {
      this.preview = status;
    },
    updateDataComp: function (val) {
      if (val && this.blockSelected && val.id === this.blockSelected.id) {
        var thisVue = this;
        this.api = this.$store.state.editor.api;
        var index = this.api.findIndex((k) => k.id === val.id);
        this.api[index] = $.extend(true, this.api[index], val);
        this.api[index].time_update = new Date().getTime();
        this.blockSelected = $.extend(true, {}, this.api[index]);

        // vuex
        this.$store.commit("editor/setApi", this.api);
        this.$store.commit(
          "editor/setBlockSelected",
          $.extend(true, {}, this.api[index])
        );
        // vuex

        if (this.changeBlock) {
          this.blockNew = $.extend(true, {}, this.blockSelected);
          history.saveBlockChange(this);
        }

        this.updateListPage(this.pageSelect);
        setStyle.setStypeItem(val, thisVue.device);
        this.$forceUpdate();
        this.time_update = this.api[index].time_update;

        // vuex
        this.$store.commit("editor/setTimeUpdate", this.api[index].time_update);
        // vuex
      } else {
        var thisVue = this;
        var index = this.api.findIndex((k) => k.id === val.id);
        this.api[index] = $.extend(true, this.api[index], val);
        this.api[index].time_update = new Date().getTime();

        // vuex
        this.$store.commit("editor/setApi", this.api);
        // vuex

        if (this.changeBlock) {
          this.blockNew = $.extend(true, {}, this.blockSelected);
          history.saveBlockChange(this);
        }

        this.updateListPage(this.pageSelect);
        setStyle.setStypeItem(val, thisVue.device);
        this.$forceUpdate();
        this.time_update = this.api[index].time_update;

        // vuex
        this.$store.commit("editor/setTimeUpdate", this.api[index].time_update);
      }
    },
    updateDataCompAction: function (val) {
      this.updateDataComp(val);
      this.statusAttribute = new Date().getTime();
    },
    updateDataPageAction: function (val) {
      var thisVue = this;
      this.page_action = $.extend(true, [], val);
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          x.page_action = $.extend(true, [], val);
        }
      });
      this.$store.commit("editor/setListPage", this.listPage);
      this.$store.commit("editor/setPageAction", val);
    },
    // END UPDATE EVENT
    ///////////////////
    ////////////
    // INIT DATA
    ////////////
    getDataPage() {
      const data = {};
      async.waterfall(
        [
          (next) => {
            // Get all page data source
            API.app.page(
              {
                id: this.$route.params.id,
              },
              (e, r) => {
                data.page = r;
                next();
              }
            );
          },
          (next) => {
            if (!data.page || !data.page.data || !data.page.data.source) {
              this.api = [];
              this.pageSelect = "home";
              this.listPage = [{ name: "home", api: [] }];
              this.listModel = constant._model.variable_default;
              $listModel = constant._model.variable_default;
              this.page_action = [];

              // vuex
              this.$store.commit("editor/setApi", []);
              this.$store.commit("editor/setPageSelect", "home");
              this.$store.commit("editor/setListPage", [
                { name: "home", api: [] },
              ]);
              this.$store.commit(
                "editor/setListModel",
                constant._model.variable_default
              );
              this.$store.commit("editor/setPageAction", []);
              // vuex

              renderBlockEditor.init(this.api, this);
            } else {
              this.dataPage = data.page.data;
              if (this.dataPage.ref_template || this.dataPage.ref_page) {
                let completeGuide = JSON.parse(
                  localStorage.getItem("completeGuide")
                );
                let isCompleteGuide =
                  completeGuide &&
                  completeGuide.find((item) => item === this.dataPage.id);
                if (!isCompleteGuide) {
                  setTimeout(() => {
                    $("#model-new-clone-page").modal({
                      backdrop: "static",
                      keyboard: false,
                    });
                  }, 500);
                }
              }
              this.version = data.page.data.source.v;
              var arrTmp = [];
              var lengTmp = data.page.data.source.listPage.length;
              for (var v = 0; v < lengTmp; v++) {
                if (data.page.data.source.listPage[v].name.search(" ") >= 0) {

                } else {
                  arrTmp.push(data.page.data.source.listPage[v]);
                }
              }
              this.listPage = arrTmp;
              this.settingPage = data.page.data.source.settingPage;
              this.domain = data.page.data.domain;
              this.listModel =
                data.page.data.source.listModel ||
                constant._model.variable_default;
              $listModel =
                data.page.data.source.listModel ||
                constant._model.variable_default;
              var thisVue = this;
              if (this.listPage && this.listPage.length > 0) {
                this.listPage.find((x) => {
                  if (x.name === thisVue.pageSelect) {
                    thisVue.changePage(x, thisVue.listPage);
                  }
                });
              }

              // // vuex
              this.$store.commit("editor/setListPage", this.listPage);
              this.$store.commit(
                "editor/setSettingPage",
                data.page.data.source.settingPage
              );
              this.$store.commit("editor/setDomain", data.page.data.domain);
              this.$store.commit(
                "editor/setListModel",
                data.page.data.source.listModel ||
                constant._model.variable_default
              );
              // // vuex
            }

            next();
          },
          (next) => {
            if (data && data.page && data.page.data) {
              localStorage.setItem("package_type", data.page.data.package_type);
            }

            this.infoApp.category = data.page.data.category;
            this.infoApp.name = data.page.data.name;
            this.infoApp.type = data.page.data.type;
            if (data.page.data.description) {
              if (
                data.page.data.description.charAt(0) === "{" ||
                data.page.data.description.charAt(0) === "["
              ) {
                this.infoApp.description = JSON.parse(
                  data.page.data.description
                );
              } else {
                this.infoApp.description = data.page.data.description;
              }
            }

            // vuex
            this.$store.commit("editor/setInfoApp", this.infoApp);
            // vuex

            //this.sortBlockColumList();

            setTimeout(() => {
              $("#hellopreloader").hide();
              $("#loading-component").hide();
              $(".modal-backdrop").remove();
            }, 3000);

            next();
          },
        ],
        (e, r) => { }
      );
    },
    getInfoMe() {
      API.app.me(null, (e, r) => {
        if (!r || r.code !== 200) return;
        localStorage.setItem("user", JSON.stringify(r.data));
      });
    },
    getDataApp() {
      API.public.dataAppReadPublic(
        { page_use: this.$route.params.id },
        (e, r) => {
          if (
            !r ||
            r.code !== 200 ||
            !r.data ||
            r.data.length <= 0 ||
            !r.data[0].data
          )
            return;
          this.dataApp = r.data[0].data;
          $dataApp = r.data[0].data;
          // vuex
          this.$store.commit("editor/setDataApp", r.data[0].data);
          // vuex
        }
      );
    },
    getAllApi() {
      var thisVue = this;
      API.app.api({ page: thisVue.$route.params.id }, (e, r) => {
        this.listApiTemplate = r.data;

        //vuex
        this.$store.commit("editor/setListApiTemplate", r.data);
        //vuex
      });
    },
    // END INIT DATA
    /////////////////
    editTracking: function (item) {
      this.itemTrackingSelect = item;
      $("#modal-setting-page").modal("hide");
      $("#modal-tracking").modal("show");
    },
    editFunction: function (item) {
      this.itemFuncSelect = item;
      $("#modal-setting-page").modal("hide");
      $("#modal-function").modal("show");
    },
    showAddNewPage: function () {
      $("#show-modal-add-screen").modal("show");
    }
  },
  mounted: function () {
    console.log("hình như chạy vô đây");
    const thisVue = this;

    localStorage.setItem("pageId", this.$route.params.id);
    localStorage.removeItem("botup-model");

    ////////////
    // INIT DATA
    ////////////
    this.getInfoMe();
    this.getDataApp();
    this.getAllApi();
    this.getDataPage();
    // END INIT DATA
    ////////////////

    /////////////
    // INIT EVENT
    /////////////
    // dropblock.init(thisVue);
    setStyle.init(thisVue.api, thisVue.device);

    groupBlock.init(thisVue);
    // END INIT EVENT
    /////////////////
    // var htmlIframe = `
    //         <meta charset="utf-8">
    //         <meta http-equiv="X-UA-Compatible" content="IE=edge">
    //         <meta name="viewport" content="width=device-width,initial-scale=1.0">
    //         <link href="https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,600,700,800|Nunito|Lato|Lora|Segoe+UI+Symbol|Alfa+Slab+One|Mulish|Poppins|Raleway|Roboto|Arial|Amatic+SC|Anton|Bahianita|Baloo+Bhaina|Baloo+Chettan|Bangers|Bungee|Bungee+Hairline|Bungee+Inline|Bungee+Outline|Bungee+Shade|Cabin|Charm|Charmonman|Chonburi|Comfortaa|Cousine|Cuprum|Dancing+Script|Dosis|Farsan|Fira+Sans+Condensed|Francois+One|Grenze|Inconsolata|Josefin+Sans|K2D|Kanit|Lemonada|Lobster|M+PLUS+1p|Major+Mono+Display|Montserrat|Noto+Sans|Nunito|Open+Sans|Oswald|Pacifico|Pangolin|Patrick+Hand|Patrick+Hand+SC|Pattaya|Play|Playfair+Display|Playfair+Display+SC|Quicksand|Roboto|Saira+Stencil+One|Sedgwick+Ave+Display|Sigmar+One|Sriracha|Srisakdi|Thasadith|VT323&display=swap" rel="stylesheet">
    //         <link rel="stylesheet" href="https://appon.vn/app/css/animate.min.css">
    //         <style>`;
    //     htmlIframe += cssDefault.init();    
    //     htmlIframe += `</style>`
    // $("head").html(htmlIframe);
    shortKey.init(thisVue);
  },
};
