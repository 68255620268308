import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";

export default {
    components: {
        ColorPicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            idSL: "",
            device: "",
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},
            listBorderStyle: ["none", "dotted", "dashed", "solid"],
            borderWidth: "0",
            borderColor: "#000000",
            borderType: "inside",
            borderStyle: "solid",
            isGraPicker: false,
            backgroundColor: "#00000000",
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            padding: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            focusBd: false,
            focusPd: false,

        };
    },
    computed: {
        borderTypeName() {
            let name = this.borderType.charAt(0).toUpperCase() + this.borderType.substring(1);
            return name;
        },
        borderStyleName() {
            let name = this.borderStyle.charAt(0).toUpperCase() + this.borderStyle.substring(1);
            return name;
        },
        isAbleStBorder() {
            if (this.componentName === "blockInput" || this.componentName === "blockTextarea") return true;
        },
        blockDisplayName() {
            switch (this.componentName) {
                case "blockInput":
                    return "Input";
                case "blockTextarea":
                    return "Textarea";
                case "blockRadio":
                    return "Radio button";
                case "blockCheckbox":
                    return "Checkbox button";
                case "blockSelect":
                    return "Dropdown";
                default:
                    return "Input";
            }
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (
                val &&
                val.id !== this.idSL &&
                (val.componentName === "blockInput" ||
                    val.componentName === "blockTextarea" ||
                    val.componentName === "blockRadio" ||
                    val.componentName === "blockCheckbox" ||
                    val.componentName === "blockSelect")
            ) {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;

                this.getInitialData();
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device": function (val) {
            this.device = val;
        },
        borderWidth: _.debounce(function () {
            if (this.focusBd == true) {
                if (this.borderWidth < 0) {
                    this.borderWidth = 0;
                }
                if (!this.block.border) {
                    this.block.border = {};
                }
                this.block.border.borderWidth = this.borderWidth + "px";
                this.block.border.borderTop = this.borderWidth + "px";
                this.block.border.borderBottom = this.borderWidth + "px";
                this.block.border.borderLeft = this.borderWidth + "px";
                this.block.border.borderRight = this.borderWidth + "px";
                this.borderBottom = this.borderWidth;
                this.borderTop = this.borderWidth;
                this.borderLeft = this.borderWidth;
                this.borderRight = this.borderWidth;
                this.updateBlock(this.block, "borderSt");
            }
        }, 300),
        borderTop: _.debounce(function () {
            if (this.borderTop < 0) {
                this.borderTop = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            this.block.border.borderTop = this.borderTop + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300),
        borderLeft: _.debounce(function () {
            if (this.borderLeft < 0) {
                this.borderLeft = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            this.block.border.borderLeft = this.borderLeft + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300),
        borderRight: _.debounce(function () {
            if (this.borderRight < 0) {
                this.borderRight = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            this.block.border.borderRight = this.borderRight + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300),
        borderBottom: _.debounce(function () {
            if (this.borderBottom < 0) {
                this.borderBottom = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            this.block.border.borderBottom = this.borderBottom + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300),
        padding: _.debounce(function () {
            if (this.focusPd == true) {
                if (this.padding < 0 || !this.padding) {
                    this.padding = 0;
                }
                if (!this.block.padding) {
                    this.block.padding = {};
                }
                this.block.padding.padding = this.padding + "px";
                this.block.padding.paddingBottom = this.padding + "px";
                this.block.padding.paddingLeft = this.padding + "px";
                this.block.padding.paddingRight = this.padding + "px";
                this.block.padding.paddingTop = this.padding + "px";
                this.paddingBottom = this.padding;
                this.paddingLeft = this.padding;
                this.paddingRight = this.padding;
                this.paddingTop = this.padding;
                this.updateBlock(this.block, "paddingSt");
            }
        }, 300),
        paddingBottom: _.debounce(function () {
            if (this.paddingBottom < 0) {
                this.paddingBottom = 0;
            }
            if (!this.block.padding) {
                this.block.padding = {};
            }
            this.block.padding.paddingBottom = this.paddingBottom + "px";
            this.updateBlock(this.block, "paddingSt");
        }, 300),
        paddingLeft: _.debounce(function () {
            if (this.paddingLeft < 0) {
                this.paddingLeft = 0;
            }
            if (!this.block.padding) {
                this.block.padding = {};
            }
            this.block.padding.paddingLeft = this.paddingLeft + "px";
            this.updateBlock(this.block, "paddingSt");
        }, 300),
        paddingRight: _.debounce(function () {
            if (this.paddingRight < 0) {
                this.paddingRight = 0;
            }
            if (!this.block.padding) {
                this.block.padding = {};
            }
            this.block.padding.paddingRight = this.paddingRight + "px";
            this.updateBlock(this.block, "paddingSt");
        }, 300),
        paddingTop: _.debounce(function () {
            if (this.paddingTop < 0) {
                this.paddingTop = 0;
            }
            if (!this.block.padding) {
                this.block.padding = {};
            }
            this.block.padding.paddingTop = this.paddingTop + "px";
            this.updateBlock(this.block, "paddingSt");
        }, 300),
    },
    methods: {
        updateColor(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].background = {};
            } else {
                if (!this.block[this.device].background) {
                    this.block[this.device].background = {};
                }
            }

            this.backgroundColor = color;
            this.block[this.device].background.isGraPicker = false;
            this.block[this.device].background.backgroundColor = color;
            this.backgroundData = this.block[this.device].background;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBorderColor(color) {
            if (!this.block.border) {
                this.block.border = {};
            }

            this.borderColor = color;
            this.block.border.borderColor = color;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderType(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            this.borderType = val;
            this.block.border.borderType = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderStyle(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderStyle = val;
            this.block.border.borderStyle = val;
            this.block[this.device].border.borderStyle = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateColorEffect(color) {
            if (!this.effectProperty.boxShadow) {
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
            this.effectProperty.boxShadow[4] = color;
            this.updateEffectConfig();
        },
        updateEffectConfig() {
            let boxShadow = "";
            let blur = "";
            let boxShadowEffect = this.effectProperty.boxShadow;

            if (boxShadowEffect) {
                this.effectProperty.boxShadow.forEach((item, index) => {
                    if (index === 4) {
                        if (!item) {
                            item = "#000000";
                        }
                        boxShadow += item;
                        return;
                    }
                    if (!boxShadowEffect[index]) {
                        boxShadowEffect[index] = 0;
                        item = 0;
                    }
                    boxShadow += item + "px ";
                });
            }
            if (this.effectProperty.blur) {
                blur = this.effectProperty.blur + "px";
            }
            this.effectBlock = {
                boxShadow,
                blur
            };

            this.block[this.device].effect = this.effectBlock;
            this.updateBlock(this.block, "effectSt");
        },
        generateListEffect() {
            this.listEffectBlock = [];
            let convertEffectProperty = (item) => {
                if (item === "blur") {
                    this.effectProperty.blur = parseFloat(this.effectBlock.blur);
                    return;
                }
                let propertyArray = this.effectBlock.boxShadow.split(" ");
                this.effectProperty.boxShadow = propertyArray.map((i, ind) => {
                    if (ind === 4) return i;
                    return parseFloat(i);
                });
            };
            for (let item in this.effectBlock) {
                if (this.effectBlock[item]) {
                    this.listEffectBlock.push(item);
                    convertEffectProperty(item);
                }
            }
        },
        updateBlock(val, type) {
            this.block = val;
            if (type === "borderSt" && !val.border.borderStyle) {
                val.border.borderStyle = "solid";
                val[this.device].border.borderStyle = "solid";
            }
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: type });
        },
        getInitialData() {
            if (!this.device) {
                this.device = "mobile";
            }
            if (!this.block[this.device]) {
                this.block[this.device] = {};
            }
            let backgroundData = this.block[this.device || "mobile"] ? this.block[this.device].background : {};
            let borderData = this.block.border || {};
            let item = $(`#${this.block.id} .block-content`);
            let padding = this.block.padding || {};

            this.borderColor = borderData.borderColor;
            if (!this.borderColor) {
                let borderColor = item.css("border-color");
                this.borderColor = borderColor || "#000000";
            }
            this.borderWidth = borderData.borderWidth && parseFloat(borderData.borderWidth);
            if (!this.borderWidth) {
                let borderWidth = item.css("border-width") || "0";
                this.borderWidth = parseFloat(borderWidth);
            }
            this.borderType = borderData.borderType || "inside";
            this.borderStyle = borderData.borderStyle || "solid";
            this.backgroundColor = backgroundData.backgroundColor || "#00000000";
            this.isGraPicker = false;
            this.effectBlock = this.block[this.device].effect || { boxShadow: "", blur: "" };
            this.generateListEffect();
            this.borderTop = Math.round(parseFloat(borderData.borderTop)) || Math.round(parseFloat(borderData.borderWidth)) || 0;
            this.borderLeft = Math.round(parseFloat(borderData.borderLeft)) || Math.round(parseFloat(borderData.borderWidth)) || 0;
            this.borderRight = Math.round(parseFloat(borderData.borderRight)) || Math.round(parseFloat(borderData.borderWidth)) || 0;
            this.borderBottom = Math.round(parseFloat(borderData.borderBottom)) || Math.round(parseFloat(borderData.borderWidth)) || 0;

            //padding
            this.paddingBottom = Math.round(parseFloat(padding.paddingBottom)) || Math.round(parseFloat(padding.padding)) || 0;
            this.paddingLeft = Math.round(parseFloat(padding.paddingLeft)) || Math.round(parseFloat(padding.padding)) || 0;
            this.paddingRight = Math.round(parseFloat(padding.paddingRight)) || Math.round(parseFloat(padding.padding)) || 0;
            this.paddingTop = Math.round(parseFloat(padding.paddingTop)) || Math.round(parseFloat(padding.padding)) || 0;

        },
        handleClickRemove(type, item) {
            if (type === "effect") {
                this.listEffectBlock = this.listEffectBlock.filter((i) => i !== item);
                this.effectProperty[item] = "";
                this.updateEffectConfig();
            }
        },
        handleClickAdd(type, item) {
            if (type === "effect") {
                if (this.listEffectBlock.length === 2) return;
                if (this.listEffectBlock.includes("boxShadow")) {
                    this.listEffectBlock.push("blur");
                    this.effectProperty.blur = 0;
                    return;
                }
                this.listEffectBlock.push("boxShadow");
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let leftPosition = event.target.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;
            let selector;
            let topSet;
            let leftSet;
            switch (nameSection) {
                case "fold":
                    if ($("#input-st").hasClass("fold-section")) {
                        $("#input-st").removeClass("fold-section");
                    } else {
                        $("#input-st").addClass("fold-section");
                    }
                    break;
                case "effect":
                    $(`#input-st .effect-input .effect:nth-child(${index + 1}) .list-option`).toggle();

                    if (item === val || this.listEffectBlock.includes(val) || !val) return;
                    this.listEffectBlock = this.listEffectBlock.map((i) => {
                        if (i === item) {
                            this.effectProperty[item] = "";
                            if (val === "blur") {
                                this.effectProperty.blur = 0;
                            } else {
                                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
                            }
                            return val;
                        }
                        return i;
                    });
                    break;
                case "edit-effect":
                    selector = `#input-st .effect-input .effect:nth-child(${index + 1})`;
                    if ($(selector).hasClass("popup-show")) {
                        $(selector).removeClass("popup-show");
                        $(selector + " .edit-popup").hide();
                        this.updateEffectConfig();
                        return;
                    } else {
                        $(selector).addClass("popup-show");
                        $(selector + " .edit-popup").show();
                    }

                    $(".edit-popup").removeClass("no-after");
                    if (windowHeight < topPosition + 290 && item === "boxShadow") {
                        topSet = windowHeight - 300;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else if (windowHeight < topPosition + 100 && item === "blur") {
                        topSet = windowHeight - 110;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else {
                        topSet = topPosition - 7;
                        leftSet = leftPosition - 10;
                    }
                    $(".edit-popup").css({
                        top: `${topSet}px`,
                        left: `${leftSet}px`
                    });
                    break;
                case "border":
                    $("#input-st .style-border .list-option")
                        .eq(0)
                        .toggle();

                    if (val) {
                        this.updateBorderType(val);
                    }
                    break;
                case "border-style":
                    $("#input-st .style-border .list-option")
                        .eq(1)
                        .toggle();

                    if (val) {
                        this.updateBorderStyle(val);
                    }
                    break;
            }
        },
        closeSt: function (event) {
            $("#input-st").parent().hide();
        }
    },
    mounted() {
        this.blockSelected = this.$store.state.editor.blockSelected;
        this.device = this.$store.state.editor.device;
        if (this.blockSelected && this.blockSelected[this.device] && this.blockSelected[this.device].background) {
            this.block = this.blockSelected;
            this.getInitialData();
        }
    }
};
