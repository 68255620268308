import { historyService } from "./history.service.js";
export default {
    data: function () {
        return {
            listHistory: [],
            itemHist: {}
        }
    },
    methods: {
        getListHistory: function() {
            var thisVue = this;
            var idPage = this.$route.params.id;
            historyService.getAllHistory(JSON.stringify({page: idPage}), function(data) {
                if (data && data.code == 200) {
                    thisVue.listHistory = data.data;
                } else {
                    thisVue.listHistory = [];
                }
            })
        },
        usingHistory: function(item) {
            var thisVue = this;
            this.$swal({
                title: "",
                text: "Bạn muốn sử dụng bản này??? ",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Sử dụng"
            }).then((result) => {
                if (result.value) {
                    historyService.getSource(JSON.stringify({page: item.page, id: item.id}), function(data) {
                        if (data && data.code == 200) {
                            thisVue.$emit("changeSourceHistory", data.data.source);
                            $("#history-app").modal("hide");
                        }
                    })
                }
            });
        }
    },
    mounted: function () {
        var thisVue = this;
        $("#history-app").on("show.bs.modal", function() {
                thisVue.getListHistory();
        })
    }
}