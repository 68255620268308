export default {
  components: {},
  data: function() {
    return {
      listPage: [],
      listApi: [],
      pageSl: "home",
      indexPage: 0,
      search: "",
      apiSl: "",
      device: "",
      itemBlockSl: "",
      itemPageSl: ""
    };
  },
  watch: {
    "$store.state.editor.listPage": function() {
      this.updateApi();
    },
    "$store.state.editor.pageSelect": function(val) {
      $(`#${this.pageSl} img`).css({ transform: "rotate(0deg)" });
      this.pageSl = val;
      this.updateApi();
    },
    "$store.state.editor.blockSelected": function(val) {
      if(!val) {
        this.apiSl = "";
        return;
      }
      if(val.id === this.apiSl) {
        return;
      }
      if(val.id !== this.apiSl) {
        this.apiSl = val.id;
      }
    },
    "$store.state.editor.api":function(val) {
      this.listApi = val;
    },
    "$store.state.editor.device"(val) {
      if(val) {
        this.device = val;
      }
  },
  },
  methods: {
    computedNameBlock:function(item) {
      let name = "";
      name = item.name || item.componentName;
      name += " #" + item.id;
      return name;
    },
    renderIconClass:function(item) {
      if(item) {
        let choose = item.componentName;
        switch(choose) {
          case 'blockText':
            return "icon-block-text";
            case 'blockColumnOnly':
            return "icon-block-column";
            case 'blockTextarea':
            return "icon-block-text";
            case 'blockButton':
            return "icon-block-button";
            case 'blockList':
            return "icon-block-list";
            case 'blockSelect':
            return "icon-block-select";
            case 'blockSvg':
            return "icon-block-svg";
            case 'blockVideo':
            return "icon-block-video";
            case 'blockCheckbox':
            return "icon-block-checkbox";
            case 'blockRadio':
            return "icon-block-radio";
            case 'blockInput':
            return "icon-block-input";
            case 'blockHtml':
            return "icon-block-html";
            case 'blockImage':
            return "icon-block-image";
            case 'blockCountdown':
            return "icon-block-countdown";
        }
      }
    },
    updateApi: function() {
      var thisVue = this;
      this.listPage = this.$store.state.editor.listPage;
      let listApi = this.listPage.find((x) => x.name === this.pageSl);
      if (listApi && listApi.api && listApi.api.length > 0) {
        listApi.api.forEach((e,index) => {
          let selector = `#screens-manager .item-list-api:nth-child(${index + 1}) .icon-right .image-eye`;
          if(e && e[this.device] && e[this.device].displayBlock === "hide") {
          setTimeout(function() {
            $(`#screens-manager .item-list-api:nth-child(${Number(index) + 1}) .icon-right .icon-div`).css("display", "block");
            $(selector).addClass("image-hide-eye");
          })
          }
        })
        this.listApi = listApi.api;
      }
      setTimeout(function() {
        $(".list-component-screen").insertAfter($("#" + thisVue.pageSl));
        $(`#${thisVue.pageSl} img`).css({ transform: "rotate(90deg)" });
      });
    },
    showModalAddScreen: function() {
      $("#show-modal-add-screen").modal("show");
    },
    selectPage: function(item) {
      let nameP = item.name;
      if(nameP === this.pageSl) {
        if (
          $("#" + nameP).hasClass("active") &&
          $(".list-component-screen").css("display") != "none"
        ) {
          $(".list-component-screen").hide();
          $(`#${nameP} img`).css({ transform: "rotate(0deg)" });
        } else {
          $(".list-component-screen").show();
          $(`#${this.pageSl} img`).css({ transform: "rotate(0deg)" });
          $(`#${nameP} img`).css({ transform: "rotate(90deg)" });
        }
        return;
      }
      this.listApi = this.listPage.find((x) => x.name === nameP).api;
      $(".list-component-screen").insertAfter($("#" + nameP));
      if (
        $("#" + nameP).hasClass("active") &&
        $(".list-component-screen").css("display") != "none"
      ) {
        $(".list-component-screen").hide();
        $(`#${nameP} img`).css({ transform: "rotate(0deg)" });
      } else {
        $(".list-component-screen").show();
        $(`#${this.pageSl} img`).css({ transform: "rotate(0deg)" });
        $(`#${nameP} img`).css({ transform: "rotate(90deg)" });
      }
      this.pageSl = nameP;
      this.$store.commit("editor/setActionEditor", {
        type: "changePage",
        value: item,
      });
    },
    clonePage: function() {
      $(".popup-menu-home").css("display", "none");
      $("#clone-page").modal("show");
    },
    deletePage: function(index) {
      $(".popup-menu-home").css("display", "none");
      var thisVue = this;
      this.$swal({
        title: "",
        text: "You won't be able to revert this page.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.value) {
          thisVue.$store.commit("editor/setActionEditor", {
            type: "removePage",
            value: index,
          });
        }
      });
    },
    duplicateBlock: function(item) {
      let thisVue = this;
      if (!item) return;
      thisVue.$store.commit("editor/setActionEditor", {
        type: "duplicateBlock",
        value: item,
      });
      $(".popup-menu-api").css("display", "none");
    },
    deleteBlock: function(item) {
      $(".popup-menu-api").css("display", "none");
      let thisVue = this;
      if (!item) return;
      this.$swal({
        title: "",
        text: "You won't be able to revert this block.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.value) {
          thisVue.$store.commit("editor/setActionEditor", {
            type: "removeBlock",
            value: item,
          });
        }
      });
      
    },
    hoverItemListApi: function(id) {
      $(`#${id}`).css({ outline: "2px solid #2F80ED" });
    },
    unHoverItemListApi: function(id) {
      $(`#${id}`).css({ outline: "" });
    },
    selectItemListApi: function(item) {
      var thisVue = this;
      thisVue.apiSl = item.id;
      var wgSl = $("#" + item.id);
      wgSl.trigger("click");
      if (thisVue.blockSelected && thisVue.blockSelected.componentName && thisVue.blockSelected.componentName === "blockLightBox") {
        console.log("chạy vô đây");
        $('.block-widget.drop-column[bt-type!="blockLightBox"]').hide();
        $("#" + thisVue.blockSelected.id).show();
        wgSl.trigger("click");
    }
    },
    clearInput: function() {
      this.search = "";
    },
  },
  mounted: function() {
    this.listPage = this.$store.state.editor.listPage;
    this.listApi = this.listPage[0].api;
    this.device = this.$store.state.editor.device;
    setTimeout(function() {
      $(".list-component-screen").insertAfter($("#home"));
      $(`#screens-manager .active img`).css({ transform: "rotate(90deg)" });
    });
  },
};
