export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            dataValidateAction: [],
            timeShowAction: 0
        };
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataValidateAction = val[this.indexAction];
            }
        },
        "$store.state.editor.dataActionSelect"(val) {
            if (this.timeShowAction !== val.timeShowAction) return;
            this.dataValidateAction.data = val.listItemValidate;
            this.updateData();
        }
    },
    methods: {
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataValidateAction;
            this.$emit("updateBlock", dataAction);
        },
        openModalConfigValidate() {
            const timeShowAction = new Date().getTime();
            const listItemValidate = this.dataValidateAction.data || [];
            this.timeShowAction = timeShowAction;

            const data = {
                timeShowAction,
                listItemValidate
            };
            this.$store.commit("editor/mutaDataActionSelect", data);
            $("#modal-config-validate").modal("show");
        },
        toggleSection(nameSection) {
            let selector;
            switch (nameSection) {
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-validate`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
                    break;
            }
        }
    },
    mounted() {
        if (this.dataAction && this.indexAction !== undefined) {
            this.dataValidateAction = this.dataAction[this.indexAction];
        }
    }
};
