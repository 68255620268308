import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import ImagePicker from "@/components/editor-v3/setting-blocks/image-picker/image-picker.vue";

export default {
    components: {
        ColorPicker,
        ImagePicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            idSL: "",
            device: "",
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},
            //data outside
            bgType: "color",
            backgroundImageType: "0",
            backgroundImageRepeat: "0",
            isGraPicker: "",
            backgroundColor: "#FFFFFF",
            overlayBackgroundImage: "",
            colorOverlay: "",
            backgroundImageUrl: "",
            backgroundData: {},
            isOverlayGraPicker: "",
            listBorderStyle: ["none", "dotted", "dashed", "solid"],
            borderWidth: "0",
            borderColor: "#000000",
            borderType: "inside",
            borderStyle: "solid",
            //data inside
            backgroundColorInside: "#FFFFFF",
            backgroundDataInside: {},
            bgTypeInside: "color",
            bgImageTypeInside: "0",
            bgImageRepeatInside: "0",
            isGraPickerInside: "",
            overlayBackgroundImageInside: "",
            colorOverlayInside: "",
            backgroundImageUrlInside: "",
            isOverlayGraPickerInside: "",
            borderWidthInside: "0",
            borderColorInside: "#000000",
            borderTypeInside: "inside",
            borderStyleInside: "solid"
        };
    },
    computed: {
        borderTypeName() {
            let name = this.borderType.charAt(0).toUpperCase() + this.borderType.substring(1);
            return name;
        },
        borderStyleName() {
            let name = this.borderStyle.charAt(0).toUpperCase() + this.borderStyle.substring(1);
            return name;
        },
        borderTypeNameInside() {
            let name = this.borderTypeInside.charAt(0).toUpperCase() + this.borderTypeInside.substring(1);
            return name;
        },
        borderStyleNameInside() {
            let name = this.borderStyleInside.charAt(0).toUpperCase() + this.borderStyleInside.substring(1);
            return name;
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id && val.componentName === "blockCountdown" && val.id != this.idSL) {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;
                if (val && val[this.device] && val[this.device].background) {
                    this.backgroundData = $.extend(true, {}, val[this.device].background);

                    this.getInitialData();
                } else {
                    this.backgroundData = {};
                    this.block.mobile.background = {};

                    this.isGraPicker = false;
                    this.isOverlayGraPicker = true;
                }

                if (val && val[this.device] && val[this.device].backgroundInside) {
                    this.backgroundDataInside = $.extend(true, {}, val[this.device].backgroundInside);

                    this.getInitialData();
                } else {
                    this.backgroundDataInside = {};
                    this.block.mobile.backgroundInside = {};

                    this.isGraPickerInside = false;
                    this.isOverlayGraPickerInside = true;
                }
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                }
            }
        },
        "$store.state.editor.device": function(val) {
            this.device = val;
        },
        borderWidth: _.debounce(function() {
            if (this.borderWidth < 0) {
                this.borderWidth = 0;
            }
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.block.border.borderWidth = this.borderWidth + "px";
            this.block[this.device].border.borderWidth = this.borderWidth + "px";
            this.updateBlock(this.block, "borderSt");
        }, 300),
        borderWidthInside: _.debounce(function() {
            if (this.borderWidthInside < 0) {
                this.borderWidthInside = 0;
            }
            if (!this.block.borderInside) {
                this.block.borderInside = {};
            }
            if (!this.block[this.device].borderInside) {
                this.block[this.device].borderInside = {};
            }

            this.block.borderInside.borderWidth = this.borderWidthInside + "px";
            this.block[this.device].borderInside.borderWidth = this.borderWidthInside + "px";
            this.updateBlock(this.block, "borderInsideSt");
        }, 300)
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager", {
                type: "background",
                dataComp: this.block
            });
        },
        showImageManagerForBgInside() {
            this.$emit("showImageManager", {
                type: "bgInsideSt",
                dataComp: this.block
            });
        },
        updateBgOutside(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].background = {};
            } else {
                if (!this.block[this.device].background) {
                    this.block[this.device].background = {};
                }
            }

            this.backgroundColor = color;
            this.block[this.device].background.isGraPicker = this.isGraPicker;
            this.block[this.device].background.backgroundColor = color;
            this.backgroundData = this.block[this.device].background;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBgInside(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].backgroundInside = {};
            } else {
                if (!this.block[this.device].backgroundInside) {
                    this.block[this.device].backgroundInside = {};
                }
            }

            this.block[this.device].backgroundInside.isGraPicker = this.isGraPickerInside;
            this.block[this.device].backgroundInside.backgroundColor = color;
            this.backgroundDataInside = this.block[this.device].backgroundInside;

            this.updateBlock(this.block, "bgInsideSt");
        },
        updateOverlayColor(color) {
            if (this.block && this.backgroundData) {
                this.colorOverlay = color;
                this.backgroundData.colorOverlay = color;

                if (!this.isOverlayGraPicker) {
                    color = `linear-gradient( ${color}, ${color})`;
                }

                this.overlayBackgroundImage = color;
                this.backgroundData.overlayBackgroundImage = color;
                this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                this.block[this.device].background = this.backgroundData;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        updateOverlayColorInside(color) {
            if (this.block && this.backgroundDataInside) {
                this.colorOverlayInside = color;
                this.backgroundDataInside.colorOverlay = color;

                if (!this.isOverlayGraPickerInside) {
                    color = `linear-gradient( ${color}, ${color})`;
                }

                this.overlayBackgroundImageInside = color;
                this.backgroundDataInside.overlayBackgroundImage = color;
                this.backgroundDataInside.isOverlayGraPicker = this.isOverlayGraPicker;
                this.block[this.device].backgroundInside = this.backgroundDataInside;

                this.updateBlock(this.block, "bgInsideSt");
            }
        },
        updateBorderColorOutside(color) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderColor = color;
            this.block.border.borderColor = color;
            this.block[this.device].border.borderColor = color;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderColorInside(color) {
            if (!this.block.borderInside) {
                this.block.borderInside = {};
            }
            if (!this.block[this.device].borderInside) {
                this.block[this.device].borderInside = {};
            }

            this.borderColorInside = color;
            this.block.borderInside.borderColor = color;
            this.block[this.device].borderInside.borderColor = color;
            this.updateBlock(this.block, "borderInsideSt");
        },
        updateBorderType(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderType = val;
            this.block.border.borderType = val;
            this.block[this.device].border.borderType = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderTypeInside(val) {
            if (!this.block.borderInside) {
                this.block.borderInside = {};
            }
            if (!this.block[this.device].borderInside) {
                this.block[this.device].borderInside = {};
            }

            this.borderTypeInside = val;
            this.block.borderInside.borderType = val;
            this.block[this.device].borderInside.borderType = val;
            this.updateBlock(this.block, "borderInsideSt");
        },
        updateBorderStyle(val) {
            if (!this.block.border) {
                this.block.border = {};
            }
            if (!this.block[this.device].border) {
                this.block[this.device].border = {};
            }

            this.borderStyle = val;
            this.block.border.borderStyle = val;
            this.block[this.device].border.borderStyle = val;
            this.updateBlock(this.block, "borderSt");
        },
        updateBorderStyleInside(val) {
            if (!this.block.borderInside) {
                this.block.borderInside = {};
            }
            if (!this.block[this.device].borderInside) {
                this.block[this.device].borderInside = {};
            }

            this.borderStyleInside = val;
            this.block.borderInside.borderStyle = val;
            this.block[this.device].borderInside.borderStyle = val;
            this.updateBlock(this.block, "borderInsideSt");
        },
        setBgType(val) {
            if (this.block && this.backgroundData) {
                this.bgType = val;
                this.backgroundData.backgroundType = val;
                this.block[this.device].background.backgroundType = val;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        setBgTypeInside(val) {
            if (this.block && this.backgroundDataInside) {
                this.bgTypeInside = val;
                this.backgroundDataInside.backgroundType = val;
                this.block[this.device].backgroundInside.backgroundType = val;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        updateColorEffect(color) {
            if (!this.effectProperty.boxShadow) {
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
            this.effectProperty.boxShadow[4] = color;
            this.updateEffectConfig();
        },
        updateEffectConfig() {
            let boxShadow = "";
            let blur = "";
            let boxShadowEffect = this.effectProperty.boxShadow;

            if (boxShadowEffect) {
                this.effectProperty.boxShadow.forEach((item, index) => {
                    if (index === 4) {
                        if (!item) {
                            item = "#000000";
                        }
                        boxShadow += item;
                        return;
                    }
                    if (!boxShadowEffect[index]) {
                        boxShadowEffect[index] = 0;
                        item = 0;
                    }
                    boxShadow += item + "px ";
                });
            }
            if (this.effectProperty.blur) {
                blur = this.effectProperty.blur + "px";
            }
            this.effectBlock = {
                boxShadow,
                blur
            };

            this.block[this.device].effect = this.effectBlock;
            this.updateBlock(this.block, "effectSt");
        },
        generateListEffect() {
            this.listEffectBlock = [];
            let convertEffectProperty = (item) => {
                if (item === "blur") {
                    this.effectProperty.blur = parseFloat(this.effectBlock.blur);
                    return;
                }
                let propertyArray = this.effectBlock.boxShadow.split(" ");
                this.effectProperty.boxShadow = propertyArray.map((i, ind) => {
                    if (ind === 4) return i;
                    return parseFloat(i);
                });
            };
            for (let item in this.effectBlock) {
                if (this.effectBlock[item]) {
                    this.listEffectBlock.push(item);
                    convertEffectProperty(item);
                }
            }
        },
        updateBgImageData(val) {
            console.log("run-bg-data::", val);
            if (!val) return;
            this.backgroundData.backgroundImageUrl = val.backgroundImageUrl;
            this.backgroundData.backgroundImageRepeat = val.backgroundImageRepeat;
            this.backgroundData.backgroundImageType = val.backgroundImageType;
            this.block[this.device].background = this.backgroundData;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBgImageDataInside(val) {
            console.log("val-inside::", val);
            if (!val) return;
            this.backgroundDataInside.backgroundImageUrl = val.backgroundImageUrl;
            this.backgroundDataInside.backgroundImageRepeat = val.backgroundImageRepeat;
            this.backgroundDataInside.backgroundImageType = val.backgroundImageType;
            this.block[this.device].backgroundInside = this.backgroundDataInside;

            this.updateBlock(this.block, "bgInsideSt");
        },
        updateBlock(val, type) {
            this.block = val;
            if (type === "borderSt" && !val.border.borderStyle) {
                val.border.borderStyle = "solid";
                val[this.device].border.borderStyle = "solid";
            }
            if (type === "borderInsideSt" && !val.borderInside.borderStyle) {
                val.borderInside.borderStyle = "solid";
                val[this.device].borderInside.borderStyle = "solid";
            }
            this.$store.dispatch("editor/updateBlock", {
                val: this.block,
                type: type
            });
        },
        getInitialData() {
            let backgroundData = this.backgroundData;
            let backgroundDataInside = this.backgroundDataInside;
            let borderData = this.block[this.device].border || {};
            let borderDataInside = this.block[this.device].borderInside || {};

            //data default outside
            this.bgType = backgroundData.backgroundType || "color";
            this.isGraPicker = backgroundData.isGraPicker || false;
            this.backgroundColor = backgroundData.backgroundColor || "#FFFFFF";
            this.overlayBackgroundImage = backgroundData.overlayBackgroundImage || "#00000000";
            this.colorOverlay = backgroundData.colorOverlay || "#00000000";
            this.backgroundImageUrl = backgroundData.backgroundImageUrl || "";
            this.isOverlayGraPicker = backgroundData.isOverlayGraPicker || false;
            this.backgroundImageType = backgroundData.backgroundImageType || "0";
            this.backgroundImageRepeat = backgroundData.backgroundImageRepeat || "0";

            this.borderColor = borderData.borderColor || "#575D85";
            this.borderWidth = borderData.borderWidth ? parseFloat(borderData.borderWidth) : "2";
            this.borderType = borderData.borderType || "inside";
            this.borderStyle = borderData.borderStyle || "solid";

            //data default inside
            this.bgTypeInside = backgroundDataInside.backgroundType || "color";
            this.isGraPickerInside = backgroundDataInside.isGraPicker || false;
            this.backgroundColorInside = backgroundDataInside.backgroundColor || "#FFFFFF";
            this.overlayBackgroundImageInside = backgroundDataInside.overlayBackgroundImage || "#00000000";
            this.colorOverlayInside = backgroundDataInside.colorOverlay || "#00000000";
            this.backgroundImageUrlInside = backgroundDataInside.backgroundImageUrl || "";
            this.isOverlayGraPickerInside = backgroundDataInside.isOverlayGraPicker || false;
            this.bgImageRepeatInside = backgroundDataInside.bgImageRepeat || "0";
            this.bgImageTypeInside = backgroundDataInside.bgImageType || "0";

            this.borderColorInside = borderDataInside.borderColor || "#575D85";
            this.borderWidthInside = borderDataInside.borderWidth ? parseFloat(borderDataInside.borderWidth) : "2";
            this.borderTypeInside = borderDataInside.borderType || "inside";
            this.borderStyleInside = borderDataInside.borderStyle || "solid";

            this.effectBlock = this.block[this.device].effect || {
                boxShadow: "",
                blur: ""
            };
            this.generateListEffect();
        },
        handleClickRemove(type, item) {
            if (type === "colorPicker") {
                if (typeof this.isOverlayGraPicker === "boolean") {
                    this.isOverlayGraPicker = "";
                    return;
                }
                this.isGraPicker = "";
            }
            if (type === "effect") {
                this.listEffectBlock = this.listEffectBlock.filter((i) => i !== item);
                this.effectProperty[item] = "";
                this.updateEffectConfig();
            }
        },
        handleClickAdd(type, item) {
            if (type === "effect") {
                if (this.listEffectBlock.length === 2) return;
                if (this.listEffectBlock.includes("boxShadow")) {
                    this.listEffectBlock.push("blur");
                    this.effectProperty.blur = 0;
                    return;
                }
                this.listEffectBlock.push("boxShadow");
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let leftPosition = event.target.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            let topSet;
            let leftSet;
            switch (nameSection) {
                case "fold":
                    if ($("#countdown-st").hasClass("fold-section")) {
                        $("#countdown-st").removeClass("fold-section");
                    } else {
                        $("#countdown-st").addClass("fold-section");
                    }
                    break;
                case "style-color":
                    $("#countdown-st .fill-bg .bg-outside").toggle();
                    if (val === "image") {
                        this.isGraPicker = "";
                        this.setBgType("image");
                        this.backgroundData.backgroundType = "image";
                    } else {
                        this.isGraPicker = val === "gradient" ? true : false;
                        this.setBgType("color");
                        this.backgroundData.backgroundType = "color";
                    }
                    this.backgroundData.isGraPicker = this.isGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "style-bg-inside":
                    $("#countdown-st .fill-bg .bg-inside").toggle();
                    if (val === "image") {
                        this.isGraPickerInside = "";
                        this.setBgTypeInside("image");
                        if (this.block && this.block[this.device] && this.block[this.device].backgroundInside) {
                            this.block[this.device].backgroundInside = {
                                backgroundImageRepeat: "",
                                backgroundImageType: "",
                                backgroundImageUrl: ""
                            };
                            this.backgroundDataInside.backgroundType = "image";
                        }
                    } else {
                        this.isGraPickerInside = val === "gradient" ? true : false;
                        this.setBgTypeInside("color");
                        this.backgroundDataInside.backgroundType = "color";
                    }

                    this.backgroundDataInside.isGraPicker = this.isGraPickerInside;
                    this.block[this.device].backgroundInside = this.backgroundDataInside;
                    this.updateBlock(this.block, "bgInsideSt");
                    break;
                case "border":
                    $("#countdown-st .style-border .list-option")
                        .eq(0)
                        .toggle();

                    if (val) {
                        this.updateBorderType(val);
                    }
                    break;
                case "border-style":
                    $("#countdown-st .style-border .list-option")
                        .eq(1)
                        .toggle();

                    if (val) {
                        this.updateBorderStyle(val);
                    }
                    break;
                case "border-inside":
                    $("#countdown-st .style-border .list-option")
                        .eq(2)
                        .toggle();

                    if (val) {
                        this.updateBorderTypeInside(val);
                    }
                    break;
                case "border-style-inside":
                    $("#countdown-st .style-border .list-option")
                        .eq(3)
                        .toggle();

                    if (val) {
                        this.updateBorderStyleInside(val);
                    }
                    break;
                case "style-overlay":
                    $("#countdown-st .fill-bg .list-style-overlay").toggle();
                    this.isOverlayGraPicker = val === "gradient" ? true : false;

                    this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "effect":
                    $(`#countdown-st .effect-column .effect:nth-child(${index + 1}) .list-option`).toggle();

                    if (item === val || this.listEffectBlock.includes(val) || !val) return;
                    this.listEffectBlock = this.listEffectBlock.map((i) => {
                        if (i === item) {
                            this.effectProperty[item] = "";
                            if (val === "blur") {
                                this.effectProperty.blur = 0;
                            } else {
                                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
                            }
                            return val;
                        }
                        return i;
                    });
                    break;
                case "edit-effect":
                    selector = `#countdown-st .effect-column .effect:nth-child(${index + 1})`;
                    if ($(selector).hasClass("popup-show")) {
                        $(selector).removeClass("popup-show");
                        $(selector + " .edit-popup").hide();
                        this.updateEffectConfig();
                        return;
                    } else {
                        $(selector).addClass("popup-show");
                        $(selector + " .edit-popup").show();
                    }
                    $(".edit-popup").removeClass("no-after");
                    if (windowHeight < topPosition + 290 && item === "boxShadow") {
                        topSet = windowHeight - 300;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else if (windowHeight < topPosition + 100 && item === "blur") {
                        topSet = windowHeight - 110;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else {
                        topSet = topPosition - 7;
                        leftSet = leftPosition - 10;
                    }
                    $(".edit-popup").css({
                        top: `${topSet}px`,
                        left: `${leftSet}px`
                    });
                    break;
            }
        },
        closeDrop: function(event) {
            $(event.target).hide();
        },
        closeSt: function(event) {
            $("#countdown-st").parent().hide();
        }
    },
    mounted() {
        this.blockSelected = this.$store.state.editor.blockSelected;
        this.device = this.$store.state.editor.device;
        if (this.blockSelected && this.blockSelected.id) {
            this.block = $.extend(true, {}, this.blockSelected);
            if (this.blockSelected[this.device].background) {
                this.backgroundData = this.blockSelected[this.device].background;
            }
            if (this.blockSelected[this.device].backgroundInside) {
                this.backgroundDataInside = this.blockSelected[this.device].backgroundInside;
            }
            this.getInitialData();
        }
    }
};
