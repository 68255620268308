import resful from "../../services/resful.js";
import { utils } from "@/libs-core/utils.min.js";
export const editorSV = {
  publish: async function(data, next) {
    let res = await resful.postUrl("https://deploy.appon.vn/v1/app/publish", data);
    next(res);
  },
  publishCustom: async function(data, next) {
    let res = await resful.postUrl("https://deploy.appon.vn/v1/app/publish-domain", data);
    next(res);
  },
  updateSource: async function(data, next) {
    let res = await resful.post("/app/update_page", data);
    next(res);
  },
  getDataSource: async function(data, next) {
    let res = await resful.get("/app/page?id=" + data.id, data);
    next(res);
  },
  saveTemplateBlock: async function(data, next) {
    let res = await resful.post("/app/create_template", data);
    next(res);
  },
  getAllConfig: async function(data, next) {
    let url = "/app/functions?" + utils.jsonToParams(data);
    let res = await resful.get(url);
    next(res);
  },
  getAllApiTemplate: async function(data, next){
    let res = await resful.get(
      "/app/api?" + utils.jsonToParams(data)
    );
    next(res);
  },
  getInfo: async function(data, next) {
    let res = await resful.get("/app/me", data);
    next(res);
  },
  getDataApp: async function(data, next) {
    let res = await resful.post("/public/data-app_read_public", data);
    next(res);
  },
  createOrUpdateDataApp: async function(data, next) {
    let res = await resful.post("/app/data-app_create_owner", data);
    next(res);
  },
  createJsData: async function(data, next) {
    let res = await resful.post("/app/js-data_create_owner", data);
    next(res);
  }
};
