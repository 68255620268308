import LayoutEditorLeft from "@/components/editor-v3/layout/layout-left-component/layout-left-component.vue";
import LayoutEditorRight from "@/components/editor-v3/layout/layout-right/layout-editor-right.vue";
import LayoutEditorTop from "@/components/editor-v3/layout/layout-top/layout-top.vue";
import API from "@/services/api/api.js";
import { utils } from "@/libs-core/utils.min.js";
import { selectedBlock } from "@/libs-core/selected-block.min.js";
import { shortKey } from "@/libs-core/short-key.min.js";
import { history } from "@/libs-core/history.min.js";
import { editorSV } from "./editor-v4.service.js";
import constant from "../../constants/index.js";
import { renderBlockEditor } from "@/libs-core/render-block-editor.min.js";
import { renderHtml } from "@/libs-core/render-html.min.js";
import { convertBlock } from "@/libs-core/convert-block.min.js";

import CssAdvanced from "@/components/editor-v3/help-editor-block/css-advance/css-advance.vue";
import Profile from "@/components/editor-v3/help-editor-block/profile-account/profile-account.vue";
import FunctionTemplate from "@/components/editor-v3/help-editor-block/function-template/function-template.vue";
import TrackingTemplate from "@/components/editor-v3/help-editor-block/tracking-template/tracking-template.vue";
import ApiTemplate from "@/components/editor-v3/help-editor-block/manager-api-template/manager-api-template.vue";
import SvgManager from "@/components/editor-v3/help-editor-block/svg-manager/svg-manager.vue";
import HelpManagerHtml from "@/components/editor-v3/help-editor-block/edit-html-block/edit-html-block.vue";
import UpgradeApp from "@/components/editor-v3/help-editor-block/upgrade-app/upgrade-app.vue";
import PaymentApp from "@/components/editor-v3/help-editor-block/payment-app/payment-app.vue";
import ManageData from "@/components/editor-v3/help-editor-block/manage-data/manage-data.vue";
import SelectDataApp from "@/components/editor-v3/help-editor-block/select-data-app/select-data-app.vue";
import SettingPublish from "@/components/editor-v3/help-editor-block/setting-publish-page/setting-publish-page.vue";
import SettingPage from "@/components/editor-v3/help-editor-block/setting-page/setting-page.vue";
import HelpManagerImage from "@/components/editor-v3/help-editor-block/image-manager/image-manager.vue";
import ModelTemplate from "@/components/editor-v3/help-editor-block/model-template/model-template.vue";
import SaveTemplateBlock from "@/components/editor-v3/help-editor-block/save-template/save-template.vue";
import CreateNewPageTemplate from "@/components/editor-v3/help-editor-block/create-new-page/create-new-page.vue";
import TemplatePageManager from "@/components/editor-v3/help-editor-block/template-page-manager/template-page-manager.vue";
import ClonePage from "@/components/editor-v3/help-editor-block/clone-page/clone-page.vue";
import UploadImageManager from "@/components/editor-v3/help-editor-block/upload-image-manager/upload-image-manager.vue";
import ModelNewClonePage from "@/components/editor-v3/help-editor-block/model-new-clone-page/model-new-clone-page.vue";
import ModalAddScreen from "@/components/editor-v3/help-editor-block/add-screen/add-screen.component.vue";
import ModalConfigParams from "@/components/editor-v3/help-editor-block/modal-config-params/modal-config-params.vue";
import FunctionManage from "@/components/editor-v3/help-editor-block/function-manage/function-manage.vue";
import TrackingManage from "@/components/editor-v3/help-editor-block/tracking-manage/tracking-manage.vue";
import ModalConfigGgForm from "@/components/editor-v3/help-editor-block/modal-config-ggform/modal-config-ggform.vue";
import ModalConfigValidate from "@/components/editor-v3/help-editor-block/modal-config-validate/modal-config-validate.vue";
import ModalConfigApi from "@/components/editor-v3/help-editor-block/modal-config-api/modal-config-api.vue";
import LayoutSettingBlock from "@/components/editor-v3/layout/layout-setting-block/layout-setting-block.vue"
import HistoryApp from "@/components/editor-v3/help-editor-block/history/history.vue";
import AddMember from "@/components/editor-v3/layout/layout-left-component/add-member/add-member.vue";

import HelpEditorText from "@/components/editor-v3/help-editor-block/editor-text-block/editor-text-block.vue";
import Preview from "@/components/editor-v3/help-editor-block/preview/preview.vue";
import Loading from "../loading/loading.vue";
import SuggestDataWidget from "@/components/editor-v3/help-editor-block/suggest-data-widget/suggest-data-widget.vue";
import Noti from "@/components/editor-v3/help-editor-block/noti/noti.vue";

import "jquery-ui/ui/widgets/droppable";
import "jquery.hotkeys";

let setStyle = require("@/libs-core/set-style-block.min.js");
window.$listModel = constant._model.variable_default;
window.$history = { undo: [], redo: [] };
window.$dataApp = {};

export default {
  name: "editor",
  data: function () {
    var thisVue = this;
    return {
      contentnt: "",
      shownt: false,
      thisVue: thisVue,
      ctrlDown: false,
      shiftdown: false,
      blockCopy: [],
      id: "id editor",
      createNew: false,
      preview: false,
      api: [],
      pageSelect: "home",
      listPage: [{ name: "home", api: [] }],
      settingPage: {},
      ofsetTop: 0,
      ofsetLeft: 0,
      time_update: new Date().getTime(),
      blockSelected: {},
      posEditorText: null,
      statusEditorText: 0,
      statusEditorForm: 1,
      moveable: "",
      statusManagerImage: 1,
      statusSelectedBlock: 1,
      statusSettingpage: 2,
      statusAttribute: 3,
      listModel: constant._model.variable_default,
      listApiTemplate: [],
      timePublish: 10,
      typeManagerImage: "image",
      domain: "",
      page_action: [],
      dataChangeImage: {},
      dataChangeHtml: {},
      dataChangeSvg: {},
      zoom: "1",
      infoApp: {
        name: "",
        type: "",
        category: "",
        description: "",
      },
      index: 0,
      valueSaveTemplate: {},
      apiCreateTemplate: [],
      timeupdateCreatePageTemplate: 100,
      backgroundPage: {},
      statusGroup: {
        down: false,
        move: false,
        up: false,
      },
      positionGroup: {
        startX: 0,
        startY: 0,
        stopX: 0,
        stopY: 0,
      },
      changeBlock: false,
      blockOld: {},
      blockNew: {},
      listHistory: [],
      dataApp: {},
      version: "0",
      device: "mobile",
      pageData: {},
      quickFuncPosition: {},
      itemFuncSelect: {},
      itemTrackingSelect: {},
    };
  },
  components: {
    Noti,
    AddMember,
    LayoutEditorRight,
    LayoutEditorTop,
    SelectDataApp,
    ManageData,
    Profile,
    Preview,
    TemplatePageManager,
    CreateNewPageTemplate,
    LayoutEditorLeft,
    HelpEditorText,
    HelpManagerImage,
    SettingPage,
    SettingPublish,
    ApiTemplate,
    ModelTemplate,
    FunctionTemplate,
    TrackingTemplate,
    CssAdvanced,
    Loading,
    SaveTemplateBlock,
    UpgradeApp,
    HelpManagerHtml,
    SvgManager,
    ClonePage,
    UploadImageManager,
    PaymentApp,
    ModelNewClonePage,
    ModalAddScreen,
    ModalConfigParams,
    ModalConfigGgForm,
    ModalConfigValidate,
    ModalConfigApi,
    FunctionManage,
    TrackingManage,
    SuggestDataWidget,
    LayoutSettingBlock,
    HistoryApp
  },
  watch: {
    "$store.state.editor.blockSelected": function (val) {
      if (val && val.id) {
        this.blockSelected = val;
      }
    },
    "$store.state.editor.backgroundPage": function (val) {
      this.updateBgPage(val);
    },
    "$store.state.editor.device": function (val) {
      this.device = val;
    },
    "$store.state.editor.pageSelect": function (val) {
      const thisVue = this;
      this.api.length = 0;
      this.pageSelect = val;
      this.listPage.find(function (x) {
        if (x.name === val) {
          thisVue.api = x.api;
          renderBlockEditor.init(thisVue.api, thisVue);
        }
      });
    },
    "$store.state.editor.actionEditor": function (val) {
      let thisVue = this;
      let actionEditor = thisVue.$store.state.editor.actionEditor;
      if (actionEditor.type === "removePage") {
        this.removePage();
        return;
      }
      if (actionEditor.type === "removeBlock") {
        this.quickDeleteBlock();
        return;
      }
      if (actionEditor.type === "duplicateBlock") {
        this.quickDuplicateBlock();
        return;
      }
      if (actionEditor.type === "changePage") {
        let temp = actionEditor.value;
        this.changePage(temp, this.listPage);
        return;
      }
    },
    api: function (val, oldVal) {

    },
    "$store.state.editor.listPage": function (val) {
      this.listPage = val;
    }
  },
  methods: {
    moveComp: function (val) {
      var itemMove = this.api.find((x) => x.id === val.idMove);
      var itemParMove = this.api.find((x) => x.id === itemMove.idPar);
      var itemImport = this.api.find((x) => x.id === val.idImport);
      if (itemParMove && itemImport && itemParMove.id === itemImport.id) {
        return;
      } else {
        if (itemParMove) {
          if (
            itemParMove.dataChild &&
            itemParMove.dataChild.indexOf(val.idMove) >= 0
          ) {
            itemParMove.dataChild.splice(
              itemParMove.dataChild.indexOf(val.idMove),
              1
            );
          }
          if (itemImport.id != itemParMove.id) {
            this.updateDataComp(itemParMove);
          }
        }
        itemMove.idPar = val.idImport;
        if (!itemImport.dataChild) {
          itemImport.dataChild = [];
        }
        itemImport.dataChild.push(val.idMove);
        this.updateDataComp(itemImport);
      }
      if (itemImport.componentName === "blockList") {
        itemMove.itemlist = true;
      } else {
        itemMove.itemlist = false;
      }
      itemMove[this.device].top = val.top + "px";
      itemMove[this.device].left = val.left + "px";
      this.updateDataComp(itemMove);
      this.$forceUpdate();
    },
    publish: function (val) {
      this.changePage(this.listPage[0]);
      var dataPublish;
      if (val.domain && val.domain.length > 0) {
        dataPublish = val.data;
      } else {
        dataPublish = val;
      }
      $("#hellopreloader")
        .css({ background: "#ccc", opacity: "0.5" })
        .show();
      var thisVue = this;
      dataPublish.push({
        path: "/public/index.html",
        source: renderHtml.renderIndex(this.settingPage),
      });

      thisVue.domain = "";
      if (!val.domain) {
        editorSV.publish(
          JSON.stringify({ pageID: thisVue.$route.params.id, files: dataPublish, host: "app.appon.vn" }),
          function (data) {
            if (data && data.code === 200) {
              thisVue.timePublish = new Date().getTime();
              thisVue.domain = data.data.link_preview.replace(
                "http://",
                "https://"
              );
              $("#modal-setting-publish").modal("show");
            } else {
              thisVue.$swal({
                type: "error",
                title: "Please try again or contact us",
                showConfirmButton: false,
                timer: 1500,
              });
              $("#modal-setting-publish").modal("hide");
            }
            $("#hellopreloader")
              .css({ background: "#ff886d", opacity: "1" })
              .hide();
          }
        );
      } else {
        editorSV.publishCustom(
          JSON.stringify({
            pageID: this.$route.params.id,
            files: dataPublish,
            domain: val.domain,
          }),
          function (data) {
            if (data && data.code === 200) {
              thisVue.timePublish = new Date().getTime();
              thisVue.domain = data.data.link_preview.replace(
                "http://",
                "https://"
              );
              $("#modal-setting-publish").modal("show");
            } else {
              thisVue.$swal({
                type: "error",
                title: "Please try again or contact us",
                showConfirmButton: false,
                timer: 1500,
              });
              $("#modal-setting-publish").modal("hide");
            }

            $("#hellopreloader")
              .css({ background: "#ff886d", opacity: "1" })
              .hide();
          }
        );
      }
      this.saveSource();
    },
    appuptomarket: function () {
      if (!this.valueSaveTemplate.source) {
        this.valueSaveTemplate.source = {};
      }
      this.valueSaveTemplate.source.listPage = this.listPage;
      this.valueSaveTemplate.source.settingPage = this.settingPage;
      this.valueSaveTemplate.source.componentName = "app";
      this.valueSaveTemplate.source.listModel = this.listModel;
      this.valueSaveTemplate.type = "app";
      this.time_update = new Date().getTime();
      $("#save-template").modal("show");
      $(".left-bar-item.templates.active").removeClass("active");
    },
    sortBlockColumList: function () {
      var thisVue = this;
      $("body").sortable();
      $("body").sortable("destroy");
      $(function () {
        $("body").sortable({
          cursor: "move",
          stop: function (event, ui) {
            var itemsortEnd = ui.item.index();
            var iditem = ui.item.attr("id");
            var apiItem = thisVue.api.find((x) => x.id === iditem);
            if (apiItem) {
              var itemNext = ui.item.next();
              var arrTmp = [];
              if (itemNext && itemNext.length > 0) {
                var idnext = itemNext.attr("id");
                for (var i = 0; i < thisVue.api.length; i++) {
                  if (thisVue.api[i].id != iditem) {
                    if (thisVue.api[i].id === idnext) {
                      arrTmp.push(apiItem);
                    }
                    arrTmp.push(thisVue.api[i]);
                  }
                }
              } else {
                for (var i = 0; i < thisVue.api.length; i++) {
                  if (thisVue.api[i].id != iditem) {
                    arrTmp.push(thisVue.api[i]);
                  }
                  if (i === thisVue.api.length - 1) {
                    arrTmp.push(apiItem);
                  }
                }
              }
              let arr = [];
              let arrBlock = arrTmp.filter(item => item.name !== "blockList" && item.name !== "blockColumnOnly");
              let arrCL = arrTmp.filter(item => item.name == "blockList" || item.name == "blockColumnOnly");
              let obj = {};
              arrBlock.forEach(item => {
                if (obj[item.idPar]) {
                  obj[item.idPar].push(item);
                } else {
                  obj[item.idPar] = [];
                  obj[item.idPar].push(item);
                }
              });
              arrCL.forEach(item => {
                arr.push(item);
                if (obj[item.id]) {
                  arr.push(...obj[item.id]);
                }
              })
              thisVue.api = [];
              setTimeout(function () {
                thisVue.api = $.extend(true, [], arr);
                thisVue.updateListPage(thisVue.pageSelect);
              });
            }
          },
        });
        $("body").disableSelection();
      });
    },
    resetIndexElement: function () {
      var thisVue = this;
      var arr = [];
      var wg = $(".block-widget");
      if (wg && wg.length > 0) {
        wg.each(function () {
          var $this = $(this);
          var wgItem = thisVue.api.find((x) => x.id === $this.attr("id"));
          if (wgItem && wgItem.id && wgItem.id.length > 0) {
            arr.push(wgItem);
          }
        });
        thisVue.api = [];
        thisVue.api = $.extend(true, [], arr);
        this.listPage = this.$store.state.editor.listPage;
        this.listPage.find(function (n) {
          if (n.name === thisVue.pageSelect) {
            n.api = $.extend(true, [], thisVue.api);
          }
        });
        this.$store.commit("editor/setApi", thisVue.api);
        //this.$store.commit("editor/setListPage", this.listPage);
      }
    },

    //////////////////
    // TEMPLATE ACTION
    //////////////////
    dropCreatePageTemplate: function (val) {
      this.apiCreateTemplate = $.extend(true, [], val);
      $("#new-page-template").modal("show");
      this.timeupdateCreatePageTemplate = new Date().getTime();
    },
    newPageTemplate: function (val) {
      this.createPage(val);
      $("#new-page-template").modal("hide");
    },
    // ENDTEMPLATE ACTION
    /////////////////////

    //////////////
    // PAGE ACTION
    //////////////
    createPage: function (val) {
      var thisVue = this;
      this.listPage.push({
        name: val.name,
        api: convertBlock.convertBlockV1(val.api) || [],
        page_action: [],
        style: val.style || {},
      });
      if (thisVue.$store.state.editor.before_popup.type === "block-action") {
        $("#modal-template-page-manager").modal("hide");
        $("#action-setting").modal("show");
        thisVue.$store.commit("editor/mutaBeforePopup", {});
      } else {
        this.changePage(
          {
            name: val.name,
            api: convertBlock.convertBlockV1(val.api) || [],
            style: val.style || {},
          },
          this.listPage
        );
        $("#add-new-page").modal("hide");
      }
    },
    removePage: function () {
      let thisVue = this;
      let index = thisVue.$store.state.editor.actionEditor;
      this.listPage.splice(index.value, 1);
      this.changePage(this.listPage[0], this.listPage);
    },
    showListpageFast: function (ev) {
      var wglist = $(".select-screen-fast .dropdown-menu");
      if (wglist.css("visibility") === "hidden") {
        wglist.css({ "visibility": "visible" });
      }
      console.log(wglist.css("visibility"));
    },
    changePage: function (val, listPage) {
      console.log("change page::: ", val);
      $("#loading-component").show();
      var wglist = $(".select-screen-fast .dropdown-menu");
      if (wglist.css("visibility") != "hidden") {
        wglist.css({ "visibility": "hidden" });
      }
      this.$store.commit("editor/setPageSelect", val.name);
      this.listPage = this.$store.state.editor.listPage;
      var thisVue = this;
      this.blockSelected = undefined;

      // vuex
      this.$store.commit("editor/setBlockSelected", undefined);
      // vuex

      $(".block-widget.selected").removeClass("selected");

      this.listPage.find(function (n) {
        if (n.name == val.name) {
          thisVue.api = n.api;
          thisVue.$store.commit("editor/setApi", n.api);
          thisVue.page_action = [];
          thisVue.page_action = n.page_action;
          thisVue.$store.commit("editor/setPageAction", n.page_action);
        }
      });
      if (val.style && val.style.background) {
        this.backgroundPage = $.extend(true, {}, val.style.background);

        // vuex
        this.$store.commit(
          "editor/setBackgroundPage",
          $.extend(true, {}, val.style.background)
        );
        // vuex

        setStyle.setStypePage(val.style, thisVue.device);
      } else {
        this.backgroundPage = {};

        // vuex
        this.$store.commit("editor/setBackgroundPage", {});
        // vuex

        setStyle.setStypePage();
      }
      this.$forceUpdate();
      setTimeout(function () {
        setStyle.init(thisVue.api, thisVue.device);
      }, 0);
      this.pageSelect = val.name;
      thisVue.time_update = new Date().getTime();
      this.$store.commit("editor/setActionEditor", {});
      this.$store.commit("editor/setTimeUpdate", new Date().getTime());
      renderBlockEditor.init(this.api, this);
    },
    // END PAGE ACTION
    //////////////////

    /////////////
    // COPY PASTE
    /////////////
    copyBlock: function () {
      var wgSelect = $(".block-widget.selected");
      if (wgSelect && wgSelect.length > 0) {
        var val = $.extend(
          true,
          {},
          this.api.find((x) => x.id === wgSelect.attr("id"))
        );
        var arr = [val];
        this.api.find(function (x) {
          if (x.idPar === val.id) {
            arr.push($.extend(true, {}, x));
          }
        });
        this.blockCopy = [];
        this.blockCopy = $.extend(true, [], arr);
      }
    },
    pasteBlockCopied: function (offsetY) {
      var thisVue = this;
      var blockCopy = $.extend(true, [], this.blockCopy);
      var wgCl = $(".main-builder > .block-widget.drop-column");
      var wgSl;
      if (wgCl && wgCl.length > 0) {
        wgCl.each(function () {
          if ($(this).hasClass("selected") || ($(this).find(".selected") && $(this).find(".selected").length > 0)) {
            wgSl = $(this);
          }
        })
      }
      if (wgSl && wgSl.length > 0 && this.blockCopy && this.blockCopy.length > 0) {
        var idSl = wgSl.attr("id");
        if (blockCopy[0].componentName === "blockColumnOnly" || blockCopy[0].componentName === "blockList"
        ) {
          var index = this.api.findIndex((x) => x.id === idSl);
          let id0 = "bt-" + new Date().getTime();
          blockCopy[0].cssAdvance = utils.replaceAllText(
            blockCopy[0].cssAdvance,
            blockCopy[0].id,
            id0
          );
          blockCopy[0].id = id0;
          this.api.splice(index, 0, blockCopy[0]);
          renderBlockEditor.renderOnceBlock(blockCopy[0], this);
          console.log(1);
          $("#" + blockCopy[0].id).insertBefore($("#" + idSl));
          for (var i = 1; i < blockCopy.length; i++) {
            if (blockCopy[i]) {
              let idi = "bt-" + new Date().getTime() + "" + i;
              blockCopy[i].cssAdvance = utils.replaceAllText(
                blockCopy[i].cssAdvance,
                blockCopy[i].id,
                idi
              );
              blockCopy[i].id = idi;
              blockCopy[i].idPar = blockCopy[0].id;
              this.api.push(blockCopy[i]);
              renderBlockEditor.renderOnceBlock(blockCopy[i], this);
            }
          }
        } else {
          console.log(2);
          blockCopy[0].idPar = idSl;
          let id0 = "bt-" + new Date().getTime();
          blockCopy[0].cssAdvance = utils.replaceAllText(
            blockCopy[0].cssAdvance,
            blockCopy[0].id,
            id0
          );
          blockCopy[0].id = id0;
          blockCopy[0][this.device].top = offsetY + "px";
          blockCopy[0][this.device].left = thisVue.ofsetLeft + "px";
          this.api.push(blockCopy[0]);
          renderBlockEditor.renderOnceBlock(blockCopy[0], this);
        }
        this.listPage.find(function (x) {
          if (x.name === thisVue.pageSelect) {
            x.api = $.extend(true, [], thisVue.api);
          }
        });
        this.$forceUpdate();
        this.blockSelected = undefined;
        this.$store.commit("editor/setBlockSelected", undefined);
        this.$store.commit("editor/setApi", this.api);
        //this.$store.commit("editor/setListPage", this.listPage);
        $(".moveable-control-box").hide();
        setTimeout(function () {
          setStyle.init(thisVue.api, thisVue.device);
        }, 100);
      }
    },
    // END COPY PASTE
    /////////////////

    /////////////
    // SAVE EVENT
    /////////////
    saveApp: function (val) {
      this.saveSource();
    },
    saveSource: function () {
      this.listPage = $.extend(true, [], this.$store.state.editor.listPage);
      console.log("save source:::: ", this.listPage)
      var thisVue = this;
      var dataUpdate = {
        listPage: this.listPage,
        settingPage: this.settingPage,
        listModel: this.listModel,
        v: "1.0",
      };
      editorSV.updateSource(
        JSON.stringify({
          name: thisVue.infoApp.name,
          id: thisVue.$route.params.id,
          source: dataUpdate,
          status: "active",
          thumbnail: this.settingPage.imageShare,
          mode: this.settingPage.mode || "private",
        }),
        function () { }
      );
    },
    // ENDSAVE EVENT
    ////////////////

    ///////////////
    // SELECT EVENT
    ///////////////
    selectedMain: function (event) {
      this.ofsetTop = event.offsetY;
      this.ofsetLeft = event.offsetX;
      if ($(event.target).attr("id") == "bt-layout-center" || $(event.target).hasClass("infinite-viewer-scroll-area")) {
        $(".selected").removeClass("selected");
        this.blockSelected = undefined;
        $("#quick-setting").removeAttr("class").hide();
        //vuex
        this.$store.commit("editor/setBlockSelected", undefined);
        $(".moveable-control-box").hide();
        // $("#bt-layout-top").removeClass("left-show");
        // $("#bt-layout-center").removeClass("left-show");
        // $("#bt-layout-left li.active .content").hide();
        // $("#bt-layout-left li.active").removeClass("active");
        //vuex
      } else {
      }
      if ($(event.target).attr("id") != "dropdownMenu2") {
        $(".select-screen-fast .dropdown-menu").css({ "visibility": "hidden" });
      }
    },
    // END SELECT EVENT
    ///////////////////

    ////////////
    // GET EVENT
    ////////////
    getChildFromArr: function (api, arr) {
      var arrChil = [];
      if (arr && arr.length > 0) {
        arr.forEach(function (el) {
          api.find(function (o) {
            if (o.id === el) {
              arrChil.push(o);
            }
          });
        });
      }
      return arrChil;
    },
    getChild: function (id) {
      var item = this.api.find((x) => x.id === id);
      var arrChild = [];
      if (item.dataChild && item.dataChild.length > 0) {
        arrChild = this.getChildFromArr(this.api, item.dataChild);
      }
      return arrChild;
    },
    // END GET EVENT
    ////////////////

    //////////////
    // CLONE EVENT
    //////////////
    clonePage: function (val) {
      if (val && val != "package") {
        var item_page = this.listPage.find((x) => x.name === val);
        var itemSlect = this.listPage.find((x) => x.name === this.pageSelect);

        if (!item_page) {
          this.listPage.push({
            name: val,
            api: convertBlock.convertBlockV1(itemSlect.api) || [],
            page_action: itemSlect.page_action || [],
            style: itemSlect.style || {},
          });
          this.changePage(
            {
              name: val,
              api: convertBlock.convertBlockV1(itemSlect.api) || [],
              page_action: itemSlect.page_action || [],
              style: itemSlect.style || {},
            },
            this.listPage
          );
          $("#clone-page").modal("hide");
        } else {
          this.$swal({
            title: "",
            text: "The page already exists, please try again!",
            type: "warning",
          });
        }
      } else {
        this.$swal({
          title: "",
          text: "Please enter the page name!",
          type: "warning",
        });
      }
    },
    cloneApiItem: function (id, arrApi) {
      if (arrApi && arrApi.length > 0) {
        var itemApi = $.extend(true, {}, arrApi.find((x) => x.id === id));
        itemApi.id = "bt-" + new Date().getTime() + this.index;
        this.index++;
        return itemApi;
      }
      return false;
    },
    cloneItemApi: function (id, idPar, arr) {
      var arrClone = [];
      var arrListChild = [];
      var dataChild = [];
      var itemClone = $.extend(true, {}, arr.find((x) => x.id === id));
      if (itemClone) {
        itemClone.id = "bt-" + new Date().getTime() + "" + this.index;
        dataChild = $.extend(true, [], itemClone.dataChild);
        if (idPar && idPar.length > 0) {
          itemClone.idPar = idPar;
        }
        this.index++;
        itemClone.dataChild = [];
        if (dataChild && dataChild.length > 0) {
          for (var i = 0; i < dataChild.length; i++) {
            arrListChild = this.cloneItemApi(dataChild[i], itemClone.id, arr);
            if (arrListChild && arrListChild.length > 0) {
              for (var j = 0; j < arrListChild.length; j++) {
                arrClone.push(arrListChild[j]);
              }
            }
          }
        }
        arrClone.push(itemClone);
      }
      return arrClone;
    },
    // END CLONE EVENT
    //////////////////

    /////////////
    // SHOW EVENT
    /////////////
    showEditorText: function (val) {
      this.posEditorText = val;
      this.statusEditorText = new Date().getTime();
    },
    showSettingForm: function () {
      this.statusEditorForm = new Date().getTime();
    },
    showManagerImage: function (val) {
      this.typeManagerImage = val.type;
      this.dataChangeImage = val.dataComp;
      $("#modal-upload-image-manager").css("z-index", "9999999999");
      $("#modal-upload-image-manager").modal("show");
    },
    showManagerHtml: function (val) {
      this.dataChangeHtml = val.dataComp;
      $("#modal-html-manager").modal("show");
    },
    showManagerSvg: function (val) {
      this.dataChangeSvg = val.dataComp;
      $("#modal-svg-manager").modal("show");
    },
    // END SHOW EVENT
    /////////////////

    //////////////
    // QUICK EVENT
    //////////////
    quickUpdate: function (val) {
      if (val && val.id) {
        this.$store.dispatch("editor/updateBlock", {
          val: val,
          type: "quickFuncSt",
        });
      }
    },
    quickDeleteBlock: function (val) {
      var wg = $(".block-widget.selected");
      if (wg && wg.length > 0) {
        var arrDelete = [];
        wg.each(function () {
          var item = $(this).find(".block-widget");
          var id = $(this).attr("id");
          arrDelete.push(id);
          if (item.length > 0) {
            item.each(function () {
              arrDelete.push($(this).attr("id"));
            });
          }
        });
        this.api = this.$store.state.editor.api;
        var thisVue = this;
        var arr = [];
        for (var i = 0; i < this.api.length; i++) {
          if (arrDelete.indexOf(thisVue.api[i].id) == -1) {
            arr.push(this.api[i]);
          }
        }
        thisVue.api = $.extend(true, [], arr);
        // this.listPage.find(function (n) {
        //   if (n && n.name === thisVue.pageSelect) {
        //     n.api = $.extend(true, [], thisVue.api);
        //   }
        // });
        this.blockSelected = undefined;
        this.$store.commit("editor/setBlockSelected", undefined);
        this.$store.commit("editor/setApi", thisVue.api);
        // this.$store.commit("editor/setListPage", this.listPage);
        wg.remove();
        $(".moveable-control-box").hide();
        setTimeout(function () {
          setStyle.init(thisVue.api, thisVue.device);
        }, 100);
      }
    },
    quickShowEditBlock: function (val) {
      if (val && val.id) {
        switch (val.componentName) {
          case "blockText":
            this.showEditorText(val);
            break;
          case "blockImage":
            this.showManagerImage({ type: "image", dataComp: val });
            break;
          case "blockHtml":
            this.showManagerHtml({ type: "html", dataComp: val });
            break;
          case "blockSvg":
            this.showManagerSvg({ type: "svg", dataComp: val });
            break;
          case "blockForm":
            this.showSettingForm();
            break;
          default:
            break;
        }
      }
    },
    quickDuplicateBlock: function () {
      let idBlockSl = this.$store.state.editor.actionEditor.value.id;
      let val = $(".block-widget.selected");
      if (!this.device || this.device.length <= 0) {
        this.device = "mobile";
      }
      if (idBlockSl || (val && val.length > 0)) {
        const thisVue = this;
        let id = idBlockSl || val.attr("id");
        let index = this.api.findIndex((x) => x.id === id);
        let wgItem = $("#" + id).find(".block-widget");
        let dataItem = this.$store.state.editor.actionEditor.value || this.api.find((x) => x.id === id) || {};
        let idClone = "bt-" + new Date().getTime();
        let dataClone = $.extend(
          true,
          {},
          dataItem
        );
        function convertCss(dataItem, idItem, dataClone) {
          dataClone.cssAdvance = utils.replaceAllText(
            dataItem.cssAdvance,
            dataItem.id,
            idItem
          );
          if (dataClone.mobile) {
            dataClone.mobile.cssAdvance = utils.replaceAllText(
              dataItem.mobile.cssAdvance,
              dataItem.id,
              idItem
            );
          }
          if (dataClone.tablet) {
            dataClone.tablet.cssAdvance = utils.replaceAllText(
              dataItem.tablet.cssAdvance,
              dataItem.id,
              idItem
            );
          }
          if (dataClone.desktop) {
            dataClone.desktop.cssAdvance = utils.replaceAllText(
              dataItem.desktop.cssAdvance,
              dataItem.id,
              idItem
            );
          }
        }
        convertCss(dataItem, idClone, dataClone);

        dataClone.id = idClone;
        if (
          dataClone.componentName != "blockColumnOnly" &&
          dataClone.componentName != "blockList"
        ) {
          dataClone[this.device].top =
            parseFloat(dataClone[this.device].top) +
            parseFloat(dataClone[this.device].height) +
            10 +
            "px";
        }
        this.api.splice(index, 0, dataClone);
        renderBlockEditor.renderOnceBlock(dataClone, thisVue);
        $("#" + dataClone.id).insertAfter($("#" + id));
        if (wgItem && wgItem.length > 0) {
          wgItem.each(function (i) {
            let dataItemApi = thisVue.api.find(x => x.id === $(this).attr("id")) || {};
            let dataItem = $.extend(
              true,
              {},
              dataItemApi
            );
            let idItem = "bt-" + new Date().getTime() + "" + i;

            dataItem.id = idItem;
            convertCss(dataItemApi, idItem, dataItem);
            if (
              dataClone.componentName === "blockColumnOnly" ||
              dataClone.componentName === "blockList"
            ) {
              dataItem.idPar = dataClone.id;
            }
            thisVue.api.splice((index + 1), 0, dataItem);
            renderBlockEditor.renderOnceBlock(dataItem, thisVue);
          });
        }
        this.listPage.find(function (x) {
          if (x.name === thisVue.pageSelect) {
            x.api = $.extend(true, [], thisVue.api);
          }
        });
        this.$store.commit("editor/setApi", this.api);
        //this.$store.commit("editor/setListPage", this.listPage);
        console.log("duplicate");
        selectedBlock.init(this);
        $(".popup-menu-api").css("display", "none");
      }
    },
    quickMoveDownBlock: function (val) {
      var thisVue = this;
      console.log("val::: ", val);
      if (val && val.idPar && val.idPar.length > 0) {
        console.log(1);
        $("#" + val.id).prependTo($("#" + val.idPar + " > .block-content"));
        thisVue.resetIndexElement();
        thisVue.updateListPage(thisVue.pageSelect);
        return;
      } else {
        console.log(2);
        var item = $("#" + val.id);
        var itemNext = $("#" + val.id)[0].nextSibling;
        console.log("itemNext:: ", itemNext);
        if (itemNext) {

          item.insertAfter(itemNext);
          this.resetIndexElement();
        }
        return;
      }
    },
    quickMoveUpBlock: function (val) {
      var thisVue = this;
      console.log("val::: ", val);
      if (val && val.idPar && val.idPar.length > 0) {
        console.log(1);
        $("#" + val.id).appendTo($("#" + val.idPar + " > .block-content"));
        thisVue.resetIndexElement();
        thisVue.updateListPage(thisVue.pageSelect);
        return;
      } else {
        console.log(2);
        var item = $("#" + val.id);
        var itemPrev = $("#" + val.id)[0].previousSibling;
        if (itemPrev) {
          item.insertBefore(itemPrev);
          this.resetIndexElement();
        }
        return;
      }

    },
    quickGetTemplateSave: function () {
      if (!this.valueSaveTemplate.source) {
        this.valueSaveTemplate.source = {};
      }
      if (this.blockSelected && this.blockSelected.id) {
        var arr = [];
        var thisVue = this;
        arr.push(
          $.extend(
            true,
            {},
            this.api.find((x) => x.id === this.blockSelected.id)
          )
        );
        this.api.find(function (x) {
          if (x.idPar === thisVue.blockSelected.id) {
            arr.push(x);
          }
        });
        this.valueSaveTemplate.source.api = $.extend(true, [], arr);
        this.valueSaveTemplate.source.componentName = this.blockSelected.componentName;
        this.valueSaveTemplate.type = "section";
      } else {
        this.valueSaveTemplate.source.style = {
          background: this.backgroundPage,
        };
        this.valueSaveTemplate.source.api = $.extend(true, [], this.api);
        this.valueSaveTemplate.source.componentName = "page";
        this.valueSaveTemplate.type = "page";
      }
      this.time_update = new Date().getTime();
      $("#save-template").modal("show");
      $(".left-bar-item.templates.active").removeClass("active");
    },
    saveQuickFuncPosition: function (position) {
      this.quickFuncPosition = position;
    },
    // END QUICK EVENT
    //////////////////

    ///////////////
    // UPDATE EVENT
    ///////////////
    updateDataApp: function (data) {
      var thisVue = this;
      editorSV.createOrUpdateDataApp(
        JSON.stringify({ page_use: this.$route.params.id, data: data }),
        function (dataS) {
          console.log("responive data sheet::: ", dataS);
          if (dataS && dataS.code === 200) {
            thisVue.dataApp = $.extend(true, {}, data);
            $dataApp = $.extend(true, {}, data);

            thisVue.$store.commit("editor/setDataApp", data);
          } else {
            console.log("error");
          }
        }
      );
    },
    updateBgPage: function (val) {
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          if (!x.style) {
            x.style = {};
            x.style.background = {};
          }
          x.style.background = $.extend(true, {}, val);
          thisVue.backgroundPage = $.extend(true, {}, val);
          thisVue.time_update = new Date().getTime();
          setStyle.setStypePage(x.style);
          if (thisVue.changeBlock) {
            thisVue.blockNew = $.extend(true, {}, val);
            history.saveBlockChange(thisVue);
          }
        }
      });
      // VUEX
      thisVue.$store.commit("editor/setListPage", thisVue.listPage);
      thisVue.$store.commit("editor/setBackgroundPage", val);
      thisVue.$store.commit("editor/setTimeUpdate", new Date().getTime());
      console.log("update background")
      // VUEX
    },
    updateBgImagePageUrl: function (val) {
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          if (!x.style) {
            x.style = {};
            x.style.background = {};
          }
          x.style.background.bgImageUrl = val;
          thisVue.backgroundPage = $.extend(true, {}, x.style.background);
          setStyle.setStypePage(x.style);
          thisVue.updateBgPage(thisVue.backgroundPage);
        }
      });
    },
    updateListPage: function (pagesl) {
      this.api = this.$store.state.editor.api;
      this.listPage = this.$store.state.editor.listPage;
      var thisVue = this;
      this.listPage.find(function (x) {
        if (x.name === thisVue.pagesl) {
          x.api.length = 0;
          x.api = $.extend(true, [], thisVue.api);
        }
      });
      this.$store.commit('editor/setApi', this.api);
      this.$store.commit("editor/setListPage", this.listPage);
    },
    updateModel: function (val) {
      this.listModel = val;
      $listModel = val;
      this.saveSource();
    },
    updateListApiTemplate: function (val) {
      this.listApiTemplate = val;
    },
    updateSettingPage: function (val) {
      if (val.type === "true") {
        this.settingPage = val.val;
        this.settingPage.show = "true";
        this.statusSettingpage = new Date().getTime();
        $("#modal-setting-page").modal("show");
      } else {
        this.settingPage = val;
      }
    },
    updateblock: function (val) {
      var thisVue = this;
      if (this.blockSelected && val && this.blockSelected.id === val.id
      ) {
        this.updateDataComp(val);
        setStyle.setStypeItem(val, thisVue.device);
      }
    },
    updatePreview: function (status) {
      this.preview = status;
    },
    updateDataComp: function (val) {
      if (val && this.blockSelected && val.id === this.blockSelected.id) {
        var thisVue = this;
        this.api = this.$store.state.editor.api;
        var index = this.api.findIndex((k) => k.id === val.id);
        this.api[index] = $.extend(true, this.api[index], val);
        this.api[index].time_update = new Date().getTime();
        this.blockSelected = $.extend(true, {}, this.api[index]);

        // vuex
        this.$store.commit("editor/setApi", this.api);
        this.$store.commit(
          "editor/setBlockSelected",
          $.extend(true, {}, this.api[index])
        );
        // vuex

        if (this.changeBlock) {
          this.blockNew = $.extend(true, {}, this.blockSelected);
          history.saveBlockChange(this);
        }

        this.updateListPage(this.pageSelect);
        setStyle.setStypeItem(val, thisVue.device);
        this.$forceUpdate();
        this.time_update = this.api[index].time_update;

        // vuex
        this.$store.commit("editor/setTimeUpdate", this.api[index].time_update);
        // vuex
      } else {
        var thisVue = this;
        var index = this.api.findIndex((k) => k.id === val.id);
        this.api[index] = $.extend(true, this.api[index], val);
        this.api[index].time_update = new Date().getTime();

        // vuex
        this.$store.commit("editor/setApi", this.api);
        // vuex

        if (this.changeBlock) {
          this.blockNew = $.extend(true, {}, this.blockSelected);
          history.saveBlockChange(this);
        }

        this.updateListPage(this.pageSelect);
        setStyle.setStypeItem(val, thisVue.device);
        this.$forceUpdate();
        this.time_update = this.api[index].time_update;

        // vuex
        this.$store.commit("editor/setTimeUpdate", this.api[index].time_update);
      }
    },
    updateDataCompAction: function (val) {
      this.updateDataComp(val);
      this.statusAttribute = new Date().getTime();
    },
    updateDataPageAction: function (val) {
      var thisVue = this;
      this.page_action = $.extend(true, [], val);
      this.listPage.find(function (x) {
        if (x.name === thisVue.pageSelect) {
          x.page_action = $.extend(true, [], val);
        }
      });
      this.$store.commit("editor/setListPage", this.listPage);
      this.$store.commit("editor/setPageAction", val);
      console.log("update data page action")
    },
    // END UPDATE EVENT
    ///////////////////
    ////////////
    // INIT DATA
    ////////////
    getDataPage() {
      var thisVue = this;
      editorSV.getDataSource({
        id: this.$route.params.id,
      }, function (data) {
        if (data && data.data) {
          thisVue.infoApp.user = data.data.user;
          thisVue.infoApp.owner = data.data.owner;
          thisVue.infoApp.category = data.data.category;
          thisVue.infoApp.name = data.data.name;
          thisVue.infoApp.type = data.data.type;
          thisVue.infoApp.id = data.data.id;
        } else {
          thisVue.infoApp = {};
        }
        if (!data.data || !data.data.source) {
          console.log(1);
          thisVue.api = [];
          thisVue.pageSelect = "home";
          thisVue.listPage = [{ name: "home", api: [] }];
          thisVue.listModel = constant._model.variable_default;
          $listModel = constant._model.variable_default;
          thisVue.page_action = [];

          // vuex
          thisVue.$store.commit("editor/setApi", []);
          thisVue.$store.commit("editor/setPageSelect", "home");
          thisVue.$store.commit("editor/setListPage", [
            { name: "home", api: [] },
          ]);
          thisVue.$store.commit(
            "editor/setListModel",
            constant._model.variable_default
          );
          thisVue.$store.commit("editor/setPageAction", []);
          // vuex

          renderBlockEditor.init(thisVue.api, thisVue);
        } else {
          console.log(2);
          thisVue.dataPage = data.data;
          if (thisVue.dataPage.ref_template || thisVue.dataPage.ref_page) {
            let completeGuide = JSON.parse(
              localStorage.getItem("completeGuide")
            );
            let isCompleteGuide =
              completeGuide &&
              completeGuide.find((item) => item === thisVue.dataPage.id);
            if (!isCompleteGuide) {
              setTimeout(() => {
                $("#model-new-clone-page").modal({
                  backdrop: "static",
                  keyboard: false,
                });
              }, 500);
            }
          }
          thisVue.version = data.data.source.v;
          var arrTmp = [];
          var lengTmp = data.data.source.listPage.length;
          for (var v = 0; v < lengTmp; v++) {
            if (data.data.source.listPage[v].name.search(" ") >= 0) {

            } else {
              arrTmp.push(data.data.source.listPage[v]);
            }
          }
          thisVue.listPage = arrTmp;
          thisVue.settingPage = data.data.source.settingPage;
          thisVue.domain = data.data.domain;
          thisVue.listModel =
            data.data.source.listModel ||
            constant._model.variable_default;
          $listModel =
            data.data.source.listModel ||
            constant._model.variable_default;
          if (thisVue.listPage && thisVue.listPage.length > 0) {
            thisVue.listPage.find((x) => {
              if (x.name === thisVue.pageSelect) {
                thisVue.api = x.api;
                if (x.style && x.style.background) {
                  thisVue.backgroundPage = x.style.background;
                } else {
                  thisVue.backgroundPage = {}
                }

                thisVue.page_action = x.page_action;
              }
            });
          }
          // // vuex
          thisVue.$store.commit("editor/setBackgroundPage", thisVue.backgroundPage);
          thisVue.$store.commit("editor/setListPage", thisVue.listPage);
          thisVue.$store.commit("editor/setApi", thisVue.api);
          thisVue.$store.commit("editor/setPageAction", thisVue.page_action);
          thisVue.$store.commit(
            "editor/setSettingPage",
            data.data.source.settingPage
          );
          thisVue.$store.commit("editor/setDomain", data.data.domain);
          thisVue.$store.commit(
            "editor/setListModel",
            data.data.source.listModel ||
            constant._model.variable_default
          );
          // // vuex
          renderBlockEditor.init(thisVue.api, thisVue);
          setStyle.setStypePage(thisVue.backgroundPage, thisVue.device);
          //
          thisVue.infoApp.category = data.data.category;
          thisVue.infoApp.name = data.data.name;
          thisVue.infoApp.type = data.data.type;
          if (data.data.description) {
            if (
              data.data.description.charAt(0) === "{" ||
              data.data.description.charAt(0) === "["
            ) {
              thisVue.infoApp.description = JSON.parse(
                data.data.description
              );
            } else {
              thisVue.infoApp.description = data.data.description;
            }
          }

          // vuex
          thisVue.$store.commit("editor/setInfoApp", thisVue.infoApp);
          // vuex

          //this.sortBlockColumList();

          setTimeout(() => {
            $("#hellopreloader").hide();
            $("#loading-component").hide();
            $(".modal-backdrop").remove();
          }, 3000);
        }
      })
    },
    getInfoMe() {
      var thisVue = this;
      API.app.me(null, (e, r) => {
        if (!r || r.code !== 200) return;
        localStorage.setItem("user", JSON.stringify(r.data));
        localStorage.setItem("package_type", r.data.package_type);
        if (r.data.reach < 490) {
          thisVue.shownt = true;
          thisVue.contentnt = "Tài khoản của bạn còn "+ r.data.reach+" UPD. Vui lòng mua thêm UPD để không làm gián đoạn ứng dụng!";
        }
      });
    },
    getDataApp() {
      API.public.dataAppReadPublic(
        { page_use: this.$route.params.id },
        (e, r) => {
          if (
            !r ||
            r.code !== 200 ||
            !r.data ||
            r.data.length <= 0 ||
            !r.data[0].data
          )
            return;
          this.dataApp = r.data[0].data;
          $dataApp = r.data[0].data;
          // vuex
          this.$store.commit("editor/setDataApp", r.data[0].data);
          // vuex
        }
      );
    },
    getAllApi() {
      API.app.api({ page: this.$route.params.id }, (e, r) => {
        this.listApiTemplate = r.data;

        //vuex
        this.$store.commit("editor/setListApiTemplate", r.data);
        //vuex
      });
    },
    // END INIT DATA
    /////////////////
    editTracking: function (item) {
      this.itemTrackingSelect = item;
      $("#modal-setting-page").modal("hide");
      $("#modal-tracking").modal("show");
    },
    editFunction: function (item) {
      this.itemFuncSelect = item;
      $("#modal-setting-page").modal("hide");
      $("#modal-function").modal("show");
    },
    showAddNewPage: function () {
      $("#show-modal-add-screen").modal("show");
    },
    changeSourceHistory: function(data) {
      console.log("data:: ", data);
      var thisVue = this;
      thisVue.version = data.v;
          var arrTmp = [];
          var lengTmp = data.listPage.length;
          for (var v = 0; v < lengTmp; v++) {
            if (data.listPage[v].name.search(" ") >= 0) {

            } else {
              arrTmp.push(data.listPage[v]);
            }
          }
          thisVue.listPage = arrTmp;
          thisVue.settingPage = data.settingPage;
          thisVue.listModel =
            data.listModel ||
            constant._model.variable_default;
          $listModel =
            data.listModel ||
            constant._model.variable_default;
          if (thisVue.listPage && thisVue.listPage.length > 0) {
            thisVue.listPage.find((x) => {
              if (x.name === thisVue.pageSelect) {
                thisVue.api = x.api;
                if (x.style && x.style.background) {
                  thisVue.backgroundPage = x.style.background;
                } else {
                  thisVue.backgroundPage = {}
                }

                thisVue.page_action = x.page_action;
              }
            });
          }
          // // vuex
          thisVue.$store.commit("editor/setBackgroundPage", thisVue.backgroundPage);
          thisVue.$store.commit("editor/setListPage", thisVue.listPage);
          thisVue.$store.commit("editor/setApi", thisVue.api);
          thisVue.$store.commit("editor/setPageAction", thisVue.page_action);
          thisVue.$store.commit("editor/setSettingPage",data.settingPage);
          thisVue.$store.commit("editor/setListModel",data.listModel || constant._model.variable_default);
          renderBlockEditor.init(thisVue.api, thisVue);
          setStyle.setStypePage(thisVue.backgroundPage, thisVue.device);
    }
  },
  mounted: function () {
    var thisVue = $.extend(true, {}, this);
    localStorage.setItem("pageId", this.$route.params.id);
    ////////////
    // INIT DATA
    ////////////
    this.getInfoMe();
    this.getDataApp();
    this.getAllApi();
    this.getDataPage();
    shortKey.init(this);
  },
};
