import ImageService from "./media-manger.service.js";
export default {
    props: ["menuSl"],
    data() {
        return {
            listImage: [],
            listFolderUser: [],
            listImageByCate: [],
            category: "",
            skip: 0,
            marketCateAndImage: [],
            templateImage: [],
            search: "",

            stop: false,
            data: [
                '<div class="example-slide">Slide 1</div>',
                '<div class="example-slide">Slide 2</div>',
                '<div class="example-slide">Slide 3</div>'
            ]
        };
    },
    watch: {
        "$store.state.editor.cate_and_image"(val) {
            this.listFolderUser = val;
            if (this.category && this.category.name) {
                let temp = val.find((item) => item.name === this.category.name);
                if (temp) {
                    this.listImageByCate = temp.assets;
                }
            }
        },
        "$store.state.editor.list_image"(val) {
            this.listImage = val;
        },
        menuSl(val) {
            if (val !== "image-manage") {
                this.listImageByCate = [];
                this.templateImage = [];
            }
        }
    },
    methods: {
        showModalUploadImage() {
            localStorage.setItem("showuploadimage", "true");
            $("#modal-upload-image-manager").modal("show");
        },
        showMenuImage(type, event) {
            let index = Number(event.target.getAttribute("data-index-image"));
            let selector = `#image-manager > .list-cate-and-image:nth-child(${index +
                4}) .image-div-v3:nth-child(${index + 2}) .item-image-div-v3:nth-child(${index +
                1}) .menu-item-image-v3`;
            if (type === "open") {
                $(selector).css("display", "flex");
                return;
            }
            $(".menu-item-image-v3").css("display", "none");
        },
        deleteImage: async function(id) {
            let result = await this.$swal({
                title: "Be careful",
                text: "This action will delete image and cannot be recovered",
                type: "warning",
                showCancelButton: true
            });
            if (!result || !result.value) {
                return;
            }
            let res = await ImageService.deleteImage(id);
            this.getAllImage();
        },
        lazyLoadWave() {
            $(".content__div").each(function(i) {
                $(this).css("animation-delay", `calc(${i * 100}ms)`);
            });
        },
        draggableBlock() {
            $(".image-manager .image-img-drag").draggable({
                appendTo: "body",
                helper: "clone",
                drag(e, ui) {},
                stop(e, ui) {}
            });
            setTimeout(function() {
                $(".image-manager .image-img-drag").each(function() {
                    let hasDragClass = $(this).is(".ui-draggable");

                    if (!hasDragClass) {
                        $(".image-manager .image-img-drag").draggable({
                            appendTo: "body",
                            helper: "clone"
                        });
                        console.log("run second");
                        return false;
                    }
                });
            }, 1000);
        },
        ////////////////
        // stick method
        ////////////////
        getUserImageCate() {
            ImageService.getCateAndImage(
                $.param({
                    owner: JSON.parse(localStorage.getItem("user")).id,
                    type_second: "image",
                    type: "user"
                }),
                (res) => {
                    this.$store.commit("editor/mutaCateAndImage", res.data);
                    this.listFolderUser = this.$store.state.editor.cate_and_image;
                }
            );
        },
        genderUrl(cate) {
            if (cate && cate.assets && cate.assets[0] && cate.assets[0].url) {
                return `url(${cate.assets[0].url})`;
            } else {
                return "url()";
            }
        },
        getImageByCate(cate) {
            const thisVue = this;

            if (cate.assets.length === 0) return;
            if (!cate.id) {
                cate.id = null;
            }
            this.category = cate;
            ImageService.getAllImage(
                JSON.stringify({
                    category: cate.id,
                    skip: this.skip,
                    limit: 20
                }),
                (res) => {
                    this.$store.commit("editor/mutaListImage", res.data);
                    setTimeout(() => {
                        thisVue.draggableBlock();
                    }, 1000);
                }
            );
        },
        loadMore() {
            const thisVue = this;

            this.skip += 20;

            ImageService.getAllImage(
                JSON.stringify({
                    category: this.category.id,
                    skip: this.skip,
                    limit: 500
                }),
                (res) => {
                    if (res.data.length === 0) {
                        this.stop = true;
                    }
                    this.$store.commit("editor/mutaListImage", [...this.listImage, ...res.data]);
                    setTimeout(() => {
                        thisVue.draggableBlock();
                    }, 1000);
                }
            );
        },
        handleScrollUser(event) {
            if (event.wheelDelta > 0) return;
            if (this.stop) return;
            this.loadMore();
        },
        // end stick method
        ///////////////////

        ////////////////////
        // maketpalate image
        ////////////////////
        getMaketImageCate() {
            const thisVue = this;

            ImageService.getMaketCateAndImage(
                JSON.stringify({
                    asset_limit: 3
                }),
                (res) => {
                    this.marketCateAndImage = res.data;
                    setTimeout(() => {
                        thisVue.draggableBlock();

                        console.log("run drag block");
                    }, 1000);
                }
            );
        },
        getImageByCateUser(cate) {
            const thisVue = this;

            this.category = cate;
            this.listImageByCate = cate.assets;
            $(".menu-div-content-v3").scrollTop(0);
            setTimeout(() => {
                thisVue.draggableBlock();
            }, 1000);
        },
        getImageTemplate(cate) {
            const thisVue = this;

            this.category = cate;
            ImageService.getImageTemplate(
                $.param({
                    category: cate.id,
                    skip: this.skip,
                    limit: 20 //20
                }),
                (res) => {
                    this.templateImage = res.data;
                    setTimeout(() => {
                        thisVue.draggableBlock();
                    }, 1000);
                }
            );
        },
        loadMoreTemplate() {
            const thisVue = this;

            this.skip += 20; //20;
            ImageService.getImageTemplate(
                $.param({
                    category: this.category.id,
                    skip: this.skip,
                    limit: 20 //20
                }),
                (res) => {
                    if (res.data.length === 0) {
                        this.stop = true;
                    }
                    this.templateImage = [...this.templateImage, ...res.data];
                    setTimeout(() => {
                        thisVue.draggableBlock();
                    }, 1000);
                }
            );
        },
        handleScroll(event) {
            if (event.wheelDelta > 0) return;
            if (this.stop) return;
            this.loadMoreTemplate();
        },
        // end maketplate image
        ///////////////////////
        back() {
            this.listImage = [];
            this.listImageByCate = [];
            this.skip = 0;
            this.templateImage = [];
            this.category = {};
            this.stop = false;
        },
        clearInput() {
            this.search = "";
        }
    },
    mounted() {
        this.lazyLoadWave();
        this.getUserImageCate();
        this.getMaketImageCate();
    }
};
