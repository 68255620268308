import { service } from "./save-template.service.js";

export default {
    props: ["typeSave", "sourceSave", "timeUpdate", "blockselected", "domain"],
    data: function() {
        return {
            nameTemplate: "",
            desTemplate: "",
            source: {},
            type: "",
            block: {},
            category: "",
            listCate: [],
            user: {},
            thumbnail: "",
            fileUpload: {}
        };
    },
    computed: {
        computeCate() {
            let cate = this.listCate.find((item) => item.id === this.category);
            if (!cate) return "My template";
            return cate.name;
        }
    },
    watch: {
        sourceSave: {
            deep: true,
            handler(val) {
                this.type = this.typeSave;
                this.source = val;
                this.block = $.extend(true, {}, this.blockselected);
            }
        },
        typeSave: function(val) {
            this.type = val;
            this.getListCategory();
        }
    },
    methods: {
        saveTemplate() {
            const thisVue = this;
            if(!this.nameTemplate && !this.desTemplate) {
                this.$swal({
                    title: "",
                    text: "Please try again!",
                    type: "warning",
                    showConfirmButton: false,
                    timer: 1500
                  });
                return;
            }
            let wg = "";
            console.log("thisVue.block:: ", thisVue.block);
            if (thisVue.block && thisVue.block.data) {
                wg = $("#" + thisVue.block.data.id);
                wg.removeClass("selected");
            } else {
                wg = $(".main-builder");
            }
            if (this.source && this.nameTemplate && this.nameTemplate.length > 0) {
                $("#loading-component").show();
                let thumbnail = thisVue.thumbnail;
                if (this.block.componentName === "blockImage") {
                    thumbnail = this.block.src;
                }

                let description = {
                    description: thisVue.desTemplate,
                    linkDemo: thisVue.domain
                };

                let value = {
                    name: thisVue.nameTemplate,
                    description: JSON.stringify(description),
                    source: thisVue.source,
                    type: thisVue.type,
                    status: "unactive",
                    mode: "public",
                    thumbnail: thumbnail,
                    category: thisVue.category,
                    page_id_clone: thisVue.$route.params.id
                };
                if (thisVue.type === "app") {
                    value.id_page_clone = thisVue.$route.params.id
                }
                service.saveTemplateBlock(JSON.stringify(value), function(data) {
                    if (data && data.code === 200) {
                        thisVue.$store.commit("editor/mutaSaveTempStatus", `${data.data.type}${data.data.id}`);
                        thisVue.$swal({
                            position: "bottom-start",
                            type: "success",
                            title: "Template has been saved.",
                            showConfirmButton: false,
                            timer: 1500
                        });
                        thisVue.nameTemplate = "";
                        thisVue.category = "";
                        thisVue.desTemplate = "";
                    } else {
                        thisVue.$swal({
                            position: "bottom-start",
                            type: "error",
                            title: "An error occurred, please try again!",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                    if (wg.hasClass("block-widget")) {
                        wg.addClass("selected");
                    }
                    thisVue.$refs.file.value = null;
                    $("#loading-component").hide();
                    $("#save-template").modal("hide");
                });
            } else {
                console.log("this.source 2: ", this.source);
            }
        },
        getListCategory: function() {
            const thisVue = this;

            let typeSecond = this.type;
            if (this.block.componentName === "blockImage") {
                typeSecond = "image";
            }
            if (this.block.componentName === "blockColumnOnly" || this.block.componentName === "blockList" || this.block.componentName === "blockLightBox") {
                typeSecond = "page";
            }
            service.getListCategory(
                $.param({
                    type: "marketplace",
                    type_second: typeSecond
                }),
                function(data) {
                    if (data && data.code === 200) {
                        thisVue.listCate = data.data;
                    }
                }
            );
        },
        handleClickUploadBtn() {
            $("#save-template #upload").trigger("click");
        },
        handleFileUpload: function() {
            const thisVue = this;
            let formData = new FormData();
            this.fileUpload = this.$refs.file.files[0];
            if (!this.fileUpload) {
                this.thumbnail = "";
                return;
            }
            formData.append("file", this.fileUpload);
            service.uploadImage({ file: formData, page: thisVue.$route.params.id }, function(res) {
                if (res && res.code === 200) {
                    thisVue.thumbnail = res.data.url;
                }
            });
        },
        toggleSection(nameSection, event, val) {
            let topPosition = event.target.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let elementHeight;
            
            switch (nameSection) {
                case "category":
                    elementHeight = $("#save-template .dropdown-pattern .list-option").outerHeight();

                    $("#save-template .dropdown-pattern .list-option").toggle();
                    if (windowHeight < topPosition + elementHeight + 40) {
                        $("#save-template .dropdown-pattern .list-option").css({
                            top: `${windowHeight - elementHeight - 40}px`
                        });
                    } else {
                        $("#save-template .dropdown-pattern .list-option").css({
                            top: `${topPosition}px`
                        });
                    }
                    
                    if (val !== undefined) {
                        this.category = val;
                    }
                    break;
            }
        }
    },
    mounted() {
        const thisVue = this;

        this.user = JSON.parse(localStorage.getItem("user"));
        this.source = this.sourceSave;
        this.type = this.typeSave;
        $("#save-template-block").on("shown.bs.modal", function() {
            thisVue.getListCategory();
        });
        $("#save-template-block").on("hidden.bs.modal", function() {
            thisVue.desTemplate = "";
            thisVue.nameTemplate = "";
            thisVue.category = "";
            thisVue.fileUpload = "";
            thisVue.thumbnail = "";
        });
    }
};
