import { utils } from "@/libs-core/utils.min.js";

export default {
    data() {
        return {
            indexParam: "",
            dataParam: [],
            keyParam: "",
            dataAppSelect: {},
            timeShowAction: 0,
            timeShow: 0
        };
    },
    computed: {
        selectDataInfo() {
            const thisVue = this;

            if (this.dataAppSelect.indexTab === undefined) return "";
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= this.dataAppSelect.indexTab) return "";
            return utils.getValueDataApp(
                thisVue.dataAppSelect.indexTab,
                thisVue.dataAppSelect.keySelect,
                thisVue.dataAppSelect.indexKey
            );
        }
    },
    watch: {
        keyParam(val) {
            this.dataParam[this.indexParam].key = val;
            let data = {
                dataParam: this.dataParam,
                timeShowAction: this.timeShowAction
            };
            this.$store.commit("editor/mutaDataActionSelect", data);
        }
    },
    methods: {
        showSelectDataApp() {

            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, this.dataParam[this.indexParam].dataAppSelect);
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
            $("#modal-config-params").css("opacity", 0);
        }
    },
    mounted() {
        const thisVue = this;

        $("#modal-config-params").on("shown.bs.modal", function() {
            let data = thisVue.$store.state.editor.dataActionSelect;

            if (!data) return;
            thisVue.indexParam = data.indexParam;
            thisVue.dataParam = data.dataParam;
            thisVue.keyParam = data.dataParam[data.indexParam].key;
            thisVue.dataAppSelect = data.dataParam[data.indexParam].dataAppSelect || {};
            thisVue.timeShowAction = data.timeShowAction;
        });
        $("#modal-config-params").on("hide.bs.modal", function() {
            let data = {
                dataParam: thisVue.dataParam,
                timeShowAction: thisVue.timeShowAction
            };
            thisVue.$store.commit("editor/mutaDataActionSelect", data);
        });

        $("#select-data-app").on("hidden.bs.modal", function() {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                thisVue.dataParam[thisVue.indexParam].dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                thisVue.timeShow = new Date().getTime();
                $("#modal-config-params").css("opacity", 1);
            }
        });
    }
};
