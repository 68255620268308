// SETTING SECTION
import PositionSetting from "@/components/editor-v3/setting-blocks/position-setting/position-setting.vue";
import TextSetting from "@/components/editor-v3/setting-blocks/text-setting/text-setting.vue";
import ButtonSetting from "@/components/editor-v3/setting-blocks/button-setting/button-setting.vue";
import InputSetting from "@/components/editor-v3/setting-blocks/input-setting/input-setting.vue";
import ShapeSetting from "@/components/editor-v3/setting-blocks/shape-setting/shape-setting.vue";
import ColumnSetting from "@/components/editor-v3/setting-blocks/column-setting/column-setting.vue";
import PageSetting from "@/components/editor-v3/setting-blocks/page-setting/page-setting.vue";
import ListSetting from "@/components/editor-v3/setting-blocks/list-setting/list-setting.vue";
import StickySetting from "@/components/editor-v3/setting-blocks/sticky-setting/sticky-setting.vue";
import ImageSetting from "@/components/editor-v3/setting-blocks/image-setting/image-setting.vue";
import ThemeSetting from "@/components/editor-v3/setting-blocks/theme-block-setting/theme-block-setting.vue";
import CountdownSetting from "@/components/editor-v3/setting-blocks/countdown-setting/countdown-setting.vue";
import LightboxSetting from "@/components/editor-v3/setting-blocks/lightbox-setting/lightbox-setting.vue";
import BorderSetting from "@/components/editor-v3/setting-blocks/border-setting/border-setting.vue";
import SliderSetting from "@/components/editor-v3/setting-blocks/setting-slider/setting-slider.vue";

// ACTION SECTION
import ConditionSetting from "@/components/editor-v3/setting-blocks/condition-setting/condition-setting.vue";
import AnimationSetting from "@/components/editor-v3/setting-blocks/animation-setting/animation-setting.vue";
import ActionSetting from "@/components/editor-v3/setting-blocks/action-setting/action-setting.vue";
import LinkSetting from "@/components/editor-v3/setting-blocks/link-setting/link-setting.vue";
import ActionPageSetting from "@/components/editor-v3/setting-blocks/action-page-setting/action-page-setting.vue";

// CONNECT TO DATA
import ConnectToData from "@/components/editor-v3/setting-blocks/connect-to-data/connect-to-data.vue";

export default {
    props: ["pageAction", "thisVue"],
    components: {
        TextSetting,
        PositionSetting,
        ButtonSetting,
        InputSetting,
        ShapeSetting,
        ColumnSetting,
        LightboxSetting,
        PageSetting,
        ListSetting,
        ConditionSetting,
        AnimationSetting,
        ActionSetting,
        ActionPageSetting,
        LinkSetting,
        ImageSetting,
        ThemeSetting,
        StickySetting,
        ConnectToData,
        CountdownSetting,
        BorderSetting,
        SliderSetting
    },
    data() {
        return {
            activeSidebarIndex: 1,
            listSetting: [],
            blockSelected: {},
            idSl: ""
        };
    },
    watch: {
        "$store.state.editor.blockSelected": function (val) {
            if (val && val.id && val.id != this.idSl) {
                this.idSl = val.id;
                this.blockSelected = null;
                this.blockSelected = $.extend(true, {}, val);
                this.getListSetting();
            } else {
                if (val && val.id == this.idSl) {
                    this.blockSelected = val;
                    //this.blockSelected = $.extend(true, {}, val);
                } else {
                    this.idSl = "";
                    this.blockSelected = undefined;
                    this.listSetting = []
                }
            }
        },
        "$store.state.editor.dbclickBlock": function (val) {
            if (val) {
                this.activeSidebarIndex = 2;
            } else {
                this.activeSidebarIndex = 1;
            }
        }
    },
    computed: {
    },
    methods: {
        handleNewPage() {
            $("#show-modal-add-screen").modal("show");
        },
        deleteBlock() {
            const thisVue = this;

            if (!this.blockSelected) return;
            this.$swal({
                title: "",
                text: "You won't be able to revert this block.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove"
            }).then((result) => {
                if (result.value) {
                    thisVue.$store.commit("editor/setActionEditor", {
                        type: "removeBlock",
                        value: thisVue.blockSelected
                    });
                }
            });
            $("#bt-layout-right .delete-block-btn").trigger("mouseleave");
        },
        duplicateBlock: function () {
            if (!this.blockSelected) return;
            this.$store.commit("editor/setActionEditor", {
                type: "duplicateBlock",
                value: this.blockSelected
            });
        },
        showCssAdvance() {
            if (!this.blockSelected) return;

            $("#css-advance").modal("show");
        },
        changeSidebarSection(index) {
            this.activeSidebarIndex = index;
        },
        getListSetting() {
            this.listSetting = [];
            let compName = this.blockSelected.componentName;
            let objectSetting = {
                blockColumnOnly: ["column-st", "background-st"],
                blockText: ["text-st", "action-st", "border-st"],
                blockButton: ["text-st", "button-st", "action-st", "background-st","border-st"],
                blockImage: ["action-st", "image-st", "border-st"],
                blockVideo: ["video-st"],
                blockInput: ["text-st", "input-st","background-st", "border-st"],
                blockTextarea: ["text-st", "input-st","background-st", "border-st"],
                blockCheckbox: ["text-st", "input-st", "action-st"],
                blockRadio: ["text-st", "input-st", "action-st"],
                blockList: ["list-st", "action-st", "button-st", "background-st"],
                blockSelect: ["text-st", "input-st", "action-st","border-st", "background-st"],
                blockHtml: [],
                blockSvg: ["shape-st", "action-st"],
                blockLightBox: ["column-st", "lightbox-st"],
                blockGroup: ["column-st"],
                blockGroupTmp: [],
                blockCountdown: ["text-st", "countdown-st", "action-st"],
                blockForm: [],
                blockSlider: ["slider-st"]
            };
            this.listSetting = compName ? objectSetting[compName] : [];
        },
        updatePageAction(val) {
            this.$emit("updatePageAction", val);
        },
        showImageManager(val) {
            this.$emit("showImageManager", val);
        },
        moveUpBlock() {
            if (!this.blockSelected) return;
            this.$emit("moveUpBlock", this.blockSelected);
        },
        moveDownBlock() {
            if (!this.blockSelected) return;
            this.$emit("moveDownBlock", this.blockSelected);
        },
    },
    mounted() {
        let blockSelected = this.$store.state.editor.blockSelected;

        this.idSl = blockSelected && blockSelected.id;
        this.blockSelected = blockSelected;
        //this.getListSetting();
        $(document).on("click", function(ev) {
            var target = $(ev.target);
            var par = target.parent();
            if (target.hasClass("apo-dropdown-list") || 
            target.hasClass("apo-dropdown-val") || 
            target.hasClass("apo-dropdown") || 
            par.hasClass("apo-dropdown-list") || 
            par.hasClass("apo-dropdown-val") || 
            par.hasClass("apo-dropdown")) {} else {
                $(".apo-dropdown-list").hide();
            }
        })
    },
    beforeDestroy: function() {
        this.activeSidebarIndex = 1
        this.listSetting = [];
        this.blockSelected = {}
        this.idSl = "";
    }
};
