import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import { utils } from "@/libs-core/utils.min.js";

export default {
    components: {
        ColorPicker
    },
    data() {
        return {
            componentName: "",
            block: {},
            idSL: "",
            listItemSlider: [{ src: "" }],
            timeShow: new Date().getTime(),
            indexSl: 0,
            dataCol: 1,
            typeSlide: "slide",
            tyle: 70
        };
    },
    watch: {
        "$store.state.editor.blockSelected": function (val) {
            if (val && val.id != this.idSL && val.componentName === "blockSlider") {
                this.idSL = val.id;
                this.block = $.extend(true, {}, val);
                this.listItemSlider = this.block.listItemSlider || [{ src: "" }];
                this.dataCol = this.block.dataCol || 1;
                this.typeSlide = this.block.typeSlide || "slide";
                this.tyle = this.block.tyleSlide || 50
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                    this.listItemSlider = this.block.listItemSlider || [{ src: "" }];
                    this.dataCol = this.block.dataCol || 1;
                    this.typeSlide = this.block.typeSlide || "slide";
                    this.tyle = this.block.tyleSlide || 50
                }
            }
        },
        "dataCol": function (val) {
            this.block.dataCol = val;
            $("#"+this.block.id+" .list-img").attr("data-col", this.block.dataCol);
            this.updateBlock(this.block, "sliderst");
        },
        "typeSlide": function (val) {
            this.block.typeSlide = val;
            $("#"+this.block.id).attr("data-type",this.typeSlide);
            this.updateBlock(this.block, "sliderst");
        },
        "tyle": function(val) {
            this.block.tyleSlide = val;
            $("#"+this.block.id+" .list-img").css({'height': (100 - val)+"%"});
            $("#"+this.block.id+" .img-show").css({'height': val+"%"});
            this.updateBlock(this.block, "sliderst");
        }
    },
    methods: {
        updateBlock(val, type) {
            this.$store.dispatch("editor/updateBlock", { val: val, type: type });
            this.renderListImage();
        },
        showImageManager(vt) {
            this.indexSl = vt;
            this.timeShow = new Date().getTime();
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            $("#modal-upload-image-manager").css("z-index", "9999999999");
            $("#modal-upload-image-manager").modal("show");
        },
        addItemSlider: function () {
            this.listItemSlider.push({ src: "" });
            this.block.listItemSlider = $.extend(true, [], this.listItemSlider);
            this.updateBlock(this.block, 'sliderst');
        },
        deleteItem: function (index) {
            this.listItemSlider.splice(index, 1);
            this.block.listItemSlider = $.extend(true, [], this.listItemSlider);
            this.updateBlock(this.block, 'sliderst');
            this.renderListImage();
        },
        changeSlider: function (vt) {
            $("#" + this.block.id + " img.img-show").attr("src", this.listItemSlider[vt].src);
            this.block.listItemSlider = $.extend(true, [], this.listItemSlider);
            this.updateBlock(this.block, "sliderst");
        },
        closeDrop: function (event) {
            $(event.target).hide();
        },
        renderListImage: function() {
            var html = "";
            if (this.listItemSlider && this.listItemSlider.length > 0) {
                for (var i = 0; i < this.listItemSlider.length; i++) {
                    html += `<img src="` + this.listItemSlider[i].src + `" data-show="` + i + `" class="item-slider" style="display:`;
                    if (!this.dataCol) {
                        this.dataCol = 1;
                    }
                    if (i < this.dataCol) {
                        html += "block";
                    } else {
                        html += "none";
                    }
                    html += `"/>`;
                }
                $("#"+this.block.id+" .list-img").html(html);
            }
        }
    },
    mounted() {
        var block = this.$store.state.editor.blockSelected;
        if (block && block.id) {
            this.block = block;
            this.idSL = block.id;
            this.listItemSlider = this.block.listItemSlider || [{ src: "" }];
            this.dataCol = this.block.dataCol || 1;
            this.typeSlide = this.block.typeSlide || "slide";
            this.tyle = this.block.tyleSlide || 50
        }
        const thisVue = this;
        $("#modal-upload-image-manager").on("hidden.bs.modal", function () {
            if (thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp) {
                thisVue.listItemSlider[thisVue.indexSl].src = thisVue.$store.state.editor.dataAppSelect.value;
                thisVue.block.listItemSlider = $.extend(true, [], thisVue.listItemSlider);
                $("#" + thisVue.block.id + " img.img-show").attr("src", thisVue.listItemSlider[thisVue.indexSl].src);
                thisVue.updateBlock(thisVue.block, "sliderst");
                thisVue.renderListImage();
            } else {
                console.log("hidden");
            }
        });
        $(".apo-dropdown").off("click").on("click", function () {
            $(".apo-dropdown-list").hide();
            $(this).parent().find(".apo-dropdown-list").show();
        });
        $(".apo-dropdown").off("blur").on("blur", function () {
            $(".item-q-setting").hide();
            $("#quick-setting-block .item").removeClass("active");
        })
    }
};
