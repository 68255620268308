import { render, staticRenderFns } from "./save-template.html?vue&type=template&id=08f483d0&scoped=true&"
import script from "./save-template.js?vue&type=script&lang=js&"
export * from "./save-template.js?vue&type=script&lang=js&"
import style0 from "./save-template.vue?vue&type=style&index=0&id=08f483d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f483d0",
  null
  
)

export default component.exports