import resfult from "@/services/resful.js";
import { utils } from "@/libs-core/utils.min.js";

export const service = {
    getAllData: async function(data, next) {
        let url = "/app/functions?" + utils.jsonToParams(data);
        let res = await resfult.get(url);
        next(res);
    },
    getApi: async function(data, next) {
        let res = await resfult.get("/app/api?" + utils.jsonToParams(data));
        next(res);
    }
};
