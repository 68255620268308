import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import ImagePicker from "@/components/editor-v3/setting-blocks/image-picker/image-picker.vue";

export default {
    components: {
        ColorPicker,
        ImagePicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            idSL: "",
            device: "",
            effectBlock: {},
            listEffectBlock: [],
            effectProperty: {},

            // BACKGROUND DATA
            bgType: "color",
            backgroundImageType: "0",
            backgroundImageRepeat: "0",
            isGraPicker: "",
            backgroundColor: "#FFFFFF",
            overlayBackgroundImage: "",
            colorOverlay: "",
            backgroundImageUrl: "",
            backgroundData: {},
            isOverlayGraPicker: ""
        };
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (
                val &&
                val.componentName === "blockLightBox" &&
                (val.id !== this.idSL || !_.isEqual(val.background, this.backgroundData))
            ) {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;

                this.getInitialData();
                if (val && val[this.device] && val[this.device].background) {
                    this.backgroundData = $.extend(true, {}, val[this.device].background);
                } else {
                    this.backgroundData = {};
                    this.block.mobile.background = {};

                    this.isGraPicker = false;
                    this.isOverlayGraPicker = true;
                }
            }
        },
        "$store.state.editor.device": function(val) {
            this.device = val;
        }
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager", {
                type: "background",
                dataComp: this.block
            });
        },
        updateColor(color) {
            if (!this.block[this.device]) {
                this.block[this.device] = {};
                this.block[this.device].background = {};
            } else {
                if (!this.block[this.device].background) {
                    this.block[this.device].background = {};
                }
            }

            this.backgroundColor = color;
            this.block[this.device].background.isGraPicker = this.isGraPicker;
            this.block[this.device].background.backgroundColor = color;
            this.backgroundData = this.block[this.device].background;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateOverlayColor(color) {
            if (this.block && this.backgroundData) {
                this.colorOverlay = color;
                this.backgroundData.colorOverlay = color;

                if (!this.isOverlayGraPicker) {
                    color = `linear-gradient( ${color}, ${color})`;
                }

                this.overlayBackgroundImage = color;
                this.backgroundData.overlayBackgroundImage = color;
                this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                this.block[this.device].background = this.backgroundData;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        setBgType(val) {
            if (this.block && this.backgroundData) {
                this.bgType = val;
                this.backgroundData.backgroundType = val;
                this.block[this.device].background.backgroundType = val;

                this.updateBlock(this.block, "backgroundSt");
            }
        },
        updateColorEffect(color) {
            if (!this.effectProperty.boxShadow) {
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
            this.effectProperty.boxShadow[4] = color;
            this.updateEffectConfig();
        },
        updateEffectConfig() {
            let boxShadow = "";
            let blur = "";
            let boxShadowEffect = this.effectProperty.boxShadow;

            if (boxShadowEffect) {
                this.effectProperty.boxShadow.forEach((item, index) => {
                    if (index === 4) {
                        if (!item) {
                            item = "#000000";
                        }
                        boxShadow += item;
                        return;
                    }
                    if (!boxShadowEffect[index]) {
                        boxShadowEffect[index] = 0;
                        item = 0;
                    }
                    boxShadow += item + "px ";
                });
            }
            if (this.effectProperty.blur) {
                blur = this.effectProperty.blur + "px";
            }
            this.effectBlock = {
                boxShadow,
                blur
            };

            this.block[this.device].effect = this.effectBlock;
            this.updateBlock(this.block, "effectSt");
        },
        generateListEffect() {
            this.listEffectBlock = [];
            let convertEffectProperty = (item) => {
                if (item === "blur") {
                    this.effectProperty.blur = parseFloat(this.effectBlock.blur);
                    return;
                }
                let propertyArray = this.effectBlock.boxShadow.split(" ");
                this.effectProperty.boxShadow = propertyArray.map((i, ind) => {
                    if (ind === 4) return i;
                    return parseFloat(i);
                });
            };
            for (let item in this.effectBlock) {
                if (this.effectBlock[item]) {
                    this.listEffectBlock.push(item);
                    convertEffectProperty(item);
                }
            }
        },
        updateBgImageData(val) {
            if (!val) return;
            this.backgroundData.backgroundImageUrl = val.backgroundImageUrl;
            this.backgroundData.backgroundImageRepeat = val.backgroundImageRepeat;
            this.backgroundData.backgroundImageType = val.backgroundImageType;
            this.block[this.device].background = this.backgroundData;

            this.updateBlock(this.block, "backgroundSt");
        },
        updateBlock(val, type) {
            this.block = val;
            if (type === "borderSt") {
                val.border.borderStyle = "solid";
                val[this.device].border.borderStyle = "solid";
            }
            this.$store.dispatch("editor/updateBlock", { val: this.block, type: type });
        },
        getInitialData() {
            let backgroundData = this.backgroundData;

            this.bgType = backgroundData.backgroundType || "color";
            this.isGraPicker = backgroundData.isGraPicker || false;
            this.backgroundColor = backgroundData.backgroundColor || "#FFFFFF";
            this.overlayBackgroundImage = backgroundData.overlayBackgroundImage || "#00000000";
            this.colorOverlay = backgroundData.colorOverlay || "#00000000";
            this.backgroundImageUrl = backgroundData.backgroundImageUrl || "";
            this.isOverlayGraPicker = backgroundData.isOverlayGraPicker || false;
            this.backgroundImageType = backgroundData.backgroundImageType || "0";
            this.backgroundImageRepeat = backgroundData.backgroundImageRepeat || "0";

            this.effectBlock = this.block[this.device].effect || { boxShadow: "", blur: "" };
            this.generateListEffect();
        },
        handleClickRemove(type, item) {
            if (type === "effect") {
                this.listEffectBlock = this.listEffectBlock.filter((i) => i !== item);
                this.effectProperty[item] = "";
                this.updateEffectConfig();
            }
        },
        handleClickAdd(type, item) {
            if (type === "effect") {
                if (this.listEffectBlock.length === 2) return;
                if (this.listEffectBlock.includes("boxShadow")) {
                    this.listEffectBlock.push("blur");
                    this.effectProperty.blur = 0;
                    return;
                }
                this.listEffectBlock.push("boxShadow");
                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            let topPosition = event.target.getBoundingClientRect().top;
            let leftPosition = event.target.getBoundingClientRect().left;
            let windowHeight = window.innerHeight;
            let elementHeight;
            let selector;
            let topSet;
            let leftSet;
            switch (nameSection) {
                case "fold":
                    if ($("#lightbox-st").hasClass("fold-section")) {
                        $("#lightbox-st").removeClass("fold-section");
                    } else {
                        $("#lightbox-st").addClass("fold-section");
                    }
                    break;
                case "style-color":
                    $("#lightbox-st .fill-bg .list-style-fill").toggle();
                    if (val === "image") {
                        this.isGraPicker = "";
                        this.setBgType("image");
                        this.backgroundData.backgroundType = "image";
                    } else {
                        this.isGraPicker = val === "gradient" ? true : false;
                        this.setBgType("color");
                        this.backgroundData.backgroundType = "color";
                    }
                    this.backgroundData.isGraPicker = this.isGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "style-overlay":
                    $("#lightbox-st .fill-bg .list-style-overlay").toggle();
                    this.isOverlayGraPicker = val === "gradient" ? true : false;

                    this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                    this.block[this.device].background = this.backgroundData;
                    this.updateBlock(this.block, "backgroundSt");
                    break;
                case "effect":
                    $(`#lightbox-st .effect-lightbox .effect:nth-child(${index + 1}) .list-option`).toggle();

                    if (item === val || this.listEffectBlock.includes(val) || !val) return;
                    this.listEffectBlock = this.listEffectBlock.map((i) => {
                        if (i === item) {
                            this.effectProperty[item] = "";
                            if (val === "blur") {
                                this.effectProperty.blur = 0;
                            } else {
                                this.effectProperty.boxShadow = [0, 0, 0, 0, "#000000"];
                            }
                            return val;
                        }
                        return i;
                    });
                    break;
                case "edit-effect":
                    selector = `#lightbox-st .effect-lightbox .effect:nth-child(${index + 1})`;
                    if ($(selector).hasClass("popup-show")) {
                        $(selector).removeClass("popup-show");
                        $(selector + " .edit-popup").hide();
                        this.updateEffectConfig();
                        return;
                    } else {
                        $(selector).addClass("popup-show");
                        $(selector + " .edit-popup").show();
                    }
                    $(".edit-popup").removeClass("no-after");
                    if (windowHeight < topPosition + 290 && item === "boxShadow") {
                        topSet = windowHeight - 300;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else if (windowHeight < topPosition + 100 && item === "blur") {
                        topSet = windowHeight - 110;
                        leftSet = leftPosition - 10;
                        $(".edit-popup").addClass("no-after");
                    } else {
                        topSet = topPosition - 7;
                        leftSet = leftPosition - 10;
                    }
                    $(".edit-popup").css({
                        top: `${topSet}px`,
                        left: `${leftSet}px`
                    });
                    break;
            }
        }
    },
    mounted() {
        this.blockSelected = this.$store.state.editor.blockSelected || {};
        this.device = this.$store.state.editor.device;
        this.block = this.blockSelected;
        if (this.blockSelected && this.blockSelected[this.device]) {
            this.getInitialData();
            if (this.blockSelected[this.device].background) {
                this.backgroundData = this.blockSelected[this.device].background;
            } else {
                this.block.mobile.background = {};
                this.backgroundData = {};
                this.bgType = "color";
                this.backgroundImageType = "0";
                this.backgroundImageRepeat = "0";
                this.backgroundColor = "rgba(0,0,0,0)";
            }
        }
    }
};
