import resfult from "../../../../services/resful.js";
export const sevice = {
  createConfig: async function(data, next) {
    let res = await resfult.post("/app/create_template_api", data);
    next(res);
  },
  getAllConfig: async function(data, next) {
    let res = await resfult.get("/app/template_api", data);
    next(res);
  },
  encrypt: async function(data, next) {
    let res = await resfult.get("/app/encrypt?key=" + data.key + "&value=" + data.value, data);
    next(res);
  },
  decrypt: async function(data, next) {
    let res = await resfult.get("/app/decrypt?hash=" + data.hash, data);
    next(res);
  }
};
