import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import ImagePicker from "@/components/editor-v3/setting-blocks/image-picker/image-picker.vue";

export default {
    components: {
        ColorPicker,
        ImagePicker
    },
    data() {
        return {
            bgType: "color",
            isGraPicker: "",
            backgroundColor: "#FFFFFF",
            overlayBackgroundImage: "",
            colorOverlay: "",
            bgImageUrl: "",
            bgImageType: "0",
            bgImageRepeat: "0",
            backgroundData: {},
            imageBgData: {},
            isOverlayGraPicker: "",
            top: 0,
            page: ""
        };
    },
    watch: {
        "$store.state.editor.pageSelect": function(val) {
            if (val && val != this.page) {
                this.page = val;
            }
        },
        "$store.state.editor.timeUpdate": function() {
            this.getInitialData();
        },
        "$store.editor.backgroundPage"(val, oldVal) {
            if (!_.isEqual(val, oldVal)) {
                this.getInitialData();
            }
        },
        "top": function(val) {
            this.top = val;
            this.backgroundData.top = this.top + "px";
            this.updateData();
        }
    },
    methods: {
        showImageManager() {
            this.$emit("showImageManager", {
                type: "background-page"
            });
        },
        updateColor(color) {
            if (!this.backgroundData) {
                this.backgroundData = {};
            }
            if (!this.backgroundData.bgType) {
                this.backgroundData.bgType = this.bgType;
            }
            this.backgroundColor = color;
            this.backgroundData.backgroundColor = color;

            this.updateData();
        },
        updateOverlayColor(color) {
            this.colorOverlay = color;
            this.backgroundData.colorOverlay = color;

            if (!this.backgroundData) {
                this.backgroundData = {};
            }
            if (!this.isOverlayGraPicker) {
                color = `linear-gradient( ${color}, ${color})`;
            }

            this.overlayBackgroundImage = color;
            this.backgroundData.overlayBackgroundImage = color;
            this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;

            this.updateData();
        },
        setBgType(val) {
            if (this.backgroundData) {
                this.bgType = val;
                this.backgroundData.bgType = val;
            }
        },
        updateBgImageData(val) {
            if (!val) return;
            this.backgroundData.bgImageUrl = val.backgroundImageUrl;
            this.backgroundData.bgImageRepeat = val.backgroundImageRepeat;
            this.backgroundData.bgImageType = val.backgroundImageType;

            this.updateData();
        },
        getInitialData() {
            let backgroundData = $.extend(true, {}, this.$store.state.editor.backgroundPage);
            this.backgroundData = backgroundData || {};
            if(!this.backgroundData.top || this.backgroundData.top == "0px") {
                this.top = 0;
            } else {
                this.top = parseFloat(this.backgroundData.top);
            }
            
            if (!backgroundData) {
                this.bgType = "color";
                this.backgroundColor = "#FFFFFF";
                this.colorOverlay = "#00000000";
                this.overlayBackgroundImage = "#00000000";
                this.bgImageRepeat = "0";
                this.bgImageUrl = "";
                this.bgImageType = "0";
                this.isGraPicker = false;
                this.isOverlayGraPicker = false;
                return;
            }
            this.bgType = backgroundData.bgType || "color";
            this.backgroundColor = backgroundData.backgroundColor || "#FFFFFF";
            this.colorOverlay = backgroundData.colorOverlay || "#00000000";
            this.overlayBackgroundImage = backgroundData.overlayBackgroundImage || "#00000000";
            this.bgImageRepeat = backgroundData.bgImageRepeat || "0";
            this.bgImageUrl = backgroundData.bgImageUrl || "";
            this.bgImageType = backgroundData.bgImageType || "0";
            this.isGraPicker = backgroundData.isGraPicker || false;
            this.isOverlayGraPicker = backgroundData.isOverlayGraPicker || false;

            this.imageBgData = {};
            this.imageBgData.backgroundImageUrl = backgroundData.bgImageUrl;
            this.imageBgData.backgroundImageRepeat = backgroundData.bgImageRepeat;
            this.imageBgData.backgroundImageType = backgroundData.bgImageType;
        },
        updateData() {
            this.$store.commit("editor/setBackgroundPage", this.backgroundData);
        },
        handleClickRemove(type, item) {
            if (type === "colorPicker") {
                if (typeof this.isOverlayGraPicker === "boolean") {
                    this.isOverlayGraPicker = "";
                    return;
                }
                this.isGraPicker = "";
            }
        },
        toggleSection(nameSection, event, val, item, index) {
            switch (nameSection) {
                case "fold":
                    if ($("#page-st").hasClass("fold-section")) {
                        $("#page-st").removeClass("fold-section");
                    } else {
                        $("#page-st").addClass("fold-section");
                    }
                    break;
                case "style-color":
                    $("#page-st .fill-bg .list-style-fill").toggle();
                    if (val === "image") {
                        this.isGraPicker = "";
                        this.setBgType("image");
                    } else {
                        this.isGraPicker = val === "gradient" ? true : false;
                        this.setBgType("color");
                    }
                    this.backgroundData.isGraPicker = this.isGraPicker;
                    this.updateData();
                    break;
                case "style-overlay":
                    $("#page-st .fill-bg .list-style-overlay").toggle();
                    this.isOverlayGraPicker = val === "gradient" ? true : false;

                    this.backgroundData.isOverlayGraPicker = this.isOverlayGraPicker;
                    this.updateData();
                    break;
            }
        }
    },
    mounted() {
        this.page = this.$store.state.editor.pageSelect;
        this.getInitialData();
    }
};
