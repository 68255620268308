import { COMMON } from "../../../../constants/common.js";
import { renderHtml } from "@/libs-core/render-html.min.js";
import { serive } from "./setting-publish.service.js";
import { utils } from "@/libs-core/utils.min.js";
export default {
  props: [
    "infoApp",
    "listModel",
    "domain",
    "settingPage",
    "timePublish",
  ],
  data: function () {
    return {
      contentView: "preview",
      domainPage: "",
      html: "",
      status_deploy: false,
      css: "",
      htmlPublish: "",
      listFunction: [],
      listTracking: [],
      activeContent: "0",
      namePage: "",
      user: {},
      check_domain: true,
      publishCustom: false,
      dns_txt: '',
      preview_domain_page: '',
      listApiTemplate: [],
      typePublish: "botup",
      listPage: [],
      checkloadfn: 1
    };
  },
  watch: {
    domain: function () {
      this.domainPage = this.domain;
    },
    timePublish: function () {
      this.domainPage =
        this.domain || COMMON.URL_PAGE_DEMO + this.$route.params.id;
    },
    domainPage: function (val) {
      if (!val) return
      if (val.indexOf('http://') !== 0 && val.indexOf('https://') !== 0)
        this.preview_domain_page = 'http://' + val
      else
        this.preview_domain_page = val
    }
  },
  methods: {
    copylink: function () {
      utils.copyToClipboard("#txt-record");
    },
    showuptomarket: function () {
      this.$emit("appuptomarket");
      $("#modal-setting-publish").modal("hide");
    },
    checkDomain: function () {
      var thisVue = this;
      serive.checkdomain({ domain: this.domainPage }, function (data) {
        if (data && data.code === 200 && data.data.status) {
          thisVue.check_domain = false;
        } else {
          thisVue.$swal("", "You need login to panel domain then create a new DNS Record 'CNAME' point to cname.appon.vn !");
        }
      });
    },
    generateDnsTxt: function () {
      var thisVue = this;
      serive.generateDnsTxt({ domain: this.domainPage }, function (data) {
        if (data && data.code === 200 && data.data.txt) {
          thisVue.$swal("", "Create a TXT Record with name '@' and content: " + data.data.txt);
          thisVue.dns_txt = data.data.txt
        } else {
          thisVue.$swal("", "The domain name you entered is incorrect!");
        }
      })
    },
    showContent: function (val) {
      this.contentView = val;
    },
    openningSoon: function () {
      this.$swal("", "This feature openning soon!");
    },
    publishStudio: function () {
      var thisVue = this;
      var runp = setInterval(function () {
        if (thisVue.checkloadfn == 4) {
          clearInterval(runp);
          var keyPublish = new Date().getTime();
          localStorage.setItem("keyPublish", keyPublish);
          thisVue.status_deploy = true;
          var data = [];
          var listNamePage = [];
          thisVue.listPage = thisVue.$store.state.editor.listPage;

          thisVue.listPage.find(function (e) {
            if (e.name != "home") {
              listNamePage.push(e.name);
            }
          })
          thisVue.listPage.find(function (x) {
            if (x.api && x.api.length > 0) {
              for (var i = 0; i < x.api.length; i++) {
                x.api[i].dataAction = thisVue.resetApiAction(x.api[i].dataAction);
              }
            }
            x.page_action = thisVue.resetApiAction(x.page_action);
            var item = {
              source: renderHtml.renderComponent(
                x.api,
                thisVue.$route.params.id,
                thisVue.listModel,
                x.page_action,
                x.style,
                thisVue.settingPage,
                x.name,
                listNamePage
              ),
              path: "/src/components/" + x.name + ".vue"
            };
            data.push(item);
          });
          data.push({
            path: "/src/main.js",
            source: renderHtml.renderMainJs(thisVue.listPage, thisVue.$route.params.id)
          });
          if (thisVue.publishCustom) {
            var dataPublish = {
              data: data,
              domain: thisVue.domainPage
            }
          }
          data.push({
            path: "/public/apifunction" + keyPublish + ".js",
            source: renderHtml.renderApiJs(thisVue.listApiTemplate)
          });
          data.push({
            path: "/public/function" + keyPublish + ".js",
            source: renderHtml.renderFunctionJs(thisVue.listFunction, thisVue.$route.params.id)
          });
          data.push({
            path: "/public/tracking" + keyPublish + ".js",
            source: renderHtml.renderTrackingJs(thisVue.listTracking)
          });
          // TODO pwa
          data.push({
            path: "/public/manifest.json",
            source: renderHtml.renderManifestJson(thisVue.settingPage)
          });
          data.push({
            path: "/public/robots.txt",
            source: renderHtml.renderRobotsTxt()
          })
          data.push({
            path: "/public/service-worker.js",
            source: renderHtml.renderRSW()
          })
          // TODO end pwa
          if (thisVue.publishCustom) {
            thisVue.$emit("publish", dataPublish);
          } else {
            thisVue.$emit("publish", data);
          }
          thisVue.check_domain = true;
        }

      }, 50);
      
    },
    publishCustomDomain: function () {
      var thisVue = this;
      thisVue.check_domain = false;
      serive.checkdomain({ domain: this.domainPage }, function (data) {
        if (data && data.code === 200 && data.data.status) {

          thisVue.publishCustom = true;
          thisVue.publishStudio();
        } else {
          thisVue.$swal("", "You need login to panel domain then create a new DNS Record 'CNAME' point to cname.appon.vn !");
          thisVue.check_domain = true;
        }
      });
    },
    resetApiAction: function (action) {
      var thisVue = this;
      if (action && action.length > 0) {
        for (var i = 0; i < action.length; i++) {
          action[i].config = $.extend(true, {}, action[i].data);
          if (action[i] && action[i].type === "api") {
            var apiSelect = thisVue.listApiTemplate.find(x => x.name === action[i].data.api_select.name);
            action[i].data.api_select = {};
            if (apiSelect) {
              action[i].data.api_select = $.extend(true, {}, apiSelect);
              action[i].data.api_select.createdAt = "";
              action[i].data.api_select.page = "";
              action[i].data.api_select.updatedAt = "";
              action[i].data.api_select.user = "";
              if (apiSelect.configs) {
                action[i].data.security = apiSelect.configs.security;
                action[i].data.method = apiSelect.configs.method;
              } else {
                action[i].data.security = "normal";
                action[i].data.method = "GET";
              }
              action[i].config = $.extend(true, {}, action[i].data);
            }
          }
          if (i === action.length - 1) {
            return action;
          }
        }
      } else {
        return action;
      }
    },
    getAllApiTemplate: function () {
      var thisVue = this;
      serive.getAllConfig(
        {
          page: thisVue.$route.params.id
        },
        function (data) {
          thisVue.listApiTemplate = data.data;
          thisVue.checkloadfn++;
        }
      );
    },
    getAllConfigTrackingFunc: function (type) {
      const thisVue = this;
      serive.getAllConfigTrackingFunc(JSON.stringify({ type: type, page: thisVue.$route.params.id }), function (
        data
      ) {
        if (data && data.code === 200) {
          if (type == "func") {
            thisVue.listFunction = data.data;
          } else {
            thisVue.listTracking = data.data;
          }
        }
        thisVue.checkloadfn++;
      });
    },
    actionPublish: function () {
      if (this.typePublish == "botup") {
        this.publishStudio();
      } else {
        this.publishCustomDomain();
      }
    }
  },
  mounted: function () {
    this.domainPage = this.domain;
    var thisVue = this;
    if (!this.domainPage) {
      this.domainPage = COMMON.URL_PAGE_DEMO + this.$route.params.id;
    }
    $("#modal-setting-publish").on("shown.bs.modal", function () {
      thisVue.checkloadfn = 1;
      thisVue.user = JSON.parse(localStorage.getItem("user"));
      thisVue.domainPage = "";
      thisVue.status_deploy = false;
      thisVue.domainPage = thisVue.domain;
      if (!thisVue.domainPage) {
        thisVue.domainPage = COMMON.URL_PAGE_DEMO + thisVue.$route.params.id;
      }
      if (thisVue.domainPage.search("app.appon.vn") === -1) {
        thisVue.check_domain = true;
      } else {
        thisVue.check_domain = true;
      }
      thisVue.publishCustom = false;
      thisVue.listPage = $.extend(true, [], thisVue.$store.state.editor.listPage);
      thisVue.getAllApiTemplate();
      thisVue.getAllConfigTrackingFunc("func");
      thisVue.getAllConfigTrackingFunc("tracking");
    });
  }
};
