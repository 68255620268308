/* eslint-disable no-undef */import VueTribute from "vue-tribute";
import constant from "../../../../constants/index.js";
import { utils } from "@/libs-core/utils.min.js";

export default {
  components: {
    VueTribute
  },
  props: {
    dataComp: {
      type: Object
    },
    pos: {
      type: Object
    }
  },
  data: function () {
    return {
      options_tribute: constant._tribute.options($listModel),
      dataBlock: {},
      content: "Some initial content",
      idSL: ""
    };
  },
  watch: {
    "$store.state.editor.blockSelected"(val) {
      if (val && val.id !== this.idSL) {
        this.dataBlock = val;
        this.idSL = this.dataBlock.id;
        this.content = this.dataBlock.html;
      }
    },
  },
  methods: {
    textReplacedTribute(e) {
      this.content = e.target.innerHTML;
    },
    saveContent: function () {
      var html = $("#text-edit").html();
      this.dataBlock.html = utils.parseModel(html);
      this.$emit("updateDataComp", this.dataBlock);
      $("#" + this.dataBlock.id + " > .block-content").html(this.dataBlock.html);
      $(".editor-text").hide();
    },
    cancelContent: function () {
      $(".editor-text").hide();
    },
    cancelParentEvent: function (ev) {
      ev.preventDefault();
    }
  },
  mounted: function () {
  }
};
