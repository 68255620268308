import ItemPage from "./pageitem/pageitem.vue";
import { renderHtml } from "@/libs-core/render-html-preview.min.js";
import { previewSv } from "./preview.service.js";
export default {
    components: {
        ItemPage
    },
    props: ["listPage", "listModel", "listApiTemplate", "list_tracking", "list_function", "pageSelect", "settingPage"],
    data: function () {
        return {
            list_model: [],
            listApi_Template: [],
            list_page: [],
            page_select: {},
            pageSelectPreview: "home",
            timeChamge: new Date().getTime(),
            indexStr: ""
        }
    },
    watch: {
        pageSelect: function (val) {
            this.changePagePreview(val);
        }
    },
    methods: {
        refresh: function (ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.renderPagePreview();
        },
        renderPreview: function () {
            $('#view-app-editor .page-item-preview').hide();
            if ($("#view-app-editor").css("display") === "none") {
                // this.list_page = [];
                // var thisVue = this;
                // $(".loading-preview").show();
                // setTimeout(function () {
                //     thisVue.listApi_Template = thisVue.listApiTemplate;
                //     thisVue.list_model = $.extend(true, [], thisVue.listModel);
                //     thisVue.list_page = $.extend(true, [], thisVue.listPage);
                //     thisVue.page_select = thisVue.list_page.find(x => x.name === thisVue.pageSelectPreview);
                //     $('#view-app-editor .page-item-preview[page-name="' + thisVue.pageSelectPreview + '"]').show();
                //     $("#view-app-editor").show();
                //     $("#preview-botup").animate({ "right": "0" }, 500);
                //     $(".show-preview").addClass("active");
                //     thisVue.timeChamge = new Date().getTime();
                // });
                this.renderPagePreview();
            } else {
                $(".show-preview").removeClass("active");
                $("#view-app-editor").hide();
                $("#preview-botup").animate({ "right": "-400px" }, 500);
            }
        },
        changePagePreview: function (page) {
            this.pageSelectPreview = page;
            var thisVue = this;
            $(".loading-preview").show();
            setTimeout(function () {
                $('#view-app-editor .page-item-preview').hide();
                thisVue.listApi_Template = thisVue.listApiTemplate;
                thisVue.list_model = $.extend(true, [], thisVue.listModel);
                thisVue.list_page = $.extend(true, [], thisVue.listPage);
                thisVue.page_select = thisVue.list_page.find(x => x.name === thisVue.pageSelectPreview);
                $('#view-app-editor .page-item-preview[page-name="' + thisVue.pageSelectPreview + '"]').show();
                thisVue.timeChamge = new Date().getTime();
            });
        },
        renderPagePreview: function () {
            var indexStr = renderHtml.renderIndex(this.settingPage, this.listApiTemplate, this.list_tracking, this.list_function, this.listPage, this.$route.params.id, this.listModel);
            let iframe = document.getElementById('content-page-preview');
            let iframedoc = iframe.document;
            if (iframe.contentDocument) {
                iframedoc = iframe.contentDocument;
                console.log("iframe has contentDocument");
            }
            else if (iframe.contentWindow) {
                iframedoc = iframe.contentWindow.document;
                console.log("iframe has contentWindow.document");
            }
            if (iframedoc) {
                iframedoc.open();
                iframedoc.write(indexStr.index);
                iframedoc.close();
            } else {
                alert('Cannot inject dynamic contents into iframe.');
            }
            $(".loading-preview").hide();
            $('#view-app-editor .page-item-preview[page-name="' + this.pageSelectPreview + '"]').show();
            $("#view-app-editor").show();
            $("#preview-botup").animate({ "right": "0" }, 500);
            $(".show-preview").addClass("active");
        }
    },
    mounted: function () {
        $(".show-preview").removeClass("active");
        $("#view-app-editor").hide();
        $("#preview-botup").animate({ "right": "-400px" }, 500);
    }
}