import ColorPicker from "@/components/editor-v3/setting-blocks/color-picker/color-picker.vue";
import { utils } from "@/libs-core/utils.min.js";

export default {
    components: {
        ColorPicker
    },
    data() {
        return {
            blockSelected: {},
            componentName: "",
            block: {},
            idSL: "",
            device: "",
            imageSrc: "",
            timeShow: "",
            dataAppSelect: {},
            objectfit: ""
        };
    },
    computed: {
        borderTypeName() {
            let name = this.borderType.charAt(0).toUpperCase() + this.borderType.substring(1);
            return name;
        },
        borderStyleName() {
            let name = this.borderStyle.charAt(0).toUpperCase() + this.borderStyle.substring(1);
            return name;
        }
    },
    watch: {
        "$store.state.editor.blockSelected"(val) {
            if (val && val.id != this.idSL && val.componentName === "blockImage") {
                this.blockSelected = val;
                this.idSL = this.blockSelected.id;
                this.block = $.extend(true, {}, this.blockSelected);
                this.componentName = val.componentName;
                this.imageSrc = this.block.src;
                this.objectfit = this.block.objectfit || "fill";
            } else {
                if (val && val.id == this.idSL) {
                    this.block = $.extend(true, {}, val);
                    this.componentName = val.componentName;
                    this.imageSrc = this.block.src;
                    this.objectfit = this.block.objectfit || "fill";
                }
            }
        },
        "$store.state.editor.device": function (val) {
            this.device = val;
        },
        imageSrc: _.debounce(function (val) {
            $("#" + this.block.id + " .block-content img").attr("src", this.imageSrc);
            this.block.src = this.imageSrc;
            this.updateBlock(this.block, "imageSt");
        }, 300),
    },
    methods: {
        updateBlock(val, type) {
            this.$store.dispatch("editor/updateBlock", { val: val, type: type });
        },
        showImageManager() {
            this.$emit("showImageManager", {
                type: "image",
                dataComp: this.block
            });
        },
        showSelectDataApp() {
            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, this.block.dataInput);
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
        },
        setSrcImage(dataInput, idBlock) {
            if (dataInput.indexTab !== undefined && dataInput.keySelect && dataInput.indexKey !== undefined) {
                let dataApp = $dataApp && $dataApp.data ? $dataApp.data : [];
                let dataTab = dataApp[dataInput.indexTab];
                let src = "";

                if (dataTab && dataTab.data) {
                    let dataKey = dataTab.data[dataInput.indexKey];
                    src = dataKey[dataInput.keySelect] || "";
                }
                setTimeout(function () {
                    $("#" + idBlock + " .block-content img").attr("src", src);
                }, 500);
            }
        },
        closeDrop: function (event) {
            $(event.target).hide();
        },
        closeSt: function (event) {
            $("#image-st").parent().hide();
        },
        updateObj: function (val) {
            this.objectfit = val;
            this.block.objectfit = val;
            $("#" + this.block.id + " .block-content img").css({ "object-fit": val });
            this.updateBlock(this.block, "imageSt");
        }
    },
    mounted() {
        const thisVue = this;

        this.blockSelected = this.$store.state.editor.blockSelected;
        this.device = this.$store.state.editor.device;
        if (this.blockSelected && this.blockSelected.id) {
            this.block = this.blockSelected;
            this.componentName = this.block.componentName;
            this.imageSrc = this.block.src;
            this.objectfit = this.block.objectfit || "fill";
        }

        $("#select-data-app").on("hidden.bs.modal", function () {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.block.dataInput = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.block.dataInput.useValue = "model";
                thisVue.imageSrc = utils.getValueDataApp(
                    thisVue.block.dataInput.indexTab,
                    thisVue.block.dataInput.keySelect,
                    thisVue.block.dataInput.indexKey
                );
                thisVue.block.src = thisVue.imageSrc;
                thisVue.setSrcImage(thisVue.block.dataInput, thisVue.block.id);

                thisVue.updateBlock(thisVue.block, "imageSt");
            } else {
                console.log("hidden");
            }
        });
    }
};
