import { utils } from "@/libs-core/utils.min.js";

export default {
    props: ["dataAction", "indexAction"],
    data() {
        return {
            timeShow: 0,
            dataAppSelect: {},
            dataModelAction: {},
            dataModel: {},
            typeSetValue: "assign",
            valueSetModel: "",
            infoSetModel: {},
            type: ""
        };
    },
    computed: {
        computeTypeSet: function() {
            if (!this.typeSetValue) return "Select type set";
            const data = this.typeSetValue.charAt(0).toUpperCase() + this.typeSetValue.substring(1);
            return data;
        }
    },
    watch: {
        dataAction(val) {
            if (this.indexAction !== undefined) {
                this.dataModelAction = val[this.indexAction];
                this.dataModel = this.dataModelAction.data || {};
                this.dataAppSelect = this.dataModel.dataAppSelect || {};
                this.typeSetValue = this.dataModel.type_set_value || "assign";
                this.valueSetModel = this.dataModel.value_set_model || "";
                this.infoSetModel =  this.dataModel.dataAppSelect || {}
            }
        },
        valueSetModel: _.debounce(function(val) {
            this.dataModel.value_set_model = val;
            this.dataModelAction.data = this.dataModel;
            this.updateData();
        }, 300),
        // infoSetModel: _.debounce(function(val) {
        //     this.dataModel.dataAppSelect = val;
        //     this.dataModelAction.data = this.dataModel;
        //     this.updateData();
        // }, 300),
    },
    methods: {
        selectDataInfo: function(val) {
            if (val.indexTab === undefined) return val;
            if (!$dataApp || !$dataApp.data || $dataApp.data.length <= val.indexTab) return val;
            return utils.getValueDataApp(
                val.indexTab,
                val.keySelect,
                val.indexKey
            );
        },
        handleCreateNewModel() {
            $("#modal-manage-data").modal("show");
        },
        removeAction() {
            this.$emit("removeAction", this.indexAction);
        },
        updateData() {
            let dataAction = $.extend(true, [], this.dataAction);

            dataAction[this.indexAction] = this.dataModelAction;
            this.$emit("updateBlock", dataAction);
        },
        showSelectDataApp(val) {
            this.type = val;
            this.timeShow = new Date().getTime();
            this.dataAppSelect = $.extend(true, {}, this.dataModel.dataAppSelect);
            this.$store.commit("editor/mutaTimeShowDataApp", this.timeShow);
            this.$store.commit("editor/mutaDataAppSelect", {
                indexTab: this.dataAppSelect.indexTab,
                keySelect: this.dataAppSelect.keySelect,
                indexKey: this.dataAppSelect.indexKey,
                saveData: false
            });
            $("#select-data-app").modal("show");
        },
        toggleSection(nameSection, event, val) {
            let selector;
            switch (nameSection) {
                case "model":
                    $(`.list-action > div:nth-child(${this.indexAction + 1}) section .list-option`).toggle();

                    if (val) {
                        this.typeSetValue = val;
                        this.dataModel.type_set_value = val;
                        this.dataModelAction.data = this.dataModel;
                        this.updateData();
                    }
                    break;
                case "expand":
                    selector = `.list-action div:nth-child(${this.indexAction + 1}) #action-model`;

                    $(`.list-action div:not(:nth-child(${this.indexAction + 1})) section`).removeClass("expand");
                    if ($(selector).hasClass("expand")) {
                        $(selector).removeClass("expand");
                    } else {
                        $(selector).addClass("expand");
                    }
            }
        }
    },
    mounted: function() {
        var thisVue = this;

        if (this.dataAction && this.indexAction !== undefined) {
            this.dataModelAction = this.dataAction[this.indexAction] || {};
            this.dataModel = this.dataModelAction.data || {};
            this.dataAppSelect = this.dataModel.dataAppSelect || {};
            this.typeSetValue = this.dataModel.type_set_value || "assign";
            this.valueSetModel = this.dataModel.value_set_model || "";
            this.infoSetModel =  this.dataModel.dataAppSelect || {}
        }

        $("#select-data-app").on("hidden.bs.modal", function() {
            if (
                thisVue.timeShow === thisVue.$store.state.editor.timeShowDataApp &&
                thisVue.$store.state.editor.dataAppSelect.saveData
            ) {
                thisVue.dataAppSelect = $.extend(true, {}, thisVue.$store.state.editor.dataAppSelect);
                thisVue.dataAppSelect.useValue = "model";
                if (thisVue.type == "model") {
                    thisVue.infoSetModel = thisVue.dataAppSelect;
                    thisVue.dataModel.dataAppSelect = $.extend(true, {}, thisVue.dataAppSelect);
                } else {
                    thisVue.valueSetModel = thisVue.selectDataInfo(thisVue.dataAppSelect);
                    thisVue.dataModel.value_set_model = thisVue.selectDataInfo(thisVue.dataAppSelect);
                }
                thisVue.dataModelAction.data = thisVue.dataModel;
                thisVue.timeShow = new Date().getTime();
                thisVue.updateData();
            }
        });
    }
};
